import { ApiWorkspaceResponse, WorkspacesProject } from 'src/types/Workspace';

type getActiveProjectNameArgs = {
  workspaces?: ApiWorkspaceResponse[];
  projectId: number;
};

export const getProjectById = ({
  workspaces,
  projectId,
}: getActiveProjectNameArgs) => {
  if (!workspaces) {
    return null;
  }
  for (const workspace of workspaces) {
    const project = getProject({
      projects: workspace?.projects || [],
      projectId,
    });

    if (project) {
      return project;
    }
  }

  return null;
};

type getProjectNameArgs = {
  projects: WorkspacesProject[];
  projectId: number;
};

const getProject = ({ projects, projectId }: getProjectNameArgs) => {
  const project = projects.find((project) => {
    return project.id === projectId;
  });

  return project;
};
