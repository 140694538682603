import React from 'react';
import { Box } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { TableLoading } from 'src/components/TableLoading';

export function RolesFormSkeleton() {
  return (
    <Box maxWidth="400px" mb="5">
      <Box mb="5">
        <Input label="Role name" required />
      </Box>
      <TableLoading headers={['Permissions']} rowsAmount={7} />
    </Box>
  );
}
