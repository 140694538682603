import React from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { CheckIcon, Cross2Icon, ReloadIcon } from '@radix-ui/react-icons';
import './ValidationList.css';

type ValidationRule = {
  isValid: boolean;
  isLoading?: boolean;
  text: string;
};

type ValidationListProps = {
  data: ValidationRule[];
};

export function ValidationList({ data }: ValidationListProps) {
  return (
    <Text size="2" color="gray">
      <ul>
        {data.map((item, index) => (
          <Flex gap="2" align="center" key={index}>
            <ValidationListIcon
              isValid={item.isValid}
              isLoading={!!item?.isLoading}
            />{' '}
            {item?.text}
          </Flex>
        ))}
        <li></li>
      </ul>
    </Text>
  );
}

type ValidationListItemProps = {
  isLoading: boolean;
  isValid: boolean;
};

function ValidationListIcon({ isLoading, isValid }: ValidationListItemProps) {
  if (isLoading) {
    return (
      <Text
        color="gray"
        className="validation-list__icon validation-list__icon_loading"
      >
        <ReloadIcon width="18" height="18" />
      </Text>
    );
  }

  if (isValid) {
    return (
      <Text color="gray" className="validation-list__icon">
        <CheckIcon width="18" height="18" />
      </Text>
    );
  }

  return (
    <Text color="tomato" className="validation-list__icon">
      <Cross2Icon width="18" height="18" />
    </Text>
  );
}
