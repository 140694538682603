import { getOrderedTreeFromFlatData } from 'src/utils/getOrderedTreeFromFlatData';
import { getDynamicGroupsFromTree } from 'src/pages/item/utils/getDynamicGroupsFromTree';
import { removeDynamicGroupsChildrenFromTree } from 'src/pages/item/utils/removeDynamicGroupsChildrenFromTree';
import { TemplateField } from 'src/types/Template';
import { ResponseItemField } from 'src/types/Item';
import { assignItemAndTemplateIdsToFields } from './assignItemAndTemplateIdsToFields';

type GetTreeWithoutDynamicGroupChildrenArgs = {
  templateFields: TemplateField[];
  itemFields: ResponseItemField[];
};

export function getTreeWithoutDynamicGroupChildren({
  templateFields,
  itemFields,
}: GetTreeWithoutDynamicGroupChildrenArgs) {
  const updatedTemplateFields = assignItemAndTemplateIdsToFields({
    templateFields,
    itemFields: itemFields,
  });

  const initialTemplateTree = getOrderedTreeFromFlatData({
    fields: updatedTemplateFields,
  });
  const dynamicGroupsMap = getDynamicGroupsFromTree({
    tree: initialTemplateTree,
  });
  const treeWithoutDynamicGroupChildren = removeDynamicGroupsChildrenFromTree({
    treeData: initialTemplateTree,
  });

  return {
    dynamicGroupsMap,
    tree: treeWithoutDynamicGroupChildren,
  };
}
