import _ from 'lodash';

export function swapWithNextItem<T>(arr: T[], indexToSwap: number) {
  const clonedArray = _.cloneDeep(arr);

  if (indexToSwap >= 0 && indexToSwap < clonedArray.length - 1) {
    [clonedArray[indexToSwap], clonedArray[indexToSwap + 1]] = [
      clonedArray[indexToSwap + 1],
      clonedArray[indexToSwap],
    ];
  }

  return clonedArray;
}
