import { ENDPOINT } from './fetcher';

type GetImageUrlArg = {
  instanceId: number;
  imagePath?: string;
};

export function getImageUrl({ instanceId, imagePath }: GetImageUrlArg) {
  if (!imagePath) {
    return null;
  }
  return `${ENDPOINT}/instances/${instanceId}/image/${imagePath}`;
}
