import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, Flex, Text, Box, Button } from '@radix-ui/themes';
import b, { Mix, Mods } from 'bem-react-helper';
import { Checkbox } from 'src/components/Checkbox';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import * as Label from '@radix-ui/react-label';
import './ChangesTable.css';
import _ from 'lodash';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const getCheckboxId = (id: string) => {
  return `sync-template-${id}`;
};

type ChangesTableType = {
  checked: boolean;
  id: number;
  name: string;
};

const columnHelper = createColumnHelper<ChangesTableType>();

type RolesTableProps = {
  mods?: Mods;
  mix?: Mix;
  methods: UseFormReturn<FieldValues>;
  templateId: string;
  templateName: string;
  isTemplateUpdated: boolean;
  data: ChangesTableType[];
};

export function ChangesTable(props: RolesTableProps) {
  const { instanceId } = useInstanceContext();
  const { methods, templateId, templateName, data, isTemplateUpdated } = props;
  const { register, getValues, setValue } = methods;
  const fieldItemsName = `${templateId}.items`;
  const fieldSelectedAllName = `${templateId}.isSelectedAll`;

  const handleHeaderCheck = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      const items = getValues(fieldItemsName);

      const newItems = _.mapValues(items, () => isChecked);
      setValue(fieldItemsName, newItems);
    },
    [getValues, fieldItemsName, setValue]
  );

  const handleRowCheck = React.useCallback(() => {
    const isSelectedAll = getValues(fieldSelectedAllName);
    const items = getValues(fieldItemsName);
    const isAllChecked = Object.values(items).every((item) => item);

    if (isAllChecked !== isSelectedAll) {
      setValue(fieldSelectedAllName, isAllChecked);
    }
  }, [fieldSelectedAllName, getValues, fieldItemsName, setValue]);

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('checked', {
        meta: {
          className: 'changes-table__id-column',
        },
        cell: (info) => {
          const itemId = info.row.original.id;
          const name = `${fieldItemsName}.id-${itemId}`;

          return (
            <Checkbox
              mods={{ type: 'primary' }}
              {...register(name, { onChange: handleRowCheck })}
            />
          );
        },
        header: () => (
          <Checkbox
            mods={{ type: 'primary' }}
            {...register(fieldSelectedAllName, {
              onChange: handleHeaderCheck,
            })}
          />
        ),
      }),
      columnHelper.accessor('id', {
        cell: (info) => {
          const itemId = info.getValue();
          return <span>{itemId}</span>;
        },
        header: () => <span>ID</span>,
      }),
      columnHelper.accessor('name', {
        cell: (info) => {
          const itemId = info.row.original.id;
          const itemName = info.getValue();
          return (
            <Button
              asChild
              color="indigo"
              variant="ghost"
              className="changes-table__button-link"
              title="Open item in new window"
            >
              <RouterNavLink
                target="_blank"
                to={appRoutes.itemEdit({
                  instanceId,
                  templateId,
                  id: itemId.toString(),
                })}
              >
                <Flex as="span" align="center" gap="1" display="inline-flex">
                  {itemName} <OpenInNewWindowIcon width={12} />
                </Flex>
              </RouterNavLink>
            </Button>
          );
        },
        header: () => <span>Name</span>,
      }),
    ],
    [
      fieldItemsName,
      fieldSelectedAllName,
      handleHeaderCheck,
      handleRowCheck,
      register,
      instanceId,
      templateId,
    ]
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const templateCheckboxId = getCheckboxId(templateId);

  return (
    <>
      <Box mb="2">
        <Flex gap="3" align="center">
          {templateName}
          <Button
            asChild
            color="indigo"
            variant="ghost"
            className="changes-table__button-link"
            title="Open template in new window"
          >
            <RouterNavLink
              target="_blank"
              to={appRoutes.templateEdit({ instanceId, templateId })}
            >
              <Flex as="span" align="center" gap="1" display="inline-flex">
                [{templateId}] <OpenInNewWindowIcon width={12} />
              </Flex>
            </RouterNavLink>
          </Button>
        </Flex>
      </Box>

      {isTemplateUpdated && (
        <Flex gap="2" align="center" mb="2">
          {/*<Badge variant="soft" radius="large" color="orange">*/}
          {/*  template modified*/}
          {/*</Badge>*/}

          <Label.Root htmlFor={templateCheckboxId}>
            <Text size="2">Include template changes in sync</Text>
          </Label.Root>

          <Checkbox
            mods={{ type: 'primary' }}
            id={templateCheckboxId}
            {...register(`${templateId}.isTemplateIncluded`)}
          />
        </Flex>
      )}

      <Table.Root variant="surface" className={b('changes-table', props)}>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell
                  key={headerIndex}
                  className={header?.column?.columnDef?.meta?.className}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                No items to display. Only the template has been updated.
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
