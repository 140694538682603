import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiItemResponse } from 'src/types/Item';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const GET_ITEM_BY_ID_KEY = 'useItemById';

export const getItemById = (
  templateId: string,
  id: string,
  instanceId: number
): Promise<ApiItemResponse> => {
  return fetcher({ url: `/editor/${instanceId}/items/${templateId}/${id}` });
};

type UseItemByIdArgs = {
  id: string | undefined;
  templateId: string | undefined;
  options?: Omit<
    UseQueryOptions<ApiItemResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useItemById({ id, templateId, options }: UseItemByIdArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError } = useQuery({
    queryKey: [GET_ITEM_BY_ID_KEY, templateId, id, instanceId],
    queryFn: () =>
      templateId && id ? getItemById(templateId, id, instanceId) : undefined,
    enabled: !!templateId && !!id && instanceId !== -1,
    gcTime: 0, // disable cache by default to avoid problems in form edit
    meta: {
      name: 'Get item by id',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
  };
}
