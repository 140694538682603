import React from 'react';
import { checkAccess } from 'src/utils/checkAccess';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import { PermissionType } from 'src/constants/permissions';
import { useUserContext } from 'src/providers/UserProvider';
import { useParams } from 'react-router-dom';

type AccessProps = {
  children:
    | React.ReactNode
    | ((props: {
        hasAccess: boolean;
        userPermissions: PermissionType[];
      }) => React.ReactNode);
  roles: WorkspaceRolesType[];
  permissionsGroups?: PermissionType[][];
  ignorePermissions?: boolean;
  getRoleFromParams?: boolean;
};

export function Access({
  children,
  ignorePermissions,
  roles,
  permissionsGroups,
  getRoleFromParams,
}: AccessProps) {
  const { workspaceId } = useParams();
  const {
    role,
    permissions: userPermissions,
    workspacesRoleMap,
  } = useUserContext();
  if (getRoleFromParams && !workspaceId) {
    return null;
  }

  let userRole: WorkspaceRolesType | null;
  if (getRoleFromParams && workspaceId) {
    userRole = workspacesRoleMap.get(Number(workspaceId)) || null;
  } else {
    userRole = role;
  }

  if (!userRole) {
    return null;
  }

  const { hasPermission, hasRole } = checkAccess({
    ignorePermissions,
    userPermissions: userPermissions || [],
    userRole,
    roles,
    permissionsGroups,
  });

  const hasAccess = hasRole && hasPermission;

  if (!hasAccess) {
    return null;
  }

  if (typeof children === 'function') {
    return (
      <>{children({ hasAccess, userPermissions: userPermissions || [] })}</>
    );
  }

  return <>{children}</>;
}
