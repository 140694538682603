import { FIELD_TYPE } from 'src/constants/components';
import { TreeNode } from 'src/types/Template';

// Dynamic group can't be the only child on another dynamic group. With such structure there is no ability to manage item fields.
export function isDynamicGroupOnlyChildOfDynamicGroup(node: TreeNode) {
  const isDynamicGroup = node.settings.type === FIELD_TYPE.DYNAMIC_GROUP;
  if (!isDynamicGroup) {
    return false;
  }
  const children = node?.children;
  if (!children) {
    return false;
  }

  const isOneChild = children.length === 1;
  if (!isOneChild) {
    return false;
  }

  return children[0].settings.type === FIELD_TYPE.DYNAMIC_GROUP;
}
