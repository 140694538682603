import { ApiItemListResponse } from 'src/types/Item';
import { FIELD_TYPE } from 'src/constants/components';

type ExtractItemLinkAndItemLinkListValuesArgs = {
  itemsList: ApiItemListResponse;
  templateFieldsMap: Map<string, string>;
};

export function extractItemLinkAndItemLinkListValues({
  itemsList,
  templateFieldsMap,
}: ExtractItemLinkAndItemLinkListValuesArgs) {
  const valuesMap: Map<string, Set<number>> = new Map();

  itemsList.items.forEach((item) => {
    item.fields.forEach((field) => {
      const templateFieldId = templateFieldsMap.get(field.template_field_id);
      if (!templateFieldId) {
        return;
      }
      if (
        field.template_field_type === FIELD_TYPE.ITEM_LINK ||
        field.template_field_type === FIELD_TYPE.ITEM_LINK_LIST
      ) {
        const valuesSet = valuesMap.get(templateFieldId) || new Set();

        if (Array.isArray(field.value)) {
          field.value.forEach((num) => valuesSet.add(num));
        } else {
          valuesSet.add(field.value as number);
        }

        valuesMap.set(templateFieldId, valuesSet);
      }
    });
  });

  return valuesMap;
}
