import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { Toast } from 'src/components/Toast';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreateWorkspaceArgs,
  useWorkspaceCreate,
} from 'src/hooks/api/useWorkspaceCreate';
import { GET_WORKSPACE_ROLES_KEY } from 'src/hooks/api/useAccountWorkspacesRoles';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { AlertDialog } from 'src/components/AlertDialog';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Workspace name is a required field')
    .max(64, 'Maximum 64 characters allowed'),
});

type WorkspaceCreationProps = {
  trigger: React.ReactNode;
  needConfirmation?: boolean;
};

export function WorkspaceCreation({
  trigger,
  needConfirmation,
}: WorkspaceCreationProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { refetch: refetchWorkspaces } = useWorkspacesList();
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: createProject,
    isMutating,
    error,
  } = useWorkspaceCreate({
    onSuccess: () => {
      setIsPopoverOpen(false);
      Toast({
        text: 'Workspace created successfully',
        variant: 'info',
      });
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_ROLES_KEY],
      });
      refetchWorkspaces();
      setIsDialogOpen(false);
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleCreation: SubmitHandler<CreateWorkspaceArgs> = async (
    data: CreateWorkspaceArgs,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    createProject(data);
  };

  const handleConfirmationDialogOpen = (e?: React.BaseSyntheticEvent) => {
    e?.preventDefault();
    setIsDialogOpen(true);
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>{trigger}</Popover.Trigger>
        <Popover.Content minWidth="300px">
          <form
            onSubmit={
              needConfirmation
                ? handleConfirmationDialogOpen
                : handleSubmit(handleCreation)
            }
          >
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.name?.message}
              label="Workspace name"
              placeholder="Enter workspace name"
              data-testid="workpsace-name"
              required
              {...register('name')}
            />

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                color="plum"
                type="submit"
                loading={isMutating}
                data-testid="create-workspace-button"
              >
                Create workspace
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>

      <AlertDialog
        title="Confirm workspace creation"
        color="tomato"
        open={isDialogOpen}
        actionText="Yes, create workspace"
        cancelText="No, cancel"
        onCancel={() => setIsDialogOpen(false)}
        onAction={handleSubmit(handleCreation)}
        loading={isMutating}
      >
        {!!error && (
          <Box mb="4">
            <ErrorApi error={error} />
          </Box>
        )}
        Are you sure you want to create this new workspace? If you only need to
        add more projects, you can do so within your current workspace, which
        may be more cost-effective.
        <Box mt="2">
          Creating a new workspace will start with a trial plan, and after the
          trial ends, you'll need to select a subscription plan to continue
          using it. Charges will apply based on your chosen plan.
        </Box>
      </AlertDialog>
    </>
  );
}
