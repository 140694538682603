import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Page__Content, Page__Header } from 'src/components/Page';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { Text, Flex, Badge, Link, Box, Button } from '@radix-ui/themes';
import { RolesTable } from './components/RolesTable';
import { PlusIcon } from '@radix-ui/react-icons';
import { useWorkspaceRoles } from 'src/hooks/api/useWorkspaceRoles';
import { PLANS } from 'src/constants/plans';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { Callout } from 'src/components/Callout';
import { TableLoading } from 'src/components/TableLoading';

const PAGE_TITLE = 'Projects roles';

export function WorkspaceRoles() {
  const navigate = useNavigate();
  const { data, isLoading, error } = useWorkspacesList();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const {
    data: rolesData,
    isLoading: isRolesLoading,
    error: rolesError,
  } = useWorkspaceRoles({ workspaceId });

  const navigateToNewRolePage = () => {
    navigate(
      appRoutes.settingsProjectNewRole({
        workspaceId,
      })
    );
  };

  if (isLoading || isRolesLoading) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} />

        <Page__Content>
          <TableLoading headers={['ID', 'Name']} rowsAmount={1} />
        </Page__Content>
      </>
    );
  }

  if (error || rolesError) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} />

        <Page__Content>
          {!!error && <ErrorApi error={error} />}
          {!!rolesError && <ErrorApi error={rolesError} />}
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !data?.workspaces) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: data?.workspaces,
  });

  if (!workspaceData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const roles = rolesData?.roles || [];

  return (
    <>
      <Page__Header
        title={
          <Flex gap="4" align="center">
            {PAGE_TITLE}{' '}
            <Text size="1" color="gray">
              {workspaceData.name}
            </Text>
          </Flex>
        }
      >
        <AccessSubscription plan={[PLANS.STARTER]}>
          <Badge color="gold" size="2">
            <Link asChild color="gold">
              <RouterNavLink to={appRoutes.settingsBilling()}>
                Upgrade
              </RouterNavLink>
            </Link>{' '}
            to create custom roles
          </Badge>
        </AccessSubscription>

        <AccessSubscription plan={[PLANS.TEAMS]}>
          <Button variant="soft" color="plum" onClick={navigateToNewRolePage}>
            <PlusIcon />
            Create role
          </Button>
        </AccessSubscription>
      </Page__Header>

      <Page__Content>
        <Box mb="4" maxWidth="480px">
          <Callout color="gold">
            These roles are shared across all projects in this workspace
          </Callout>
        </Box>

        {!!roles.length && (
          <RolesTable roles={roles} workspaceId={workspaceId} />
        )}
      </Page__Content>
    </>
  );
}
