import { getFlatDataFromTree } from '@nosferatu500/react-sortable-tree';
import { FlatMenuItem, MenuNode } from 'src/types/Menu';
import { getNodeKey } from 'src/utils/getNodeKey';

type GetFlatDataFromMenuTreeArgs = {
  treeData: MenuNode[];
};

export function getFlatDataFromMenuTree({
  treeData,
}: GetFlatDataFromMenuTreeArgs) {
  const flatData = getFlatDataFromTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
  }) as FlatMenuItem[];

  return flatData.map((item) => {
    return {
      parent_id: item.parentNode?.id || 'root',
      id: item.node.id,
      label: item.node.label,
      template_id: item.node.template_id,
      order: item.node.order,
    };
  });
}
