import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { DragHandleDots2Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { Box, IconButton, Skeleton } from '@radix-ui/themes';
import { Nav } from 'src/components/Nav';
import { useLocalStorage } from 'usehooks-ts';
import { Page__Header } from './Page__Header/Page__Header';
import { Page__Content } from './Page__Content/Page__Content';
import { Page__Drawer } from './Page__Drawer/Page__Drawer';
import { useShowMobileSidebar } from 'src/hooks/useShowMobileSidebar';

const RESIZABLE_SIDEBAR_WIDTH = 'resizable-sidebar-width';

type PageProps = {
  mods?: Mods;
  mix?: Mix;
  children?: React.ReactNode;
  loading?: boolean;
  nav: React.ReactNode;
};

export function Page(props: PageProps) {
  const { isShowMobileSidebar } = useShowMobileSidebar();
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  if (props.loading) {
    return (
      <div className={b('page', props)}>
        <Nav loading={true} />
        <div className="page__body">
          <Page__Header isLoading={true} />

          <Page__Content>
            <Skeleton width="400px" height="29px" mb="2" />
            <Skeleton width="350px" height="29px" mb="2" />
            <Skeleton width="300px" height="29px" mb="2" />
          </Page__Content>
        </div>
      </div>
    );
  }

  if (isShowMobileSidebar) {
    return (
      <>
        {isNavOpen && (
          <Page__Drawer onNavClose={() => setIsNavOpen(false)}>
            {props.nav}
          </Page__Drawer>
        )}
        <div className="page page_type_primary page_mobile">
          {!isNavOpen && (
            <Box mx="3" className="nav-mobile__button">
              <IconButton
                variant="soft"
                size="1"
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                <HamburgerMenuIcon />
              </IconButton>
            </Box>
          )}
          <div className={b('page__body', props)}>{props.children}</div>
        </div>
      </>
    );
  }

  return (
    <div className={b('page', props)}>
      <MemoizedNav nav={props.nav} />
      <div className={b('page__body', props)}>{props.children}</div>
    </div>
  );
}

const MemoizedNav = React.memo(ResizedNav);

type ResizedNavProps = {
  nav: React.ReactNode;
};

function ResizedNav(props: ResizedNavProps) {
  const [sidebarWidth, setSidebarWidth] = useLocalStorage(
    RESIZABLE_SIDEBAR_WIDTH,
    286
  );

  const handleResize = React.useMemo(() => {
    return (
      e: React.SyntheticEvent<Element, Event>,
      data: ResizeCallbackData
    ) => {
      setSidebarWidth(data.size.width);
    };
  }, [setSidebarWidth]);

  const minConstraints: [number, number] = React.useMemo(
    () => [275, Infinity],
    []
  );
  const maxConstraints: [number, number] = React.useMemo(
    () => [Infinity, Infinity],
    []
  );
  return (
    <ResizableBox
      onResizeStop={handleResize}
      width={sidebarWidth}
      axis="x"
      minConstraints={minConstraints}
      maxConstraints={maxConstraints}
      handle={
        <div className="page__drag-button-container">
          <IconButton
            className="page__drag-button"
            variant="soft"
            size="1"
            title="Resize sidebar"
          >
            <DragHandleDots2Icon />
          </IconButton>
        </div>
      }
    >
      {props.nav}
    </ResizableBox>
  );
}
