import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { MenuItem, MenuItemStatus } from 'src/types/Menu';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type MenuItemUpdate = MenuItem & { status: MenuItemStatus };

type Data = {
  menu: MenuItemUpdate[];
  instanceId: number;
};

const updateMenu = (arg: Data): Promise<Data> => {
  return fetcher({
    url: `/editor/${arg.instanceId}/menu`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useMenuUpdate(opt?: MutationOptions<Data>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (data: Omit<Data, 'instanceId'>) =>
      updateMenu({ ...data, instanceId }),
    meta: {
      name: 'Navigation update',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
