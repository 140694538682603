import { insertNode } from '@nosferatu500/react-sortable-tree';
import { MenuNode, MenuItem } from 'src/types/Menu';
import { getNodeKey } from 'src/utils/getNodeKey';

type InsertItemArgs = {
  treeData: MenuNode[];
  node: MenuItem;
};

export function insertItem({ treeData, node }: InsertItemArgs) {
  let updatedTree: MenuNode[] = [];
  const { treeData: tree } = insertNode({
    treeData,
    getNodeKey,
    newNode: node,
    depth: 0,
    minimumTreeIndex: 0,
    expandParent: true,
  });

  updatedTree = tree as MenuNode[];

  return updatedTree;
}
