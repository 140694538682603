import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useInstanceContext } from '../providers/InstanceProvider';
import { Page } from 'src/components/Page';
import IndexPage from 'src/pages/main';
import { useUserContext } from 'src/providers/UserProvider';

type PrimaryLayoutRouteProps = {
  nav: React.ReactNode;
};

export function PrimaryLayoutRoute({ nav }: PrimaryLayoutRouteProps) {
  const { instanceId } = useInstanceContext();
  const { isLoading: isUserPermissionsLoading } = useUserContext();

  // no reason to render app without instance id or user's permissions
  if (instanceId === -1 || isUserPermissionsLoading) {
    return <Page mods={{ type: 'primary' }} loading={true} nav={nav} />;
  }

  // navigate to the main page if user doesn't have active instance
  if (instanceId === -1) {
    return (
      <Page mods={{ type: 'primary' }} nav={nav}>
        <IndexPage />
        <Navigate to={'/'} />
      </Page>
    );
  }

  return (
    <Page mods={{ type: 'primary' }} nav={nav}>
      <Outlet />
    </Page>
  );
}
