import { ItemField } from 'src/types/Item';
import { TreeNode, TreeNodeFlat } from 'src/types/Template';
import { getFlatDataFromTemplateTree } from 'src/utils/getFlatDataFromTemplateTree';

type GetListOfFieldsFromNewItemArgs = {
  formData: { [key: string]: string | number | number[] };
  templateTree: TreeNode[];
};

export function getListOfFieldsFromItem({
  formData,
  templateTree,
}: GetListOfFieldsFromNewItemArgs) {
  const flatTemplate: TreeNodeFlat[] = getFlatDataFromTemplateTree({
    templateTree,
  });
  const templateMap: Map<string, Omit<TreeNode, 'children'>> = new Map();
  flatTemplate.forEach((item) => {
    const entityFieldId = item.node.entityFieldId;
    if (entityFieldId) {
      templateMap.set(entityFieldId, item.node);
    }
  });

  const fields: ItemField[] = [];
  Object.entries(formData).forEach(([id, value]) => {
    const field = templateMap.get(id);

    if (!field) {
      return;
    }

    fields.push({
      id,
      template_field_id: field?.templateFieldId || '',
      value: value,
      template_field_type: field.settings.type,
      template_group_id: field?.templateGroupId || '',
      template_group_path: field?.templateGroupPath || null,
      status: 1,
    });
  });

  return fields;
}
