import React, { Fragment } from 'react';
import b, { Mix, Mods } from 'bem-react-helper';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, Flex, Badge, Text } from '@radix-ui/themes';
import './PartialChangesTable.css';

export type PartialChangesTableType = {
  templateName: string;
  isTemplateIncluded: boolean;
  items: {
    name: string;
    id: number;
  }[];
};

const columnHelper = createColumnHelper<PartialChangesTableType>();

type PartialsChangesTableProps = {
  mods?: Mods;
  mix?: Mix;
  data: PartialChangesTableType[];
};

export function PartialChangesTable(props: PartialsChangesTableProps) {
  const { data } = props;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('templateName', {
        cell: (info) => {
          const templateName = info.getValue();
          const isSelected = info.row.original.isTemplateIncluded;

          return (
            <Flex align="center" gap="2">
              {templateName}
              {isSelected && <Badge color="grass">included in sync</Badge>}
            </Flex>
          );
        },
        header: () => <span>Template name</span>,
      }),
      columnHelper.accessor('items', {
        cell: (info) => {
          const items = info.getValue();
          return (
            <Flex gap="2" wrap="wrap">
              {!items.length && <Text>No items selected</Text>}
              {items.map((item) => (
                <Fragment key={item.id}>
                  <Badge>
                    [{item.id}] {item.name}
                  </Badge>
                </Fragment>
              ))}
            </Flex>
          );
        },
        header: () => <span>Selected items</span>,
      }),
    ],
    []
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root
        variant="surface"
        className={b('partial-changes-table', props)}
      >
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell key={headerIndex}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No results</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
