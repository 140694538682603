import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import type { MutationOptions } from 'src/types/Api';

export type InviteUserArg = {
  email: string;
  role_id: WorkspaceRolesType;
  workspaceId: number;
};

const inviteUser = (arg: InviteUserArg): Promise<InviteUserArg> => {
  const { workspaceId, ...data } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/invite`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  });
};

export function useWorkspaceInvite(opt?: MutationOptions<InviteUserArg>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: inviteUser,
    meta: {
      name: 'User invite',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
