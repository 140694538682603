import React from 'react';
import { Combobox } from 'src/components/Combobox';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Box } from '@radix-ui/themes';
import { Control } from 'react-hook-form';
import { getFilteredOptions } from 'src/utils/getFilteredOptions';
import { useWorkspaceMembers } from 'src/hooks/api/useWorkspaceMembers';

type MembersComboboxProps<T extends FieldValues> = {
  control: Control<T>;
  errorMessage?: string | undefined;
  workspaceId: number;
  name: Path<T>;
};

export function MembersCombobox<T extends FieldValues>({
  control,
  errorMessage,
  workspaceId,
  name,
}: MembersComboboxProps<T>) {
  const [query, setQuery] = React.useState('');
  const { data } = useWorkspaceMembers({ workspaceId });

  const options = data?.accounts?.map((item) => ({
    id: item.id.toString(),
    label: `${item.name} (${item.email})`,
  }));

  const getSelectedOption = (value: string | null) => {
    if (!options) {
      return null;
    }

    const selectedOption = options.find((option) => option.id === value);
    return selectedOption || null;
  };

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Combobox
              onChange={(value) => {
                const comboboxValue = value?.id || null;
                onChange(comboboxValue as PathValue<T, Path<T>>);
              }}
              onInputChange={setQuery}
              value={getSelectedOption(value)}
              inputValue={query}
              options={getFilteredOptions({
                value: query,
                options: options || [],
              })}
              label="Member"
              placeholder="Search"
              nullable
              required
              hideMeta
            />

            {errorMessage && (
              <Box mt="2">
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
          </>
        );
      }}
      control={control}
      name={name}
    />
  );
}
