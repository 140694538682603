import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type Change = {
  template_id: string;
  is_update_template: boolean;
  items: number[];
};

export type MoveChangesArgs = {
  sourceInstanceId: number;
  changes: Change[];
};

const moveChanges = (arg: MoveChangesArgs): Promise<unknown> => {
  const { sourceInstanceId, ...rest } = arg;
  return fetcher({
    url: `/instances/${sourceInstanceId}/partial-changes`,
    options: {
      method: 'POST',
      body: JSON.stringify(rest),
    },
  });
};

export function useInstanceMovePartialChanges(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: moveChanges,
    meta: {
      name: 'Move partial changes',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
