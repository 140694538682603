import React from 'react';
import { fetcher } from 'src/utils/fetcher';
import { ApiError } from 'src/types/Api';
import { StringObject } from 'src/types/Common';

type Response = StringObject[];

type GetReportDownloadArgs = {
  url: string;
};

const getFileJson = ({ url }: GetReportDownloadArgs): Promise<Response> => {
  return fetcher({
    omitCredentials: true,
    externalUrl: url,
    url: '',
    headers: {
      'Accept-Encoding': 'gzip',
    },
    hideCsrf: true,
  });
};

export function useExportGetFileJson() {
  const [data, setData] = React.useState<Response | null>(null);
  const [error, setError] = React.useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchFileJson = async (url: string) => {
    if (!url) return;

    setIsLoading(true);
    setError(null);

    try {
      const result = await getFileJson({ url });
      setData(result);
      return result;
    } catch (err) {
      setError(err as ApiError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    fetchFileJson,
  };
}
