import React from 'react';
import { PLANS } from 'src/constants/plans';
import { Subscription } from 'src/types/Subscription';
import { useSubscriptionUpgrade } from 'src/hooks/api/useSubscriptionUpgrade';
import { Box } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { TeamsPlanBasicCard } from './TeamsPlanBasicCard';

type TeamsPlanUpgradeProps = {
  workspaceId: string;
  subscription?: Subscription;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function TeamsPlanUpgrade({
  workspaceId,
  onSuccessfulPayment,
  subscription,
}: TeamsPlanUpgradeProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {
    mutate: upgradeSubscription,
    isMutating: isUpgrading,
    error: upgradeError,
  } = useSubscriptionUpgrade({
    onSuccess: () => {
      onSuccessfulPayment({
        plan: PLANS.TEAMS,
        status: SUBSCRIPTION_STATUS.ACTIVE,
      });
      setIsDialogOpen(false);
    },
  });

  const handleUpgrade = () => {
    upgradeSubscription({ workspaceId });
  };

  return (
    <>
      <TeamsPlanBasicCard
        subscription={subscription}
        onPlanSelect={() => setIsDialogOpen(true)}
      />

      <AlertDialog
        title="Upgrade to the Teams plan"
        onAction={handleUpgrade}
        onCancel={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        color="tomato"
        actionText="Yes, upgrade my plan"
        cancelText="No, keep my current plan"
        loading={isUpgrading}
      >
        {!!upgradeError && (
          <Box mb="4">
            <ErrorApi error={upgradeError} />
          </Box>
        )}
        You're about to upgrade to the Teams plan, which will apply immediately.
        <Box mt="2">
          Your billing will be adjusted to $29/month per member + tax, and the
          charge will be applied to your next billing cycle.
        </Box>
        <Box mt="2">Do you want to proceed?</Box>
      </AlertDialog>
    </>
  );
}
