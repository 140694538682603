import { ObjectValues } from 'src/types/Common';
export const PERMISSIONS = {
  READ: 1,
  EXPORT: 2,
  MENU_MANAGEMENT: 3,
  INSTANCE_CHANGES: 4,
  MOVE_INSTANCE_CHANGES: 5,
  CREATE_ITEM: 6,
  CREATE_TEMPLATE: 7,
  // RESTORE_ITEM_BACKUP: 8,
} as const;

export const PERMISSIONS_LIST = Object.values(PERMISSIONS);

export type PermissionType = ObjectValues<typeof PERMISSIONS>;

export type PermissionsKeys = keyof typeof PERMISSIONS;

export const PERMISSION_STATUS = {
  CREATE: 1,
  DELETE: 2,
} as const;

export type PermissionStatus = ObjectValues<typeof PERMISSION_STATUS>;

export const PERMISSIONS__LABELS = {
  [PERMISSIONS.READ]: 'Can view project content',
  [PERMISSIONS.EXPORT]: 'Can export items',
  [PERMISSIONS.MENU_MANAGEMENT]: 'Can setup navigation',
  [PERMISSIONS.INSTANCE_CHANGES]: 'Can view changes in instance sync',
  [PERMISSIONS.MOVE_INSTANCE_CHANGES]: 'Can move changes between instances',
  [PERMISSIONS.CREATE_ITEM]: 'Can create item',
  [PERMISSIONS.CREATE_TEMPLATE]: 'Can create template',
  // [PERMISSIONS.RESTORE_ITEM_BACKUP]: 'Can restore items from backup',
};
