import { ObjectValues } from './Common';
import { TreeItem, FlatDataItem } from '@nosferatu500/react-sortable-tree';

export const MENU_ITEM_STATUS = {
  ADDED: 1,
  UPDATED: 2,
  REMOVED: 3,
} as const;

export type MenuItemStatus = ObjectValues<typeof MENU_ITEM_STATUS>;

export type MenuItemWithStatus = MenuItem & {
  status: MenuItemStatus;
};

export type MenuItem = {
  id: string;
  label: string;
  template_id: string;
  parent_id: string;
  order: number;
};

export type MenuNode = MenuItem &
  TreeItem & {
    children?: MenuNode[];
  };

export type FlatMenuItem = FlatDataItem & MenuItem;
