import { WorkspacesProject } from 'src/types/Workspace';
import { INSTANCE } from 'src/constants/instance';
import { EmptyObject } from 'src/types/Common';

type GetInstancesIdArgs = {
  project: WorkspacesProject | EmptyObject;
};

export type Instances = {
  [INSTANCE.DEV]?: number;
  [INSTANCE.STAGE]?: number;
  [INSTANCE.PROD]?: number;
};
export const getInstancesIds = ({ project }: GetInstancesIdArgs) => {
  const instances: Instances = {} as Instances;

  project?.instances?.forEach((instance) => {
    switch (instance.env) {
      case INSTANCE.DEV:
        instances[INSTANCE.DEV] = instance.id;
        break;
      case INSTANCE.STAGE:
        instances[INSTANCE.STAGE] = instance.id;
        break;
      case INSTANCE.PROD:
        instances[INSTANCE.PROD] = instance.id;
        break;
    }
  });

  return instances;
};
