import React from 'react';
import b from 'bem-react-helper';
import { useMenu } from 'src/hooks/api/useMenu';
import { getMenuTreeFromFlatData } from './utils/getMenuTreeFromFlatData';
import { Disclosure } from '@headlessui/react';
import { ReactComponent as Directory } from 'src/assets/directory.svg';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, IconButton, Popover, Skeleton } from '@radix-ui/themes';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  PlusIcon,
} from '@radix-ui/react-icons';
import { MenuNode } from 'src/types/Menu';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { ScrollArea } from 'src/components/ScrollArea';
import { appRoutes } from 'src/utils/routePaths';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { sortChildrenByOrder } from 'src/utils/sortChildrenByOrder';
import { AccessInstance } from 'src/components/AccessInstance';
import { INSTANCE } from 'src/constants/instance';
import { Callout } from '../Callout';

export function NavTree() {
  const { instanceId } = useInstanceContext();
  const { data, isLoading } = useMenu();
  const menuList = data?.menu;

  if (instanceId !== -1 && isLoading) {
    return (
      <div>
        <Nav__GroupHeadline />
        {new Array(3).fill(0).map((_, i) => (
          <div key={i}>
            <Skeleton width="259px" height="32px" mb="2" />
          </div>
        ))}
      </div>
    );
  }

  if (instanceId === -1) {
    return null;
  }

  const tree = getMenuTreeFromFlatData({ data: menuList || [] });
  const treeWithOrderedChildren = sortChildrenByOrder({
    treeData: tree,
  });

  return (
    <div className="nav-tree">
      <Nav__GroupHeadline showButton={true} />

      <div className="nav-tree__templates-container">
        <ScrollArea mods={{ type: 'secondary' }}>
          <Box pr="3">
            <ul>
              {treeWithOrderedChildren.map((item) => (
                <Nav__Item key={item.id} data={item} />
              ))}
            </ul>
          </Box>
        </ScrollArea>
      </div>
    </div>
  );
}

type Nav__GroupHeadlineProps = {
  showButton?: boolean;
};

function Nav__GroupHeadline({ showButton }: Nav__GroupHeadlineProps) {
  const { instanceId } = useInstanceContext();
  const navigate = useNavigate();

  const createTemplate = () => {
    const url = appRoutes.templateNew({ instanceId });
    navigate(url);
  };

  return (
    <div className="nav__group-headline">
      <span>Templates</span>
      {showButton && (
        <Access
          roles={ROLES_LIST}
          permissionsGroups={[[PERMISSIONS.CREATE_TEMPLATE]]}
        >
          <AccessInstance allowedInstances={[INSTANCE.DEV]}>
            <IconButton
              title="Create template"
              variant="ghost"
              color="gold"
              onClick={createTemplate}
            >
              <PlusIcon />
            </IconButton>
          </AccessInstance>

          <AccessInstance allowedInstances={[INSTANCE.STAGE, INSTANCE.PROD]}>
            <Popover.Root>
              <Popover.Trigger>
                <IconButton
                  title="Create template"
                  variant="ghost"
                  color="gold"
                >
                  <PlusIcon />
                </IconButton>
              </Popover.Trigger>
              <Popover.Content>
                <Box maxWidth="90vw">
                  <Callout color="tomato">
                    You can only create templates in the development
                    environment. Here, you can review or manage existing
                    templates.
                  </Callout>
                </Box>
              </Popover.Content>
            </Popover.Root>
          </AccessInstance>
        </Access>
      )}
    </div>
  );
}

const getMenuFromLocalstorage = () => {
  const menuString = localStorage.getItem(MENU_KEY);
  const menu: { [key: string]: boolean } = menuString
    ? JSON.parse(menuString)
    : {};

  return menu;
};

const MENU_KEY = 'sidebar-menu';

type NavItemProps = {
  data: MenuNode;
};

export function Nav__Item({ data }: NavItemProps) {
  const isGroup = !data.template_id;

  const isGroupOpen = (id: string) => {
    const menu = getMenuFromLocalstorage();
    return menu[id];
  };

  return (
    <li className="nav__item">
      {!data?.children && !isGroup && <NavLink data={data} />}

      {!data?.children && isGroup && (
        <div className="nav__item-group">
          <Directory className="nav__item-directory-icon" />
          <Nav__TextOverflowed text={data.label} />
        </div>
      )}

      {!!data?.children?.length && (
        <Disclosure defaultOpen={isGroupOpen(data.id)}>
          {({ open }) => (
            <>
              <Disclosure.Button className="nav__item-group">
                <Nav__ItemBody data={data} isOpen={open} />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                <ul>
                  {data?.children?.map((item) => (
                    <Nav__Item key={item.id} data={item} />
                  ))}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </li>
  );
}

type NavItemBodyProps = {
  isOpen: boolean;
  data: MenuNode;
};

// Disclosure component can't be controlled, use useEffect to compensate this https://github.com/tailwindlabs/headlessui/discussions/2692
export function Nav__ItemBody({ isOpen, data }: NavItemBodyProps) {
  React.useEffect(() => {
    const menu = getMenuFromLocalstorage();

    const newMenu = { ...menu };
    newMenu[data.id] = isOpen;
    localStorage.setItem(MENU_KEY, JSON.stringify(newMenu));
  }, [data.id, isOpen]);

  return (
    <>
      <Nav__TextOverflowed text={data.label} />

      {isOpen ? (
        <ChevronDownIcon className="nav__item-arrow-icon" />
      ) : (
        <ChevronRightIcon className="nav__item-arrow-icon" />
      )}
    </>
  );
}

type NavLinkProps = {
  data: MenuNode;
};

function NavLink({ data }: NavLinkProps) {
  const { instanceId } = useInstanceContext();
  const { templateId } = useParams();
  const isActive = templateId === data.template_id;

  return (
    <RouterLink
      className={b('nav__item-link', {}, { active: isActive })}
      to={appRoutes.items({
        instanceId,
        templateId: data.template_id,
      })}
      title={data.label}
    >
      <Nav__TextOverflowed text={data.label} />
    </RouterLink>
  );
}

export function Nav__TextOverflowed({ text }: { text: string }) {
  return (
    <span className="nav__item-text-wrapper">
      <span className="nav__item-text">{text}</span>
    </span>
  );
}
