import React from 'react';
import { useParams } from 'react-router-dom';
import { TemplateNew } from './new';
import { useTemplateById } from 'src/hooks/api/useTemplateById';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { Callout } from 'src/components/Callout';
import { COMMON_ERROR } from 'src/constants/errorStatusCode';
import {} from '@radix-ui/themes';
import { TabsSkeleton } from './components/TabsSkeleton';
import { appRoutes } from 'src/utils/routePaths';
import { useInstanceContext } from 'src/providers/InstanceProvider';

export function TemplateCopy() {
  const { instanceId } = useInstanceContext();
  const params = useParams();
  const {
    data: template,
    error: templateError,
    isLoading: isTemplateLoading,
  } = useTemplateById({
    id: params?.templateId,
    options: {
      gcTime: 0, // disable cache by default to avoid problems in form edit
      staleTime: 0, // disable cache by default to avoid problems in form edit
    },
  });

  if (isTemplateLoading) {
    return (
      <>
        <Page__Header
          title="Create template"
          backButtonUrl={appRoutes.items({
            instanceId,
            templateId: params?.templateId || '',
          })}
        />

        <Page__Content>
          <TabsSkeleton />
        </Page__Content>
      </>
    );
  }

  if (templateError || !template) {
    return (
      <>
        <Page__Header title="Create template" />

        <Page__Content>
          {!!templateError && <ErrorApi error={templateError} />}

          <Callout color="tomato">{COMMON_ERROR}</Callout>
        </Page__Content>
      </>
    );
  }

  return <TemplateNew initialFields={template.fields || []} />;
}
