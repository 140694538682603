import React, { createContext, useContext, useState } from 'react';
import { TreeNode } from 'src/types/Template';

type NodesMap = Map<string, TreeNode[]>;
type ItemFormContextProps = {
  templateTree: TreeNode[];
  setTemplateTree: (data: TreeNode[]) => void;
  dynamicGroups: NodesMap;
  setDynamicGroups: (nodes: NodesMap) => void;
};

const ItemFormContext = createContext<ItemFormContextProps>({
  templateTree: [],
  setTemplateTree: () => {},
  dynamicGroups: new Map(),
  setDynamicGroups: () => {},
});

type ItemFormProviderProps = {
  children: React.ReactNode;
};
export const ItemFormProvider = ({ children }: ItemFormProviderProps) => {
  const [dynamicGroups, setDynamicGroups] = useState(new Map());
  const [templateTree, setTemplateTree] = useState<TreeNode[]>([]);

  return (
    <ItemFormContext.Provider
      value={{
        templateTree,
        setTemplateTree,
        dynamicGroups,
        setDynamicGroups,
      }}
    >
      {children}
    </ItemFormContext.Provider>
  );
};

export const useItemFormContext = () => {
  const context = useContext(ItemFormContext);
  if (!context) {
    throw new Error('useItemFormContext must be used within a ItemFormContext');
  }
  return context;
};
