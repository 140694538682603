import type { NavigateFunction } from 'react-router-dom';

type History = { navigate: null | NavigateFunction };

// There is no official support yet
// https://github.com/remix-run/react-router/issues/8264
// https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
// https://jasonwatmore.com/react-router-6-navigate-outside-react-components
// custom history object to allow navigation outside react components
export const history: History = {
  navigate: null,
};
