import { SCROLL_AREA_CLASS } from 'src/components/ScrollArea';

export const updateScrollIndicatorPosition = (element: HTMLDivElement) => {
  const scrollBottom =
    element.scrollHeight - element.scrollTop - element.clientHeight;
  const scrollTop = element.scrollTop;

  if (!element.classList.contains(SCROLL_AREA_CLASS.ROOT)) {
    element.classList.add(SCROLL_AREA_CLASS.ROOT);
    element.classList.add(SCROLL_AREA_CLASS.STICKY);
  }

  if (scrollTop > 0 && !element.classList.contains(SCROLL_AREA_CLASS.TOP)) {
    element.classList.add(SCROLL_AREA_CLASS.TOP);
  } else if (
    scrollTop === 0 &&
    element.classList.contains(SCROLL_AREA_CLASS.TOP)
  ) {
    element.classList.remove(SCROLL_AREA_CLASS.TOP);
  }

  if (
    scrollBottom > 0 &&
    !element.classList.contains(SCROLL_AREA_CLASS.BOTTOM)
  ) {
    element.classList.add(SCROLL_AREA_CLASS.BOTTOM);
  } else if (
    scrollBottom === 0 &&
    element.classList.contains(SCROLL_AREA_CLASS.BOTTOM)
  ) {
    element.classList.remove(SCROLL_AREA_CLASS.BOTTOM);
  }
};
