import { ObjectValues } from 'src/types/Common';
export const WORKSPACES_ROLES = {
  OWNER: 1,
  ADMIN: 2,
  MEMBER: 3,
} as const;

export const ROLES_LIST = Object.values(WORKSPACES_ROLES);

export type WorkspaceRolesType = ObjectValues<typeof WORKSPACES_ROLES>;

export const WORKSPACES_ROLES_LABELS = {
  [WORKSPACES_ROLES.OWNER]: 'Owner',
  [WORKSPACES_ROLES.ADMIN]: 'Admin',
  [WORKSPACES_ROLES.MEMBER]: 'Member',
} as const;
