import _ from 'lodash';

export function swapWithPreviousItem<T>(arr: T[], indexToSwap: number) {
  const clonedArray = _.cloneDeep(arr);

  if (indexToSwap > 0) {
    [clonedArray[indexToSwap], clonedArray[indexToSwap - 1]] = [
      clonedArray[indexToSwap - 1],
      clonedArray[indexToSwap],
    ];
  }

  return clonedArray;
}
