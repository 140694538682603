import React, { FormEvent } from 'react';
import { Box, Container, Flex, Button } from '@radix-ui/themes';
import { useForm } from 'react-hook-form';
import { ItemForm } from 'src/components/ItemForm';
import { AlertDialog } from 'src/components/AlertDialog/index.js';
import { Page__Header, Page__Content } from 'src/components/Page';
import { ApiTemplateResponse } from 'src/types/Template';
import { ApiItemResponse } from 'src/types/Item';
import { Input } from 'src/components/Input';
import { getFormInitialValues } from './utils/getFormInitialValues';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useItemFormContext } from 'src/providers/ItemFormProvider';
import { ErrorApi } from 'src/components/ErrorApi';
import type { ApiError } from 'src/types/Api';
import { appRoutes } from 'src/utils/routePaths';
import { itemFormResolver } from './utils/itemFormResolver';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';

type FormProps = {
  item?: ApiItemResponse;
  initialTemplate: ApiTemplateResponse;
  onSubmit: (args: {
    data: { [key: string]: string | number | number[] };
  }) => void;
  error: ApiError | null;
  isSaving: boolean;
};

export function Form(props: FormProps) {
  const { instanceId } = useInstanceContext();
  const { item, initialTemplate, error, isSaving } = props;
  const { templateTree } = useItemFormContext();
  const [isSaveAlertOpen, setSaveAlertOpen] = React.useState(false);

  const defaultValues = getFormInitialValues(item?.fields);
  defaultValues.internalLabel = item?.header?.internal_label || '';

  const methods = useForm({
    resolver: itemFormResolver,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: true, // need this to clean up the form values when user remove dynamic group
    context: { templateTree },
  });

  const {
    trigger,
    register,
    formState: { errors },
  } = methods;

  const saveItem = (data: { [key: string]: string | number | number[] }) => {
    props.onSubmit({
      data,
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    // save without confirmation on ctrlKey or metaKey
    if ('ctrlKey' in event && (event.ctrlKey || event.metaKey)) {
      await methods.handleSubmit(saveItem)();
    } else {
      setSaveAlertOpen(true);
    }
  };

  const itemId = item?.id;

  return (
    <>
      <Page__Header
        backButtonUrl={appRoutes.items({
          instanceId,
          templateId: initialTemplate.id,
        })}
        title={
          <>
            {initialTemplate.header.label} |{' '}
            {itemId ? `# ${itemId}` : 'New item'}
          </>
        }
        dataTestId="item-title"
      >
        <Flex align="center" justify="start" gap="4">
          <Access
            roles={ROLES_LIST}
            permissionsGroups={[[PERMISSIONS.CREATE_ITEM]]}
          >
            <AlertDialog
              open={isSaveAlertOpen}
              title={itemId ? 'Update item' : 'Create item'}
              loading={isSaving}
              trigger={
                <Button
                  variant="soft"
                  color="plum"
                  type="submit"
                  onClick={handleSubmit}
                  loading={isSaving}
                >
                  Save changes
                </Button>
              }
              actionText="Yes, save changes"
              cancelText="No, keep editing"
              onCancel={() => setSaveAlertOpen(false)}
              onAction={methods.handleSubmit(saveItem)}
            >
              Are you sure you want to save the changes to the item?
            </AlertDialog>
          </Access>
        </Flex>
      </Page__Header>

      <Page__Content>
        <Container size="2" display="initial" align="center" pl="1" pr="4">
          {!!error && (
            <Box mb="4">
              <ErrorApi error={error} />
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            <Box mb="6">
              <Input
                mods={{ type: 'primary' }}
                label="Label"
                helperText="Used to identify items within the app. Not included in exports"
                errorMessage={errors.internalLabel?.message}
                {...register('internalLabel')}
                required
              />
            </Box>

            <ItemForm methods={methods} templateTree={templateTree} />
          </form>
        </Container>
      </Page__Content>
    </>
  );
}
