import { TemplateField } from 'src/types/Template';
import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';

type SetTemplateFromFlatDataArgs = {
  data: TemplateField[];
};

export function getTemplateTreeFromFlatData({
  data,
}: SetTemplateFromFlatDataArgs) {
  const tree = getTreeFromFlatData({
    flatData: data,
    getKey: (node) => node.id,
    getParentKey: (node) => node.parentId,
    rootKey: 'root',
  });

  return tree;
}
