import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from './Form.jsx';
import { useTemplateById } from 'src/hooks/api/useTemplateById';
import { useItemById } from 'src/hooks/api/useItemById';
import { useEditItem } from 'src/hooks/api/useEditItem';
import { addStatusesToFields } from './utils/addStatusesToFields';
import {
  ItemFormProvider,
  useItemFormContext,
} from 'src/providers/ItemFormProvider';
import { getListOfFieldsFromItem } from 'src/pages/item/utils/getListOfFieldsFromItem';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { appRoutes } from 'src/utils/routePaths';
import { Callout } from 'src/components/Callout';
import { COMMON_ERROR } from 'src/constants/errorStatusCode';
import { getTreeWithoutDynamicGroupChildren } from './utils/getTreeWithoutDynamicGroupChildren';
import { buildInitialTree } from './utils/buildInitialTree';
import { SkeletonPage } from './SkeletonPage';
import { Toast } from 'src/components/Toast';

function Edit() {
  const { instanceId } = useInstanceContext();
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: initialTemplate,
    error: templateError,
    isLoading: isTemplateLoading,
  } = useTemplateById({
    id: params?.templateId,
    options: {
      gcTime: 0, // disable cache by default to avoid problems in form edit
      staleTime: 0, // disable cache by default to avoid problems in form edit
    },
  });
  const {
    data: item,
    error: itemError,
    isLoading: isItemLoading,
  } = useItemById({
    templateId: params?.templateId,
    id: params?.id,
  });
  const {
    mutate: editItem,
    error: itemSaveError,
    isMutating: isItemSaving,
  } = useEditItem({
    onSuccess: onSuccessSave,
  });
  const { templateTree, setDynamicGroups, setTemplateTree } =
    useItemFormContext();

  React.useEffect(() => {
    const fields = initialTemplate?.fields;
    if (!fields || !item?.id) {
      return;
    }

    const { dynamicGroupsMap, tree: treeWithoutDynamicGroupChildren } =
      getTreeWithoutDynamicGroupChildren({
        templateFields: initialTemplate.fields || [],
        itemFields: item.fields,
      });

    const tree = buildInitialTree({
      treeData: treeWithoutDynamicGroupChildren,
      dynamicGroupsChildrenStampMap: dynamicGroupsMap,
      itemFields: item.fields,
    });

    setDynamicGroups(dynamicGroupsMap);
    setTemplateTree(tree);
  }, [item, initialTemplate, setDynamicGroups, setTemplateTree]);

  const handleSubmit = ({
    data: formData,
  }: {
    data: { [key: string]: string | number | number[] };
  }) => {
    const { internalLabel, ...fieldsValues } = formData;
    if (!initialTemplate) {
      // throw error, smth weird happened in this case
      return;
    }
    const fields = getListOfFieldsFromItem({
      formData: fieldsValues,
      templateTree,
    });

    const updatedFields = addStatusesToFields({
      initialData: item?.fields || [],
      currentData: fields,
    });

    const isInternalLabelChanged =
      item?.header?.internal_label !== internalLabel;

    if (!updatedFields.length && !isInternalLabelChanged) {
      Toast({
        text: 'No changes to save',
        variant: 'info',
      });
      return;
    }

    editItem({
      fields: updatedFields,
      template_id: initialTemplate.id,
      id: item?.id,
      internal_label: internalLabel as string,
    });
  };

  function onSuccessSave() {
    const url = appRoutes.items({
      instanceId,
      templateId: params.templateId || '',
    });
    navigate(url);
  }

  if (templateError || itemError) {
    return (
      <>
        <Page__Header title="Edit item" />

        <Page__Content>
          {templateError && <ErrorApi error={templateError} />}
          {itemError && <ErrorApi error={itemError} />}
        </Page__Content>
      </>
    );
  }

  if (isItemLoading || isTemplateLoading) {
    return (
      <>
        <Page__Header
          isLoading
          backButtonUrl={appRoutes.items({
            instanceId,
            templateId: params?.templateId || '',
          })}
        />

        <Page__Content>
          <SkeletonPage />
        </Page__Content>
      </>
    );
  }

  if (!item || !initialTemplate) {
    return (
      <>
        <Page__Header title="Edit item" />

        <Page__Content>
          <Callout color="tomato">{COMMON_ERROR}</Callout>
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Form
        item={item}
        initialTemplate={initialTemplate}
        onSubmit={handleSubmit}
        error={itemSaveError}
        isSaving={isItemSaving}
      />
    </>
  );
}

export function ItemEdit() {
  return (
    <ItemFormProvider>
      <Edit />
    </ItemFormProvider>
  );
}
