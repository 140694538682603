import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { EmptyObject } from 'src/types/Common';

export type CancelSubscriptionArg = {
  workspaceId: string;
};

const cancelSubscription = (
  arg: CancelSubscriptionArg
): Promise<EmptyObject> => {
  const { workspaceId } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/subscriptions`,
    options: {
      method: 'DELETE',
    },
  });
};

export function useSubscriptionCancel(opt?: MutationOptions<EmptyObject>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: cancelSubscription,
    meta: {
      name: 'Subscription cancellation',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
