import {
  DefaultValues,
  FieldErrors,
  Path,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { Box, Flex, Badge, Text, Button } from '@radix-ui/themes';
import { MembersCombobox } from './MembersCombobox';
import { ErrorApi } from 'src/components/ErrorApi';
import { Page__Content, Page__Header } from 'src/components/Page';
import { appRoutes } from 'src/utils/routePaths';
import { AlertDialog } from 'src/components/AlertDialog';
import type { ApiError } from 'src/types/Api';
import { Role } from 'src/types/Workspace';
import { AddUserFormValues, UpdateUserFormValues } from '../types/FormValues';
import { ComboboxMultipleField } from 'src/components/ComboboxMultipleField';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { INSTANCE } from 'src/constants/instance';
import { Instances } from '../utils/getInstancesIds';

type FormErrors<T extends AddUserFormValues | UpdateUserFormValues> =
  FieldErrors<T> & {
    atLeastOne?: { message: string };
  };

type MemberFormProps<
  T extends AddUserFormValues | UpdateUserFormValues,
  U extends ObjectSchema<T>,
> = {
  workspaceId: number;
  projectId: number;
  memberId?: number;
  memberName?: string;
  projectName: string;
  resetMemberMutation: () => void;
  isMemberSaving: boolean;
  memberSavingError: null | ApiError;
  roles: Role[];
  onSubmit: (data: T) => void;
  initialValues?: DefaultValues<T>;
  validationSchema: U;
  availableInstances: Instances;
};

export function MemberForm<
  T extends AddUserFormValues | UpdateUserFormValues,
  U extends ObjectSchema<T>,
>({
  workspaceId,
  projectId,
  projectName,
  memberId,
  memberName,
  resetMemberMutation,
  isMemberSaving,
  memberSavingError,
  roles,
  onSubmit,
  validationSchema,
  initialValues,
  availableInstances,
}: MemberFormProps<T, U>) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    React.useState(false);
  const methods = useForm<T>({
    resolver: yupResolver<T>(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });

  const {
    trigger,
    formState: { errors },
    control,
    handleSubmit,
  } = methods;

  const saveForm = async () => {
    const isValid = await trigger();

    if (isValid) {
      setIsConfirmationDialogOpen(true);
      resetMemberMutation();
    }
  };

  const submitForm: SubmitHandler<T> = async (
    data: T,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    onSubmit(data);
  };

  const options = roles.map((item) => ({
    id: item.id.toString(),
    label: item.name,
  }));

  return (
    <>
      <Page__Header
        backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        title={
          <Flex gap="3" align="center">
            {memberId
              ? `Update roles for ${memberName}`
              : 'Add member to project'}
            <Text size="1" color="gray">
              {projectName}
            </Text>
          </Flex>
        }
      >
        <Flex gap="2" align="center">
          <Button
            variant="soft"
            color="plum"
            loading={isMemberSaving}
            onClick={saveForm}
          >
            Save changes
          </Button>
        </Flex>
      </Page__Header>

      <Page__Content>
        {!!memberSavingError && (
          <Box mb="4">
            <ErrorApi error={memberSavingError} />
          </Box>
        )}

        <AlertDialog
          open={isConfirmationDialogOpen}
          actionText="Yes, save changes"
          cancelText="No, keep editing"
          onCancel={() => setIsConfirmationDialogOpen(false)}
          onAction={handleSubmit(submitForm)}
          loading={isMemberSaving}
        >
          {!!memberSavingError && (
            <Box mb="4">
              <ErrorApi error={memberSavingError} />
            </Box>
          )}
          Are you sure you want to save the changes to the member?
        </AlertDialog>

        <Box maxWidth="400px">
          <form onSubmit={saveForm}>
            {!memberId && (
              <Box mb="6">
                <MembersCombobox
                  name={'memberId' as Path<T>}
                  control={control}
                  workspaceId={workspaceId}
                  errorMessage={
                    (errors as FormErrors<AddUserFormValues>).memberId?.message
                  }
                />
              </Box>
            )}

            {availableInstances?.Dev && (
              <Box>
                <ComboboxMultipleField<T>
                  control={control}
                  name={INSTANCE.DEV as Path<T>}
                  options={options}
                  hideMeta={true}
                  label={
                    <Badge variant="soft" color="grass">
                      Dev
                    </Badge>
                  }
                />
              </Box>
            )}

            {availableInstances?.Stage && (
              <Box mt="4">
                <ComboboxMultipleField<T>
                  control={control}
                  name={INSTANCE.STAGE as Path<T>}
                  options={options}
                  hideMeta={true}
                  label={
                    <Badge variant="soft" color="cyan">
                      Stage
                    </Badge>
                  }
                />
              </Box>
            )}

            {availableInstances?.Prod && (
              <Box mt="4">
                <ComboboxMultipleField<T>
                  control={control}
                  name={INSTANCE.PROD as Path<T>}
                  options={options}
                  hideMeta={true}
                  label={
                    <Badge variant="soft" color="red">
                      Prod
                    </Badge>
                  }
                />
              </Box>
            )}

            {/* didn't find better way to adjust errors type*/}
            {!!(errors as FormErrors<T>)?.atLeastOne && (
              <Box mt="1">
                <ErrorMessage>
                  {(errors as FormErrors<T>)?.atLeastOne?.message}
                </ErrorMessage>
              </Box>
            )}
          </form>
        </Box>
      </Page__Content>
    </>
  );
}
