import React from 'react';
import { Input } from 'src/components/Input';
import { Checkbox } from 'src/components/Checkbox';
import * as yup from 'yup';
import { FIELD_TYPE } from 'src/constants/components';
import type * as TemplateTypes from 'src/types/Template';
import { Grid } from '@radix-ui/themes';
import { TemplateSettingsForm } from './components/TemplateSettingsForm';
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import {
  nameSchema,
  labelSchema,
  maximumIntegerSchema,
  minimumIntegerSchema,
} from './utils/schema';
import { setValuesAsNumber } from './utils/setValuesAsNumber';
import { nameTooltip } from './utils/tooltipText';

const settingsValidationSchema = yup.object().shape(
  {
    type: yup.string().oneOf([FIELD_TYPE.INTEGER]).required(),
    name: nameSchema,
    label: labelSchema,
    minimum: minimumIntegerSchema,
    maximum: maximumIntegerSchema,
    required: yup.boolean().required(),
  },
  [['maximum', 'minimum']] // https://dev.to/gabrielterriaga/how-to-validate-two-fields-that-depend-on-each-other-with-yup-1ccg
);

export const getDefaultValues = (): TemplateTypes.SettingsIntegerType => ({
  type: FIELD_TYPE.INTEGER,
  name: '',
  label: '',
  required: false,
  minimum: null,
  maximum: null,
});

export const validate = (
  data: TemplateTypes.SettingsIntegerType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

type GetFieldValidationSchemaArgs = {
  data: TemplateTypes.SettingsIntegerType;
  id?: string;
};
export const getFieldValidationSchema = ({
  data,
}: GetFieldValidationSchemaArgs) => {
  let schema = yup
    .number()
    .typeError('should be a number')
    .integer('should be integer')
    .nullable();

  if (data.minimum) {
    schema = schema.min(data.minimum, `should be at least ${data.minimum}`);
  }

  if (data.maximum) {
    schema = schema.max(data.maximum, `should be no more than ${data.maximum}`);
  }

  if (data.required) {
    schema = schema.required(`required field`);
  }

  return schema;
};

type FieldProps = {
  data: TemplateTypes.SettingsIntegerType;
  id: string;
  methods: UseFormReturn<{ [key: string]: string }>; // uuid used for field name
};

export function Field({ data, methods, id }: FieldProps) {
  const { register, formState } = methods;

  return (
    <Input
      label={data.label}
      required={data.required}
      errorMessage={formState?.errors[id]?.message}
      {...register?.(id, {
        setValueAs: setValuesAsNumber,
      })}
    />
  );
}

type SettingsProps = {
  methods: UseFormReturn<TemplateTypes.SettingsIntegerType>;
};

type ValidFieldNames = keyof TemplateTypes.SettingsIntegerType;

export function Settings({ methods }: SettingsProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Grid gap="4">
      <Input
        label="Label"
        errorMessage={errors.label?.message}
        required
        autoFocus
        {...register<ValidFieldNames>('label')}
      />

      <Input
        label="Name"
        tooltip={nameTooltip}
        errorMessage={errors.name?.message}
        required
        {...register<ValidFieldNames>('name')}
        data-testid="integer-field-name"
      />

      <Checkbox
        mods={{ type: 'primary' }}
        errorMessage={errors.required?.message}
        {...register<ValidFieldNames>('required')}
      >
        Required
      </Checkbox>

      <Input
        label="Minimum value"
        errorMessage={errors.minimum?.message}
        {...register<ValidFieldNames>('minimum', {
          setValueAs: setValuesAsNumber,
        })}
      />

      <Input
        label="Maximum value"
        errorMessage={errors.maximum?.message}
        {...register<ValidFieldNames>('maximum', {
          setValueAs: setValuesAsNumber,
        })}
      />
    </Grid>
  );
}

type SettingsFormProps = {
  initialValues: TemplateTypes.SettingsIntegerType;
};

export function SettingsForm({ initialValues }: SettingsFormProps) {
  // backend can send empty fields from other settings. The reason is that all fields setting should merged by backend.
  // to solve the issue, pick just fields which we need
  const values = _.pick(initialValues, Object.keys(getDefaultValues()));
  return (
    <TemplateSettingsForm
      initialValues={values}
      validationSchema={settingsValidationSchema}
    >
      {(methods: UseFormReturn<TemplateTypes.SettingsIntegerType>) => (
        <Settings methods={methods} />
      )}
    </TemplateSettingsForm>
  );
}
