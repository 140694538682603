import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { RoleForm } from './components/RoleForm';
import { FormValues } from './types/FormValues';
import { useWorkspaceRoleDefinitionUpdate } from 'src/hooks/api/useWorkspaceRoleDefinitionUpdate';
import { useWorkspaceRoleDefinition } from 'src/hooks/api/useWorkspaceRoleDefinition';
import { getFormInitialValues } from './utils/getFormInitialValues';
import { getPermissionsFromUpdatedForm } from './utils/getPermissionsFromUpdatedForm';
import { RolesFormSkeleton } from './components/RolesFormSkeleton';

export function ProjectRoleEdit() {
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const roleId = Number(params.roleId);
  const navigate = useNavigate();
  const {
    data: workspacesList,
    isLoading: isWorkspaceLoading,
    error: workspaceListError,
  } = useWorkspacesList();
  const {
    data: roleData,
    isLoading: isRoleLoading,
    error: roleError,
    refetch: refetchRole,
  } = useWorkspaceRoleDefinition({
    workspaceId,
    roleId,
  });
  const {
    mutate: updateRole,
    isMutating: isRoleUpdating,
    error: roleUpdatingError,
    reset: resetRoleMutation,
  } = useWorkspaceRoleDefinitionUpdate({
    onSuccess: () => {
      refetchRole();
      navigate(appRoutes.settingsWorkspaceRoles({ workspaceId }));
    },
  });

  if (isWorkspaceLoading || isRoleLoading) {
    return (
      <>
        <Page__Header
          title="Edit role"
          backButtonUrl={appRoutes.settingsWorkspaceRoles({ workspaceId })}
        />

        <Page__Content>
          <RolesFormSkeleton />
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !workspacesList?.workspaces) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  if (workspaceListError || roleError) {
    return (
      <>
        <Page__Header
          title="Edit role"
          backButtonUrl={appRoutes.settingsWorkspaceRoles({ workspaceId })}
        />

        <Page__Content>
          {workspaceListError && <ErrorApi error={workspaceListError} />}

          {roleError && <ErrorApi error={roleError} />}
        </Page__Content>
      </>
    );
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: workspacesList?.workspaces,
  });

  if (!workspaceData || !roleData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const handleSubmit = (formData: FormValues) => {
    const updatedPermissions = getPermissionsFromUpdatedForm({
      formData,
      role: roleData,
    });

    updateRole({
      role_id: roleData.role_id,
      permissions: updatedPermissions,
      name: formData.name,
      workspace_id: workspaceId,
    });
  };

  return (
    <>
      <RoleForm
        onSubmit={handleSubmit}
        workspaceName={workspaceData.name}
        isRoleSaving={isRoleUpdating}
        roleSavingError={roleUpdatingError}
        resetRoleMutation={resetRoleMutation}
        workspaceId={workspaceId}
        defaultValues={getFormInitialValues(roleData)}
        roleName={roleData.name}
      />
    </>
  );
}
