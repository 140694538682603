import type { MutationOptions } from 'src/types/Api';
import { useApiMutationTurnstile } from 'src/components/CloudflareTurnstile';

export type ResendEmailArgs = {
  email: string;
};

export function useRegistrationResendEmail(opt?: MutationOptions<unknown>) {
  const { mutate, isMutating, error, isChallenging, challengeError } =
    useApiMutationTurnstile({
      meta: {
        name: 'Resending email',
      },
      ...opt,
    });

  const resendEmail = (args: ResendEmailArgs) =>
    mutate({ url: '/registration/resend-email', method: 'POST', body: args });

  return {
    mutate: resendEmail,
    isMutating,
    error,
    isChallenging,
    challengeError,
  };
}
