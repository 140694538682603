import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { ScrollArea } from 'src/components/ScrollArea';

type PageProps = {
  children: React.ReactNode;
  mods?: Mods;
  mix?: Mix;
  hasScrollArea?: boolean;
};

export function Page__Content(props: PageProps) {
  const { hasScrollArea = true } = props;

  return (
    <div
      className={b('page__content', props, {
        overflow: hasScrollArea ? 'hidden' : '',
      })}
    >
      {hasScrollArea ? (
        <ScrollArea>{props.children}</ScrollArea>
      ) : (
        props.children
      )}
    </div>
  );
}
