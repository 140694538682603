import React from 'react';
import b, { Mix, Mods } from 'bem-react-helper';
import * as TabsRadix from '@radix-ui/react-tabs';
import { Badge, Tabs as TabsThemes } from '@radix-ui/themes';
import './Tabs.css';

type TabsProps = {
  mods?: Mods;
  mix?: Mix;
  children: React.ReactNode;
  defaultValue?: TabsRadix.TabsProps['defaultValue'];
  value?: TabsRadix.TabsProps['value'];
  onValueChange?: TabsRadix.TabsProps['onValueChange'];
};

export function Tabs(props: TabsProps) {
  return (
    <TabsThemes.Root
      className={b('tabs', props)}
      value={props.value}
      defaultValue={props.defaultValue}
      onValueChange={props.onValueChange}
    >
      {props.children}
    </TabsThemes.Root>
  );
}

type TabsListProps = {
  children: React.ReactNode;
  ariaLabel?: TabsRadix.TabsListProps['aria-label'];
  mods?: Mods;
  mix?: Mix;
};

export function Tabs__List(props: TabsListProps) {
  return (
    <TabsThemes.List
      className={b('tabs__list', props)}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </TabsThemes.List>
  );
}

type TabsItemProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  value: TabsRadix.TabsTriggerProps['value'];
  error?: string;
  dataTestId?: string;
  mods?: Mods;
  mix?: Mix;
  disabled?: boolean;
};

export function Tabs__Item(props: TabsItemProps) {
  const { children, value, icon: Icon, dataTestId, disabled } = props;
  return (
    <TabsThemes.Trigger
      className={b('tabs__trigger', props)}
      value={value}
      data-testid={dataTestId}
      disabled={disabled}
    >
      {Icon}
      <span className="tabs__item-text">
        {children}{' '}
        {!!props.error && (
          <Badge color="tomato" variant="soft" ml="1" data-testid="tab-error">
            {props.error}
          </Badge>
        )}
      </span>
    </TabsThemes.Trigger>
  );
}

type TabsContentProps = {
  children: React.ReactNode;
  value: TabsRadix.TabsContentProps['value'];
  asChild?: boolean;
  forceMount?: boolean;
  hidden?: boolean;
  mods?: Mods;
  mix?: Mix;
};

export function Tabs__Content(props: TabsContentProps) {
  return (
    <TabsThemes.Content
      className={b('tab__content', props)}
      value={props.value}
      asChild={!!props.asChild}
      hidden={props.hidden}
      {...(props.forceMount ? { forceMount: true } : {})}
    >
      {props.children}
    </TabsThemes.Content>
  );
}
