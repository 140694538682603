import React from 'react';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getInstanceById } from 'src/utils/getInstanceById';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { InstanceType } from 'src/constants/instance';
import { Navigate } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';

type AccessInstanceProps = {
  children: React.ReactNode;
  allowedInstances: (InstanceType | undefined)[];
  redirectIfFailed?: boolean;
};

export function AccessInstance({
  children,
  allowedInstances = [],
  redirectIfFailed,
}: AccessInstanceProps) {
  const { instanceId } = useInstanceContext();
  const { data: workspacesList } = useWorkspacesList();
  const activeInstance = getInstanceById({
    workspaces: workspacesList?.workspaces || [],
    instanceId,
  });

  if (allowedInstances.includes(activeInstance?.env)) {
    return <>{children}</>;
  }

  if (redirectIfFailed) {
    return <Navigate to={appRoutes.actionNotAllowedForInstance()} />;
  }

  return null;
}
