import React from 'react';
import { ErrorPage } from 'src/components/ErrorPage';
// import error403UrlWebp from 'src/assets/error-403.webp';
// import error403Url from 'src/assets/error-403.png';
import { Box, Button, Link, Text } from '@radix-ui/themes';
import { Link as RouterLink } from 'react-router-dom';

export function ErrorAccessDenied() {
  return (
    <ErrorPage
      // imgSrc={error403Url}
      // imgSrcWebp={error403UrlWebp}
      // imgAlt="A cartoon character looking through a keyhole"
      headline="Oops! Access denied"
    >
      <Box mb="6">
        <Text size="4">
          Sorry, you don't have permission to access this. Please ask the
          administrator or contact us at{' '}
          <Link href="mailto:support@lootfabriq.io">support@lootfabriq.io</Link>
          .
        </Text>
      </Box>

      <Button variant="soft" color="plum" asChild mb="2" size="3">
        <RouterLink to="/">Return home</RouterLink>
      </Button>
    </ErrorPage>
  );
}
