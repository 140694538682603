import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

type Data = {
  workspaceId: string;
};

const upgradeSubscription = (arg: Data): Promise<Data> => {
  const { workspaceId } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/subscriptions/upgrade`,
    options: {
      method: 'POST',
      body: JSON.stringify({}),
    },
  });
};

export function useSubscriptionUpgrade(opt?: MutationOptions<Data>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: upgradeSubscription,
    meta: {
      name: 'Subscription upgrade',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
