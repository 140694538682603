import React from 'react';
import * as Sentry from '@sentry/react';

type SendFeedbackArgs = {
  message: string;
};

type UseSendFeedbackArgs = {
  onSuccess: () => void;
};

export function useSendFeedback({ onSuccess }: UseSendFeedbackArgs) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const sendFeedback = ({ message }: SendFeedbackArgs) => {
    setIsLoading(true);
    setError('');

    Sentry.sendFeedback({ message })
      .then(() => {
        onSuccess();
      })
      .catch((reason: unknown) => {
        if (typeof reason === 'string') {
          setError(reason);
        } else {
          setError(JSON.stringify(reason));
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    sendFeedback,
    isLoading,
    error,
  };
}
