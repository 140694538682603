import React from 'react';
import { Box, Button, Text, Link } from '@radix-ui/themes';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorPage } from 'src/components/ErrorPage';
// import errorUrl from 'src/assets/error-global.png';
import { CopyCode } from 'src/components/CopyCode';

type ErrorGlobalProps = {
  eventId?: string;
};

export function ErrorGlobal({ eventId }: ErrorGlobalProps) {
  return (
    <ErrorPage
      // todo: add webp and retina version
      // imgSrc={errorUrl}
      // imgSrcWebp={errorUrl}
      // imgAlt="A cartoon character who looks upset"
      headline="Oops! Something went wrong"
    >
      <Box mb="4">
        <Text size="4">
          We encountered a major problem while processing your request.
        </Text>
      </Box>
      <Box mb="4">
        <Text size="4">
          Please try again, and if the issue persists, contact us at{' '}
          <Link href="mailto:support@lootfabriq.io">support@lootfabriq.io</Link>{' '}
          with the following:
        </Text>
      </Box>
      <Box mb="4">
        <CopyCode code={`Error ID: ${eventId}`} language="text" />
      </Box>

      <Box mb="6">
        <Text size="4">
          We apologise for any inconvenience and appreciate your patience!
        </Text>
      </Box>

      <Button variant="soft" color="plum" asChild mb="2" size="3">
        <RouterLink to="/">Return home</RouterLink>
      </Button>
    </ErrorPage>
  );
}
