import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Heading, Link, Text, Button } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { Notification } from './components/Notification';
import {
  usePasswordReset,
  PasswordResetRequestType,
} from 'src/hooks/api/usePasswordReset';
import { appRoutes } from 'src/utils/routePaths';
import { Challenge } from 'src/components/CloudflareTurnstile';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is a required field'),
});

export default function PasswordReset() {
  const [showNotification, setShowNotification] = React.useState(false);

  const {
    mutate: resetPassword,
    error,
    isMutating,
    isChallenging,
    challengeError,
  } = usePasswordReset({
    onSuccess: () => {
      if (!showNotification) {
        setShowNotification(true);
      }
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleRegistration: SubmitHandler<PasswordResetRequestType> = async (
    data: PasswordResetRequestType
  ) => {
    resetPassword(data);
  };

  if (showNotification) {
    return <Notification />;
  }

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <form onSubmit={handleSubmit(handleRegistration)}>
        <Box>
          <Heading mb="4" data-testid="password-reset-header">
            Forgot your password?
          </Heading>

          <Box mb="4">
            <Text>
              Enter your email address so that we can send you instructions to
              reset your password.
            </Text>
          </Box>

          {!!error && (
            <Box my="4">
              <ErrorApi error={error} />
            </Box>
          )}
          <Box mb="6">
            <Input
              size="3"
              label="Email"
              data-testid="password-reset-email"
              errorMessage={errors.email?.message}
              required
              {...register('email')}
            />
          </Box>

          <Challenge isChallenging={isChallenging} error={challengeError} />

          <Flex justify="end">
            <Button
              variant="soft"
              color="plum"
              size="4"
              type="submit"
              style={{ width: '100%' }}
              loading={isMutating}
              data-testid="reset-password"
            >
              Reset password
            </Button>
          </Flex>
        </Box>
      </form>

      <Flex mt="6" justify="center">
        <Text size="2" color="gray" align="center">
          <Link asChild size="2" underline="always" color="gray">
            <RouterLink to={appRoutes.login()}>Return to login</RouterLink>
          </Link>
        </Text>
      </Flex>
    </PageAccent>
  );
}
