import React, { Fragment } from 'react';
import { Button, Select } from '@radix-ui/themes';
import { ApiWorkspaceResponse } from 'src/types/Workspace';
import { PlusIcon } from '@radix-ui/react-icons';
import { WorkspaceCreation } from 'src/components/WorkspaceCreation';

type WorkspacesSelectProps = {
  workspaces: ApiWorkspaceResponse[];
  value: string;
  onChange: (value: string) => void;
};

export function WorkspacesSelect({
  workspaces,
  value,
  onChange,
}: WorkspacesSelectProps) {
  return (
    <>
      <Select.Root value={value} onValueChange={onChange} size="3">
        <Select.Trigger placeholder="Select workspace" variant="surface" />
        <Select.Content variant="soft">
          {workspaces.map((item, index) => (
            <Fragment key={index}>
              <Select.Item value={item.id.toString()}>{item.name}</Select.Item>
            </Fragment>
          ))}
        </Select.Content>
      </Select.Root>

      <WorkspaceCreation
        needConfirmation={true}
        trigger={
          <Button variant="soft" color="gray" size="1">
            <PlusIcon /> Create workspace
          </Button>
        }
      />
    </>
  );
}
