import { Outlet, useParams } from 'react-router-dom';
import React from 'react';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type InstanceProviderRouteProps = {
  children?: React.ReactNode;
};

export function InstanceRoute({ children }: InstanceProviderRouteProps) {
  const { instanceId, setInstanceId } = useInstanceContext();
  const { instanceId: instanceIdParam } = useParams();

  React.useEffect(() => {
    const id = Number(instanceIdParam);

    if (!isNaN(id) && instanceId !== id) {
      setInstanceId(id);
    }
  }, [instanceId, instanceIdParam, setInstanceId]);

  return children || <Outlet />;
}
