import React from 'react';
import { Button } from '@radix-ui/themes';
import { useSubscriptionTransaction } from 'src/hooks/api/useSubscriptionTransaction';
import { usePaddleCheckout } from '../hooks/usePaddleCheckout';
import { CheckoutPaymentMethodDialog } from './CheckoutPaymentMethodDialog';

type ManagePaymentMethodProps = {
  workspaceId: string;
};

export function ManagePaymentMethod({ workspaceId }: ManagePaymentMethodProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { openCheckout } = usePaddleCheckout({});
  const { isLoading: isTransactionDataLoading, refetch: getTransaction } =
    useSubscriptionTransaction({
      workspaceId: Number(workspaceId),
    });

  const handleUpdatePaymentMethod = async () => {
    const { data: transactionData } = await getTransaction();

    if (!transactionData?.transaction_id) {
      return;
    }

    openCheckout({
      transactionId: transactionData.transaction_id,
    });
  };

  return (
    <>
      {isDialogOpen && (
        <CheckoutPaymentMethodDialog
          isOpen={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          openCheckout={handleUpdatePaymentMethod}
        />
      )}
      <Button
        color="gold"
        variant="soft"
        onClick={() => setIsDialogOpen(true)}
        loading={isTransactionDataLoading}
      >
        Manage payment method
      </Button>
    </>
  );
}
