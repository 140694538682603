import * as Sentry from '@sentry/react';
import React from 'react';
import { MemberForm } from './components/MemberForm';
import { UpdateUserFormValues } from './types/FormValues';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useWorkspaceRoles } from 'src/hooks/api/useWorkspaceRoles';
import { getProjectById } from 'src/utils/getProjectById';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { appRoutes } from 'src/utils/routePaths';
import { Skeleton } from '@radix-ui/themes';
import {
  Role,
  useProjectMemberRolesUpdate,
} from 'src/hooks/api/useProjectMemberRolesUpdate';
import { updateUserFormValidationSchema } from './utils/validationSchema';
import { Instances } from './utils/getInstancesIds';
import { useProjectMemberRoles } from 'src/hooks/api/useProjectMemberRoles';
import { getFormDefaultValues } from './utils/getFormDefaultValues';
import { addRolesDiff } from './utils/addRolesDiff';
import { getAvailableInstances } from './utils/getAvailableInstances';
import { AccessPage } from './components/AccessPage';

export function ProjectEditMember() {
  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const projectId = Number(params.projectId);
  const memberId = Number(params.memberId);

  const {
    data: workspacesList,
    isLoading: isWorkspaceLoading,
    error: workspaceListError,
  } = useWorkspacesList();
  const {
    data: rolesData,
    isLoading: isRolesLoading,
    error: rolesError,
  } = useWorkspaceRoles({ workspaceId });
  const {
    data: memberRoles,
    isLoading: memberRolesLoading,
    error: memberRolesError,
    refetch: refetchRole,
  } = useProjectMemberRoles({ projectId, memberId });

  const {
    mutate: updateMember,
    isMutating: isMemberSaving,
    error: memberSavingError,
    reset: resetMemberMutation,
  } = useProjectMemberRolesUpdate({
    onSuccess: () => {
      navigate(appRoutes.settingsProject({ projectId, workspaceId }));
      refetchRole();
    },
  });

  const availableInstances = getAvailableInstances({
    workspaces: workspacesList?.workspaces || [],
    projectId,
  });
  const instanceKeys = Object.keys(availableInstances) as Array<
    keyof Instances
  >;

  const handleSubmit = (formData: UpdateUserFormValues) => {
    try {
      const roles: Role[] = [];
      const defaultValues = getFormDefaultValues({
        initialData: memberRoles?.instances || [],
      });

      instanceKeys.forEach((instanceKey) => {
        addRolesDiff({
          initial: defaultValues[instanceKey],
          final: formData[instanceKey],
          instanceId: availableInstances[instanceKey] as number,
          roles,
        });
      });

      updateMember({
        projectId: projectId,
        memberId,
        roles,
      });
    } catch (error) {
      // catch error if some instances ids not found
      const sentryEventId = Sentry.captureException(error);
      return navigate(appRoutes.error(), { state: { sentryEventId } });
    }
  };

  if (isWorkspaceLoading || isRolesLoading || memberRolesLoading) {
    return (
      <>
        <Page__Header
          title="Update roles"
          backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        />

        <Page__Content>
          <Skeleton width="300px" height="29px" />
        </Page__Content>
      </>
    );
  }

  if (workspaceListError || rolesError) {
    return (
      <>
        <Page__Header
          title="Update role"
          backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        />

        <Page__Content>
          {workspaceListError && <ErrorApi error={workspaceListError} />}

          {rolesError && <ErrorApi error={rolesError} />}

          {memberRolesError && <ErrorApi error={memberRolesError} />}
        </Page__Content>
      </>
    );
  }

  const projectData = getProjectById({
    projectId,
    workspaces: workspacesList?.workspaces || [],
  });

  if (!rolesData || !workspacesList || !projectData || !memberRoles) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const defaultValues = getFormDefaultValues({
    initialData: memberRoles?.instances || [],
  });

  const memberName = `${memberRoles.user.name} (${memberRoles.user.email})`;

  if (!instanceKeys.length) {
    return (
      <AccessPage
        projectId={projectId}
        workspaceId={workspaceId}
        projectName={projectData?.name}
        memberName={memberName}
      />
    );
  }

  return (
    <>
      <MemberForm
        workspaceId={workspaceId}
        projectId={projectId}
        projectName={projectData.name}
        resetMemberMutation={resetMemberMutation}
        isMemberSaving={isMemberSaving}
        memberSavingError={memberSavingError}
        roles={rolesData?.roles || []}
        onSubmit={handleSubmit}
        validationSchema={updateUserFormValidationSchema}
        memberId={memberId}
        initialValues={defaultValues}
        memberName={memberName}
        availableInstances={availableInstances}
      />
    </>
  );
}
