import React from 'react';
import { Box, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { ApiError } from 'src/types/Api';

type SyncAllChangesButtonProps = {
  onSubmit: () => void;
  isLoading: boolean;
  error: ApiError | null;
  isAlertOpen: boolean;
  onAlertOpen: (isOpen: boolean) => void;
};

export function SyncAllChangesButton({
  onSubmit,
  isLoading,
  error,
  isAlertOpen,
  onAlertOpen,
}: SyncAllChangesButtonProps) {
  return (
    <AlertDialog
      title="Sync changes"
      onAction={onSubmit}
      onCancel={() => onAlertOpen(false)}
      open={isAlertOpen}
      color="tomato"
      actionText="Yes, sync all changes"
      cancelText="No, cancel"
      loading={isLoading}
      trigger={
        <Button
          color="gold"
          variant="soft"
          onClick={() => onAlertOpen(true)}
          loading={isLoading}
        >
          Sync all
        </Button>
      }
    >
      {!!error && (
        <Box mb="4">
          <ErrorApi error={error} />
        </Box>
      )}
      Are you sure you want to sync all changes? <br />
      <br />
      This will include all items and templates, regardless of your current
      selections. Please review before proceeding.
    </AlertDialog>
  );
}
