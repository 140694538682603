import React from 'react';
import { Callout } from 'src/components/Callout';
import type { ApiError } from 'src/types/Api';
import { ERROR_STATUS_CODE } from 'src/constants/errorStatusCode';

type ErrorApiProps = {
  error: ApiError;
};

export function ErrorApi({ error }: ErrorApiProps) {
  const statusCode = error?.statusCode;
  const statusCodeMessage = statusCode ? ERROR_STATUS_CODE[statusCode] : '';
  const message = statusCodeMessage || error?.error || error?.message;

  if (!message) {
    return null;
  }

  return <Callout color="tomato">{message}</Callout>;
}
