import React from 'react';
import { Box, Strong, Text, Button } from '@radix-ui/themes';
import { AlertDialog } from 'src/components/AlertDialog';
import { ErrorApi } from 'src/components/ErrorApi';
import { Input } from 'src/components/Input';
import { useProjectRemove } from 'src/hooks/api/useProjectRemove';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type RemoveProjectProps = {
  projectId: number;
  workspaceId: number;
  projectName: string;
};

export function RemoveProject({
  projectId,
  projectName,
  workspaceId,
}: RemoveProjectProps) {
  const navigate = useNavigate();
  const { refetchInstanceId } = useInstanceContext();
  const {
    error,
    isMutating,
    mutate: removeProject,
  } = useProjectRemove({
    onSuccess: () => {
      setIsAlertOpen(false);
      navigate(appRoutes.settingsWorkspace({ workspaceId }));
      refetchInstanceId();
    },
  });
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [inputValueError, setInputValueError] = React.useState(false);

  const handleProjectRemoval = () => {
    if (inputValue !== projectName) {
      setInputValueError(true);
      return;
    }
    if (inputValueError) {
      setInputValueError(false);
    }
    removeProject({ projectId });
  };

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === projectName && inputValueError) {
      setInputValueError(false);
    }
    setInputValue(value);
  };

  return (
    <AlertDialog
      open={isAlertOpen}
      title="Project removal"
      loading={isMutating}
      color="tomato"
      trigger={
        <Button
          variant="soft"
          color="tomato"
          title="Delete project"
          onClick={() => setIsAlertOpen(true)}
        >
          Delete project
        </Button>
      }
      actionText="Yes, remove project"
      cancelText="No, keep project"
      onCancel={() => setIsAlertOpen(false)}
      onAction={handleProjectRemoval}
    >
      {!!error && (
        <Box mb="4">
          <ErrorApi error={error} />
        </Box>
      )}
      Are you sure you want to remove project <Strong>{projectName}</Strong>?
      <Box mt="2">
        <Text>
          If&nbsp;you confirm, we&nbsp;will send an&nbsp;email to&nbsp;all
          project owners. You will have 3&nbsp;days to&nbsp;cancel this action
          before the project is&nbsp;permanently deleted.
        </Text>
      </Box>
      <Box my="2" maxWidth="400px">
        <Input
          label="Please type the project name to confirm removal"
          placeholder="Enter project name"
          required
          value={inputValue}
          onChange={handleInputValueChange}
          errorMessage={
            inputValueError ? (
              <Text>
                Project name you entered does not match{' '}
                <Strong>{projectName}</Strong>
              </Text>
            ) : (
              ''
            )
          }
        />
      </Box>
    </AlertDialog>
  );
}
