import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type CreateWorkspaceArgs = {
  name: string;
};

const createWorkspace = (arg: CreateWorkspaceArgs): Promise<unknown> => {
  return fetcher({
    url: '/workspaces',
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useWorkspaceCreate(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createWorkspace,
    meta: {
      name: 'Workspace creation',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
