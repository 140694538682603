import { getInstancesListByProjectId } from './getInstancesListByProjectId';
import { ApiWorkspaceResponse } from 'src/types/Workspace';

export type GetInstancesOptionsArgs = {
  projectId: number;
  workspaces: ApiWorkspaceResponse[];
};

export const getInstancesOptions = ({
  projectId,
  workspaces,
}: GetInstancesOptionsArgs) => {
  const instances = getInstancesListByProjectId({
    id: projectId,
    workspaces,
  });
  const options = instances.map((item) => ({
    id: item.id.toString(),
    label: item.env,
  }));

  return options;
};
