import * as yup from 'yup';
import { INSTANCE } from 'src/constants/instance';

export const updateUserFormValidationSchema = yup
  .object()
  .shape({
    [INSTANCE.DEV]: yup.array().of(yup.string()).nullable(),
    [INSTANCE.STAGE]: yup.array().of(yup.string()).nullable(),
    [INSTANCE.PROD]: yup.array().of(yup.string()).nullable(),
  })
  .test(
    'atLeastOne',
    'At least one of "dev", "stage", or "prod" must be filled',
    (value, { createError }) => {
      const { Dev, Stage, Prod } = value || {};
      const isValid =
        (Dev && Dev.length > 0) ||
        (Stage && Stage.length > 0) ||
        (Prod && Prod.length > 0);
      return (
        isValid ||
        createError({
          path: 'atLeastOne',
          message: 'At least one of "dev", "stage", or "prod" must be filled',
        })
      );
    }
  );

export const addUserFormValidationSchema = updateUserFormValidationSchema.shape(
  {
    memberId: yup.string().nullable().required('Member is a required field'),
  }
);
