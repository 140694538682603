import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';
import { MenuNode } from '../types/Menu';

type AddChildrenOrderArgs<T extends TreeNode | MenuNode> = {
  treeData: T[];
};

export const addChildrenOrder = <T extends TreeNode | MenuNode>({
  treeData,
}: AddChildrenOrderArgs<T>) => {
  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: T }) => {
      const children = node.children;
      if (!children) {
        return node;
      }

      children.forEach((obj, index) => {
        obj.order = index;
      });

      return {
        ...node,
        children,
      };
    },
  });

  return updatedTree as T[];
};
