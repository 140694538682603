import React from 'react';
import { PLANS } from 'src/constants/plans';
import { Subscription } from 'src/types/Subscription';
import { usePaddleCheckout } from '../hooks/usePaddleCheckout';
import { CheckoutDialog } from './CheckoutDialog';
import { CheckoutEventsData } from '@paddle/paddle-js';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { TeamsPlanBasicCard } from './TeamsPlanBasicCard';

const PADDLE_PRICE_ID_TEAMS = import.meta.env.VITE_PADDLE_PRICE_ID_TEAMS;

type TeamsPlanProps = {
  workspaceId: string;
  subscription?: Subscription;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function TeamsPlanCheckout({
  workspaceId,
  subscription,
  onSuccessfulPayment,
}: TeamsPlanProps) {
  const [checkoutData, setCheckoutData] = React.useState<
    CheckoutEventsData | undefined
  >();
  const [isCheckoutOpen, setIsCheckoutOpen] = React.useState(false);
  const { openCheckout } = usePaddleCheckout({
    onCheckoutDataUpdate: (data) => setCheckoutData(data),
    onSuccessfulPayment: () => {
      onSuccessfulPayment({
        plan: PLANS.TEAMS,
        status: SUBSCRIPTION_STATUS.ACTIVE,
      });
    },
  });

  const handleTeamsPlanSelection = () => {
    openCheckout({
      items: [
        {
          priceId: PADDLE_PRICE_ID_TEAMS,
          quantity: subscription?.used_members || 1,
        },
      ],
      customData: {
        workspaceId,
      },
    });
  };

  return (
    <>
      {isCheckoutOpen && (
        <CheckoutDialog
          isOpen={isCheckoutOpen}
          onOpenChange={setIsCheckoutOpen}
          openCheckout={handleTeamsPlanSelection}
          tax={checkoutData?.totals?.tax}
          total={checkoutData?.totals?.total}
          numberOfMembers={checkoutData?.items[0].quantity}
          subtotal={checkoutData?.totals?.subtotal}
          planName={checkoutData?.items[0].product.name}
          isLoading={!checkoutData}
        />
      )}

      <TeamsPlanBasicCard
        subscription={subscription}
        onPlanSelect={() => setIsCheckoutOpen(true)}
      />
    </>
  );
}
