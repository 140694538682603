import _ from 'lodash';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { Badge, IconButton } from '@radix-ui/themes';
import React, { Fragment } from 'react';
import type { Color } from '../TemplateTree';
import { FieldType, COMPONENTS_HEADLINES } from 'src/constants/components';
import type { SettingsType } from 'src/types/Template';
import { fields } from 'src/fields';
import { DoubleArrowUpIcon, DoubleArrowDownIcon } from '@radix-ui/react-icons';

type TreeNodeProps = {
  mods?: Mods;
  mix?: Mix;
  type: FieldType;
  name: string;
  label: string;
  color: Color;
  meta: Omit<SettingsType, 'name' | 'label' | 'type'>;
  reorder?: boolean;
  onMoveUp: (id: string, parentId: string) => void;
  onMoveDown: (id: string, parentId: string) => void;
  id: string;
  parentId: string;
};

export function TreeNode(props: TreeNodeProps) {
  const {
    type,
    name,
    label,
    color,
    reorder,
    meta = {},
    onMoveUp,
    onMoveDown,
    id,
    parentId,
  } = props;
  const nodeDefaultValue = fields[type].getDefaultValues();
  const nodeMeta = _.pick(meta, Object.keys(nodeDefaultValue));
  const metaEntries = Object.entries(nodeMeta);

  return (
    <div
      className={b('tree-node', props, { reorder: !!reorder })}
      data-testid="tree-node"
    >
      <div className="tree-node__container">
        {!!reorder && (
          <div className="tree-node__button-group">
            <IconButton
              variant="ghost"
              size="1"
              title="Move up"
              onClick={() => onMoveUp(id, parentId)}
            >
              <DoubleArrowUpIcon />
            </IconButton>

            <IconButton
              variant="ghost"
              size="1"
              title="Move down"
              onClick={() => onMoveDown(id, parentId)}
            >
              <DoubleArrowDownIcon />
            </IconButton>
          </div>
        )}

        <div>
          <div className="tree-node__header">
            <div>
              <span
                className="tree-node__header-subtitle"
                data-testid="tree-node-name"
              >
                [{name}]
              </span>{' '}
              <span data-testid="tree-node-label">{label}</span>
            </div>

            <Badge color={color} data-testid="tree-node-type">
              {COMPONENTS_HEADLINES[type].badge}
            </Badge>
          </div>

          {!!metaEntries.length && (
            <div className="tree-node__body">
              {metaEntries.map(([key, value], index) => (
                <Fragment key={index}>
                  {value?.toString() && (
                    <span className="tree-node__meta">
                      <span>{key}:</span> {value?.toString()}
                    </span>
                  )}
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
