import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { SelectField } from 'src/components/SelectField';
import { ErrorApi } from 'src/components/ErrorApi';
import { Callout } from 'src/components/Callout';
import {
  useInstanceActivate,
  ActivateInstanceArgs,
} from 'src/hooks/api/useInstanceActivate';
import { INSTANCE } from 'src/constants/instance';
import { ApiWorkspacesListResponse, Instance } from 'src/types/Workspace';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { getProjectsOptions } from './utils/getProjectsOptions';
import { getInstancesOptions } from './utils/getInstancesOptions';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';

type InstanceLabel = {
  color: 'grass' | 'cyan' | 'red' | 'gray';
  label: string;
};

const CURRENT_INSTANCE_LABEL: Record<string, InstanceLabel> = {
  [INSTANCE.DEV]: {
    color: 'grass',
    label: 'dev',
  },
  [INSTANCE.STAGE]: {
    color: 'cyan',
    label: 'stage',
  },
  [INSTANCE.PROD]: {
    color: 'red',
    label: 'prod',
  },
};

const validationSchema = yup.object().shape({
  projectId: yup.number().required('Project is a required field'),
  instanceId: yup.number().required('Instance is a required field'),
});

type InstanceActivatePopupProps = {
  workspacesData: ApiWorkspacesListResponse;
  activeInstanceData: Instance | null;
  activeProjectId?: number;
};

export function InstanceActivatePopup({
  activeInstanceData,
  workspacesData,
  activeProjectId,
}: InstanceActivatePopupProps) {
  const { refetchInstanceId } = useInstanceContext();
  const { refetch } = useWorkspacesList();
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: activateInstance,
    isMutating,
    error,
  } = useInstanceActivate({
    onSuccess: () => {
      setIsPopoverOpen(false);
      refetchInstanceId();
      navigate('/');
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      projectId: activeProjectId,
    },
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
  } = methods;

  const handleInstanceUpdate: SubmitHandler<ActivateInstanceArgs> = async (
    data: ActivateInstanceArgs,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    activateInstance({ instanceId: data.instanceId });
  };

  const handlePopupOpenChange = (value: boolean) => {
    setIsPopoverOpen(value);

    if (value) {
      refetch();
    }
  };

  const activeInstanceStyles = activeInstanceData?.env
    ? CURRENT_INSTANCE_LABEL[activeInstanceData?.env]
    : {
        color: 'gray' as const,
        label: '',
      };

  const projectId = watch('projectId');
  const projectOptions = getProjectsOptions({
    workspaces: workspacesData.workspaces || [],
  });
  const instanceOptions = projectId
    ? getInstancesOptions({
        workspaces: workspacesData.workspaces || [],
        projectId,
      })
    : [];

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={handlePopupOpenChange}>
        <Popover.Trigger>
          <Box>
            <Button
              size="1"
              variant="surface"
              color={
                activeInstanceStyles?.label
                  ? activeInstanceStyles?.color
                  : 'tomato'
              }
            >
              {activeInstanceStyles?.label
                ? activeInstanceStyles?.label
                : 'Select instance'}
            </Button>
          </Box>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleInstanceUpdate)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Controller
              control={control}
              name="projectId"
              render={({ field: { value, onChange } }) => {
                const handleSelect = (value: string) => {
                  onChange(+value);
                };

                return (
                  <SelectField
                    errorMessage={errors.projectId?.message}
                    options={projectOptions}
                    value={value?.toString()}
                    onChange={handleSelect as (value: string) => void}
                    label="Project"
                    placeholder="Select project"
                    required
                  />
                );
              }}
            />

            {!!projectId && (
              <Box mt="2">
                <Controller
                  control={control}
                  name="instanceId"
                  render={({ field: { value, onChange } }) => {
                    const handleSelect = (value: string) => {
                      onChange(+value);
                    };

                    return (
                      <SelectField
                        errorMessage={errors.instanceId?.message}
                        options={instanceOptions}
                        value={value?.toString()}
                        onChange={handleSelect as (value: string) => void}
                        label="Instance"
                        placeholder="Select instance"
                        required
                      />
                    );
                  }}
                />
              </Box>
            )}

            <Box mt="4">
              <Callout color="gold">
                Changing instance? Save your work - you'll be redirected to the
                home page.
              </Callout>
            </Box>

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                type="submit"
                color="plum"
                loading={isMutating}
              >
                Change instance
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
