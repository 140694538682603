import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import { MenuItem, MenuNode } from 'src/types/Menu';

type GetTreeFromFlatDataArgs = {
  data: MenuItem[];
};

export const getMenuTreeFromFlatData = ({ data }: GetTreeFromFlatDataArgs) => {
  const tree: MenuNode[] = getTreeFromFlatData({
    flatData: data,
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent_id,
    rootKey: 'root',
  });

  return tree;
};
