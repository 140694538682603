import React from 'react';
import { IconButton } from '@radix-ui/themes';
import { Cross2Icon } from '@radix-ui/react-icons';

type ComboboxValuesProps = {
  onRemove: (id: string) => void;
  selectedValues: { id: string; label: string }[];
};

export function ComboboxValues({
  selectedValues,
  onRemove,
}: ComboboxValuesProps) {
  return (
    <ul className="combobox-values">
      {selectedValues.map((item) => (
        <li key={item.id} className="combobox-values__item" title={item.label}>
          <span className="combobox-values__label">{item.label}</span>
          <IconButton
            variant="soft"
            color="plum"
            size="1"
            className="combobox-values__button"
            onClick={() => onRemove(item.id)}
          >
            <Cross2Icon />
          </IconButton>
        </li>
      ))}
    </ul>
  );
}
