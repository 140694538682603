import { PermissionStatus, PermissionType } from 'src/constants/permissions';
import { ObjectValues } from './Common';

export type PermissionUpdate = {
  permission_id: PermissionType;
  status: PermissionStatus;
};

export type Permission = {
  instance_id: number;
  permission_id: PermissionType;
};

export type RoleDefinition = {
  role_id: string;
  name: string;
  permissions: Permission[] | null;
};

export type ApiRoleDefinition = {
  role_id: number;
  name: string;
  permissions: PermissionType[] | null;
};

export const ROLE_STATUS = {
  CREATE: 1,
  DELETE: 2,
} as const;

export type RoleStatus = ObjectValues<typeof ROLE_STATUS>;
