import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import type { MutationOptions } from 'src/types/Api';

type Data = {
  member_id: number;
  role_id: WorkspaceRolesType;
  workspaceId: number;
};

const updateRole = (arg: Data): Promise<Data> => {
  const { workspaceId, ...data } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/accounts`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  });
};

export function useWorkspaceRoleUpdate(opt?: MutationOptions<Data>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: updateRole,
    meta: {
      name: 'Role assignment',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
