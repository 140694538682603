import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';

export const GET_ACCOUNT_KEY = 'useAccount';

type ResponseType = {
  name: string;
  email: string;
};

export const getAccountData = (): Promise<ResponseType> => {
  return fetcher({ url: `/accounts` });
};

type UseAccountArgs = {
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useAccount({ options }: UseAccountArgs = {}) {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: [GET_ACCOUNT_KEY],
    queryFn: getAccountData,
    meta: {
      name: 'Account info',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
}
