import { ResponseItemField } from 'src/types/Item';

export type ItemFieldsValuesMap = Map<string, Map<string, ResponseItemField>>;

type GetItemFieldsValuesMapArgs = {
  data: ResponseItemField[];
};

export function getItemFieldsValuesMap({ data }: GetItemFieldsValuesMapArgs) {
  const itemMap: ItemFieldsValuesMap = new Map();

  data.forEach((item) => {
    const fieldId = item.template_field_id;
    const groupPath = item.template_group_path;
    if (!groupPath || !fieldId) {
      return;
    }
    const groupPathString = groupPath.join(',');
    const fieldValue = itemMap.get(fieldId);
    if (fieldValue) {
      fieldValue.set(groupPathString, item);

      return;
    }

    const fieldMap: Map<string, ResponseItemField> = new Map();
    fieldMap.set(groupPathString, item);
    itemMap.set(fieldId, fieldMap);
  });

  return itemMap;
}
