import { changeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { MenuItem, MenuNode } from 'src/types/Menu';
import { getNodeKey } from 'src/utils/getNodeKey';

type UpdateNameArgs = {
  treeData: MenuNode[];
  path: number[] | string[];
  node: MenuItem;
  name: string;
};

export function updateName({ treeData, path, node, name }: UpdateNameArgs) {
  const updatedTree: MenuNode[] = changeNodeAtPath({
    treeData,
    // @ts-expect-error: library error path type depends on getNodeKey, in our case it's string[]
    path,
    getNodeKey,
    newNode: { ...node, label: name },
  }) as MenuNode[];

  return updatedTree;
}
