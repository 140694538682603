import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { RoleStatus } from 'src/types/Roles';

export type Role = {
  instance_id: number;
  role_id: number;
  status: RoleStatus;
};

export type UpdateAccountRoleArgs = {
  projectId: number;
  memberId: number;
  roles: Role[];
};

const updateAccountRole = (arg: UpdateAccountRoleArgs): Promise<unknown> => {
  const { projectId, memberId, ...rest } = arg;
  return fetcher({
    url: `/projects/${projectId}/accounts/${memberId}/roles`,
    options: {
      method: 'POST',
      body: JSON.stringify(rest),
    },
  });
};

export function useProjectMemberRolesUpdate(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: updateAccountRole,
    meta: {
      name: "Member's role update",
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    reset,
  };
}
