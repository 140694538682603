import React from 'react';
import _ from 'lodash';
import { Input } from 'src/components/Input';
import * as yup from 'yup';
import { FIELD_TYPE } from 'src/constants/components';
import { Button, Grid } from '@radix-ui/themes';
import { TemplateSettingsForm } from './components/TemplateSettingsForm';
import * as TemplateTypes from 'src/types/Template';
import { UseFormReturn } from 'react-hook-form';
import './DynamicGroup.css';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { useItemFormContext } from 'src/providers/ItemFormProvider';
import { insertDynamicGroupChildren } from '../components/ItemForm/utils/insertDynamicGroupChildren';
import { TreeNode } from 'src/types/Template';
import { nameSchema, labelSchema } from './utils/schema';
import b from 'bem-react-helper';
import { nameTooltip } from './utils/tooltipText';

const settingsValidationSchema = yup.object().shape({
  type: yup.string().oneOf([FIELD_TYPE.DYNAMIC_GROUP]).required(),
  name: nameSchema,
  label: labelSchema,
});

export const getDefaultValues = () => ({
  type: FIELD_TYPE.DYNAMIC_GROUP,
  name: '',
  label: '',
});

export const validate = (
  data: TemplateTypes.SettingsDynamicGroupType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

export const getFieldValidationSchema = () => {
  // don't need validation for dynamic group
  return null;
};

type FieldProps = {
  node: TreeNode;
  data: TemplateTypes.SettingsTextType;
  children: React.ReactNode;
};

export type Color = 'grass' | 'yellow' | 'lime' | 'sky';
const colors: Color[] = ['grass', 'sky', 'yellow', 'lime'];

/* istanbul ignore next -- @preserve ;test with e2e */
export function Field({ data, children, node }: FieldProps) {
  const { setTemplateTree, templateTree, dynamicGroups } = useItemFormContext();
  const handleGroupAdd = () => {
    const fieldId = node.templateFieldId || node.id;
    const children = dynamicGroups.get(fieldId);
    const updatedTree = insertDynamicGroupChildren({
      treeData: templateTree,
      groupId: node.id,
      childrenStamp: children || [],
    });

    setTemplateTree(updatedTree);
  };
  let color: Color = 'lime';
  const groupPath = node?.templateGroupPath;
  if (groupPath) {
    const modulo = groupPath.length % colors.length;
    color = colors[modulo];
  }

  return (
    <div className={b('dynamic-group', {}, { color })}>
      <div className="dynamic-group__label">
        {data.label}
        <Button
          variant="soft"
          onClick={handleGroupAdd}
          type="button"
          size="1"
          color={color}
        >
          <PlusCircledIcon width="16" height="16" />
          Add group
        </Button>
      </div>
      {children}
    </div>
  );
}

type SettingsProps = {
  methods: UseFormReturn<TemplateTypes.SettingsDynamicGroupType>;
};

type ValidFieldNames = keyof TemplateTypes.SettingsDynamicGroupType;

export function Settings({ methods }: SettingsProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Grid gap="4">
      <Input
        label="Label"
        required
        autoFocus
        errorMessage={errors.label?.message}
        {...register<ValidFieldNames>('label')}
      />

      <Input
        label="Name"
        required
        tooltip={nameTooltip}
        errorMessage={errors.name?.message}
        {...register<ValidFieldNames>('name')}
        data-testid="dynamic-group-field-name"
      />
    </Grid>
  );
}

type SettingsFormProps = {
  initialValues: TemplateTypes.SettingsDynamicGroupType;
};

/* istanbul ignore next -- @preserve ;test with e2e */
export function SettingsForm({ initialValues }: SettingsFormProps) {
  // backend can send empty fields from other settings. The reason is that all fields setting should merged by backend.
  // to solve the issue, pick just fields which we need
  const values = _.pick(initialValues, Object.keys(getDefaultValues()));
  return (
    <TemplateSettingsForm
      initialValues={values}
      validationSchema={settingsValidationSchema}
    >
      {(methods: UseFormReturn<TemplateTypes.SettingsDynamicGroupType>) => (
        <Settings methods={methods} />
      )}
    </TemplateSettingsForm>
  );
}
