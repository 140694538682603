import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiWorkspaceRolesResponse } from 'src/types/Workspace';

export const GET_WORKSPACE_ROLES_KEY = 'useWorkspaceRoles';

type GetWorkspaceRolesArg = {
  workspaceId: number;
};

const getWorkspaceRoles = ({
  workspaceId,
}: GetWorkspaceRolesArg): Promise<ApiWorkspaceRolesResponse> => {
  return fetcher({ url: `/workspaces/${workspaceId}/roles` });
};

type UseMenuArgs = {
  workspaceId: number | undefined;
  options?: Omit<
    UseQueryOptions<ApiWorkspaceRolesResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useWorkspaceRoles({ options, workspaceId }: UseMenuArgs) {
  const { data, error, isPending, isError, isSuccess } = useQuery({
    queryKey: [GET_WORKSPACE_ROLES_KEY, workspaceId],
    queryFn: () =>
      workspaceId ? getWorkspaceRoles({ workspaceId }) : undefined,
    meta: {
      name: 'Workspaces roles',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
    isSuccess,
  };
}
