import React from 'react';
import { useId } from 'react-aria';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { TextArea as RadixTextArea } from '@radix-ui/themes';
import {
  textAreaPropDefs,
  GetPropDefTypes,
} from '@radix-ui/themes/dist/esm/props';
import { HelperText } from 'src/components/HelperText';
import { RequiredMark } from 'src/components/RequiredMark';

type BaseTextAreaProps = GetPropDefTypes<typeof textAreaPropDefs>;

type TextFieldProps = {
  mods?: Mods;
  mix?: Mix;
  label?: string | React.ReactNode;
  errorMessage?: string | undefined;
  id?: string;
  required?: boolean;
  labelSuffix?: React.ReactNode;
  helperText?: React.ReactNode | string;
};

type CombinedProps = TextFieldProps &
  Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    'size' | 'defaultValue' | 'value'
  > &
  BaseTextAreaProps & { rows: number };

export const TextArea = React.forwardRef<HTMLTextAreaElement, CombinedProps>(
  (props, ref) => {
    const {
      label,
      errorMessage,
      id,
      required,
      labelSuffix,
      mods,
      mix,
      helperText,
      ...rest
    } = props;
    const fieldId = useId();

    return (
      <div className={b('text-area', { mods, mix })}>
        {label && (
          <label className="text-area__label" htmlFor={id || fieldId}>
            <span>
              {label} {required && <RequiredMark />}
            </span>
            {labelSuffix}
          </label>
        )}
        <RadixTextArea
          className="text-area__control"
          ref={ref}
          id={id || fieldId}
          {...rest}
        />
        {!!helperText && (
          <HelperText mix="text-area__helper-text">{helperText}</HelperText>
        )}

        {!!errorMessage && (
          <div className="text-area__error">
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </div>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
