import { ObjectValues } from 'src/types/Common';

export const INSTANCE = {
  DEV: 'Dev',
  STAGE: 'Stage',
  PROD: 'Prod',
} as const;

export type InstanceType = ObjectValues<typeof INSTANCE>;

export const INSTANCE_BADGE_COLOR = {
  [INSTANCE.DEV]: 'grass',
  [INSTANCE.STAGE]: 'cyan',
  [INSTANCE.PROD]: 'tomato',
} as const;
