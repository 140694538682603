import { INSTANCE } from 'src/constants/instance';
import { DevType, InstanceValue, StageType } from 'src/types/Workspace';

type InstanceBadgeData = {
  label: string;
  color: 'grass' | 'cyan' | 'tomato';
};

const INSTANCE_BADGE_DATA: Record<InstanceValue, InstanceBadgeData> = {
  [INSTANCE.DEV]: {
    label: 'dev',
    color: 'grass',
  },
  [INSTANCE.STAGE]: {
    label: 'stage',
    color: 'cyan',
  },
  [INSTANCE.PROD]: {
    label: 'prod',
    color: 'tomato',
  },
};

const INSTANCE_SYNC_DESTINATION = {
  [INSTANCE.DEV]: INSTANCE.STAGE,
  [INSTANCE.STAGE]: INSTANCE.PROD,
};

export const getInstanceSourceAndDestination = (
  instanceType: DevType | StageType
) => {
  const destinationType = INSTANCE_SYNC_DESTINATION[instanceType];
  return {
    source: INSTANCE_BADGE_DATA[instanceType],
    destination: INSTANCE_BADGE_DATA[destinationType],
  };
};
