import React from 'react';
import {
  Tabs,
  Tabs__Content,
  Tabs__Item,
  Tabs__List,
} from 'src/components/Tabs';
import { Box, Flex } from '@radix-ui/themes';
import { FieldsTable, FieldsTableType } from './FieldsTable';
import { TemplateField } from 'src/types/Template';
import { FIELD_TYPE, TEMPLATE_FIELD_STATUS } from 'src/constants/components';

type FieldsReviewProps = {
  fields: TemplateField[];
};

export function FieldsReview({ fields }: FieldsReviewProps) {
  const getData = (fields: TemplateField[]) => {
    const added: FieldsTableType[] = [];
    const updated: FieldsTableType[] = [];
    const removed: FieldsTableType[] = [];

    fields.forEach((field) => {
      if (field.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW) {
        return;
      }

      if (field.status === TEMPLATE_FIELD_STATUS.ADDED) {
        added.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }

      if (field.status === TEMPLATE_FIELD_STATUS.REMOVED) {
        removed.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }

      if (field.status === TEMPLATE_FIELD_STATUS.UPDATED) {
        updated.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }
    });

    return { added, removed, updated };
  };

  const data = getData(fields);
  return (
    <div className="report-table">
      <Tabs defaultValue="removed">
        <Flex>
          <Tabs__List>
            <Tabs__Item value="removed">Removed fields</Tabs__Item>
            <Tabs__Item value="updated">Updated fields</Tabs__Item>
            <Tabs__Item value="added">Added fields</Tabs__Item>
          </Tabs__List>
        </Flex>

        <Box pt="3">
          <Tabs__Content value="removed">
            <FieldsTable data={data.removed} />
          </Tabs__Content>

          <Tabs__Content value="updated">
            <FieldsTable data={data.updated} />
          </Tabs__Content>

          <Tabs__Content value="added">
            <FieldsTable data={data.added} />
          </Tabs__Content>
        </Box>
      </Tabs>
    </div>
  );
}
