import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, Flex } from '@radix-ui/themes';
import b, { Mix, Mods } from 'bem-react-helper';
import {
  PERMISSIONS,
  PERMISSIONS__LABELS,
  PermissionsKeys,
} from 'src/constants/permissions';
import { Checkbox } from 'src/components/Checkbox';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from '../types/FormValues';

type PermissionTableType = {
  permissionKey: PermissionsKeys;
  checked: boolean;
};

const columnHelper = createColumnHelper<PermissionTableType>();

type RolesTableProps = {
  mods?: Mods;
  mix?: Mix;
  methods: UseFormReturn<FormValues>;
};

const getData = () => {
  const data: PermissionTableType[] = [];
  Object.entries(PERMISSIONS).forEach(([key]) => {
    const row = {
      permissionKey: key as PermissionsKeys,
      checked: false,
    };

    data.push(row);
  });

  return data;
};

const getCheckboxId = (id: number) => {
  return `permission-${id}`;
};

export function RolesTable(props: RolesTableProps) {
  const { methods } = props;
  const {
    register,
    formState: { errors },
  } = methods;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('permissionKey', {
        cell: (info) => {
          const permissionKey = info.getValue();
          const permissionId = PERMISSIONS[permissionKey];
          return (
            <label htmlFor={getCheckboxId(permissionId)}>
              {PERMISSIONS__LABELS[permissionId]}
            </label>
          );
        },
        header: () => <span>Permissions</span>,
      }),
      columnHelper.accessor('checked', {
        cell: (info) => {
          const permissionKey = info.row.original.permissionKey;
          const permissionId = PERMISSIONS[permissionKey];
          return (
            <Flex justify="center">
              <Checkbox
                id={getCheckboxId(permissionId)}
                mods={{ type: 'primary' }}
                errorMessage={errors.permissions?.[permissionKey]?.message}
                {...register(`permissions.${permissionKey}`)}
              />
            </Flex>
          );
        },
        header: () => <span></span>,
      }),
    ],
    [errors.permissions, register]
  );
  const data = React.useMemo(() => getData(), []);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface" className={b('project-roles-table', props)}>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell key={headerIndex}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <Table.Row key={rowIndex}>
              {row.getVisibleCells().map((cell, cellIndex) => (
                <Table.Cell key={cellIndex}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </>
  );
}
