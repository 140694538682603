import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type MoveAllChangesArgs = {
  sourceInstanceId: number;
};

const moveAllChanges = (arg: MoveAllChangesArgs): Promise<unknown> => {
  return fetcher({
    url: `/instances/${arg.sourceInstanceId}/changes`,
    options: {
      method: 'POST',
      body: JSON.stringify({}),
    },
  });
};

export function useInstanceMoveAllChanges(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: moveAllChanges,
    meta: {
      name: 'Move all changes',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
