import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { SubscriptionMembersUpdate } from 'src/types/Subscription';

type Data = {
  workspaceId: string;
  new_value: number;
};

const getMembersPricePreview = (
  arg: Data
): Promise<SubscriptionMembersUpdate> => {
  const { workspaceId, new_value } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/subscriptions/members/preview`,
    options: {
      method: 'POST',
      body: JSON.stringify({ new_value }),
    },
  });
};

export function useSubscriptionMembersUpdatePreview(
  opt?: MutationOptions<SubscriptionMembersUpdate>
) {
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: getMembersPricePreview,
    meta: {
      name: 'Members price preview',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    data,
  };
}
