import * as yup from 'yup';
import { isFieldNameUnique } from 'src/utils/isFieldNameUnique';

export const nameSchema = yup
  .string()
  .min(1)
  .max(64)
  .required()
  .test(
    'isUnique',
    'name must be unique within the same parent',
    (value, context) => {
      const templateTree = context?.options?.context?.templateTree || [];
      return isFieldNameUnique({ tree: templateTree, name: value });
    }
  )
  .test(
    'notEqualId',
    'please choose a different name, "id" is reserved',
    (value) => {
      return value !== 'id';
    }
  )
  .test(
    'isValidFormat',
    'name can only consist of Latin letters, numbers, underscores (_) and hyphens (-)',
    (value) => {
      // regular expression to match only Latin letters, numbers, underscores, and hyphens
      const regex = /^[a-zA-Z0-9_-]+$/;
      return regex.test(value);
    }
  );

export const labelSchema = yup.string().min(1).max(96).required();

export const minimumIntegerSchema = yup
  .number()
  .integer('should be integer')
  .nullable()
  .typeError('should be a number')
  .when('maximum', {
    is: (val: number | null) => typeof val === 'number',
    then: (schema) =>
      schema.max(
        yup.ref('maximum'),
        'must be less than or equal to maximum value'
      ),
  });

export const maximumIntegerSchema = yup
  .number()
  .integer('should be integer')
  .nullable()
  .typeError('should be a number')
  .when('minimum', {
    is: (val: number | null) => typeof val === 'number',
    then: (schema) =>
      schema.min(
        yup.ref('minimum'),
        'must be greater than or equal to minimum value'
      ),
  });

export const minimumFloatSchema = yup
  .number()
  .nullable()
  .typeError("should be a number with '.' as a decimal separator")
  .when('maximum', {
    is: (val: number | null) => typeof val === 'number',
    then: (schema) =>
      schema.max(
        yup.ref('maximum'),
        'must be less than or equal to maximum value'
      ),
  });

export const maximumFloatSchema = yup
  .number()
  .nullable()
  .typeError("should be a number with '.' as a decimal separator")
  .when('minimum', {
    is: (val: number | null) => typeof val === 'number',
    then: (schema) =>
      schema.min(
        yup.ref('minimum'),
        'must be greater than or equal to minimum value'
      ),
  });
