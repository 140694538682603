/* istanbul ignore file -- @preserve */
import { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import { TreeNode } from 'src/types/Template';

type ExpandTemplateArgs = {
  templateTree: TreeNode[];
};

export function expandTemplate({ templateTree }: ExpandTemplateArgs) {
  return toggleExpandedForAll({ treeData: templateTree }) as TreeNode[];
}
