import React from 'react';
import { Combobox } from 'src/components/Combobox';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Box } from '@radix-ui/themes';
import { ComboboxValues } from 'src/components/ComboboxValues';
import { Control } from 'react-hook-form';
import { getFilteredOptions } from 'src/utils/getFilteredOptions';

type Option = {
  id: string;
  label: string;
};

type ComboboxMultipleFieldProps<T extends FieldValues> = {
  control: Control<T>;
  errorMessage?: string;
  name: Path<T>;
  required?: boolean;
  label: string | React.ReactNode;
  options: Option[];
  placeholder?: string;
  hideMeta?: boolean;
  nullable?: boolean;
  isCustomFilter?: boolean;
};

export function ComboboxMultipleField<T extends FieldValues>({
  control,
  errorMessage,
  name,
  required,
  label,
  options,
  hideMeta,
  isCustomFilter,
  nullable = false,
  placeholder = 'Search',
}: ComboboxMultipleFieldProps<T>) {
  const [query, setQuery] = React.useState('');

  const removeItem = (id: string, value: Option[]) => {
    const filteredItems = value.filter((item) => item.id !== id);

    return filteredItems;
  };

  const getIds = (value: Option[] | null) => {
    if (!value) {
      return null;
    }
    const IDs = value.map((item) => item.id);
    return IDs.length ? IDs : null;
  };

  const transformIdsToOptions = (
    value: (string | undefined)[] | null | undefined
  ) => {
    if (!value || !options) {
      return [];
    }

    const selectedOptions: Option[] = [];

    value.forEach((id) => {
      const foundOption = options.find((option) => option.id === id);
      if (foundOption) {
        selectedOptions.push(foundOption);
      }
    });

    return selectedOptions;
  };

  const comboboxOptions = isCustomFilter
    ? options
    : getFilteredOptions({
        value: query,
        options: options || [],
      });

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        const handleChange = (value: Option[] | null) => {
          const IDs = getIds(value);

          onChange(IDs as PathValue<T, Path<T>>);
        };
        return (
          <>
            <Combobox
              onChange={handleChange}
              onInputChange={setQuery}
              value={transformIdsToOptions(value as string[])}
              inputValue={query}
              options={comboboxOptions}
              label={label}
              placeholder={placeholder}
              required={required}
              hideMeta={hideMeta}
              // @ts-expect-error: problem with types in headless ui https://github.com/tailwindlabs/headlessui/issues/2438
              nullable={nullable}
              multiple
            />

            {!!options?.length && (
              <ComboboxValues
                selectedValues={transformIdsToOptions(value as string[])}
                onRemove={(id) => {
                  const selectedOptions = transformIdsToOptions(
                    value as string[]
                  );
                  const filteredItems = removeItem(id, selectedOptions);
                  const IDs = getIds(filteredItems);
                  onChange(IDs as PathValue<T, Path<T>>);
                }}
              />
            )}

            {errorMessage && (
              <Box mt="1">
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
          </>
        );
      }}
      control={control}
      name={name}
    />
  );
}
