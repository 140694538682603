import type { Role } from 'src/hooks/api/useProjectMemberRolesUpdate';
import type { RoleStatus } from 'src/types/Roles';
import type { Instances } from './getInstancesIds';
import type { InstanceValue } from 'src/types/Workspace';
import type { AddUserFormValues } from '../types/FormValues';

type AddNewRolesForInstanceArgs = {
  formData: AddUserFormValues;
  instanceKey: InstanceValue;
  instances: Instances;
  roles: Role[];
  roleStatus: RoleStatus;
};

export const addNewRolesForInstance = ({
  formData,
  instanceKey,
  instances,
  roles,
  roleStatus,
}: AddNewRolesForInstanceArgs) => {
  formData[instanceKey]?.forEach((roleId) => {
    const instanceId = instances[instanceKey];
    if (typeof instanceId !== 'number') {
      throw new Error('instanceId is not a number');
    }
    const role: Role = {
      instance_id: instanceId,
      status: roleStatus,
      role_id: Number(roleId),
    };
    roles.push(role);
  });
};
