import { useMutation } from '@tanstack/react-query';
import { MutationOptions, ApiError } from 'src/types/Api';
import { useTurnstile } from './useTurnstile';
import { fetcher } from 'src/utils/fetcher';
import { isChallengeRequired } from '../utils/isChallengeRequired';

export function useApiMutationTurnstile(opt?: MutationOptions<unknown>) {
  const {
    runTurnstileChallenge,
    isChallenging,
    error: challengeError,
  } = useTurnstile();

  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: async (args: RequestArgs) => {
      return await executeRequest(args, runTurnstileChallenge);
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    reset,
    error: isError ? error : null,
    isChallenging,
    challengeError,
  };
}

type RequestArgs = {
  url: string;
  method: string;
  body?: unknown;
};

async function executeRequest(
  args: RequestArgs,
  runTurnstileChallenge: () => Promise<unknown>
) {
  const makeRequest = async () => {
    return fetcher({
      url: args.url,
      options: {
        method: args.method,
        body: JSON.stringify(args.body),
      },
      isPublic: true,
    });
  };

  try {
    const response = await makeRequest();

    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    if (isChallengeRequired({ headers: apiError.headers })) {
      await runTurnstileChallenge();
      const response = await makeRequest();

      return response.data;
    } else {
      throw error;
    }
  }
}
