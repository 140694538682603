import * as Sentry from '@sentry/react';
import React from 'react';
import { MemberForm } from './components/MemberForm';
import type { AddUserFormValues } from './types/FormValues';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useWorkspaceRoles } from 'src/hooks/api/useWorkspaceRoles';
import { getProjectById } from 'src/utils/getProjectById';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { appRoutes } from 'src/utils/routePaths';
import { Skeleton } from '@radix-ui/themes';
import {
  Role,
  useProjectMemberRolesUpdate,
} from 'src/hooks/api/useProjectMemberRolesUpdate';
import { addUserFormValidationSchema } from './utils/validationSchema';
import { ROLE_STATUS } from 'src/types/Roles';
import { Instances } from './utils/getInstancesIds';
import { addNewRolesForInstance } from './utils/addNewRolesForInstance';
import { getAvailableInstances } from './utils/getAvailableInstances';
import { AccessPage } from './components/AccessPage';

export function ProjectAddMember() {
  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const projectId = Number(params.projectId);
  const {
    data: workspacesList,
    isLoading: isWorkspaceLoading,
    error: workspaceListError,
  } = useWorkspacesList();
  const {
    data: rolesData,
    isLoading: isRolesLoading,
    error: rolesError,
  } = useWorkspaceRoles({ workspaceId });
  const {
    mutate: addMember,
    isMutating: isMemberSaving,
    error: memberSavingError,
    reset: resetMemberMutation,
  } = useProjectMemberRolesUpdate({
    onSuccess: () => {
      navigate(appRoutes.settingsProject({ projectId, workspaceId }));
    },
  });

  const availableInstances = getAvailableInstances({
    workspaces: workspacesList?.workspaces || [],
    projectId,
  });
  const instanceKeys = Object.keys(availableInstances) as Array<
    keyof Instances
  >;

  const handleSubmit = (formData: AddUserFormValues) => {
    try {
      const roles: Role[] = [];
      instanceKeys.forEach((item) => {
        addNewRolesForInstance({
          formData,
          instanceKey: item,
          instances: availableInstances,
          roles,
          roleStatus: ROLE_STATUS.CREATE,
        });
      });

      addMember({
        projectId: projectId,
        memberId: Number(formData.memberId),
        roles,
      });
    } catch (error) {
      // catch error if some instances ids not found
      const sentryEventId = Sentry.captureException(error);
      return navigate(appRoutes.error(), { state: { sentryEventId } });
    }
  };

  if (isWorkspaceLoading || isRolesLoading) {
    return (
      <>
        <Page__Header
          title="Add member to project"
          backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        />

        <Page__Content>
          <Skeleton width="300px" height="29px" />
        </Page__Content>
      </>
    );
  }

  if (workspaceListError || rolesError) {
    return (
      <>
        <Page__Header
          title="Add member to project"
          backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        />

        <Page__Content>
          {workspaceListError && <ErrorApi error={workspaceListError} />}

          {rolesError && <ErrorApi error={rolesError} />}
        </Page__Content>
      </>
    );
  }

  const projectData = getProjectById({
    projectId,
    workspaces: workspacesList?.workspaces || [],
  });

  if (!rolesData || !workspacesList || !projectData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  if (!instanceKeys.length) {
    return (
      <AccessPage
        projectId={projectId}
        workspaceId={workspaceId}
        projectName={projectData?.name}
      />
    );
  }

  return (
    <>
      <MemberForm
        workspaceId={workspaceId}
        projectId={projectId}
        projectName={projectData.name}
        resetMemberMutation={resetMemberMutation}
        isMemberSaving={isMemberSaving}
        memberSavingError={memberSavingError}
        roles={rolesData?.roles || []}
        onSubmit={handleSubmit}
        validationSchema={addUserFormValidationSchema}
        availableInstances={availableInstances}
      />
    </>
  );
}
