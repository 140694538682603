import { useParams } from 'react-router-dom';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { getWorkspaceByInstanceId } from 'src/utils/getWorkspaceByInstanceId';
import { useSubscription } from './api/useSubscription';

type useActivePlanArgs = {
  selectedWorkspaceId?: string;
};

export function useActiveSubscription({
  selectedWorkspaceId,
}: useActivePlanArgs) {
  const { instanceId } = useInstanceContext();
  const { data: workspacesData, isLoading: isWorkspacesDataLoading } =
    useWorkspacesList();
  const params = useParams();
  let workspaceId = params.workspaceId || selectedWorkspaceId;
  if (!workspaceId) {
    const activeWorkspace = getWorkspaceByInstanceId({
      workspaces: workspacesData?.workspaces,
      instanceId,
    });

    if (activeWorkspace?.id) {
      workspaceId = activeWorkspace.id.toString();
    }
  }
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useSubscription({
      workspaceId: workspaceId?.toString(),
    });

  return {
    subscription,
    isLoading: isWorkspacesDataLoading || isSubscriptionLoading,
  };
}
