import React from 'react';
import { useExportGetDownloadUrl } from 'src/hooks/api/useExportGetDownloadUrl';
import { useExportGetFileJson } from 'src/hooks/api/useExportGetFileJson';
import { DownloadIcon } from '@radix-ui/react-icons';
import { Toast } from 'src/components/Toast';
import { Text, Button } from '@radix-ui/themes';

type DownloadFileProps = {
  path: string;
  fileName: string;
};

export function DownloadFile({ path, fileName }: DownloadFileProps) {
  const { isLoading: isFileUrlLoading, refetch: fetchUrl } =
    useExportGetDownloadUrl({
      pathFromReport: path,
      fileName,
    });

  const {
    isLoading: isJsonLoading,
    fetchFileJson,
    error: exportFileError,
  } = useExportGetFileJson();

  React.useEffect(() => {
    const error = exportFileError;
    if (error) {
      Toast({
        text: `${error.message}` || (
          <Text>
            Something went wrong.
            <br /> Please try again later
          </Text>
        ),
        variant: 'error',
      });
    }
  }, [exportFileError]);

  const downloadJsonFile = (jsonData: unknown, filename: string) => {
    const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleClick = async () => {
    const { data: fileData } = await fetchUrl();
    if (!fileData?.url) {
      return;
    }
    const data = await fetchFileJson(fileData?.url);

    if (data?.length) {
      downloadJsonFile(data, fileName);
    }
  };

  return (
    <>
      <Button
        color="indigo"
        variant="ghost"
        onClick={handleClick}
        loading={isJsonLoading || isFileUrlLoading}
      >
        <DownloadIcon /> Download
      </Button>
    </>
  );
}
