import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type AccountEmailUpdateArg = {
  email: string;
  code: string;
};

const updateEmail = (
  arg: AccountEmailUpdateArg
): Promise<AccountEmailUpdateArg> => {
  return fetcher({
    url: `/accounts/change-email/confirmation`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useAccountEmailUpdateConfirmation(
  opt?: MutationOptions<AccountEmailUpdateArg>
) {
  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: updateEmail,
    meta: {
      name: 'Email update confirmation',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    reset,
  };
}
