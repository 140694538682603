import type { ResponseItemField } from 'src/types/Item';

type TemplateGroupId = string;
type TemplateGroupPath = string;
type AmountOfRows = number;
type SetOfChildrenPaths = Set<string>;

type GetDynamicGroupsMapFromItemType = {
  data: ResponseItemField[];
};

export function getDynamicGroupRowsMap({
  data,
}: GetDynamicGroupsMapFromItemType) {
  const dataMap: Map<
    TemplateGroupId,
    Map<TemplateGroupPath, SetOfChildrenPaths>
  > = new Map();

  data.forEach((field) => {
    const groupId = field.template_group_id;
    if (!groupId) {
      return; // Skip fields without a group ID
    }

    const fieldPath = field.template_group_path || [];
    const parentPath = fieldPath.length > 1 ? fieldPath.slice(0, -1) : ['root'];
    const parentPathString = parentPath.join(',');

    // Get or initialize the map for the group
    const groupMap: Map<TemplateGroupPath, SetOfChildrenPaths> =
      dataMap.get(groupId) || new Map();
    dataMap.set(groupId, groupMap);

    // Get or initialize the set for the unique paths at this parent path
    const pathSet = groupMap.get(parentPathString) || new Set();
    groupMap.set(parentPathString, pathSet);

    // Add the current full path as a unique entry to the set
    pathSet.add(fieldPath.join(','));
  });

  const result: Map<
    TemplateGroupId,
    Map<TemplateGroupPath, AmountOfRows>
  > = new Map();

  // Convert the sets into counts of unique child paths
  dataMap.forEach((groupMap, groupId) => {
    const countMap = new Map();
    groupMap.forEach((pathSet, parentPath) => {
      countMap.set(parentPath, pathSet.size);
    });
    result.set(groupId, countMap);
  });

  return result;
}
