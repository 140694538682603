import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from './Form.jsx';
import { useTemplateById } from 'src/hooks/api/useTemplateById';
import { useCreateItem } from 'src/hooks/api/useCreateItem';
import {
  ItemFormProvider,
  useItemFormContext,
} from 'src/providers/ItemFormProvider';
import { getListOfFieldsFromItem } from 'src/pages/item/utils/getListOfFieldsFromItem';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { appRoutes } from 'src/utils/routePaths';
import { getTreeWithoutDynamicGroupChildren } from './utils/getTreeWithoutDynamicGroupChildren';
import { SkeletonPage } from './SkeletonPage';

function New() {
  const { instanceId } = useInstanceContext();
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: initialTemplate,
    error: templateError,
    isLoading: isTemplateLoading,
  } = useTemplateById({
    id: params?.templateId,
    options: {
      gcTime: 0, // disable cache by default to avoid problems in form edit
      staleTime: 0, // disable cache by default to avoid problems in form edit
    },
  });
  const {
    mutate: createItem,
    error: itemSaveError,
    isMutating: isItemSaving,
  } = useCreateItem({
    onSuccess: onSuccessSave,
  });
  const { templateTree, setDynamicGroups, setTemplateTree } =
    useItemFormContext();
  const [isFormInit, setIsFormInit] = React.useState(false);

  React.useEffect(() => {
    if (!isFormInit && initialTemplate) {
      setIsFormInit(true);

      const fields = initialTemplate?.fields;
      if (!fields) {
        return;
      }

      const { dynamicGroupsMap, tree: treeWithoutDynamicGroupChildren } =
        getTreeWithoutDynamicGroupChildren({
          templateFields: initialTemplate.fields || [],
          itemFields: [],
        });

      setDynamicGroups(dynamicGroupsMap);
      setTemplateTree(treeWithoutDynamicGroupChildren);
    }
  }, [initialTemplate, isFormInit, setDynamicGroups, setTemplateTree]);

  const handleSubmit = ({
    data: formData,
  }: {
    data: { [key: string]: string | number | number[] };
  }) => {
    const { internalLabel, ...fieldsValues } = formData;
    if (!initialTemplate) {
      // throw error, smth weird happened in this case
      return;
    }

    const fields = getListOfFieldsFromItem({
      formData: fieldsValues,
      templateTree,
    });

    createItem({
      fields,
      template_id: initialTemplate.id,
      internal_label: internalLabel as string,
    });
  };

  function onSuccessSave() {
    const url = appRoutes.items({
      instanceId,
      templateId: params.templateId || '',
    });
    navigate(url);
  }

  if (templateError) {
    return (
      <>
        <Page__Header title="New item" />

        <Page__Content>
          <ErrorApi error={templateError} />
        </Page__Content>
      </>
    );
  }

  if (isTemplateLoading || !initialTemplate) {
    return (
      <>
        <Page__Header
          title="New item"
          backButtonUrl={appRoutes.items({
            instanceId,
            templateId: params?.templateId || '',
          })}
        />

        <Page__Content>
          <SkeletonPage />
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Form
        initialTemplate={initialTemplate}
        onSubmit={handleSubmit}
        error={itemSaveError}
        isSaving={isItemSaving}
      />
    </>
  );
}

export function ItemNew() {
  return (
    <ItemFormProvider>
      <New />
    </ItemFormProvider>
  );
}
