import { Navigate, Outlet, useParams } from 'react-router-dom';
import React from 'react';
import { appRoutes } from 'src/utils/routePaths';
import { useUserContext } from 'src/providers/UserProvider';
import { PermissionType } from 'src/constants/permissions';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import { checkAccess } from 'src/utils/checkAccess';

type RoleBasedRouteProps = {
  roles: WorkspaceRolesType[];
  permissionsGroups?: PermissionType[][];
  forbidden?: boolean;
  ignorePermissions?: boolean;
  children?: React.ReactNode;
};

export function SettingsRoleBasedRoute({
  roles = [],
  permissionsGroups = [],
  ignorePermissions = false,
  children,
}: RoleBasedRouteProps) {
  const { workspaceId } = useParams();
  const {
    permissions: userPermissions,
    workspacesRoleMap,
    isLoading,
  } = useUserContext();
  const userRole = workspacesRoleMap.get(Number(workspaceId));

  if (isLoading) {
    return null;
  }

  if (!userRole) {
    return <Navigate to={appRoutes.accessDenied()} />;
  }

  const { hasPermission, hasRole } = checkAccess({
    ignorePermissions,
    userPermissions: userPermissions || [],
    userRole,
    roles,
    permissionsGroups,
  });

  // For restricted routes, check roles and permissions
  if (!hasRole || !hasPermission) {
    return <Navigate to={appRoutes.accessDenied()} />;
  }

  return <>{children || <Outlet />}</>;
}
