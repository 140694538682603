import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { Subscription } from 'src/types/Subscription';

export type SubscriptionState = {
  trial: boolean;
  active: boolean;
  canceled: boolean;
  pastDue: boolean;
  cancelRequested: boolean;
};

type GetSubscriptionStateArgs = {
  subscription?: Subscription;
};

export function getSubscriptionState({
  subscription,
}: GetSubscriptionStateArgs): SubscriptionState {
  return {
    active: subscription?.status === SUBSCRIPTION_STATUS.ACTIVE,
    trial: subscription?.status === SUBSCRIPTION_STATUS.TRIAL,
    canceled: subscription?.status === SUBSCRIPTION_STATUS.CANCELED,
    pastDue: subscription?.status === SUBSCRIPTION_STATUS.PAST_DUE,
    cancelRequested:
      subscription?.status === SUBSCRIPTION_STATUS.CANCEL_REQUESTED,
  };
}
