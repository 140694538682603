import React from 'react';
import { ErrorPage } from 'src/components/ErrorPage';
// import error404UrlWebp from 'src/assets/error-404.webp';
// import error404Url from 'src/assets/error-404.png';
import { Box, Button, Text } from '@radix-ui/themes';
import { Link as RouterLink } from 'react-router-dom';

export function ErrorNotFound() {
  return (
    <ErrorPage
      // imgSrc={error404Url}
      // imgSrcWebp={error404UrlWebp}
      // imgAlt="Cartoon character with magnifying glass"
      headline="Page not found"
    >
      <Box mb="6">
        <Text size="4">
          Oops! We couldn't find the page you were looking for.
        </Text>
      </Box>

      <Button variant="soft" color="plum" asChild mb="2" size="3">
        <RouterLink to="/">Return home</RouterLink>
      </Button>
    </ErrorPage>
  );
}
