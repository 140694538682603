import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { RoleForm } from './components/RoleForm';
import { FormValues } from './types/FormValues';
import { useWorkspaceRoleDefinitionUpdate } from 'src/hooks/api/useWorkspaceRoleDefinitionUpdate';
import { getPermissionsFromNewForm } from './utils/getPermissionsFromNewForm';
import { RolesFormSkeleton } from './components/RolesFormSkeleton';

export function ProjectRoleNew() {
  const navigate = useNavigate();
  const {
    data: workspacesList,
    isLoading: isWorkspaceLoading,
    error: workspaceListError,
  } = useWorkspacesList();
  const {
    mutate: createRole,
    isMutating: isRoleCreating,
    error: roleCreationError,
    reset: resetRoleMutation,
  } = useWorkspaceRoleDefinitionUpdate({
    onSuccess: () => {
      navigate(appRoutes.settingsWorkspaceRoles({ workspaceId }));
    },
  });
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  if (isWorkspaceLoading) {
    return (
      <>
        <Page__Header
          title="Create new role"
          backButtonUrl={appRoutes.settingsWorkspaceRoles({ workspaceId })}
        />

        <Page__Content>
          <RolesFormSkeleton />
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !workspacesList?.workspaces) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  if (workspaceListError) {
    return (
      <>
        <Page__Header
          title="Create new role"
          backButtonUrl={appRoutes.settingsWorkspaceRoles({ workspaceId })}
        />

        <Page__Content>
          <ErrorApi error={workspaceListError} />
        </Page__Content>
      </>
    );
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: workspacesList?.workspaces,
  });

  if (!workspaceData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const handleSubmit = (formData: FormValues) => {
    const addedPermissions = getPermissionsFromNewForm(formData);

    createRole({
      permissions: addedPermissions,
      name: formData.name,
      workspace_id: workspaceId,
    });
  };

  return (
    <>
      <RoleForm
        onSubmit={handleSubmit}
        workspaceName={workspaceData.name}
        isRoleSaving={isRoleCreating}
        roleSavingError={roleCreationError}
        resetRoleMutation={resetRoleMutation}
        workspaceId={workspaceId}
      />
    </>
  );
}
