import React from 'react';
import { fetcher } from 'src/utils/fetcher';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';

const login = (): Promise<unknown> => {
  return fetcher({
    url: '/accounts/logout',
    options: {
      method: 'POST',
    },
  });
};

export function useLogout() {
  const navigate = useNavigate();
  const onSuccess = React.useCallback(
    () => navigate(appRoutes.login()),
    [navigate]
  );

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: login,
    meta: {
      name: 'Logout',
    },
    onSuccess,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
