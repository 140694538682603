import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiItemHeadersListResponse } from 'src/types/Item';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const ITEMS_LIST_KEY = 'useItemsHeadersList';

const getItemsList = (
  id: string,
  instanceId: number
): Promise<ApiItemHeadersListResponse> => {
  return fetcher({ url: `/editor/${instanceId}/items/${id}/headers` });
};

type UseItemsHeadersListArgs = {
  templateId: string | undefined;
  options?: Omit<
    UseQueryOptions<ApiItemHeadersListResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useItemsHeadersList({
  templateId,
  options,
}: UseItemsHeadersListArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError } = useQuery({
    queryKey: [ITEMS_LIST_KEY, templateId, instanceId],
    queryFn: () =>
      templateId ? getItemsList(templateId, instanceId) : undefined,
    enabled: !!templateId && instanceId !== -1,
    meta: {
      name: 'Items headers',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
  };
}
