import React from 'react';
import { Box, Heading, Text, Link } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';

export function Notification() {
  return (
    <PageAccent mods={{ type: 'primary' }}>
      <Heading mb="4" data-testid="password-reset-notification">
        Check your inbox
      </Heading>

      <Box mb="4">
        <Text>
          If&nbsp;we&nbsp;find an&nbsp;account with this email, you will receive
          instructions to&nbsp;reset your password.
        </Text>
      </Box>

      <Box>
        <Text>
          Didn&rsquo;t get the instructions? Email&nbsp;us at&nbsp;
          <Link href="mailto:support@lootfabriq.io">support@lootfabriq.io</Link>
          .
        </Text>
      </Box>
    </PageAccent>
  );
}
