import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { TemplateField } from 'src/types/Template';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type SaveTemplateArgs = {
  id: string;
  label: string;
  fields: TemplateField[];
  version: number;
  instanceId: number;
};

const saveTemplate = (arg: SaveTemplateArgs): Promise<unknown> => {
  return fetcher({
    url: `/editor/${arg.instanceId}/templates`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useSaveTemplate(opt?: MutationOptions<unknown>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (args: Omit<SaveTemplateArgs, 'instanceId'>) =>
      saveTemplate({ ...args, instanceId }),
    meta: {
      name: 'Save template',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
