import React from 'react';
import { RolesTable } from './RolesTable';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'src/components/Input';
import { AlertDialog } from 'src/components/AlertDialog';
import { Box, Flex, Text, Button } from '@radix-ui/themes';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import type { ApiError } from 'src/types/Api';
import { FormValues } from '../types/FormValues';
import { validationSchema } from '../utils/validationSchema';
import { appRoutes } from 'src/utils/routePaths';

type RoleFormProps = {
  onSubmit: (data: FormValues) => void;
  workspaceName: string;
  isRoleSaving: boolean;
  roleSavingError: ApiError | null;
  roleName?: string;
  defaultValues?: FormValues;
  resetRoleMutation: () => void;
  workspaceId: number;
};

export function RoleForm({
  onSubmit,
  workspaceName,
  isRoleSaving,
  roleSavingError,
  roleName,
  defaultValues,
  resetRoleMutation,
  workspaceId,
}: RoleFormProps) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    React.useState(false);
  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const submitForm: SubmitHandler<FormValues> = async (
    data: FormValues,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    onSubmit(data);
  };

  const {
    trigger,
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const saveForm = async () => {
    const isValid = await trigger();

    if (isValid) {
      setIsConfirmationDialogOpen(true);
      resetRoleMutation();
    }
  };

  return (
    <>
      <Page__Header
        backButtonUrl={appRoutes.settingsWorkspaceRoles({ workspaceId })}
        title={
          <Flex gap="3" align="center">
            {roleName ? `Edit role ${roleName}` : 'Create new role'}
            <Text size="1" color="gray">
              {workspaceName}
            </Text>
          </Flex>
        }
      >
        <Flex gap="2" align="center">
          <Button
            variant="soft"
            color="plum"
            loading={isRoleSaving}
            onClick={saveForm}
          >
            {roleName ? `Update role` : 'Save role'}
          </Button>
        </Flex>
      </Page__Header>

      <Page__Content>
        {!!roleSavingError && (
          <Box mb="4">
            <ErrorApi error={roleSavingError} />
          </Box>
        )}

        <AlertDialog
          open={isConfirmationDialogOpen}
          actionText="Yes, save changes"
          cancelText="No, keep editing"
          onCancel={() => setIsConfirmationDialogOpen(false)}
          onAction={handleSubmit(submitForm)}
          loading={isRoleSaving}
        >
          {!!roleSavingError && (
            <Box mb="4">
              <ErrorApi error={roleSavingError} />
            </Box>
          )}
          Are you sure you want to save the changes to the role?
        </AlertDialog>
        <form onSubmit={saveForm}>
          <Box mb="5" maxWidth="400px">
            <Input
              label="Role name"
              errorMessage={errors.name?.message}
              required
              {...register('name')}
            />
          </Box>

          <Box maxWidth="400px">
            <RolesTable methods={methods} />
          </Box>
        </form>
      </Page__Content>
    </>
  );
}
