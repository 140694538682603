import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Heading, Text, Link, Skeleton } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { ErrorApi } from 'src/components/ErrorApi';
import { useEmailConfirmation } from 'src/hooks/api/useEmailConfirmation';

export function EmailUpdateConfirmation() {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get('hash');
  const { error, isLoading } = useEmailConfirmation({ hash });

  if (isLoading) {
    return (
      <PageAccent mods={{ type: 'primary' }}>
        <Skeleton width="300px" height="30px" mb="2" />
        <Skeleton width="200px" height="30px" />
      </PageAccent>
    );
  }

  if (error) {
    return (
      <PageAccent mods={{ type: 'primary' }}>
        {!!error && <ErrorApi error={error} />}
      </PageAccent>
    );
  }

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <Heading mb="4">Email update successful</Heading>

      <Box mb="4">
        <Text>
          Thank you for confirming your new email address. Your email update is
          now complete.
        </Text>
      </Box>

      <Box>
        <Text>
          If you have any questions or need further assistance, feel free to
          contact our support team{' '}
          <Link href="mailto:support@lootfabriq.io">support@lootfabriq.io</Link>
          .
        </Text>
      </Box>
    </PageAccent>
  );
}
