import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import {
  WorkspaceRolesType,
  WORKSPACES_ROLES,
} from 'src/constants/workspaceRoles';
import { SelectField } from 'src/components/SelectField';
import { Toast } from 'src/components/Toast';
import { useWorkspaceRoleUpdate } from 'src/hooks/api/useWorkspaceRoleUpdate';
import { useQueryClient } from '@tanstack/react-query';
import { GET_WORKSPACE_MEMBERS_KEY } from 'src/hooks/api/useWorkspaceMembers';
import { useUserContext } from 'src/providers/UserProvider';
import { getWorkspaceRoles } from '../utils/getWorkspaceRoles';
import { useSubscription } from 'src/hooks/api/useSubscription';

const validationSchema = yup.object().shape({
  role_id: yup
    .mixed<WorkspaceRolesType>()
    .required('Role is a required field')
    .oneOf(Object.values(WORKSPACES_ROLES)),
});

type UpdateRoleProps = {
  workspaceId: number;
  memberId: number;
  isPopoverOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export function UpdateRole({
  workspaceId,
  onOpenChange,
  isPopoverOpen,
  memberId,
}: UpdateRoleProps) {
  const { data: subscription } = useSubscription({
    workspaceId: workspaceId.toString(),
  });
  const { workspacesRoleMap } = useUserContext();
  const queryClient = useQueryClient();
  const {
    mutate: updateRole,
    isMutating,
    error,
  } = useWorkspaceRoleUpdate({
    onSuccess: () => {
      onOpenChange(false);
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_MEMBERS_KEY] });
      Toast({
        text: 'Role was updated',
        variant: 'info',
      });
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = methods;

  const handleUpdate: SubmitHandler<{ role_id: WorkspaceRolesType }> = async (
    data: { role_id: WorkspaceRolesType },
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    updateRole({ ...data, workspaceId, member_id: memberId });
  };

  const userRole = workspacesRoleMap.get(workspaceId) || null;
  const roleOptions = getWorkspaceRoles({
    role: userRole,
    plan: subscription?.type,
  });

  return (
    <>
      <Popover.Root
        open={isPopoverOpen}
        onOpenChange={onOpenChange}
        modal={true}
      >
        <Popover.Trigger>
          <span></span>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleUpdate)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Box>
              <Controller
                control={control}
                name="role_id"
                render={({ field: { value, onChange } }) => {
                  const handleSelect = (value: string) => {
                    const option = Number(value) as WorkspaceRolesType;
                    onChange(option);
                  };

                  return (
                    <SelectField
                      errorMessage={errors.role_id?.message}
                      options={roleOptions}
                      value={value?.toString()}
                      onChange={handleSelect as (value: string) => void}
                      label="Role"
                      placeholder="Select role"
                      required
                    />
                  );
                }}
              />
            </Box>

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                type="submit"
                color="plum"
                loading={isMutating}
              >
                Update role
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
