import React from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon } from '@radix-ui/react-icons';
import * as yup from 'yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { SelectField } from 'src/components/SelectField';
import { ErrorApi } from 'src/components/ErrorApi';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import {
  useProjectCreate,
  CreateProjectArgs,
} from 'src/hooks/api/useProjectCreate';
import { useUserContext } from 'src/providers/UserProvider';
import { getAvailableWorkspaces } from './utils/getAvailableWorkspaces';
import { Toast } from 'src/components/Toast';
import { getFirstAvailableProjectInstanceId } from 'src/utils/getFirstAvailableProjectInstanceId';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useInstanceActivate } from 'src/hooks/api/useInstanceActivate';

const validationSchema = yup.object().shape({
  project_name: yup
    .string()
    .required('Project name is a required field')
    .max(32, 'Maximum 32 characters allowed'),
  workspace_id: yup.number().required('Workspace id is a required field'),
});

export function ProjectCreation() {
  const { instanceId, refetchInstanceId } = useInstanceContext();
  const { mutate: activateInstance } = useInstanceActivate({
    onSuccess: () => {
      refetchInstanceId();
    },
  });
  const { workspacesRoleMap } = useUserContext();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: createProject,
    isMutating,
    error,
  } = useProjectCreate({
    onSuccess: () => {
      setIsPopoverOpen(false);
      Toast({
        text: 'Project created successfully',
        variant: 'info',
      });
      handleProjectCreationSuccess();
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const {
    data,
    isLoading: isWorkspacesLoading,
    refetch: refetchWorkspaces,
  } = useWorkspacesList();
  const workspaces = data?.workspaces || [];

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = methods;

  async function handleProjectCreationSuccess() {
    const { data: updatedWorkspaces } = await refetchWorkspaces();
    if (instanceId < 1) {
      const firstAvailableInstance =
        getFirstAvailableProjectInstanceId(updatedWorkspaces);

      if (!firstAvailableInstance) {
        return;
      }
      activateInstance({ instanceId: firstAvailableInstance });
    }
  }

  const handleCreation: SubmitHandler<CreateProjectArgs> = async (
    data: CreateProjectArgs,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    createProject(data);
  };

  const availableWorkspaces = getAvailableWorkspaces({
    workspaces,
    workspacesRoleMap,
  });

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Box>
            <Button
              size="1"
              variant="soft"
              color="gold"
              data-testid="create-project"
            >
              <PlusIcon /> New project
            </Button>
          </Box>
        </Popover.Trigger>
        <Popover.Content>
          <form onSubmit={handleSubmit(handleCreation)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.project_name?.message}
              label="Project name"
              placeholder="Enter project name"
              data-testid="project-name"
              required
              {...register('project_name')}
            />

            <Flex align="center" mt="2">
              <Controller
                control={control}
                name="workspace_id"
                render={({ field: { value, onChange } }) => {
                  const options = availableWorkspaces.map((item) => ({
                    id: item.id.toString(),
                    label: item.name,
                  }));

                  const handleSelect = (value: string) => {
                    onChange(+value);
                  };

                  return (
                    <SelectField
                      isLoading={isWorkspacesLoading}
                      errorMessage={errors.workspace_id?.message}
                      options={options}
                      value={value?.toString()}
                      onChange={handleSelect as (value: string) => void}
                      label="Workspace"
                      placeholder="Select workspace"
                      dataTestId="workspace-name"
                      required
                    />
                  );
                }}
              />
            </Flex>
            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                color="plum"
                type="submit"
                loading={isMutating}
                data-testid="create-project-button"
              >
                Create project
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
