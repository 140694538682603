import React from 'react';
import { Box, Skeleton } from '@radix-ui/themes';
import { CopyCode } from 'src/components/CopyCode';
import { ErrorApi } from 'src/components/ErrorApi';
import { useTemplateExportJson } from 'src/hooks/api/useTemplateExportJson';
import { getListOfFieldsFromItem } from 'src/pages/item/utils/getListOfFieldsFromItem';
import { StringObject } from 'src/types/Common';
import { Callout } from 'src/components/Callout';
import { convertTreeToTemplateFields } from 'src/utils/convertTreeToTemplateFields';
import { addStatusesToTemplateFields } from 'src/pages/template/utils/addStatusesToTemplateFields';
import { useItemFormContext } from 'src/providers/ItemFormProvider';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { updateImagesPathInPreview } from '../../utils/updateImagesPathInPreview';

type JsonProps = {
  formData: StringObject;
};

export function Json({ formData }: JsonProps) {
  const { templateTree: itemTree } = useItemFormContext();
  const { templateTree } = useTemplateTreeContext();
  const {
    mutate: getJson,
    data: code,
    error,
    isMutating,
  } = useTemplateExportJson();

  React.useEffect(() => {
    if (!itemTree.length) {
      return;
    }

    const templateData = convertTreeToTemplateFields({
      templateTree,
    });
    const templateFields = addStatusesToTemplateFields({
      initialData: [],
      currentData: templateData,
    });

    const fields = getListOfFieldsFromItem({
      formData,
      templateTree: itemTree,
    });

    getJson({
      template_fields: templateFields,
      template_id: 'wow',
      item_fields: fields,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMutating) {
    return <Skeleton height="40px" />;
  }

  if (!itemTree.length) {
    return (
      <Box className="p-template-json__callout">
        <Callout color="gold">Populate fields in Preview form</Callout>
      </Box>
    );
  }

  updateImagesPathInPreview(code || {});
  const formattedCode = JSON.stringify(code || {}, null, 4);

  return (
    <div>
      {error && (
        <Box mb="4">
          <ErrorApi error={error} />
        </Box>
      )}
      <CopyCode code={formattedCode} language="json" />
    </div>
  );
}
