import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import type { EmptyObject } from 'src/types/Common';

type Response = EmptyObject;

const sendCode = (): Promise<Response> => {
  return fetcher({
    url: `/accounts/change-email/code`,
    options: {
      method: 'POST',
    },
  });
};

export function useAccountEmailUpdateSendCode(opt?: MutationOptions<Response>) {
  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: sendCode,
    meta: {
      name: 'Send code',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    reset,
  };
}
