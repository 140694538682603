import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from '../../providers/InstanceProvider';

export const GET_API_TOKEN_KEY = 'useApiToken';

type ResponseType = {
  token: string;
};

export const getApiToken = (instanceID: number): Promise<ResponseType> => {
  return fetcher({ url: `/instances/${instanceID}/api-token ` });
};

type UseAccountArgs = {
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useApiToken({ options }: UseAccountArgs = {}) {
  const { instanceId } = useInstanceContext();
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: [GET_API_TOKEN_KEY],
    queryFn: instanceId > 0 ? () => getApiToken(instanceId) : undefined,
    enabled: instanceId > 0,
    meta: {
      name: 'API token',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    refetch,
  };
}
