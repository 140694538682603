import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiRoleDefinition } from 'src/types/Roles';

export const WORKSPACE_ROLE_DEFINITION_URL = 'useWorkspaceRoleDefinition';

export const getRole = (
  workspaceId: number,
  roleId: number
): Promise<ApiRoleDefinition> => {
  return fetcher({ url: `/workspaces/${workspaceId}/roles/${roleId}` });
};

type UseWorkspaceRoleDefinitionArgs = {
  workspaceId: number | undefined;
  roleId: number | undefined;
  options?: Omit<
    UseQueryOptions<ApiRoleDefinition | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useWorkspaceRoleDefinition({
  workspaceId,
  roleId,
  options,
}: UseWorkspaceRoleDefinitionArgs) {
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: [WORKSPACE_ROLE_DEFINITION_URL, workspaceId, roleId],
    queryFn: () =>
      workspaceId && roleId ? getRole(workspaceId, roleId) : undefined,
    enabled: !!workspaceId && !!roleId,
    meta: {
      name: 'Roles list',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    refetch,
  };
}
