import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Pencil1Icon } from '@radix-ui/react-icons';
import * as yup from 'yup';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Popover,
  Text,
  Button,
} from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAccountEmailUpdateConfirmation,
  AccountEmailUpdateArg,
} from 'src/hooks/api/useAccountEmailUpdateConfirmation';
import { GET_ACCOUNT_KEY } from 'src/hooks/api/useAccount';
import { useAccountEmailUpdateSendCode } from 'src/hooks/api/useAccountEmailUpdateSendCode';

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
  code: yup.string().required('Code is a required field'),
});

const STEPS = {
  SEND_CODE: 1,
  ENTER_CODE: 2,
  CONFIRM_EMAIL: 3,
};

export function AccountEmailUpdatePopup() {
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [step, setStep] = React.useState(STEPS.SEND_CODE);
  const {
    mutate: sendCode,
    error: sendCodeError,
    isMutating: isSendingCode,
    reset: resetSendMutation,
  } = useAccountEmailUpdateSendCode({
    onSuccess: () => {
      setStep(STEPS.ENTER_CODE);
    },
  });
  const {
    mutate: updateEmail,
    isMutating: isUpdatingEmail,
    error: emailUpdateError,
    reset: resetUpdateMutation,
  } = useAccountEmailUpdateConfirmation({
    onSuccess: () => {
      setStep(STEPS.CONFIRM_EMAIL);
      queryClient.invalidateQueries({ queryKey: [GET_ACCOUNT_KEY] });
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleCreation: SubmitHandler<AccountEmailUpdateArg> = async (
    data: AccountEmailUpdateArg,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    updateEmail(data);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setStep(STEPS.SEND_CODE);
      resetSendMutation();
      resetUpdateMutation();
    }
    setIsPopoverOpen(isOpen);
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={handleOpenChange}>
        <Popover.Trigger>
          <IconButton
            size="1"
            title="Update email"
            variant="ghost"
            color="gold"
          >
            <Pencil1Icon />
          </IconButton>
        </Popover.Trigger>
        <Popover.Content width="360px">
          {step === STEPS.SEND_CODE && (
            <div>
              {!!sendCodeError && (
                <Box mb="4">
                  <ErrorApi error={sendCodeError} />
                </Box>
              )}

              <Text size="2">
                Before changing email, let&rsquo;s confirm it&rsquo;s you.
                <br /> Click the button below to&nbsp;receive
                a&nbsp;verification code at&nbsp;your current email address.
              </Text>

              <Flex justify="between" align="center" mt="4">
                <Button
                  onClick={() => setStep(STEPS.ENTER_CODE)}
                  variant="ghost"
                >
                  Already have code?
                </Button>

                <Button
                  variant="soft"
                  color="plum"
                  type="submit"
                  loading={isSendingCode}
                  onClick={sendCode}
                >
                  Send code
                </Button>
              </Flex>
            </div>
          )}

          {step === STEPS.ENTER_CODE && (
            <form onSubmit={handleSubmit(handleCreation)}>
              {!!emailUpdateError && (
                <Box mb="4">
                  <ErrorApi error={emailUpdateError} />
                </Box>
              )}

              <Input
                errorMessage={errors.email?.message}
                label="New email address"
                placeholder="Enter new email"
                required
                {...register('email')}
              />

              <Box mt="2">
                <Input
                  errorMessage={errors.code?.message}
                  label="Verification code (sent to your current email)"
                  placeholder="Enter code"
                  required
                  {...register('code')}
                />
              </Box>

              <Flex justify="end" mt="4">
                <Button
                  variant="soft"
                  color="plum"
                  type="submit"
                  loading={isUpdatingEmail}
                >
                  Update email
                </Button>
              </Flex>
            </form>
          )}

          {step === STEPS.CONFIRM_EMAIL && (
            <div>
              <Heading size="3" mb="2">
                Almost there 🪄
              </Heading>
              <Text size="2">
                Check your updated email for a confirmation message and follow
                the instructions to finalize the update.
              </Text>
            </div>
          )}
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
