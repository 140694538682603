import { ObjectValues } from 'src/types/Common';

export const SUBSCRIPTION_STATUS = {
  TRIAL: 'Trial',
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  PAST_DUE: 'PastDue',
  CANCEL_REQUESTED: 'CancelRequested',
} as const;

export type SubscriptionStatusType = ObjectValues<typeof SUBSCRIPTION_STATUS>;
