import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ItemForm } from 'src/components/ItemForm';
import { Callout } from 'src/components/Callout';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { useItemFormContext } from 'src/providers/ItemFormProvider';
import { getTreeWithoutDynamicGroupChildren } from 'src/pages/item/utils/getTreeWithoutDynamicGroupChildren';
import { convertTreeToTemplateFields } from 'src/utils/convertTreeToTemplateFields';
import { buildInitialTree } from 'src/pages/item/utils/buildInitialTree';
import { getListOfFieldsFromItem } from 'src/pages/item/utils/getListOfFieldsFromItem';

type PreviewProps = {
  methods: UseFormReturn<FieldValues>;
};

export function Preview({ methods }: PreviewProps) {
  const { templateTree } = useTemplateTreeContext();
  const {
    setTemplateTree,
    setDynamicGroups,
    templateTree: itemTree,
  } = useItemFormContext();

  React.useEffect(() => {
    if (!templateTree.length) {
      setTemplateTree([]);
      setDynamicGroups(new Map());
      methods.reset();
    }

    const formData = methods.getValues();
    const itemFields = getListOfFieldsFromItem({
      formData,
      templateTree: itemTree,
    });

    const templateFields = convertTreeToTemplateFields({
      templateTree: templateTree,
    });
    const { dynamicGroupsMap, tree: treeWithoutDynamicGroupChildren } =
      getTreeWithoutDynamicGroupChildren({
        templateFields,
        itemFields: itemFields,
      });

    const tree = buildInitialTree({
      treeData: treeWithoutDynamicGroupChildren,
      dynamicGroupsChildrenStampMap: dynamicGroupsMap,
      itemFields: itemFields,
    });

    setDynamicGroups(dynamicGroupsMap);
    setTemplateTree(tree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!itemTree.length) {
    return <Callout color="gold">Add fields to preview form</Callout>;
  }

  return (
    <>
      {/* ugly but works */}
      <div data-preview-item-form></div>
      <ItemForm methods={methods} templateTree={itemTree} />
    </>
  );
}
