/* istanbul ignore file -- @preserve */ // use e2e tests for this component
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { ObjectSchema } from 'yup';
import { SettingsType } from 'src/types/Template';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { updateTreeNode } from 'src/utils/updateTreeNode';
import { RemoveField } from '../RemoveField';
import { Box } from '@radix-ui/themes';
import { Callout } from 'src/components/Callout';
import { validateTemplate } from 'src/utils/validateTemplate';
import { invalidNodesToMap } from 'src/utils/invalidNodesToMap';
import { canNodeHaveChildren } from 'src/utils/canNodeHaveChildren';
import { isDynamicGroupOnlyChildOfDynamicGroup } from 'src/utils/isDynamicGroupOnlyChild';

type FormSettingsProps<T extends FieldValues, U extends ObjectSchema<T>> = {
  children: (methods: UseFormReturn<T>) => React.ReactNode;
  initialValues: DefaultValues<T>;
  validationSchema: U;
};

// https://github.com/orgs/react-hook-form/discussions/7063
export function TemplateSettingsForm<
  T extends SettingsType,
  U extends ObjectSchema<T>,
>({ initialValues, validationSchema, children }: FormSettingsProps<T, U>) {
  const {
    templateTree,
    templateTreeActiveField,
    setTemplateTree,
    setTemplateTreeInvalidNodes,
  } = useTemplateTreeContext();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 250,
    context: {
      templateTree,
    },
    defaultValues: {
      ...initialValues,
    },
  });

  const { watch, getValues, trigger } = methods;

  React.useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update node when user is typing in settings
  React.useEffect(() => {
    const subscription = watch(async () => {
      const updatedTree = updateTreeNode({
        data: getValues(),
        tree: templateTree,
        nodeToUpdate: templateTreeActiveField,
      });
      if (!updatedTree) {
        return;
      }
      await trigger();
      const invalidFields = await validateTemplate(updatedTree);
      const invalidFieldsMap = invalidNodesToMap({ data: invalidFields });
      setTemplateTreeInvalidNodes(invalidFieldsMap);
      setTemplateTree(updatedTree);
    });
    return () => subscription.unsubscribe();
  }, [
    getValues,
    setTemplateTree,
    setTemplateTreeInvalidNodes,
    templateTree,
    templateTreeActiveField,
    trigger,
    watch,
  ]);

  if (!children) {
    return null;
  }

  if (!templateTreeActiveField) {
    return null;
  }

  const canHaveChildren = canNodeHaveChildren(templateTreeActiveField.node);
  const isSelectedFieldHasChildren =
    templateTreeActiveField?.node?.children?.length;

  const isDynamicGroupOnlyChild = isDynamicGroupOnlyChildOfDynamicGroup(
    templateTreeActiveField.node
  );

  // don't use form context due to bug https://github.com/orgs/react-hook-form/discussions/3894#discussioncomment-5110057
  return (
    <div className="template-settings-form">
      {children(methods)}{' '}
      <Box mt="4">
        <RemoveField />
      </Box>
      {!canHaveChildren && !!isSelectedFieldHasChildren && (
        <Box mt="4">
          <Callout color="tomato">
            Only Group and Dynamic group can have children
          </Callout>
        </Box>
      )}
      {isDynamicGroupOnlyChild && (
        <Box mt="4">
          <Callout color="tomato">
            Dynamic group cannot have another dynamic group as its only child
          </Callout>
        </Box>
      )}
    </div>
  );
}
