import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { EmptyObject } from 'src/types/Common';

export type RemoveMemberArg = {
  projectId: number;
  memberId: number;
};

const removeMember = (arg: RemoveMemberArg): Promise<EmptyObject> => {
  const { projectId, memberId } = arg;
  return fetcher({
    url: `/projects/${projectId}/accounts/${memberId}`,
    options: {
      method: 'DELETE',
    },
  });
};

export function useProjectRemoveMember(opt?: MutationOptions<EmptyObject>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: removeMember,
    meta: {
      name: 'Member removal',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
