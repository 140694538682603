import * as yup from 'yup';

const NUMBER_REGEX = /\d/;
const LETTERS_MIX_REGEX = /(?=.*[a-z])(?=.*[A-Z])/;
const SPACES_REGEX = /^\S+$/;

const MIN_ERROR = 'min';
const CONTAINS_NUMBER_ERROR = 'containsNumber';
const CONTAINS_LETTERS_MIX_ERROR = 'lettersMix';
const CONTAINS_SPACES_ERROR = 'noSpaces';
export const PASSWORD_ERRORS = {
  [MIN_ERROR]: MIN_ERROR,
  [CONTAINS_NUMBER_ERROR]: CONTAINS_NUMBER_ERROR,
  [CONTAINS_LETTERS_MIX_ERROR]: CONTAINS_LETTERS_MIX_ERROR,
  [CONTAINS_SPACES_ERROR]: CONTAINS_SPACES_ERROR,
};

export function getPasswordValidationSchema() {
  return yup.object().shape({
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(NUMBER_REGEX, {
        message: 'Must include at least 1 number',
        name: CONTAINS_NUMBER_ERROR,
      })
      .matches(LETTERS_MIX_REGEX, {
        message: 'Must use a mix of uppercase and lowercase letters',
        name: CONTAINS_LETTERS_MIX_ERROR,
      })
      .matches(SPACES_REGEX, {
        message: 'No spaces allowed',
        name: CONTAINS_SPACES_ERROR,
      }),
  });
}
