import React from 'react';
import { Box, Table, Skeleton } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { useSubscriptionMembersUpdate } from 'src/hooks/api/useSubscriptionMembersUpdate';
import { SubscriptionMembersUpdate } from 'src/types/Subscription';
import { ApiError } from 'src/types/Api';
import pluralize from 'pluralize';
import { getPrice } from '../utils/getPrice';
import { MEMBERS_INCLUDED_IN_PLAN } from './MemberPricing';

type MemberPricingDialogProps = {
  workspaceId: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  newAdditionalSeats: number;
  membersDiff: number;
  membersPricePreview: SubscriptionMembersUpdate | undefined;
  isLoading: boolean;
  subscriptionPreviewError: ApiError | null;
  onSuccessfulUpdate: (members: number) => void;
};

export function MemberPricingDialog({
  workspaceId,
  isDialogOpen,
  setIsDialogOpen,
  newAdditionalSeats,
  membersDiff,
  membersPricePreview,
  isLoading,
  subscriptionPreviewError,
  onSuccessfulUpdate,
}: MemberPricingDialogProps) {
  const {
    mutate: updateMembers,
    isMutating: isUpdateMembersMutating,
    error: updateMembersError,
  } = useSubscriptionMembersUpdate({
    onSuccess: () => {
      onSuccessfulUpdate(newAdditionalSeats + MEMBERS_INCLUDED_IN_PLAN);
      setIsDialogOpen(false);
    },
  });

  const handleMembersChange = (value: number) => {
    updateMembers({
      workspaceId,
      new_value: value,
    });
  };

  return (
    <AlertDialog
      title="Confirm subscription changes"
      onAction={() => handleMembersChange(newAdditionalSeats)}
      onCancel={() => setIsDialogOpen(false)}
      open={isDialogOpen}
      color="plum"
      actionText="Yes, confirm member changes"
      cancelText="No, keep my current members"
      loading={isUpdateMembersMutating || isLoading}
    >
      {!!updateMembersError && (
        <Box mb="4">
          <ErrorApi error={updateMembersError} />
        </Box>
      )}
      {!!subscriptionPreviewError && (
        <Box mb="4">
          <ErrorApi error={subscriptionPreviewError} />
        </Box>
      )}
      You're updating your subscription by{' '}
      {membersDiff > 0 ? 'adding' : 'removing'} {Math.abs(membersDiff)}{' '}
      {pluralize('member', Math.abs(membersDiff))}:
      <Box my="4">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Payment</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Subtotal</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Taxes</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Total</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell>Next payment</Table.RowHeaderCell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.next?.subtotal)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.next?.tax)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.next?.total)}
                </Skeleton>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeaderCell>Future payments</Table.RowHeaderCell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.recurring?.subtotal)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.recurring?.tax)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(membersPricePreview?.recurring?.total)}
                </Skeleton>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
      </Box>
      You can start using the new member slots now, but payment will be
      processed later on your next billing date. This is why your next payment
      may differ from your future recurring payments.
    </AlertDialog>
  );
}
