import { InstanceChanges, InstanceTemplateChanges } from 'src/types/Instance';

type FilterTemplatesArgs = {
  instanceData: InstanceChanges;
  filterValues: string[] | null;
};

export function filterTemplates({
  instanceData,
  filterValues,
}: FilterTemplatesArgs): InstanceTemplateChanges[] {
  let filteredTemplates = instanceData.templates;
  if (filterValues && filterValues.length > 0) {
    filteredTemplates = filteredTemplates.filter((item) =>
      filterValues.includes(item.template_id)
    );
  }
  return filteredTemplates;
}
