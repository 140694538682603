import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { TreeNode } from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';
import * as DynamicGroupRow from 'src/fields/DynamicGroupRow';
import { ResponseItemField } from 'src/types/Item';

type BuildInitialTreeChildrenArgs = {
  node: TreeNode;
  rowsAmountMapByGroupId: Map<string, Map<string, number>>;
  dynamicGroupsChildrenStampMap: Map<string, TreeNode[]>;
  parentId: string;
  parentGroupPath: number[] | undefined | null;
  parentGroupId: string;
  itemFieldsMap: Map<string, Map<string, ResponseItemField>>;
};
export function buildInitialTreeChildren({
  node,
  rowsAmountMapByGroupId,
  parentId,
  parentGroupPath,
  parentGroupId,
  dynamicGroupsChildrenStampMap,
  itemFieldsMap,
}: BuildInitialTreeChildrenArgs) {
  node.id = uuidv4();
  node.parentId = parentId;
  node.templateGroupPath = parentGroupPath;
  node.templateGroupId = parentGroupId;

  if (node.settings.type === FIELD_TYPE.DYNAMIC_GROUP) {
    node.templateGroupId = node.templateFieldId;

    buildDynamicGroupChildren({
      groupNode: node,
      rowsAmountMapByGroupId,
      dynamicGroupsChildrenStampMap,
      itemFieldsMap: itemFieldsMap,
    });
  } else {
    if (parentGroupPath && node.templateFieldId) {
      const itemFieldValues = itemFieldsMap.get(node.templateFieldId);
      const itemFieldValue = itemFieldValues?.get(parentGroupPath.join(','));
      node.entityFieldId = itemFieldValue?.id || uuidv4();
    }

    node?.children?.forEach((childNode) => {
      buildInitialTreeChildren({
        rowsAmountMapByGroupId,
        dynamicGroupsChildrenStampMap,
        node: childNode,
        parentId: node.id,
        parentGroupPath: node.templateGroupPath,
        parentGroupId: node.templateGroupId || '',
        itemFieldsMap: itemFieldsMap,
      });
    });
  }
}

type BuildDynamicGroupChildrenArgs = {
  rowsAmountMapByGroupId: Map<string, Map<string, number>>;
  groupNode: TreeNode;
  dynamicGroupsChildrenStampMap: Map<string, TreeNode[]>;
  itemFieldsMap: Map<string, Map<string, ResponseItemField>>;
};

function buildDynamicGroupChildren({
  rowsAmountMapByGroupId,
  groupNode,
  dynamicGroupsChildrenStampMap,
  itemFieldsMap,
}: BuildDynamicGroupChildrenArgs) {
  const amountOfNodesInGroup = rowsAmountMapByGroupId.get(
    groupNode?.templateFieldId || ''
  );
  // reset children, otherwise nested dynamic group children from stamp leaves in tree
  groupNode.children = [];

  if (!amountOfNodesInGroup) {
    return;
  }

  const templateGroupPath = groupNode?.templateGroupPath?.length
    ? groupNode.templateGroupPath
    : ['root'];
  const rowsAmount = amountOfNodesInGroup.get(templateGroupPath.join(','));

  if (!rowsAmount) {
    return;
  }

  const groupStamp = dynamicGroupsChildrenStampMap.get(
    groupNode.templateFieldId || ''
  );

  if (!groupStamp) {
    groupNode.children = [];

    return;
  }

  const groupPath = groupNode.templateGroupPath;

  for (let i = 0; i < rowsAmount; i++) {
    const row = buildRow({
      stamp: groupStamp,
      parentNode: groupNode,
    });

    groupNode.children = groupNode.children
      ? [...groupNode.children, row]
      : [row];

    buildInitialTreeChildren({
      node: row,
      rowsAmountMapByGroupId,
      parentId: groupNode.id,
      dynamicGroupsChildrenStampMap,
      parentGroupId: groupNode.templateFieldId || '',
      parentGroupPath: groupPath ? [...groupPath, i] : [i],
      itemFieldsMap: itemFieldsMap,
    });
  }
}

type BuildRowArgs = {
  stamp: TreeNode[];
  parentNode: TreeNode;
};

function buildRow({ stamp, parentNode }: BuildRowArgs) {
  const children = _.cloneDeep(stamp);
  const dynamicGroupRow: TreeNode = {
    id: uuidv4(),
    parentId: parentNode.id,
    settings: DynamicGroupRow.getDefaultValues(),
    expanded: true,
    children,
  };

  return dynamicGroupRow;
}
