import { getProjectById } from 'src/utils/getProjectById';
import { getInstancesIds } from './getInstancesIds';
import { ApiWorkspaceResponse } from 'src/types/Workspace';

type GetAvailableInstancesArgs = {
  workspaces: ApiWorkspaceResponse[];
  projectId: number;
};

export const getAvailableInstances = ({
  workspaces,
  projectId,
}: GetAvailableInstancesArgs) => {
  const currentProject = getProjectById({
    workspaces: workspaces || [],
    projectId,
  });

  return getInstancesIds({
    project: currentProject || {},
  });
};
