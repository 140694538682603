import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { EmptyObject } from 'src/types/Common';

export type RevokeInviteArg = {
  workspaceId: number;
  member_id: number;
};

const revokeInvite = (arg: RevokeInviteArg): Promise<EmptyObject> => {
  const { workspaceId, ...rest } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/accounts`,
    options: {
      method: 'DELETE',
      body: JSON.stringify(rest),
    },
  });
};

export function useWorkspaceMemberRevoke(opt?: MutationOptions<EmptyObject>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: revokeInvite,
    meta: {
      name: 'Revoke invite',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
