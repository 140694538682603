import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type UpdateTokenArg = {
  instanceId: number;
};

const updateToken = (arg: UpdateTokenArg): Promise<UpdateTokenArg> => {
  const { instanceId } = arg;
  return fetcher({
    url: `/instances/${instanceId}/api-token`,
    options: {
      method: 'POST',
      body: JSON.stringify({}),
    },
  });
};

export function useApiTokenUpdate(opt?: MutationOptions<UpdateTokenArg>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: updateToken,
    meta: {
      name: 'Token update',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
