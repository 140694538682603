import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiItemListResponse } from 'src/types/Item';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const ITEMS_LIST_KEY = 'useItemsList';

const getItemsList = (
  id: string,
  instanceId: number
): Promise<ApiItemListResponse> => {
  return fetcher({ url: `/editor/${instanceId}/items/${id}` });
};

type UseItemsListArgs = {
  templateId: string | undefined;
  options?: Omit<
    UseQueryOptions<ApiItemListResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useItemsList({ templateId, options }: UseItemsListArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError } = useQuery({
    queryKey: [ITEMS_LIST_KEY, templateId, instanceId],
    queryFn: () =>
      templateId ? getItemsList(templateId, instanceId) : undefined,
    enabled: !!templateId && instanceId !== -1,
    meta: {
      name: 'Items list',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
  };
}
