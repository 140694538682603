import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';

export const GET_SUBSCRIPTION_TRANSACTION_KEY = 'useSubscriptionTransaction';

type ResponseType = {
  transaction_id: string;
};

type GetTransactionArgs = {
  workspaceId: number;
};
export const getTransaction = ({
  workspaceId,
}: GetTransactionArgs): Promise<ResponseType> => {
  return fetcher({
    url: `/workspaces/${workspaceId}/subscriptions/payment-details`,
  });
};

type UseSubscriptionTransactionArgs = {
  workspaceId?: number;
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useSubscriptionTransaction({
  options,
  workspaceId,
}: UseSubscriptionTransactionArgs) {
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: [GET_SUBSCRIPTION_TRANSACTION_KEY],
    queryFn: () => (workspaceId ? getTransaction({ workspaceId }) : undefined),
    enabled: false,
    meta: {
      name: 'Subscription transaction',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    refetch,
  };
}
