import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { ExportReportFull } from 'src/types/ExportReport';

const GET_REPORT_BY_ID_KEY = 'useExportReportById';

type Response = {
  report: ExportReportFull;
};

const getReportById = (
  instanceId: number,
  reportId: string
): Promise<Response> => {
  return fetcher({ url: `/instances/${instanceId}/reports/${reportId}` });
};

type UseReportByIdArgs = {
  reportId?: string;
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useExportReportById({ options, reportId }: UseReportByIdArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError } = useQuery({
    queryKey: [GET_REPORT_BY_ID_KEY, instanceId, reportId],
    queryFn: () => (reportId ? getReportById(instanceId, reportId) : undefined),
    enabled: instanceId > 0 && !!reportId,
    meta: {
      name: 'Get export report by ID',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
  };
}
