import { ResponseItemField } from 'src/types/Item';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TemplateField } from 'src/types/Template';
type Field = TemplateField & { templateFieldId?: string } & {
  entityFieldId?: string;
};

export type AssignItemAndTemplateIdsToFields = {
  templateFields: Field[];
  itemFields: ResponseItemField[];
};

export function assignItemAndTemplateIdsToFields({
  templateFields,
  itemFields,
}: AssignItemAndTemplateIdsToFields) {
  const templateFieldIdToItemMap = mapTemplateFieldIdsToItems({
    fields: itemFields,
  });
  const clonedTemplateFields = _.cloneDeep(templateFields);
  // duplicate id to templateFieldId, so node always keeps link to template field. Need this for dynamic group
  clonedTemplateFields.forEach((item) => {
    const itemField = templateFieldIdToItemMap.get(item?.id || '');
    item.templateFieldId = item.id;

    // if field has template_group_path, it's dynamic group field and it will be processed separately
    if (!itemField?.template_group_path) {
      item.entityFieldId = itemField?.id || uuidv4();
    }
  });

  return clonedTemplateFields;
}

type GetItemValuesMap = {
  fields: ResponseItemField[];
};

export function mapTemplateFieldIdsToItems({ fields }: GetItemValuesMap) {
  const itemMap: Map<string, ResponseItemField> = new Map();

  fields.forEach((item) => {
    itemMap.set(item.template_field_id, item);
  });

  return itemMap;
}
