import React from 'react';
import { ErrorPage } from 'src/components/ErrorPage';
import { Box, Button, Text } from '@radix-ui/themes';
import { Link as RouterLink } from 'react-router-dom';

export function ErrorActionNotAllowedForInstance() {
  return (
    <ErrorPage headline="Oops! Action not allowed">
      <Box mb="6">
        <Text size="4">
          Sorry, creating new items or templates is not permitted in this
          environment. Stage and production instances are for managing existing
          content only.
        </Text>
        <Box mt="2">
          <Text size="4">
            If you need to make changes, please use the development instance.
          </Text>
        </Box>
      </Box>

      <Button variant="soft" color="plum" asChild mb="2" size="3">
        <RouterLink to="/">Return home</RouterLink>
      </Button>
    </ErrorPage>
  );
}
