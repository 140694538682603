import React, { createContext, useContext, useState } from 'react';
import { ActiveNodeType, TreeNode } from 'src/types/Template';
import { FlatDataItem } from '@nosferatu500/react-sortable-tree';

type NodesMap = Map<string, FlatDataItem>;
type TemplateTreeContextProps = {
  templateTree: TreeNode[];
  setTemplateTree: (data: TreeNode[]) => void;
  templateTreeActiveField: ActiveNodeType | null;
  setTemplateTreeActiveField: (field: ActiveNodeType | null) => void;
  templateTreeInvalidNodes: NodesMap;
  setTemplateTreeInvalidNodes: (nodes: NodesMap) => void;
  isFormSubmitted: boolean; // set it to true when user click Save template, and if form invalid - scroll to first invalid node
  setIsFormSubmitted: (isSubmitted: boolean) => void;
};

const TemplateTreeContext = createContext<TemplateTreeContextProps>({
  templateTree: [],
  setTemplateTree: () => {},
  templateTreeActiveField: null,
  setTemplateTreeActiveField: () => {},
  templateTreeInvalidNodes: new Map(),
  setTemplateTreeInvalidNodes: () => {},
  isFormSubmitted: false,
  setIsFormSubmitted: () => {},
});

type TemplateTreeProviderProps = {
  children: React.ReactNode;
};
export const TemplateTreeProvider = ({
  children,
}: TemplateTreeProviderProps) => {
  const [templateTree, setTemplateTree] = useState<TreeNode[]>([]);
  const [templateTreeActiveField, setTemplateTreeActiveField] =
    useState<ActiveNodeType | null>(null);
  const [templateTreeInvalidNodes, setTemplateTreeInvalidNodes] = useState(
    new Map()
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  return (
    <TemplateTreeContext.Provider
      value={{
        templateTree,
        setTemplateTree,
        templateTreeActiveField,
        setTemplateTreeActiveField,
        templateTreeInvalidNodes,
        setTemplateTreeInvalidNodes,
        isFormSubmitted,
        setIsFormSubmitted,
      }}
    >
      {children}
    </TemplateTreeContext.Provider>
  );
};

export const useTemplateTreeContext = () => {
  const context = useContext(TemplateTreeContext);
  if (!context) {
    throw new Error(
      'useTemplateTreeContext must be used within a TemplateTreeContext'
    );
  }
  return context;
};
