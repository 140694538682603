import { WorkspaceRolesType } from '../constants/workspaceRoles';
import { PermissionType } from '../constants/permissions';

type CheckAccessArgs = {
  userRole: WorkspaceRolesType | null;
  userPermissions: PermissionType[];
  roles: WorkspaceRolesType[];
  permissionsGroups?: PermissionType[][];
  ignorePermissions?: boolean;
};

export function checkAccess({
  userRole,
  userPermissions,
  permissionsGroups = [],
  roles,
  ignorePermissions,
}: CheckAccessArgs) {
  const hasRole = userRole && roles.includes(userRole);

  if (ignorePermissions) {
    return { hasRole, hasPermission: true };
  }

  const hasPermission = permissionsGroups.some((group) =>
    group.every((perm) => userPermissions.includes(perm))
  );

  return { hasRole: !!hasRole, hasPermission };
}
