import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Card, Flex, Text, Inset, Box, Skeleton } from '@radix-ui/themes';
import { ArrowRightIcon } from '@radix-ui/react-icons';

type CardLinkProps = {
  to: string;
  title: string;
  children?: React.ReactNode;
  href?: string;
  target?: string;
  rel?: string;
  icon: React.ReactNode;
  badge?: React.ReactNode;
  isLoading?: boolean;
};

export function CardLink({
  to,
  title,
  children,
  href,
  target,
  rel,
  icon,
  badge,
  isLoading,
}: CardLinkProps) {
  const LinkTag = href ? 'a' : RouterNavLink;

  return (
    <Card variant="surface" size="2" asChild className="card-link">
      <LinkTag
        to={to}
        target={target}
        rel={rel}
        href={href}
        className="card-link__link"
      >
        <Inset clip="padding-box" side="left" className="card-link__inset">
          <div className="card-link__block">{icon}</div>
        </Inset>

        <div>
          <Flex align="center" gap="2">
            <Flex align="center" gap="2">
              <Skeleton loading={!!isLoading}>
                <Text size="3" weight="medium">
                  {title}
                </Text>
              </Skeleton>
              {badge}
            </Flex>
            <ArrowRightIcon className="card-link__arrow" />
          </Flex>
          <Box mt="2">
            <Text size="3" color="gray">
              {children}
            </Text>
          </Box>
        </div>
      </LinkTag>
    </Card>
  );
}
