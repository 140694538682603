import React from 'react';
import { Box, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { Subscription } from 'src/types/Subscription';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { useSubscriptionRestore } from 'src/hooks/api/useSubscriptionRestore';

type SubscriptionReactivationProps = {
  workspaceId: string;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
  subscription: Subscription | undefined;
};

export function SubscriptionReactivation({
  workspaceId,
  onSuccessfulPayment,
  subscription,
}: SubscriptionReactivationProps) {
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const {
    mutate: reactivateSubscription,
    error: reactivateSubscriptionError,
    isMutating: isSubscriptionReactivating,
  } = useSubscriptionRestore({
    onSuccess: () => {
      onSuccessfulPayment({
        plan: subscription?.type,
        status: SUBSCRIPTION_STATUS.ACTIVE,
      });
      setAlertOpen(false);
    },
  });

  return (
    <AlertDialog
      title="Reactivate subscription?"
      onAction={() => reactivateSubscription({ workspaceId })}
      onCancel={() => setAlertOpen(false)}
      open={isAlertOpen}
      actionText="Yes, reactivate subscription"
      cancelText="No, keep canceled"
      loading={isSubscriptionReactivating}
      trigger={
        <Button
          color="plum"
          variant="soft"
          onClick={() => setAlertOpen(true)}
          loading={isSubscriptionReactivating}
        >
          Reactivate subscription
        </Button>
      }
    >
      {!!reactivateSubscriptionError && (
        <Box mb="4">
          <ErrorApi error={reactivateSubscriptionError} />
        </Box>
      )}
      Are you sure you want to reactivate your subscription? Your current
      billing period will continue, and your access will be restored.
    </AlertDialog>
  );
}
