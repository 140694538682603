import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { AccountWorkspaces } from 'src/types/Account';

export const GET_WORKSPACE_ROLES_KEY = 'useAccountWorkspacesRoles';

type Response = AccountWorkspaces;

const getWorkspacesRoles = async (): Promise<Response> => {
  const response = await fetcher({
    url: '/accounts/workspaces',
  });

  return response;
};

type UseMenuArgs = {
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>;
};

export function useAccountWorkspacesRoles({ options }: UseMenuArgs = {}) {
  const { data, error, isLoading, isError, isSuccess } = useQuery({
    queryKey: [GET_WORKSPACE_ROLES_KEY],
    queryFn: () => getWorkspacesRoles(),
    meta: {
      name: 'Workspaces roles',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    isSuccess,
  };
}
