import React from 'react';
import _ from 'lodash';
import { Input } from 'src/components/Input';
import * as yup from 'yup';
import { FIELD_TYPE } from 'src/constants/components.js';
import { Grid } from '@radix-ui/themes';
import { TemplateSettingsForm } from './components/TemplateSettingsForm';
import './Group.css';
import * as TemplateTypes from 'src/types/Template';
import { UseFormReturn } from 'react-hook-form';
import { nameSchema, labelSchema } from './utils/schema';
import { nameTooltip } from './utils/tooltipText';

const settingsValidationSchema = yup.object().shape({
  type: yup.string().oneOf([FIELD_TYPE.GROUP]).required(),
  name: nameSchema,
  label: labelSchema,
});

export const getDefaultValues = () => ({
  type: FIELD_TYPE.GROUP,
  name: '',
  label: '',
});

export const validate = (
  data: TemplateTypes.SettingsGroupType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

export const getFieldValidationSchema = () => {
  // don't need validation for group
  return null;
};

type FieldProps = {
  data: TemplateTypes.SettingsGroupType;
  children: React.ReactNode;
  id: string;
  methods: UseFormReturn<{ [key: string]: string }>;
};

/* istanbul ignore next -- @preserve */
export function Field({ data, children, methods, id }: FieldProps) {
  const { register, setValue } = methods;

  React.useEffect(() => {
    register(id);
    setValue(id, '');
  }, [register, setValue, id]);

  return (
    <div className="group">
      {data.label && <div className="group__label">{data.label}</div>}
      <div className="group__body">{children}</div>
    </div>
  );
}

type SettingsProps = {
  methods: UseFormReturn<TemplateTypes.SettingsGroupType>;
};

type ValidFieldNames = keyof TemplateTypes.SettingsGroupType;

export function Settings({ methods }: SettingsProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Grid gap="4">
      <Input
        label="Label"
        required
        errorMessage={errors.label?.message}
        autoFocus
        {...register<ValidFieldNames>('label')}
      />
      <Input
        label="Name"
        required
        tooltip={nameTooltip}
        errorMessage={errors.name?.message}
        {...register<ValidFieldNames>('name')}
        data-testid="group-field-name"
      />
    </Grid>
  );
}

type SettingsFormProps = {
  initialValues: TemplateTypes.SettingsGroupType;
};

/* istanbul ignore next -- @preserve ;test with e2e */
export function SettingsForm({ initialValues }: SettingsFormProps) {
  // backend can send empty fields from other settings. The reason is that all fields setting should merged by backend.
  // to solve the issue, pick just fields which we need
  const values = _.pick(initialValues, Object.keys(getDefaultValues()));
  return (
    <TemplateSettingsForm
      initialValues={values}
      validationSchema={settingsValidationSchema}
    >
      {(methods: UseFormReturn<TemplateTypes.SettingsGroupType>) => (
        <Settings methods={methods} />
      )}
    </TemplateSettingsForm>
  );
}
