import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { appRoutes } from 'src/utils/routePaths';
import { Box, Flex, Text } from '@radix-ui/themes';
import { Callout } from 'src/components/Callout';

type AccessPageProps = {
  projectId: number;
  workspaceId: number;
  memberName?: string;
  projectName: string;
};

export function AccessPage({
  projectId,
  workspaceId,
  projectName,
  memberName,
}: AccessPageProps) {
  return (
    <>
      <Page__Header
        backButtonUrl={appRoutes.settingsProject({ projectId, workspaceId })}
        title={
          <Flex gap="3" align="center">
            {memberName
              ? `Update roles for ${memberName}`
              : 'Add member to project'}
            <Text size="1" color="gray">
              {projectName}
            </Text>
          </Flex>
        }
      ></Page__Header>
      <Page__Content>
        <Box maxWidth="600px">
          <Callout color="gold">
            You need to be added to this project before you can add users or
            update roles. Please contact your administrator to gain access.
          </Callout>
        </Box>
      </Page__Content>
    </>
  );
}
