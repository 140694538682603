import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@radix-ui/themes';
import { debounce } from 'lodash';
import { ValidationList } from 'src/components/ValidationList';
import { HelperText } from 'src/components/HelperText';
import { Input } from 'src/components/Input';
import { useTemplateIdCheck } from 'src/hooks/api/useTemplateIdCheck';
import { ErrorMessage } from 'src/components/ErrorMessage';

const ID_ERRORS = ['min', 'max', 'latin'];

type FormData = {
  templateId: string;
  templateLabel: string;
  isTemplateIdUnique: boolean;
};

type DetailsFormProps = {
  isNew: boolean;
};

export function DetailsForm({ isNew }: DetailsFormProps) {
  const methods = useFormContext<FormData>();
  const {
    formState: { errors },
    register,
    setValue,
    trigger,
  } = methods;
  const [templateId, setTemplateId] = React.useState('');
  const { data } = useTemplateIdCheck({
    templateId,
  });

  React.useEffect(() => {
    if (!isNew) {
      return;
    }
    const isIdUnique = data?.is_unique !== false;

    if (isIdUnique) {
      setValue('isTemplateIdUnique', true);
    } else {
      setValue('isTemplateIdUnique', false);
    }
    trigger('isTemplateIdUnique'); // https://github.com/react-hook-form/react-hook-form/issues/2352#issuecomment-665560867
  }, [data?.is_unique, isNew, setValue, trigger]);

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateId(e.target.value);
  };

  const debouncedHandleIdChange = debounce(handleIdChange, 400);

  const errorType = errors.templateId?.type || '';
  // don't show errors if includes in id checklist
  const idErrorMessage = ID_ERRORS.includes(errorType)
    ? ''
    : errors.templateId?.message;

  const idErrors = [
    {
      isValid: !errors.isTemplateIdUnique,
      text: 'Must be unique across all templates',
    },
    {
      isValid: !errors.templateId?.types?.latin,
      text: 'Include only Latin letters and numbers',
    },
    {
      isValid: !errors.templateId?.types?.min,
      text: 'Minimum length: 3 characters',
    },
    {
      isValid: !errors.templateId?.types?.max,
      text: 'Maximum length: 32 characters',
    },
  ];

  return (
    <>
      <Box mb="4">
        <Input
          mods={{ type: 'primary' }}
          required
          label="Name"
          placeholder="Enter template name"
          data-testid="template-name"
          errorMessage={errors.templateLabel?.message}
          {...register('templateLabel')}
        />
      </Box>

      <Box mb="4">
        <Input
          mods={{ type: 'primary' }}
          required
          label="ID"
          placeholder="Enter template id"
          errorMessage={idErrorMessage}
          data-testid="template-id"
          disabled={!isNew}
          {...register('templateId', {
            onChange: debouncedHandleIdChange,
            validate: () => data?.is_unique,
          })}
        />

        {isNew ? (
          <Box mt="2">
            <ValidationList data={idErrors} />
          </Box>
        ) : (
          // normally we shouldn't render it as id disabled, but just in case, so we always see errors
          <Box>
            {idErrors
              .filter((error) => !error.isValid)
              .map((error, index) => (
                <Box mt="1" key={index}>
                  <ErrorMessage>{error.text}</ErrorMessage>
                </Box>
              ))}
          </Box>
        )}

        <Box mt="2">
          <HelperText>
            ID&nbsp;is fixed and cannot be&nbsp;modified once created.
            It&nbsp;serves as&nbsp;a&nbsp;permanent and unchangeable reference
            for your template.
          </HelperText>
        </Box>
      </Box>
    </>
  );
}
