import React from 'react';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { useAccount } from 'src/hooks/api/useAccount';
import { useAccountSettings } from 'src/hooks/api/useAccountSettings';
import { useAccountWorkspacesRoles } from 'src/hooks/api/useAccountWorkspacesRoles';
import { Page } from 'src/components/Page';
import { Nav__Templates } from 'src/components/Nav';

// load here all critical data before app renders
export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading: isWorkspacesLoading } = useWorkspacesList();
  const { isLoading: isAccountLoading } = useAccount();
  const { isLoading: isAccountSettingsLoading } = useAccountSettings();
  const { isLoading: isAccountWorkspacesRolesLoading } =
    useAccountWorkspacesRoles();

  if (
    isWorkspacesLoading ||
    isAccountLoading ||
    isAccountSettingsLoading ||
    isAccountWorkspacesRolesLoading
  ) {
    return (
      <Page
        mods={{ type: 'primary' }}
        loading={true}
        nav={<Nav__Templates />}
      />
    );
  }

  return <>{children}</>;
};
