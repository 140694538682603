import { Page__Content, Page__Header } from 'src/components/Page';
import { Token } from './components/Token';

export function ExportSettings() {
  return (
    <>
      <Page__Header title="Export settings" backButtonUrl="/" />

      <Page__Content>
        <Token />
      </Page__Content>
    </>
  );
}
