import React from 'react';
import { DndWrapper } from 'src/components/DndWrapper';
import { Box, Flex, Skeleton, Grid } from '@radix-ui/themes';
import {
  Tabs,
  Tabs__Item,
  Tabs__List,
  Tabs__Content,
} from 'src/components/Tabs';
import { ComponentsList } from '../ComponentsList';

export function TabsSkeleton() {
  return (
    <Flex>
      <Tabs value="fields">
        <Tabs__List>
          <Tabs__Item value="details" disabled>
            Details
          </Tabs__Item>
          <Tabs__Item value="fields">Fields</Tabs__Item>
          <Tabs__Item value="preview" disabled>
            Preview form
          </Tabs__Item>
          <Tabs__Item value="json" disabled>
            JSON preview
          </Tabs__Item>
        </Tabs__List>

        <Box pt="3">
          <Tabs__Content value="fields">
            <Tabs value="componets" mods={{ type: 'secondary' }}>
              <Tabs__List>
                <Tabs__Item value="componets">Components</Tabs__Item>
                <Tabs__Item value="settings">Settings</Tabs__Item>
              </Tabs__List>

              <Box pt="3">
                <Tabs__Content value="componets">
                  <Grid columns="auto 1fr" gap="8">
                    <Box width="340px">
                      <DndWrapper>
                        <ComponentsList />
                      </DndWrapper>
                    </Box>

                    <Box>
                      <Skeleton width="200px" height="70px" />
                      <Skeleton width="200px" height="70px" mt="3" />
                    </Box>
                  </Grid>
                </Tabs__Content>
              </Box>
            </Tabs>
          </Tabs__Content>
        </Box>
      </Tabs>
    </Flex>
  );
}
