import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type RenameWorkspaceArg = {
  name: string;
  workspaceId: number;
};

const renameWorkspace = (
  arg: RenameWorkspaceArg
): Promise<RenameWorkspaceArg> => {
  const { workspaceId, ...data } = arg;
  return fetcher({
    url: `/workspaces/${workspaceId}/rename`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  });
};

export function useWorkspaceRename(opt?: MutationOptions<RenameWorkspaceArg>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: renameWorkspace,
    meta: {
      name: 'Rename workspace',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
