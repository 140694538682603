import { MutationOptions } from 'src/types/Api';
import { useApiMutationTurnstile } from 'src/components/CloudflareTurnstile';

export type LoginArgs = {
  email: string;
  password: string;
};

export function useLogin(opt?: MutationOptions<unknown>) {
  const { mutate, isMutating, error, isChallenging, challengeError } =
    useApiMutationTurnstile({
      meta: {
        name: 'Login',
      },
      ...opt,
    });

  const login = (args: LoginArgs) =>
    mutate({ url: '/accounts/login', method: 'POST', body: args });

  return {
    mutate: login,
    isMutating,
    error,
    isChallenging,
    challengeError,
  };
}
