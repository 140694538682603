import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type AccountRenameArg = {
  name: string;
};

const renameAccount = (arg: AccountRenameArg): Promise<AccountRenameArg> => {
  return fetcher({
    url: `/accounts/change-name`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useAccountRename(opt?: MutationOptions<AccountRenameArg>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: renameAccount,
    meta: {
      name: 'Account rename',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
