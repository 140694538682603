import { getFlatDataFromTree } from '@nosferatu500/react-sortable-tree';
import { TreeNode, TreeNodeFlat } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';

type GetFlatDataFromTemplateArgs = {
  templateTree: TreeNode[];
};

export function getFlatDataFromTemplateTree({
  templateTree,
}: GetFlatDataFromTemplateArgs) {
  const flatData = getFlatDataFromTree({
    treeData: templateTree,
    getNodeKey,
    ignoreCollapsed: false,
  }) as TreeNodeFlat[];

  return flatData;
}
