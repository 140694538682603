import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiTemplateListResponse } from 'src/types/Template';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const GET_TEMPLATES_KEY = 'useTemplatesList';

const getTemplateList = (
  instanceId: number
): Promise<ApiTemplateListResponse> => {
  return fetcher({ url: `/editor/${instanceId}/templates` });
};

type UseTemplateArgs = {
  options?: Omit<
    UseQueryOptions<ApiTemplateListResponse>,
    'queryKey' | 'queryFn'
  >;
};

export function useTemplatesList({ options }: UseTemplateArgs = {}) {
  const { instanceId } = useInstanceContext();
  const { data, error, isLoading, isSuccess, isError } = useQuery({
    queryKey: [GET_TEMPLATES_KEY, instanceId],
    queryFn: () => getTemplateList(instanceId),
    enabled: instanceId > 0,
    meta: {
      name: 'Templates list',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    isSuccess,
    error: isError ? error : null,
  };
}
