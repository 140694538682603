import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import { swapWithPreviousItem } from 'src/pages/template/utils/swapWithPreviousItem';
import { swapWithNextItem } from 'src/pages/template/utils/swapWithNextItem';
import type { TreeNode } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';

export const SWAP_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

type SwapChildrenArgs = {
  id: string;
  parentId: string;
  direction: 'up' | 'down';
  treeData: TreeNode[];
};

export const swapChildren = ({
  id,
  parentId,
  direction,
  treeData,
}: SwapChildrenArgs) => {
  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: TreeNode }) => {
      if (node.id !== parentId) {
        return node;
      }
      const children = node.children;
      if (!children) {
        return node;
      }

      const indexToSwap = children.findIndex((item) => item.id === id);
      let swappedChildren;

      if (direction === SWAP_DIRECTION.UP) {
        swappedChildren = swapWithPreviousItem(children, indexToSwap);
      } else {
        swappedChildren = swapWithNextItem(children, indexToSwap);
      }

      return {
        ...node,
        children: swappedChildren,
      };
    },
  });

  return updatedTree as TreeNode[];
};
