import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { Subscription } from 'src/types/Subscription';

export const GET_SUBSCRIPTIONS_KEY = 'useSubscriptions';

type ResponseType = Subscription;

type GetSubscriptionArgs = {
  workspaceId: string;
};
export const getSubscription = ({
  workspaceId,
}: GetSubscriptionArgs): Promise<ResponseType> => {
  return fetcher({ url: `/workspaces/${workspaceId}/subscriptions` });
};

type UseSubscriptionArgs = {
  workspaceId?: string;
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useSubscription({ options, workspaceId }: UseSubscriptionArgs) {
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: [GET_SUBSCRIPTIONS_KEY, workspaceId],
    queryFn: () => (workspaceId ? getSubscription({ workspaceId }) : undefined),
    enabled: !!workspaceId,
    staleTime: Infinity, // changes rarely, it's better to refetch if needed
    gcTime: Infinity,
    meta: {
      name: 'Get subscription',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    refetch,
  };
}
