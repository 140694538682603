import { Subscription } from 'src/types/Subscription';
import { getSubscriptionState } from './getSubscriptionState';
import { PlanType } from 'src/constants/plans';

type isPlanActiveArgs = {
  subscription?: Subscription;
  plan: PlanType;
};

export function isPlanSelected({ subscription, plan }: isPlanActiveArgs) {
  const subscriptionState = getSubscriptionState({ subscription });

  return (
    subscription?.type === plan &&
    (subscriptionState.active ||
      subscriptionState.trial ||
      subscriptionState.pastDue ||
      subscriptionState.cancelRequested)
  );
}
