import React from 'react';
import pluralize from 'pluralize';
import { calculateTimeLeft } from 'src/utils/calculateTimeLeft';
import { Subscription } from 'src/types/Subscription';
import { PlanType } from 'src/constants/plans';
import { getSubscriptionState } from '../utils/getSubscriptionState';
import { Badge } from '@radix-ui/themes';

type PlanCardBadgeProps = {
  plan: PlanType;
  subscription?: Subscription;
};

export function PlanCardBadge({ plan, subscription }: PlanCardBadgeProps) {
  const subscriptionTimeLeft = calculateTimeLeft({
    targetDateStr: subscription?.next_billing_date_at,
  });
  const subscriptionState = getSubscriptionState({ subscription });
  const isPlanActive =
    subscription?.type === plan &&
    (subscriptionState.active || subscriptionState.cancelRequested);

  if (
    subscriptionState.trial &&
    subscriptionTimeLeft.amount > 0 &&
    subscription?.type === plan
  ) {
    return (
      <Badge color="plum">
        Trial - {subscriptionTimeLeft.amount}{' '}
        {subscriptionTimeLeft.type === 'd'
          ? pluralize('day', subscriptionTimeLeft.amount)
          : pluralize('hour', subscriptionTimeLeft.amount)}{' '}
        left
      </Badge>
    );
  }

  if (
    subscriptionState.cancelRequested &&
    subscriptionTimeLeft.amount > 0 &&
    subscription?.type === plan
  ) {
    return (
      <Badge color="plum">
        Cancelled - {subscriptionTimeLeft.amount}{' '}
        {subscriptionTimeLeft.type === 'd'
          ? pluralize('day', subscriptionTimeLeft.amount)
          : pluralize('hour', subscriptionTimeLeft.amount)}{' '}
        left
      </Badge>
    );
  }

  if (isPlanActive) {
    return <Badge color="plum">active</Badge>;
  }

  return null;
}
