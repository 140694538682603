import { TreeNode } from 'src/types/Template';

export type Node = {
  node: TreeNode;
};

type InvalidNodesToMapArgs = {
  data: Node[];
};
export function invalidNodesToMap({ data }: InvalidNodesToMapArgs) {
  const invalidNodesMap = new Map();
  data.forEach((item) => {
    invalidNodesMap.set(item.node.id, item.node);
  });
  return invalidNodesMap;
}
