import * as yup from 'yup';
import { PERMISSIONS, PermissionsKeys } from 'src/constants/permissions';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Role name is a required field'),
  permissions: yup.object().shape(
    Object.keys(PERMISSIONS).reduce(
      (acc, key) => {
        acc[key as PermissionsKeys] = yup.boolean().required();
        return acc;
      },
      {} as Record<PermissionsKeys, yup.BooleanSchema<boolean>>
    )
  ),
});
