import { checkToken } from 'src/utils/token.js';
import { history } from 'src/utils/history';
import { appRoutes } from 'src/utils/routePaths';
import { isChallengeRequired } from 'src/components/CloudflareTurnstile';

export const ENDPOINT = import.meta.env.VITE_ENDPOINT;

type RequestOptions = {
  method?: string;
  credentials?: RequestCredentials;
  headers?: HeadersInit;
};

type fetcherArgs = {
  url: string;
  externalUrl?: string;
  options?: RequestInit;
  isPublic?: boolean;
  omitCredentials?: boolean;
  headers?: HeadersInit;
  hideCsrf?: boolean;
};

export const fetcher = async ({
  url,
  externalUrl,
  options,
  isPublic,
  omitCredentials,
  headers,
  hideCsrf,
}: fetcherArgs) => {
  const defaultOptions: RequestOptions = {
    method: 'GET',
    credentials: omitCredentials ? 'omit' : 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(hideCsrf ? {} : { 'x-lootf-csrf-protection': '1' }),
      ...headers,
    },
  };
  const fetchOptions: RequestInit = {
    ...defaultOptions,
    ...options,
  } as RequestInit;
  if (!isPublic) {
    await checkToken(url);
  }
  const response = await fetch(
    externalUrl || `${ENDPOINT}${url}`,
    fetchOptions
  );

  if (isChallengeRequired({ headers: response.headers })) {
    throw { headers: response?.headers };
  }
  const data = await response.json();
  if (response.status === 401) {
    handleUnauthorizedError(url, data?.error);
  }

  if (!response.ok) {
    throw data;
  }

  return data;
};

export function handleUnauthorizedError(url: string, error: string) {
  const isLoginUrl = url === '/account/login';
  if (isLoginUrl) {
    throw new Error(error);
  } else {
    if (history.navigate) {
      history.navigate(appRoutes.login());
    }
  }
}

export async function refreshToken() {
  return fetch(`${ENDPOINT}/accounts/refresh`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'x-lootf-csrf-protection': '1',
    },
  });
}
