import React from 'react';
import { Box, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { ApiError } from 'src/types/Api';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InstanceTemplateChanges } from 'src/types/Instance';
import {
  PartialChangesTable,
  PartialChangesTableType,
} from './PartialChangesTable';

type SyncPartialChangesButtonProps = {
  onSubmit: () => void;
  isLoading: boolean;
  error: ApiError | null;
  isAlertOpen: boolean;
  onAlertOpen: (isOpen: boolean) => void;
  methods: UseFormReturn<FieldValues>;
  templates: InstanceTemplateChanges[];
};

export function SyncPartialChangesButton({
  onSubmit,
  isLoading,
  error,
  isAlertOpen,
  onAlertOpen,
  methods,
  templates,
}: SyncPartialChangesButtonProps) {
  const [data, setData] = React.useState<PartialChangesTableType[]>([]);
  const { getValues } = methods;

  const handleButtonClick = () => {
    onAlertOpen(true);
    const tableData: PartialChangesTableType[] = [];
    const formData = getValues();

    templates.forEach((template) => {
      const formValueTemplate = formData[template.template_id];

      if (!formValueTemplate) {
        return;
      }

      const selectedItems: PartialChangesTableType['items'] = [];
      template.items.forEach((item) => {
        if (formValueTemplate.items[`id-${item.id}`]) {
          const itemData = {
            name: item.name,
            id: item.id,
          };

          selectedItems.push(itemData);
        }
      });

      const tableRow: PartialChangesTableType = {
        isTemplateIncluded: formValueTemplate.isTemplateIncluded,
        templateName: template.name,
        items: selectedItems,
      };

      if (!tableRow.isTemplateIncluded && !tableRow.items.length) {
        return;
      }

      tableData.push(tableRow);
    });

    setData(tableData);
  };

  return (
    <AlertDialog
      title="Sync changes"
      onAction={onSubmit}
      onCancel={() => onAlertOpen(false)}
      open={isAlertOpen}
      color="tomato"
      contentMaxWidth={data.length ? '1000px' : '600px'}
      actionText="Yes, sync selected changes"
      cancelText={data.length ? 'No, cancel' : 'Close'}
      loading={isLoading}
      hideActionButton={!data.length}
      trigger={
        <Button
          color="gold"
          variant="soft"
          onClick={handleButtonClick}
          loading={isLoading}
        >
          Sync selected
        </Button>
      }
    >
      {!!error && (
        <Box mb="4">
          <ErrorApi error={error} />
        </Box>
      )}
      {data.length ? (
        <>
          Are you sure you want to sync the selected changes? <br />
          <br />
          This will include only the items and templates you have selected.
          Please review your selections before proceeding.
          <Box mt="4">
            <PartialChangesTable data={data} />
          </Box>
        </>
      ) : (
        <span>
          You have not selected any changes to sync. Please select at least one
          change and try again.
        </span>
      )}
    </AlertDialog>
  );
}
