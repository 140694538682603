import { getTemplateTreeFromFlatData } from './getTemplateTreeFromFlatData';
import { sortChildrenByOrder } from './sortChildrenByOrder';
import { TemplateField, TreeNode } from 'src/types/Template';

type GetOrderedTreeFromFlatDataArgs = {
  fields: TemplateField[];
};

export function getOrderedTreeFromFlatData({
  fields,
}: GetOrderedTreeFromFlatDataArgs) {
  const tree = getTemplateTreeFromFlatData({
    data: fields,
  });
  const treeWithOrderedChildren = sortChildrenByOrder<TreeNode>({
    treeData: tree,
  });

  return treeWithOrderedChildren;
}
