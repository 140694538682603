import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { EmptyObject } from 'src/types/Common';

export const GET_REMOVAL_CANCELLATION_KEY = 'useProjectRemovalCancellation';

type Response = EmptyObject;

const cancelProjectRemoval = (hash: string): Promise<Response> => {
  return fetcher({
    url: `/projects/cancel-removing?hash=${hash}`,
    isPublic: true,
  });
};

type UseMenuArgs = {
  hash: string | null;
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useProjectRemovalCancellation({ options, hash }: UseMenuArgs) {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: [GET_REMOVAL_CANCELLATION_KEY, hash],
    queryFn: () => (hash ? cancelProjectRemoval(hash) : undefined),
    enabled: !!hash,
    meta: {
      name: 'Project removal cancellation',
    },
    ...options,
  });

  return {
    data,
    isLoading: isLoading,
    error: isError ? error : null,
  };
}
