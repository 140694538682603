import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Box } from '@radix-ui/themes';
import { Control, FieldValues } from 'react-hook-form';
import { StringObject } from 'src/types/Common';
import { ImageUpload } from 'src/components/ImageUpload';

type ControlType = Control<FieldValues, StringObject>;

type ImageFieldProps = {
  control: ControlType;
  errorMessage?: string | undefined;
  id: string;
  required?: boolean;
  label: string;
  name: string;
};

export function ImageField({
  control,
  errorMessage,
  id,
  required,
  label,
  name,
}: ImageFieldProps) {
  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <ImageUpload
              onChange={(image) => onChange(image)}
              value={value}
              label={label}
              required={required}
              alt={`Image for ${name} field`}
            />

            {errorMessage && (
              <Box mt="2">
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
          </>
        );
      }}
      control={control}
      name={id}
    />
  );
}
