export const getLocalStorageKey = (templateId: string) => {
  return `hidden-columns-${templateId}`;
};
export const saveHiddenColumns = (
  templateId: string,
  hiddenColumns: string[]
) => {
  const key = getLocalStorageKey(templateId);
  localStorage.setItem(key, JSON.stringify(hiddenColumns));
};

export const getHiddenColumns = (templateId: string) => {
  const key = getLocalStorageKey(templateId);
  const storedColumns = localStorage.getItem(key);

  return storedColumns ? JSON.parse(storedColumns) : [];
};
