import React from 'react';
import {
  initializePaddle,
  Paddle,
  CheckoutEventNames,
  CheckoutOpenOptions,
  CheckoutEventsData,
} from '@paddle/paddle-js';

const PADDLE_TOKEN = import.meta.env.VITE_PADDLE_TOKEN;
const PADDLE_ENV = import.meta.env.VITE_PADDLE_ENV;

type UsePaddleCheckoutArgs = {
  onSuccessfulPayment?: () => void;
  onCheckoutDataUpdate?: (checkoutData: CheckoutEventsData | undefined) => void;
};

export function usePaddleCheckout({
  onSuccessfulPayment,
  onCheckoutDataUpdate,
}: UsePaddleCheckoutArgs) {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = React.useState<Paddle>();

  // Download and initialize Paddle instance from CDN
  React.useEffect(() => {
    initializePaddle({
      environment: PADDLE_ENV,
      token: PADDLE_TOKEN,
      checkout: {
        settings: {
          displayMode: 'inline',
          frameTarget: 'checkout-container',
          frameInitialHeight: 450,
          frameStyle:
            'width: 100%; min-width: 260px; background-color: transparent; border: none;',
        },
      },
      eventCallback: (event) => {
        if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          onSuccessfulPayment?.();
        }

        if (event.data) {
          onCheckoutDataUpdate?.(event.data);
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [onCheckoutDataUpdate, onSuccessfulPayment, paddle]);

  // Callback to open a checkout
  const openCheckout = (options: CheckoutOpenOptions) => {
    paddle?.Checkout.open(options);
  };

  return { openCheckout };
}
