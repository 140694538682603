import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { Text, Flex, Box, Heading, Button } from '@radix-ui/themes';
import { getProjectById } from 'src/utils/getProjectById';
import { RenameProject } from './components/RenameProject';
import { MembersTab } from './components/MembersTab';
import { RemoveProject } from './components/RemoveProject';
import { PersonIcon } from '@radix-ui/react-icons';
import { TableLoading } from 'src/components/TableLoading';
import { Access } from 'src/components/Access';
import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';

export function Project() {
  const navigate = useNavigate();
  const { data, isLoading, error } = useWorkspacesList();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const projectId = Number(params.projectId);

  if (isLoading) {
    return (
      <>
        <Page__Header isLoading={true} />

        <Page__Content>
          <TableLoading headers={['ID', 'Name', 'Email']} rowsAmount={1} />
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !data?.workspaces || !projectId) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  if (error) {
    return (
      <>
        <Page__Header title="Project" />

        <Page__Content>
          <ErrorApi error={error} />
        </Page__Content>
      </>
    );
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: data?.workspaces,
  });

  const projectData = getProjectById({
    projectId,
    workspaces: data?.workspaces,
  });

  if (!workspaceData || !projectData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const navigateToAddMemberPage = () => {
    navigate(
      appRoutes.settingsProjectAddMember({
        projectId,
        workspaceId,
      })
    );
  };

  return (
    <>
      <Page__Header
        title={
          <Flex gap="3" align="center">
            {projectData.name}{' '}
            <Text size="1" color="gray">
              {workspaceData.name}
            </Text>
          </Flex>
        }
      >
        <Flex gap="2" align="center">
          <RenameProject
            projectId={projectData.id}
            projectName={projectData.name}
          />

          <Access roles={[WORKSPACES_ROLES.OWNER]} ignorePermissions>
            <RemoveProject
              projectId={projectData.id}
              projectName={projectData.name}
              workspaceId={workspaceId}
            />
          </Access>
        </Flex>
      </Page__Header>

      <Page__Content>
        <Flex mb="3" gap="4" align="center" justify="between">
          <Heading size="3" weight="regular" color="gray">
            Project members
          </Heading>

          <Box p="3">
            <Button
              size="2"
              variant="ghost"
              color="gold"
              onClick={navigateToAddMemberPage}
            >
              <PersonIcon /> Add member to project
            </Button>
          </Box>
        </Flex>

        <MembersTab
          workspaceId={workspaceId}
          projectId={projectData.id}
          workspaces={data?.workspaces || []}
        />
      </Page__Content>
    </>
  );
}
