import { removeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { MenuNode } from 'src/types/Menu';
import { getNodeKey } from 'src/utils/getNodeKey';

type RemoveItemArgs = {
  treeData: MenuNode[];
  path: number[] | string[];
};

export function removeItem({ treeData, path }: RemoveItemArgs) {
  const updatedTree: MenuNode[] = removeNodeAtPath({
    treeData,
    // @ts-expect-error: library error path type depends on getNodeKey, in our case it's string[]
    path,
    getNodeKey,
    ignoreCollapsed: false,
  }) as MenuNode[];

  return updatedTree;
}
