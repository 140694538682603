import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { PermissionType } from 'src/constants/permissions';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';

const GET_ACCOUNT_PERMISSIONS_KEY = 'useAccountPermissions';

type ResponseType = {
  workspace_role_id: WorkspaceRolesType;
  permissions: PermissionType[];
};

export const getPermissions = (instanceId: number): Promise<ResponseType> => {
  return fetcher({ url: `/instances/${instanceId}/permissions` });
};

type UseAccountPermissionsArgs = {
  instanceId?: number | undefined;
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useAccountPermissions({
  instanceId = 0,
  options,
}: UseAccountPermissionsArgs) {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: [GET_ACCOUNT_PERMISSIONS_KEY, instanceId],
    queryFn: () => (instanceId ? getPermissions(instanceId) : undefined),
    enabled: instanceId > 0,
    meta: {
      name: 'Account permissions',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
}
