import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { EmptyObject } from 'src/types/Common';

export const GET_EMAIL_CONFIRMATION_KEY = 'useEmailConfirmation';

type Response = EmptyObject;

const confirmEmail = (hash: string): Promise<Response> => {
  return fetcher({
    url: `/accounts/change-email/complete?hash=${hash}`,
    isPublic: true,
  });
};

type UseEmailArgs = {
  hash: string | null;
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useEmailConfirmation({ options, hash }: UseEmailArgs) {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: [GET_EMAIL_CONFIRMATION_KEY, hash],
    queryFn: () => (hash ? confirmEmail(hash) : undefined),
    enabled: !!hash,
    meta: {
      name: 'Email confirmation',
    },
    ...options,
  });

  return {
    data,
    isLoading: isLoading,
    error: isError ? error : null,
  };
}
