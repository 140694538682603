import {
  PERMISSION_STATUS,
  PERMISSIONS,
  PermissionsKeys,
} from 'src/constants/permissions';
import { FormValues } from '../types/FormValues';
import { getFormInitialValues } from './getFormInitialValues';
import { ApiRoleDefinition, PermissionUpdate } from 'src/types/Roles';

type GetPermissionsFromUpdatedFormArgs = {
  formData: FormValues;
  role: ApiRoleDefinition;
};
export function getPermissionsFromUpdatedForm({
  formData,
  role,
}: GetPermissionsFromUpdatedFormArgs) {
  const initialValues = getFormInitialValues(role);
  const changes: PermissionUpdate[] = [];

  Object.entries(formData.permissions).forEach(([key, checked]) => {
    const permissionKey = key as PermissionsKeys;
    const initialChecked = initialValues.permissions[permissionKey];

    if (checked !== initialChecked) {
      const status = checked
        ? PERMISSION_STATUS.CREATE
        : PERMISSION_STATUS.DELETE;

      changes.push({
        permission_id: PERMISSIONS[permissionKey],
        status,
      });
    }
  });

  return changes;
}
