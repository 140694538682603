import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Flex, Button } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { Callout } from 'src/components/Callout';
import { ErrorApi } from 'src/components/ErrorApi';
import { useRegistrationResendEmail } from 'src/hooks/api/useRegistrationResendEmail';
import { TOO_MANY_ATTEMPTS } from 'src/constants/errorStatusCode';
import { useCountdown, useCounter } from 'usehooks-ts';
import './Notification.css';
import { appRoutes } from 'src/utils/routePaths';
import { Challenge } from 'src/components/CloudflareTurnstile';

type NotificationProps = {
  email: string;
};

export function Notification({ email }: NotificationProps) {
  const navigate = useNavigate();
  const [isEmailResent, setIsEmailResent] = React.useState(false);
  const [countdown, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 59,
    intervalMs: 1000,
  });
  const { count: amountOfAttempts, increment: incrementAmountOfAttempts } =
    useCounter(0);

  const {
    mutate: resendEmail,
    isMutating,
    error,
    isChallenging,
    challengeError,
  } = useRegistrationResendEmail({
    onSuccess: () => {
      setIsEmailResent(true);
      resetCountdown();
      startCountdown();
      incrementAmountOfAttempts();
    },
    onError: (err) => {
      if (err.statusCode === TOO_MANY_ATTEMPTS) {
        navigate(appRoutes.registrationHelp());
        return;
      }
      setIsEmailResent(false);
    },
  });

  const formatTimer = (value: number) => value.toString().padStart(2, '0');

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <Heading mb="4" data-testid="registration-notification">
        Almost there 🪄
      </Heading>

      <Box mb="4">
        <Text>
          We&rsquo;ve sent you an&nbsp;email to&nbsp;confirm your registration.
          Please check your inbox to&nbsp;complete the process.
        </Text>
      </Box>

      <Box mb="4">
        <Text>
          If&nbsp;you can&rsquo;t find&nbsp;it, remember to&nbsp;check your spam
          folder.
        </Text>
      </Box>

      <Box mb="4">
        <Text>
          If&nbsp;you don&rsquo;t receive an&nbsp;email within a&nbsp;few
          minutes, you can resend&nbsp;it.
        </Text>
      </Box>

      {isEmailResent && (
        <Box mb="4">
          <Callout color="plum">
            We&rsquo;ve successfully resent the email
            {amountOfAttempts > 1 && (
              <span>
                <br />({amountOfAttempts} resends in total)
              </span>
            )}
          </Callout>
        </Box>
      )}

      <Challenge isChallenging={isChallenging} error={challengeError} />

      <Box>
        <Button
          variant="soft"
          color="plum"
          size="3"
          style={{ width: '100%' }}
          loading={isMutating}
          disabled={countdown > 0 && isEmailResent}
          onClick={() => resendEmail({ email })}
          data-testid="resend-email"
        >
          Resend email
        </Button>
      </Box>

      {countdown > 0 && isEmailResent && (
        <Flex mt="2" align="center" justify="center">
          <Text color="gray" size="2">
            You can resend again in{' '}
            <span className="p-registration-notification__count">
              00:{formatTimer(countdown)}
            </span>{' '}
            seconds
          </Text>
        </Flex>
      )}

      {!!error && (
        <Box mt="4">
          <ErrorApi error={error} />
        </Box>
      )}
    </PageAccent>
  );
}
