import React from 'react';
import * as yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Heading, Button, Link, Text } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { Input } from 'src/components/Input';
import { useLogin, LoginArgs } from 'src/hooks/api/useLogin';
import { ErrorApi } from 'src/components/ErrorApi';
import { Challenge } from 'src/components/CloudflareTurnstile';
import { appRoutes } from 'src/utils/routePaths';
import { Link as RouterLink } from 'react-router-dom';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
});

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const {
    mutate: login,
    error,
    isMutating,
    isChallenging,
    challengeError,
  } = useLogin({
    onSuccess: () => navigate(from),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleLogin: SubmitHandler<LoginArgs> = async (
    data: LoginArgs,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    login(data);
  };

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Box>
          <Heading mb="4" data-testid="login-header">
            Log in to your account
          </Heading>

          {!!error && (
            <Box my="4">
              <ErrorApi error={error} />
            </Box>
          )}

          <Box mb="4">
            <Input
              size="3"
              label="Email"
              errorMessage={errors.email?.message}
              data-testid="login-email"
              required
              {...register('email')}
            />
          </Box>
          <Box mb="6">
            <Input
              size="3"
              label="Password"
              type="password"
              errorMessage={errors.password?.message}
              data-testid="login-password"
              required
              {...register('password')}
            />
          </Box>

          <Challenge isChallenging={isChallenging} error={challengeError} />

          <Flex justify="center">
            <Button
              style={{ width: '100%' }}
              loading={isMutating}
              size="4"
              color="plum"
              variant="soft"
              type="submit"
              data-testid="login-button"
            >
              Log in
            </Button>
          </Flex>
        </Box>
      </form>

      <Flex mt="6" justify="center">
        <Text size="2" color="gray" align="center">
          Don&rsquo;t have an&nbsp;account?{' '}
          <Link asChild size="2" underline="always" color="gray">
            <RouterLink to={appRoutes.registration()}>Sign up</RouterLink>
          </Link>
        </Text>
      </Flex>

      <Flex mt="2" justify="center">
        <Link asChild color="gray" size="2" underline="always">
          <RouterLink to={appRoutes.passwordReset()}>
            Forgot your password?
          </RouterLink>
        </Link>
      </Flex>
    </PageAccent>
  );
}
