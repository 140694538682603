import React from 'react';
import b from 'bem-react-helper';
import {
  FileTrigger,
  DropZone,
  Button as AriaButton,
} from 'react-aria-components';
import type { DropEvent, FileDropItem } from 'react-aria';
import { Link, Text, Box, IconButton } from '@radix-ui/themes';
import { TrashIcon } from '@radix-ui/react-icons';
import { ACCEPTED_FILE_TYPE, validateImage } from './utils/validateImage';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { AlertDialog } from 'src/components/AlertDialog';
import { formatError } from 'src/utils/formatError';
import { convertToBase64 } from './utils/fileToBase64';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { LazyImage } from 'src/components/LazyImage';
import { getImageUrl } from 'src/utils/getImageUrl';
import { ImagePreview } from 'src/components/ImagePreview';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { PLANS } from 'src/constants/plans';
import { useActiveSubscription } from 'src/hooks/useActiveSubscription';
import { RequiredMark } from 'src/components/RequiredMark';

export const ERROR_TYPE = {
  SIZE: 'size',
  EXTENSION: 'extension',
};

function getErrorsText({ maxSizeMB }: { maxSizeMB: number }) {
  const errors = {
    [ERROR_TYPE.SIZE]: `Please upload an image smaller than ${maxSizeMB}MB`,
    [ERROR_TYPE.EXTENSION]: 'Please upload a file of image type (JPG, PNG)',
  };

  return errors;
}

type ImageUploadProps = {
  value?: string;
  label: string;
  required?: boolean;
  onChange: (value: string) => void;
  alt: string;
};

export function ImageUpload({
  value,
  label,
  required,
  onChange,
  alt,
}: ImageUploadProps) {
  const [isPreview, setIsPreview] = React.useState(false);
  const { subscription } = useActiveSubscription({});
  const { instanceId } = useInstanceContext();
  const imageSrc = getImageUrl({ instanceId, imagePath: value });
  const [image, setImage] = React.useState<string | null | undefined>(imageSrc);
  const [errors, setErrors] = React.useState<string[] | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const maxSizeMB = subscription?.type === PLANS.TEAMS ? 4 : 2;

  React.useEffect(() => {
    const previewElement = document.querySelector('[data-preview-item-form]');
    if (previewElement) {
      setIsPreview(true);
    }
  }, []);

  const handleImage = async (file: File | FileDropItem) => {
    try {
      const errorsList = await validateImage(file, maxSizeMB);
      setErrors(errorsList);
      if (errorsList.length) {
        return;
      }

      const base64 = await convertToBase64(file);
      setImage(base64);
      const imageData = base64.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
      onChange(isPreview ? base64 : imageData);
    } catch (error) {
      const formattedError = formatError(error);
      setErrors([formattedError]);
    }
  };

  const handleDrop = async (e: DropEvent) => {
    const files = e.items.filter((file) => {
      return file.kind === 'file';
    }) as FileDropItem[];

    handleImage(files[0]);
  };

  const handleSelect = async (filesList: FileList | null) => {
    if (!filesList) {
      return;
    }

    const files = Array.from(filesList);
    handleImage(files[0]);
  };

  const handleRemoveConfirmation = () => {
    setImage(null);
    setIsDialogOpen(false);
    onChange('');
  };

  const errorsMessages = getErrorsText({ maxSizeMB });

  return (
    <div className="image-upload">
      <div className="image-upload__label">
        {label} {required && <RequiredMark />}
      </div>
      {!image && (
        <DropZone onDrop={handleDrop} className="image-upload__drop-zone">
          {({ isDropTarget }) => (
            <div
              className={b(
                'image-upload__body',
                {},
                { hovered: isDropTarget, invalid: !!errors?.length }
              )}
            >
              {isDropTarget && <Text color="plum">Drop it</Text>}

              {!isDropTarget && (
                <>
                  <Box>
                    <Text color="gray">Drop and drop image here or </Text>
                    <FileTrigger
                      onSelect={handleSelect}
                      acceptedFileTypes={ACCEPTED_FILE_TYPE}
                    >
                      <Link color="plum" asChild highContrast>
                        <AriaButton>Click to browse</AriaButton>
                      </Link>
                    </FileTrigger>
                  </Box>
                  <Box mt="2">
                    <Text color="gray" size="2">
                      <AccessSubscription plan={[PLANS.STARTER]}>
                        Max file size 2MB
                      </AccessSubscription>

                      <AccessSubscription plan={[PLANS.TEAMS]}>
                        Max file size 4MB
                      </AccessSubscription>
                    </Text>
                  </Box>
                </>
              )}
            </div>
          )}
        </DropZone>
      )}

      {!!errors?.length && (
        <Box mt="2">
          {errors.map((error, index) => {
            const errorText = errorsMessages[error]
              ? errorsMessages[error]
              : error;
            return <ErrorMessage key={index}>{errorText}</ErrorMessage>;
          })}
        </Box>
      )}

      {!!image && (
        <ImagePreview
          image={
            <LazyImage
              mix="image-upload__img"
              src={isPreview ? value : image}
              alt={alt}
            />
          }
        >
          <AlertDialog
            onAction={handleRemoveConfirmation}
            onCancel={() => setIsDialogOpen(false)}
            open={isDialogOpen}
            color="tomato"
            actionText="Yes, remove image"
            trigger={
              <IconButton
                className="image-upload__button"
                size="1"
                title="Delete image"
                color="tomato"
                onClick={() => setIsDialogOpen(true)}
              >
                <TrashIcon />
              </IconButton>
            }
          >
            Are you sure you want to remove image?
          </AlertDialog>
        </ImagePreview>
      )}
    </div>
  );
}
