import { SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { Input } from 'src/components/Input';
import { useQueryClient } from '@tanstack/react-query';
import { GET_WORKSPACE_KEY } from 'src/hooks/api/useWorkspacesList';
import {
  useProjectRename,
  RenameProjectArg,
} from 'src/hooks/api/useProjectRename';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Project name is a required field')
    .max(32, 'Maximum 32 characters allowed'),
});

type RenameProjectProps = {
  projectName: string;
  projectId: number;
};

export function RenameProject({ projectName, projectId }: RenameProjectProps) {
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: renameProject,
    isMutating,
    error,
  } = useProjectRename({
    onSuccess: () => {
      setIsPopoverOpen(false);
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_KEY] });
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { name: projectName },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleRename: SubmitHandler<
    Omit<RenameProjectArg, 'projectId'>
  > = async (
    data: Omit<RenameProjectArg, 'projectId'>,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    renameProject({ ...data, projectId });
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button variant="soft" color="gold">
            Rename project
          </Button>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleRename)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.name?.message}
              label="Project name"
              placeholder="Enter project name"
              data-testid="project-name"
              required
              {...register('name')}
            />

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                type="submit"
                color="plum"
                loading={isMutating}
              >
                Rename project
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
