import { walk } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';
import { canNodeHaveChildren } from 'src/utils/canNodeHaveChildren';
import { isDynamicGroupOnlyChildOfDynamicGroup } from 'src/utils/isDynamicGroupOnlyChild';

type ValidateTemplateChildrenArgs = {
  tree: TreeNode[];
};

// returns map of invalid nodes
export function validateTemplateChildren({
  tree,
}: ValidateTemplateChildrenArgs) {
  const invalidNodes: Map<string, TreeNode> = new Map();

  walk({
    treeData: tree,
    getNodeKey,
    callback: ({ node }: { node: TreeNode }) => {
      if (!canNodeHaveChildren(node) && node.children?.length) {
        invalidNodes.set(node.id, node);
      }

      if (isDynamicGroupOnlyChildOfDynamicGroup(node)) {
        invalidNodes.set(node.id, node);
      }
    },
    ignoreCollapsed: false,
  });

  return invalidNodes;
}
