import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { PersonIcon } from '@radix-ui/react-icons';
import { ErrorApi } from 'src/components/ErrorApi';
import { Input } from 'src/components/Input';
import {
  useWorkspaceInvite,
  InviteUserArg,
} from 'src/hooks/api/useWorkspaceInvite';
import {
  WorkspaceRolesType,
  WORKSPACES_ROLES,
} from 'src/constants/workspaceRoles';
import { SelectField } from 'src/components/SelectField';
import { Toast } from 'src/components/Toast';
import { useQueryClient } from '@tanstack/react-query';
import { GET_WORKSPACE_MEMBERS_KEY } from 'src/hooks/api/useWorkspaceMembers';
import { useUserContext } from 'src/providers/UserProvider';
import { getWorkspaceRoles } from '../utils/getWorkspaceRoles';
import { useSubscription } from 'src/hooks/api/useSubscription';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Must be a valid email'),
  role_id: yup
    .mixed<WorkspaceRolesType>()
    .required('Role is a required field')
    .oneOf(Object.values(WORKSPACES_ROLES)),
});

type InviteUserProps = {
  workspaceId: number;
};

export function InviteUser({ workspaceId }: InviteUserProps) {
  const { data: subscription } = useSubscription({
    workspaceId: workspaceId.toString(),
  });
  const { workspacesRoleMap } = useUserContext();
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: inviteUser,
    isMutating,
    error,
  } = useWorkspaceInvite({
    onSuccess: () => {
      setIsPopoverOpen(false);
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_MEMBERS_KEY, workspaceId],
      });
      Toast({
        text: 'Invitation was sent',
        variant: 'info',
      });
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = methods;

  const handleInvite: SubmitHandler<
    Omit<InviteUserArg, 'workspaceId'>
  > = async (
    data: Omit<InviteUserArg, 'workspaceId'>,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    inviteUser({ ...data, workspaceId });
  };

  const userRole = workspacesRoleMap.get(workspaceId) || null;
  const roleOptions = getWorkspaceRoles({
    role: userRole,
    plan: subscription?.type,
  });

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button variant="soft" color="plum">
            <PersonIcon /> Invite member
          </Button>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleInvite)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.email?.message}
              label="Email"
              placeholder="Enter email"
              required
              {...register('email')}
            />

            <Box mt="2">
              <Controller
                control={control}
                name="role_id"
                render={({ field: { value, onChange } }) => {
                  const handleSelect = (value: string) => {
                    const option = Number(value) as WorkspaceRolesType;
                    onChange(option);
                  };

                  return (
                    <SelectField
                      errorMessage={errors.role_id?.message}
                      options={roleOptions}
                      value={value?.toString()}
                      onChange={handleSelect as (value: string) => void}
                      label="Role"
                      placeholder="Select role"
                      required
                    />
                  );
                }}
              />
            </Box>

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                type="submit"
                color="plum"
                loading={isMutating}
              >
                Send invite
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
