import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';

export const GET_PROJECT_MEMBERS_KEY = 'useProjectMembers';

type Account = {
  id: number;
  name: string;
  email: string;
};

type ResponseType = {
  members: Account[];
};

export const getProjectAccounts = (
  projectId: number
): Promise<ResponseType> => {
  return fetcher({ url: `/projects/${projectId}/accounts` });
};

type UseProjectMembersArgs = {
  projectId?: number | undefined;
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useProjectMembers({
  projectId,
  options,
}: UseProjectMembersArgs) {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: [GET_PROJECT_MEMBERS_KEY, projectId],
    queryFn: () => (projectId ? getProjectAccounts(projectId) : undefined),
    enabled: !!projectId,
    meta: {
      name: 'Members list',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
}
