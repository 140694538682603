import { ActiveNodeType, SettingsType, TreeNode } from 'src/types/Template';
import {
  changeNodeAtPath,
  getNodeAtPath,
} from '@nosferatu500/react-sortable-tree';
import { getNodeKey } from 'src/utils/getNodeKey';

type UpdateTreeNodeArgs<T extends SettingsType> = {
  data: T;
  tree: TreeNode[];
  nodeToUpdate: ActiveNodeType | null;
};

export function updateTreeNode<T extends SettingsType>({
  data,
  tree,
  nodeToUpdate,
}: UpdateTreeNodeArgs<T>) {
  if (!nodeToUpdate?.path) {
    return;
  }

  const treeNode = getNodeAtPath({
    treeData: tree,
    // @ts-expect-error: library error path type depends on getNodeKey, in our case it's string[]
    path: nodeToUpdate.path,
    getNodeKey,
  });

  const node = treeNode?.node;
  if (!node) {
    return;
  }

  node.settings = data;

  const updatedTree = changeNodeAtPath({
    treeData: tree,
    // @ts-expect-error: library error path type depends on getNodeKey, in our case it's string[]
    path: nodeToUpdate.path,
    newNode: node,
    getNodeKey,
  }) as TreeNode[];

  return updatedTree;
}
