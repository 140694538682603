export function getPrice(value: string | undefined) {
  if (!value) {
    return 0;
  }
  const price = Number(value);

  if (isNaN(price)) {
    return 0;
  }

  return price / 100;
}
