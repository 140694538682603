import type { Role } from 'src/hooks/api/useProjectMemberRolesUpdate';
import { ROLE_STATUS } from 'src/types/Roles';

type AddRolesDiffArgs = {
  initial: (string | undefined)[] | undefined | null;
  final: (string | undefined)[] | undefined | null;
  instanceId: number;
  roles: Role[];
};

export const addRolesDiff = ({
  initial,
  final,
  instanceId,
  roles,
}: AddRolesDiffArgs) => {
  const initialSet = new Set(initial?.filter(Boolean) as string[]);
  const finalSet = new Set(final?.filter(Boolean) as string[]);

  // Roles to add
  finalSet.forEach((roleId) => {
    if (!initialSet.has(roleId)) {
      roles.push({
        instance_id: instanceId,
        role_id: Number(roleId),
        status: ROLE_STATUS.CREATE,
      });
    }
  });

  // Roles to delete
  initialSet.forEach((roleId) => {
    if (!finalSet.has(roleId)) {
      roles.push({
        instance_id: instanceId,
        role_id: Number(roleId),
        status: ROLE_STATUS.DELETE,
      });
    }
  });
};
