import { ResponseItemField } from 'src/types/Item';

type DefaultValuesType = {
  [key: string]: string | number | number[];
};

export const getFormInitialValues = (data: ResponseItemField[] | undefined) => {
  const defaultValues: DefaultValuesType = {};

  data?.forEach((item) => {
    defaultValues[item.id] = item.value;
  });

  return defaultValues;
};
