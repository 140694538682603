import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';
import { WorkspacesRolesMap } from 'src/utils/getWorkspacesRolesMap';

type canCreateProjectArgs = {
  workspacesRoleMap: WorkspacesRolesMap;
};

export function canCreateProject({ workspacesRoleMap }: canCreateProjectArgs) {
  return !![...workspacesRoleMap.values()].find(
    (role) => role === WORKSPACES_ROLES.ADMIN || role === WORKSPACES_ROLES.OWNER
  );
}
