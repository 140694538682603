import React from 'react';
import { Box, Button, Flex, Popover } from '@radix-ui/themes';
import { Link2Icon } from '@radix-ui/react-icons';
import { insertItem } from '../../utils/insertItem';
import { MenuNode } from 'src/types/Menu';
import { useTemplatesList } from 'src/hooks/api/useTemplatesList';
import { v4 as uuidv4 } from 'uuid';
import { SelectField } from 'src/components/SelectField';
import { Toast } from '../../../../../components/Toast';

type GroupCreationProps = {
  treeData: MenuNode[];
  onCreate: (tree: MenuNode[]) => void;
};

export function ItemCreation({ treeData, onCreate }: GroupCreationProps) {
  const [templateId, setTemplateId] = React.useState('');
  const { data } = useTemplatesList();
  const templates = data?.templates;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const template = templates?.find((item) => item.id === templateId);

    if (!template) {
      // add error handling
      return;
    }

    const item = {
      id: uuidv4(),
      label: template.header.label,
      template_id: templateId,
      parent_id: 'root',
      order: 0,
    };

    try {
      const updatedTree = insertItem({ treeData, node: item });
      onCreate(updatedTree);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred';
      Toast({
        text: errorMessage,
        variant: 'error',
      });
    }
  };

  const selectOptions = templates?.map((template) => ({
    id: template.id.toString(),
    label: template.header.label,
  }));

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="soft" color="gold" size="1">
          <Link2Icon width="16" height="16" /> Add link
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <form onSubmit={handleSubmit}>
          <Box minWidth="300px">
            <SelectField
              options={selectOptions || []}
              onChange={(value) => setTemplateId(value)}
              label="Template"
              placeholder="Pick template"
              required
            />
          </Box>
          <Popover.Close>
            <Flex justify="end">
              <Button variant="soft" type="submit" mt="4" color="plum">
                Create link
              </Button>
            </Flex>
          </Popover.Close>
        </form>
      </Popover.Content>
    </Popover.Root>
  );
}
