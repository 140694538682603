import React from 'react';
import { TreeNode } from 'src/types/Template';
import { ItemsTable } from './components/ItemsTable';
import { Page__Content, Page__Header } from 'src/components/Page';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Box,
  IconButton,
  Text,
  Skeleton,
  Popover,
} from '@radix-ui/themes';
import { CopyIcon } from '@radix-ui/react-icons';
import { useTemplateById } from 'src/hooks/api/useTemplateById';
import { useItemsList } from 'src/hooks/api/useItemsList';
import type { ApiItemListResponse, ApiItemResponse } from 'src/types/Item';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { ResponseItemField } from 'src/types/Item';
import { getTemplateTreeFromFlatData } from 'src/utils/getTemplateTreeFromFlatData';
// import { TableList } from './components/TableList';
import { FIELD_TYPE } from 'src/constants/components';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import './index.css';
import { ErrorApi } from 'src/components/ErrorApi';
import { TableLoading } from 'src/components/TableLoading';
import { appRoutes } from 'src/utils/routePaths';
import { ImageCell } from './components/ImageCell';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { useItemsLabelsByIds } from 'src/hooks/api/useItemsLabelsByIds';
import { extractItemLinkAndItemLinkListValues } from './utils/extractItemLinkAndItemLinkListValues';
import { fillItemLinkAndItemLinkListTemplateIdMap } from './utils/fillItemLinkAndItemLinkListTemplateIdMap';
import { INSTANCE } from 'src/constants/instance';
import { AccessInstance } from 'src/components/AccessInstance';
import { Callout } from 'src/components/Callout';

export type DataType = {
  id: string;
} & {
  internalLabel: string;
} & {
  [key: string]: {
    item: ResponseItemField[];
    node: TreeNode;
  };
};

type GetColumnsArgs = {
  templateId: string;
  tree: TreeNode[];
  instanceId: number;
  itemsLabelsMap: Map<string, Map<number, string>>;
};

const getColumns = ({
  templateId,
  tree,
  instanceId,
  itemsLabelsMap,
}: GetColumnsArgs) => {
  const columns: ColumnDef<DataType>[] = [];

  // add id column
  columns.push({
    accessorKey: 'id',
    enableHiding: false,
    header: 'ID',
    meta: {
      className: 'items-table__column_sticky',
    },
    cell: ({ row }) => (
      <div>
        <Button asChild color="indigo" variant="ghost">
          <RouterLink
            to={appRoutes.itemEdit({
              instanceId,
              templateId,
              id: row.original.id,
            })}
          >
            {row.getValue('id')}
          </RouterLink>
        </Button>
      </div>
    ),
  });

  // add Item name column
  columns.push({
    accessorKey: 'internalLabel',
    enableHiding: false,
    header: 'Label',
    cell: ({ row }) => (
      <div>
        <Button asChild color="indigo" variant="ghost">
          <RouterLink
            to={appRoutes.itemEdit({
              instanceId,
              templateId,
              id: row.original.id,
            })}
          >
            {row.getValue('internalLabel')}
          </RouterLink>
        </Button>
      </div>
    ),
  });

  tree.forEach((treeNode) => {
    const column = {
      accessorKey: treeNode.id,
      header:
        treeNode.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW
          ? ''
          : treeNode.settings.label,
      cell: ({ row }: { row: Row<DataType> }) => {
        const rowValue: { item: ResponseItemField[]; node: TreeNode } =
          row.getValue(treeNode.id);

        if (!rowValue) {
          return null;
        }
        const { node, item } = rowValue;
        const itemMap = getItemMap({ data: item });

        if (node.settings.type === FIELD_TYPE.IMAGE) {
          const fieldValue = itemMap.get(node.id) as string;
          return (
            <ImageCell
              instanceId={instanceId}
              fieldName={node.settings.name}
              fieldValue={fieldValue}
            />
          );
        }

        if (node.settings.type === FIELD_TYPE.DYNAMIC_GROUP) {
          // return <TableList data={node.children} itemMap={itemMap} />;
          return null;
        }

        if (node.settings.type === FIELD_TYPE.GROUP) {
          return null;
        }

        const fieldValue = itemMap.get(node.id);

        if (node.settings.type === FIELD_TYPE.ITEM_LINK) {
          const itemLabelsValuesMap = itemsLabelsMap.get(
            node.settings.templateId
          );

          return (
            <span>
              {itemLabelsValuesMap?.get(fieldValue as number)}{' '}
              <Text color="gray">[{fieldValue}]</Text>
            </span>
          );
        }

        if (node.settings.type === FIELD_TYPE.ITEM_LINK_LIST) {
          const itemLabelsValuesMap = itemsLabelsMap.get(
            node.settings.templateId
          );

          if (!itemLabelsValuesMap) {
            return null;
          }
          const linkListValues = fieldValue as number[];
          return (
            <>
              {linkListValues.map((item, index) => (
                <span key={index}>
                  {itemLabelsValuesMap.get(item)}{' '}
                  <Text color="gray">[{item}]</Text>
                  {index < linkListValues.length - 1 ? ', ' : ''}
                </span>
              ))}
            </>
          );
        }

        return <div>{fieldValue || '-'}</div>;
      },
    };

    columns.push(column);
  });

  return columns;
};

type GetDataArgs = {
  items: ApiItemResponse[];
  templateTree: TreeNode[];
};
const getData = ({ items, templateTree }: GetDataArgs) => {
  const tableData: Array<DataType> = [];

  items.forEach((item) => {
    const row: DataType = {
      id: item.id,
      internalLabel: item.header.internal_label,
    } as DataType;
    templateTree.forEach((node: TreeNode) => {
      row[node.id] = { node, item: item.fields };
    });
    tableData.push(row);
  });

  return tableData;
};

function getItemMap({ data = [] }: { data: ResponseItemField[] }) {
  const map: Map<string, number | string | number[]> = new Map();
  data?.forEach((item) => {
    map.set(item.template_field_id, item.value);
  });

  return map;
}

export default function TemplateManagement() {
  const navigate = useNavigate();
  const { instanceId } = useInstanceContext();
  const [columns, setColumns] = React.useState<ColumnDef<DataType>[]>([]);
  const [data, setData] = React.useState<DataType[]>([]);
  const params = useParams();
  const {
    data: template,
    isLoading: isTemplateLoading,
    error: templateError,
  } = useTemplateById({
    id: params?.templateId,
  });
  const {
    data: itemsList,
    isLoading: isItemsLoading,
    error: itemsListError,
  } = useItemsList({
    templateId: template?.id,
  });

  const { mutateAsync: getItemsLabelsByIds, error: itemsLabelsByIdsError } =
    useItemsLabelsByIds();

  const [itemsLabelsMap, setItemsLabelsMap] = React.useState<
    Map<string, Map<number, string>>
  >(new Map());
  const [templateItemLinksMap, setTemplateItemLinksMap] = React.useState<
    Map<string, string>
  >(new Map());

  React.useEffect(() => {
    if (!template) {
      return;
    }

    setTemplateItemLinksMap(fillItemLinkAndItemLinkListTemplateIdMap(template));
  }, [template]);

  React.useEffect(() => {
    const items = itemsList?.items;
    if (!items) {
      return;
    }
    setItemsLabels(itemsList);

    async function setItemsLabels(itemsList: ApiItemListResponse) {
      const itemLinkAndItemLinkListValues =
        extractItemLinkAndItemLinkListValues({
          itemsList: itemsList,
          templateFieldsMap: templateItemLinksMap,
        });

      if (!itemLinkAndItemLinkListValues.size) {
        return;
      }
      const newItemsLabelsMap = new Map();

      const promises = Array.from(itemLinkAndItemLinkListValues.entries()).map(
        async ([templateId, idsSet]) => {
          const ids = Array.from(idsSet);
          const response = await getItemsLabelsByIds({
            templateId,
            ids,
          });

          const labelsMap = new Map<number, string>();
          response.items?.forEach((item) => {
            labelsMap.set(item.id, item.internal_label);
          });

          newItemsLabelsMap.set(templateId, labelsMap);
        }
      );

      await Promise.all(promises);
      setItemsLabelsMap(newItemsLabelsMap);
    }
  }, [getItemsLabelsByIds, itemsList, templateItemLinksMap]);

  React.useEffect(() => {
    const templateFields = template?.fields || [];

    const items = itemsList?.items;
    const templateId = template?.id;
    if (!items || !templateId) {
      return;
    }

    const tree = getTemplateTreeFromFlatData({ data: templateFields });
    const tableColumns = getColumns({
      tree,
      templateId: templateId,
      instanceId,
      itemsLabelsMap,
    });

    // tableColumns.push({
    //   id: 'actions',
    //   enableHiding: false,
    //   cell: ({ row }) => (
    //     <>
    //       <TableDropdown
    //         itemId={row.original.id}
    //         templateId={params.templateId}
    //       />
    //     </>
    //   ),
    // });

    setColumns(tableColumns);
    setData(
      getData({
        items: items,
        templateTree: tree,
      })
    );
  }, [template, itemsList, instanceId, params.templateId, itemsLabelsMap]);

  const handleCopyTemplate = () => {
    const url = appRoutes.templateCopy({
      instanceId,
      templateId: params.templateId || '',
    });
    navigate(url);
  };

  if (templateError) {
    return (
      <>
        <Page__Header title="Items list" />

        <Page__Content>
          {templateError && <ErrorApi error={templateError} />}
        </Page__Content>
      </>
    );
  }

  if (isTemplateLoading || isItemsLoading) {
    return (
      <>
        <Page__Header title={<Skeleton width="140px" height="20px" />} />
        <Page__Content>
          <TableLoading rowsAmount={3} headers={['', '', '']} />
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Page__Header
        title={template?.header?.label}
        dataTestId="items-list-title"
        headingSuffix={
          <IconButton
            style={{ display: 'none' }}
            color="gold"
            variant="ghost"
            title="Clone template"
            onClick={handleCopyTemplate}
          >
            <CopyIcon />
          </IconButton>
        }
      >
        <Flex gap="4" align="center" justify="end">
          <Access
            roles={ROLES_LIST}
            permissionsGroups={[[PERMISSIONS.CREATE_ITEM]]}
          >
            <AccessInstance allowedInstances={[INSTANCE.DEV]}>
              <Button variant="soft" color="plum" asChild>
                <RouterLink
                  to={appRoutes.itemNew({
                    instanceId,
                    templateId: params.templateId || '',
                  })}
                  data-testid="create-item"
                >
                  Create item
                </RouterLink>
              </Button>
            </AccessInstance>

            <AccessInstance allowedInstances={[INSTANCE.STAGE, INSTANCE.PROD]}>
              <Popover.Root>
                <Popover.Trigger>
                  <Button variant="soft" color="plum">
                    Create item
                  </Button>
                </Popover.Trigger>
                <Popover.Content>
                  <Box maxWidth="90vw">
                    <Callout color="tomato">
                      You can only create items in the development environment.
                      Here, you can review or manage existing items.
                    </Callout>
                  </Box>
                </Popover.Content>
              </Popover.Root>
            </AccessInstance>
          </Access>

          <Access roles={ROLES_LIST} ignorePermissions>
            {({ userPermissions }) => (
              <Button variant="soft" color="gold" asChild>
                <RouterLink
                  to={appRoutes.templateEdit({
                    instanceId,
                    templateId: params.templateId || '',
                  })}
                >
                  {!userPermissions.includes(PERMISSIONS.CREATE_TEMPLATE)
                    ? 'View template'
                    : 'Edit template'}
                </RouterLink>
              </Button>
            )}
          </Access>
        </Flex>
      </Page__Header>

      <Page__Content hasScrollArea={false} mix="items-list__body">
        {!!itemsListError && (
          <Box mb="6">
            <ErrorApi error={itemsListError} />
          </Box>
        )}

        {!!itemsLabelsByIdsError && (
          <Box mb="6">
            <ErrorApi error={itemsLabelsByIdsError} />
          </Box>
        )}

        {!itemsListError && (
          <ItemsTable
            columns={columns}
            data={data}
            templateId={params.templateId || ''}
          />
        )}
      </Page__Content>
    </>
  );
}
