import React, { Fragment } from 'react';
import {
  Combobox as HeadlessCombobox,
  Transition,
  ComboboxProps as HeadlessComboboxProps,
} from '@headlessui/react';
import { CheckIcon, CaretSortIcon } from '@radix-ui/react-icons';
import b from 'bem-react-helper';
import { Text } from '@radix-ui/themes';
import { useFloating, size, offset, flip } from '@floating-ui/react';

type Option = {
  id: string;
  label: string;
};

type CommonProps = {
  onInputChange: (value: string) => void;
  options: Option[];
  inputValue: string;
  afterLeave?: () => void;
  label?: string | React.ReactNode;
  placeholder?: string;
  required?: boolean | undefined;
  hideMeta?: boolean;
  disabled?: boolean;
};

type ComboboxPropsMultiple = CommonProps &
  HeadlessComboboxProps<Option, true, true, typeof Fragment>;

type ComboboxProps = CommonProps &
  HeadlessComboboxProps<Option, true, false, typeof Fragment>;

// https://github.com/tailwindlabs/headlessui/issues/2438
export function Combobox({
  onChange,
  value,
  onInputChange,
  options,
  inputValue,
  afterLeave,
  label,
  multiple,
  placeholder,
  nullable,
  required,
  hideMeta,
  disabled,
}: ComboboxProps | ComboboxPropsMultiple) {
  const { refs, floatingStyles } = useFloating({
    strategy: 'fixed',
    middleware: [
      flip(),
      offset(10),
      size({
        apply({ elements, ...state }) {
          // Change styles, e.g.
          Object.assign(elements.floating.style, {
            width: `${state.rects.reference.width}px`,
          });
        },
      }),
    ],
  });
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const comboboxRef = React.useRef<HTMLDivElement | null>(null);

  // https://github.com/tailwindlabs/headlessui/discussions/1236
  const handleInputFocus = () => {
    const isVisible = comboboxRef?.current?.querySelector('#isVisibleElement');
    const buttonNode = buttonRef.current;
    if (!isVisible && buttonNode) {
      buttonNode.click();
    }
  };

  const displayValue = (item: Option | null) => (item ? item.label : '');

  return (
    <div
      className={b('combobox', {}, { disabled: !!disabled })}
      ref={comboboxRef}
    >
      <HeadlessCombobox
        disabled={disabled}
        value={value}
        onChange={onChange}
        // @ts-expect-error: problem with types in headless ui https://github.com/tailwindlabs/headlessui/issues/2438
        multiple={multiple}
        // @ts-expect-error: problem with types in headless ui https://github.com/tailwindlabs/headlessui/issues/2438
        nullable={nullable}
      >
        <div className="combobox__body">
          <div className="combobox__description">
            <HeadlessCombobox.Label className="combobox__label">
              {label}{' '}
              <span className="combobox__meta">
                {!hideMeta && <>{multiple ? '[multiple]' : '[single]'} </>}
                {required ? (
                  <Text color="plum" size="4">
                    *
                  </Text>
                ) : (
                  ''
                )}
              </span>
            </HeadlessCombobox.Label>

            {multiple && !!value?.length && (
              <div className="combobox__meta">
                selected {value?.length} items
              </div>
            )}
          </div>
          <div className="combobox__field" ref={refs.setReference}>
            <HeadlessCombobox.Input
              className="combobox__input"
              onChange={(e) => onInputChange(e.target.value)}
              placeholder={placeholder}
              displayValue={displayValue}
              onFocus={handleInputFocus}
              autoComplete="off"
            />
            <HeadlessCombobox.Button
              className="button combobox__button"
              ref={buttonRef}
            >
              <CaretSortIcon width="20px" height="20px" aria-hidden="true" />
            </HeadlessCombobox.Button>
          </div>

          <Transition as={Fragment} afterLeave={afterLeave}>
            <HeadlessCombobox.Options
              className="combobox__options-list"
              id="isVisibleElement"
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {options.length === 0 && inputValue !== '' ? (
                <div className="combobox__placeholder">Nothing found</div>
              ) : (
                options.map((item) => (
                  <HeadlessCombobox.Option
                    key={item.id}
                    className={({ active, selected }) =>
                      b('combobox__option', {}, { active, selected })
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={b(
                            'combobox__option-text',
                            {},
                            { selected }
                          )}
                        >
                          {item.label}
                        </span>
                        <span className={b('combobox__option-check', {}, {})}>
                          {selected && (
                            <CheckIcon
                              color="gray"
                              className="combobox__option-check-icon"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </>
                    )}
                  </HeadlessCombobox.Option>
                ))
              )}
            </HeadlessCombobox.Options>
          </Transition>
        </div>
      </HeadlessCombobox>
    </div>
  );
}
