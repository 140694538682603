import { differenceInDays, differenceInHours } from 'date-fns';

interface TimeLeftParams {
  targetDateStr?: string;
}

interface TimeLeftResult {
  amount: number;
  type: 'd' | 'h';
}

export function calculateTimeLeft(params: TimeLeftParams): TimeLeftResult {
  const { targetDateStr } = params;

  if (!targetDateStr) {
    return { amount: 0, type: 'd' };
  }

  const targetDate = new Date(targetDateStr);
  const currentDate = new Date();

  const daysLeft = differenceInDays(targetDate, currentDate);

  if (daysLeft !== 0) {
    return { amount: daysLeft, type: 'd' };
  }

  const hoursLeft = differenceInHours(targetDate, currentDate);

  return { amount: hoursLeft, type: 'h' };
}
