export const TOO_MANY_ATTEMPTS = 10005;
export const CONFIRMATION_NOT_FOUND = 10006;
export const MALFORMED_BODY_PAYLOAD = 10003;

export const COMMON_ERROR =
  'Oops! Something went wrong. Please try again. If you continue to have issues, contact our support team.';

export const ERROR_STATUS_CODE: Record<number, string> = {
  [TOO_MANY_ATTEMPTS]: 'Too many resend attempts',
  [CONFIRMATION_NOT_FOUND]: "We couldn't locate your confirmation email",
  [MALFORMED_BODY_PAYLOAD]: COMMON_ERROR,
};
