import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, DropdownMenu, IconButton, Strong } from '@radix-ui/themes';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Link as RouterLink } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { useWorkspaceRoleRemove } from 'src/hooks/api/useWorkspaceRoleRemove';
import { Toast } from 'src/components/Toast';
import { GET_WORKSPACE_ROLES_KEY } from 'src/hooks/api/useWorkspaceRoles';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';

type TableDropdownProps = {
  workspaceId: number;
  roleId: number;
  roleName: string;
};

export function TableDropdown({
  workspaceId,
  roleId,
  roleName,
}: TableDropdownProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const {
    mutate: removeRole,
    error: removeRoleError,
    isMutating: isRoleRemoving,
  } = useWorkspaceRoleRemove({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_ROLES_KEY, workspaceId],
      });
      Toast({
        text: 'Role successfully deleted',
        variant: 'info',
      });
    },
  });
  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton variant="ghost" title="Edit role">
            <DotsHorizontalIcon />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content variant="soft">
          <DropdownMenu.Item asChild>
            <RouterLink
              to={appRoutes.settingsProjectEditRole({
                roleId,
                workspaceId,
              })}
            >
              Update role
            </RouterLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item color="red" onClick={() => setIsDialogOpen(true)}>
            Remove role
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <AlertDialog
        title="Remove project role"
        color="tomato"
        open={isDialogOpen}
        actionText="Yes, remove role"
        cancelText="No, keep role"
        onCancel={() => setIsDialogOpen(false)}
        onAction={() => removeRole({ workspaceId, role_id: roleId })}
        loading={isRoleRemoving}
      >
        {!!removeRoleError && (
          <Box mb="4">
            <ErrorApi error={removeRoleError} />
          </Box>
        )}
        Are you sure you want to remove the <Strong>{roleName}</Strong> role?
        <Box mt="2">
          This action cannot be undone, and any users currently assigned to this
          role will lose their associated permissions.
        </Box>
      </AlertDialog>
    </>
  );
}
