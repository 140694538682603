import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ReportsListTable } from './components/ReportsListTable';
import { useExportReportsList } from 'src/hooks/api/useExportReportsList';
import { ErrorApi } from 'src/components/ErrorApi';
import { TableLoading } from 'src/components/TableLoading';

const PAGE_TITLE = 'Exported data';

export function ExportedData() {
  const {
    data: reportsData,
    isLoading: isReportsDataLoading,
    error: reportsDataError,
  } = useExportReportsList({});

  if (isReportsDataLoading) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          <TableLoading headers={['ID', 'Created', 'Status']} rowsAmount={3} />
        </Page__Content>
      </>
    );
  }

  if (reportsDataError) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          <ErrorApi error={reportsDataError} />
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

      <Page__Content>
        <ReportsListTable data={reportsData?.reports || []} />
      </Page__Content>
    </>
  );
}
