import React from 'react';
import { format } from 'date-fns';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, Flex, Badge, Button } from '@radix-ui/themes';
import { DialogReport } from './DialogReport';

type ReportsTableType = {
  id: string;
  created_at: string;
  total_steps: number;
  completed_steps: number;
};

const columnHelper = createColumnHelper<ReportsTableType>();

type ReportsTableProps = {
  data: ReportsTableType[];
};

export function ReportsListTable(props: ReportsTableProps) {
  const { data } = props;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => {
          const reportId = info.getValue();

          return (
            <DialogReport
              reportId={reportId}
              trigger={
                <Button variant="ghost" color="indigo">
                  {reportId}
                </Button>
              }
            />
          );
        },
        header: () => <span>ID</span>,
        size: 240,
      }),
      columnHelper.accessor('created_at', {
        cell: (info) => {
          const createdAt = format(info.getValue(), 'dd MMM, yyyy H:mm:ss');
          return <span>{createdAt}</span>;
        },
        header: () => <span>Created</span>,
        size: 240,
      }),
      columnHelper.accessor('total_steps', {
        cell: (info) => {
          const totalSteps = info.getValue();
          const completedSteps = info.row.original.completed_steps;
          const progress = totalSteps
            ? (completedSteps / totalSteps) * 100
            : 100;

          return (
            <Flex align="start" justify="start" height="100%">
              {progress === 100 ? (
                <Badge color="grass">completed</Badge>
              ) : (
                <Badge color="orange">{Math.round(progress)}% completed</Badge>
              )}
            </Flex>
          );
        },
        header: () => <span>Status</span>,
        size: 240,
      }),
    ],
    []
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell
                  key={headerIndex}
                  {...(header.id === 'id' || header.id === 'created_at'
                    ? { width: `${header.getSize()}px` }
                    : {})}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No results</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
