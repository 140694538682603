import _ from 'lodash';
import { MenuItem, MENU_ITEM_STATUS } from 'src/types/Menu';

type GetChangedItemsArgs = {
  initialData: MenuItem[];
  updatedData: MenuItem[];
};

export function getChangedItems({
  initialData,
  updatedData,
}: GetChangedItemsArgs) {
  const changedItems = [];
  const addedItems = getAddedItems({ updatedData, initialData });
  changedItems.push(...addedItems);

  const removedItems = getRemovedItems({ updatedData, initialData });
  changedItems.push(...removedItems);

  const updatedItems = getUpdatedItems({ updatedData, initialData });
  changedItems.push(...updatedItems);

  return changedItems;
}

type GetAddedItemsArgs = {
  initialData: MenuItem[];
  updatedData: MenuItem[];
};

function getAddedItems({ updatedData, initialData }: GetAddedItemsArgs) {
  const addedItems = _.differenceBy(updatedData, initialData, 'id');

  return addedItems.map((item) => ({
    ...item,
    status: MENU_ITEM_STATUS.ADDED,
  }));
}

type GetRemovedItemsArgs = {
  initialData: MenuItem[];
  updatedData: MenuItem[];
};

function getRemovedItems({ updatedData, initialData }: GetRemovedItemsArgs) {
  const removedItems = _.differenceBy(initialData, updatedData, 'id');

  return removedItems.map((item) => ({
    ...item,
    status: MENU_ITEM_STATUS.REMOVED,
  }));
}

type GetUpdatedItemsArgs = {
  initialData: MenuItem[];
  updatedData: MenuItem[];
};

function getUpdatedItems({ updatedData, initialData }: GetUpdatedItemsArgs) {
  const updatedItems = _.intersectionBy(updatedData, initialData, 'id').filter(
    (newItem) => {
      const oldItem = _.find(initialData, { id: newItem.id });
      return (
        newItem.label !== oldItem?.label ||
        newItem.parent_id !== oldItem?.parent_id ||
        newItem.order !== oldItem?.order
      );
    }
  );

  return updatedItems.map((item) => ({
    ...item,
    status: MENU_ITEM_STATUS.UPDATED,
  }));
}
