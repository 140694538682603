import { TreeNode } from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';
import { convertTreeToTemplateFields } from './convertTreeToTemplateFields';

type IsFieldNameUniqueArgs = {
  tree: TreeNode[];
  name: string;
};

export function isFieldNameUnique({ tree, name }: IsFieldNameUniqueArgs) {
  const templateData = convertTreeToTemplateFields({ templateTree: tree });
  let isNameUnique = true;
  const parentNameMap: { [parentId: string]: Set<string> } = {};

  templateData.some((field) => {
    if (field.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW) return false;

    if (field.settings.name === name) {
      const parentId = field.parentId || 'root';

      if (!parentNameMap[parentId]) {
        parentNameMap[parentId] = new Set();
      }

      if (parentNameMap[parentId].has(field.settings.name)) {
        isNameUnique = false;

        return true;
      }

      parentNameMap[parentId].add(field.settings.name);
    }

    return false;
  });

  return isNameUnique;
}
