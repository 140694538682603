import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { InstanceChanges } from 'src/types/Instance';

export const GET_INSTANCE_CHANGES_KEY = 'useInstanceChanges';

type Response = InstanceChanges;

const getChanges = (instanceId: number): Promise<Response> => {
  return fetcher({ url: `/instances/${instanceId}/changes` });
};

type UseInstanceChangesArgs = {
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>;
};

export function useInstanceChanges({ options }: UseInstanceChangesArgs = {}) {
  const { instanceId } = useInstanceContext();
  const { data, error, isError, isLoading, refetch } = useQuery({
    queryKey: [GET_INSTANCE_CHANGES_KEY, instanceId],
    enabled: instanceId > 0,
    queryFn: () => getChanges(instanceId),
    meta: {
      name: 'Instance changes list',
    },
    ...options,
  });

  return {
    data,
    isLoading: isLoading,
    error: isError ? error : null,
    refetch,
  };
}
