import { ActiveNodeType } from 'src/types/Template';
import { fields } from 'src/fields';

type FieldSettingsProps = {
  data: ActiveNodeType['node'];
};

export function FieldSettings({ data }: FieldSettingsProps) {
  const Component = fields[data.settings.type].SettingsForm;

  // @ts-expect-error: fix me if you can
  return Component && <Component initialValues={data.settings} />;
}
