import { Box, Button, Heading, Text } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';

export default function RegistrationNotification() {
  return (
    <PageAccent mods={{ type: 'primary' }}>
      <Heading mb="4">Need help?</Heading>

      <Box mb="4">
        <Text>
          It&nbsp;seems like you&rsquo;ve attempted to&nbsp;send the email
          multiple times.
        </Text>
      </Box>

      <Box mb="6">
        <Text>
          If&nbsp;you&rsquo;re still not receiving&nbsp;it, please contact our
          support team for further assistance.
        </Text>
      </Box>

      <Box>
        <Button variant="soft" color="plum" size="3" style={{ width: '100%' }}>
          Contact support
        </Button>
      </Box>
    </PageAccent>
  );
}
