import React from 'react';
import { PlanType } from 'src/constants/plans';
import { SubscriptionStatusType } from 'src/constants/subscription';
import { useInterval } from 'usehooks-ts';
import { useSubscription } from 'src/hooks/api/useSubscription';
import { Toast } from 'src/components/Toast';

type ExpectedSubscription = {
  plan: PlanType | undefined;
  status: SubscriptionStatusType | undefined;
  members: number | undefined;
  projects: number | undefined;
} | null;

type UseSubscriptionPollingArgs = {
  workspaceId: string;
};

export function useSubscriptionPolling({
  workspaceId,
}: UseSubscriptionPollingArgs) {
  const { data: subscription } = useSubscription({ workspaceId });
  const [expectedSubscription, setExpectedSubscription] =
    React.useState<ExpectedSubscription>(null);
  const [isSubscriptionPollingEnabled, setIsSubscriptionPollingEnabled] =
    React.useState(false);
  const [isShowPollingNotification, setIsShowPollingNotification] =
    React.useState(false);

  const enablePolling = React.useCallback(
    ({
      expectedSubscription,
    }: {
      expectedSubscription: ExpectedSubscription;
    }) => {
      setIsSubscriptionPollingEnabled(true);
      setExpectedSubscription(expectedSubscription);
    },
    []
  );

  const disablePolling = React.useCallback(() => {
    setIsSubscriptionPollingEnabled(false);
    setExpectedSubscription(null);
    setIsShowPollingNotification(false);
  }, []);

  useInterval(
    () => {
      if (isSubscriptionPollingEnabled) {
        disablePolling();
      }
    },
    isSubscriptionPollingEnabled ? 60000 : null // disable polling after 1 min
  );

  React.useEffect(() => {
    if (
      isSubscriptionPollingEnabled &&
      subscription?.type === expectedSubscription?.plan &&
      subscription?.status === expectedSubscription?.status &&
      subscription?.members === expectedSubscription?.members &&
      subscription?.projects === expectedSubscription?.projects
    ) {
      disablePolling();
      Toast({
        variant: 'info',
        text: 'Subscription updated',
      });
    }
  }, [
    expectedSubscription?.plan,
    expectedSubscription?.status,
    subscription?.type,
    subscription?.status,
    disablePolling,
    subscription?.members,
    subscription?.projects,
    expectedSubscription?.members,
    expectedSubscription?.projects,
    isSubscriptionPollingEnabled,
  ]);

  return {
    isSubscriptionPollingEnabled,
    enablePolling,
    isShowPollingNotification,
    setIsShowPollingNotification,
  };
}
