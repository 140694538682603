import {
  WorkspaceRolesType,
  WORKSPACES_ROLES,
} from 'src/constants/workspaceRoles';
import { PLANS, PlanType } from 'src/constants/plans';

const ROLES_OPTIONS = [
  {
    id: WORKSPACES_ROLES.MEMBER.toString(),
    label: 'Member',
  },
  {
    id: WORKSPACES_ROLES.ADMIN.toString(),
    label: 'Admin',
  },
  {
    id: WORKSPACES_ROLES.OWNER.toString(),
    label: 'Owner',
  },
];

const TEAMS_PLAN_ROLES = {
  OWNER: ROLES_OPTIONS,
  ADMIN: ROLES_OPTIONS.filter(
    (role) => role.id !== WORKSPACES_ROLES.OWNER.toString()
  ),
  MEMBER: ROLES_OPTIONS.filter(
    (role) => role.id === WORKSPACES_ROLES.MEMBER.toString()
  ),
};

const STARTER_PLAN_ROLES = {
  OWNER: ROLES_OPTIONS.filter(
    (role) => role.id !== WORKSPACES_ROLES.MEMBER.toString()
  ),
  ADMIN: ROLES_OPTIONS.filter(
    (role) => role.id === WORKSPACES_ROLES.ADMIN.toString()
  ),
  MEMBER: [],
};

type GetWorkspaceRolesArgs = {
  role: WorkspaceRolesType | null;
  plan: PlanType | undefined;
};

export function getWorkspaceRoles({ role, plan }: GetWorkspaceRolesArgs) {
  const availableOptions =
    plan === PLANS.TEAMS ? TEAMS_PLAN_ROLES : STARTER_PLAN_ROLES;

  switch (role) {
    case WORKSPACES_ROLES.OWNER:
      return availableOptions.OWNER;
    case WORKSPACES_ROLES.ADMIN:
      return availableOptions.ADMIN;
    default:
      return availableOptions.MEMBER;
  }
}
