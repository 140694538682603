import _, { isArray } from 'lodash';
import { ItemField, ResponseItemField } from 'src/types/Item';
import { FIELD_TYPE, TEMPLATE_FIELD_STATUS } from 'src/constants/components';

type ItemMap = Map<string, ItemField>;

type AddStatusesToFieldsArgs = {
  initialData: ResponseItemField[];
  currentData: ItemField[];
};

export const addStatusesToFields = ({
  initialData,
  currentData,
}: AddStatusesToFieldsArgs): ItemField[] => {
  const initialDataMap: ItemMap = isArray(initialData)
    ? getMap(initialData)
    : new Map();
  const currentDataMap: ItemMap = isArray(currentData)
    ? getMap(currentData)
    : new Map();
  const fields: ItemField[] = [];

  currentDataMap.forEach((field) => {
    const newField = initialDataMap.get(field.id);
    if (!newField) {
      fields.push({ ...field, status: TEMPLATE_FIELD_STATUS.ADDED });
      return;
    }

    if (!_.isEqual(newField, field)) {
      fields.push({
        ...field,
        status: TEMPLATE_FIELD_STATUS.UPDATED,
      });
    }
  });

  initialDataMap.forEach((field) => {
    const newFieldValue = currentDataMap.get(field.id);
    if (!newFieldValue) {
      fields.push({
        ...field,
        status: TEMPLATE_FIELD_STATUS.REMOVED,
      });
    }
  });

  const updatedFields = addImageUploadId({ initialData, fields }); // need purely for backend, have no idea what is this

  return updatedFields;
};

export function getMap(
  data: ItemField[] | ResponseItemField[]
): Map<ItemField['id'], ItemField> {
  const map = new Map();

  data.forEach((field) => {
    // remove status fields so it's easy to compare fields
    const {
      value,
      id,
      template_field_id,
      template_field_type,
      template_group_id,
      template_group_path,
    } = _.cloneDeep(field);
    map.set(id, {
      value,
      id,
      template_field_id,
      template_field_type,
      template_group_id,
      template_group_path,
    });
  });

  return map;
}

type AddImageUploadIdArg = {
  initialData: ResponseItemField[];
  fields: ItemField[];
};

function addImageUploadId({ initialData, fields }: AddImageUploadIdArg) {
  const initialFieldsMap = getInitialFieldsMap(initialData);

  const updatedFields = fields.map((field) => {
    if (field.template_field_type === FIELD_TYPE.IMAGE) {
      const newField = _.cloneDeep(field);
      const initialField = initialFieldsMap.get(newField.id);
      const uploadId = initialField?.upload_id;
      if (uploadId) {
        newField.upload_id = uploadId;
      }

      return newField;
    }

    return field;
  });

  return updatedFields;
}

function getInitialFieldsMap(
  data: ResponseItemField[]
): Map<ResponseItemField['id'], ResponseItemField> {
  const map = new Map();

  data.forEach((field) => {
    // remove status fields so it's easy to compare fields
    const initialField = _.cloneDeep(field);
    map.set(initialField.id, initialField);
  });

  return map;
}
