import React from 'react';
import * as yup from 'yup';
import { FIELD_TYPE } from 'src/constants/components';
import { Flex, IconButton } from '@radix-ui/themes';
import * as TemplateTypes from 'src/types/Template';
import './DynamicGroup.css';
import { TrashIcon } from '@radix-ui/react-icons';
import { removeDynamicGroupRow } from 'src/components/ItemForm/utils/removeDynamicGroupRow';
import { useItemFormContext } from 'src/providers/ItemFormProvider';
import { TreeNode } from 'src/types/Template';

const settingsValidationSchema = yup.object().shape({
  type: yup.string().oneOf([FIELD_TYPE.DYNAMIC_GROUP_ROW]).required(),
});

export const getDefaultValues = () => ({
  type: FIELD_TYPE.DYNAMIC_GROUP_ROW,
});

export const validate = (
  data: TemplateTypes.SettingsDynamicGroupRowType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

export const getFieldValidationSchema = () => {
  // don't need validation for dynamic group
  return null;
};

type FieldProps = {
  data: TemplateTypes.SettingsTextType;
  children: React.ReactNode;
  node: TreeNode;
};

/* istanbul ignore next -- @preserve ;test with e2e */
export function Field({ children, node }: FieldProps) {
  const { setTemplateTree, templateTree } = useItemFormContext();
  const handleRemove = () => {
    const updatedTree = removeDynamicGroupRow({
      treeData: templateTree,
      groupId: node.templateGroupId || 'root',
      rowId: node.id,
    });
    setTemplateTree(updatedTree);
  };
  return (
    <div className="dynamic-group">
      <div className="dynamic-group__body">
        <Flex justify="end">
          <IconButton
            variant="soft"
            size="1"
            onClick={handleRemove}
            type="button"
          >
            <TrashIcon />
          </IconButton>
        </Flex>

        {children}
      </div>
    </div>
  );
}

// dynamic row shouldn't have settings form, this component needed only for FE internal stuff
export function SettingsForm() {
  return null;
}
