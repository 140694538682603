import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Navigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { Text, Flex } from '@radix-ui/themes';
import { InviteUser } from './components/InviteUser';
import { useWorkspaceMembers } from 'src/hooks/api/useWorkspaceMembers';
import { MembersTable } from './components/MembersTable';
import { TableLoading } from 'src/components/TableLoading';

export function WorkspaceMembers() {
  const { data, isLoading, error } = useWorkspacesList();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const {
    data: membersData,
    isLoading: isMembersLoading,
    error: membersError,
  } = useWorkspaceMembers({ workspaceId });

  if (isLoading || isMembersLoading) {
    return (
      <>
        <Page__Header title="Workspace members" />

        <Page__Content>
          <TableLoading
            rowsAmount={1}
            headers={['ID', 'Name', 'Email', 'Role']}
          />
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !data?.workspaces) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  if (error || membersError) {
    return (
      <>
        <Page__Header title="Workspace members" />

        <Page__Content>
          {!!error && <ErrorApi error={error} />}
          {!!membersError && <ErrorApi error={membersError} />}
        </Page__Content>
      </>
    );
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: data?.workspaces,
  });

  if (!workspaceData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const accounts = membersData?.accounts || [];

  return (
    <>
      <Page__Header
        title={
          <Flex gap="4" align="center">
            Workspace members{' '}
            <Text size="1" color="gray">
              {workspaceData.name}
            </Text>
          </Flex>
        }
      >
        <InviteUser workspaceId={workspaceId} />
      </Page__Header>

      <Page__Content>
        {!!accounts.length && (
          <MembersTable accounts={accounts} workspaceId={workspaceId} />
        )}
      </Page__Content>
    </>
  );
}
