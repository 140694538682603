import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { EmptyObject } from 'src/types/Common';

export type RemoveProjectArg = {
  projectId: number;
};

const removeProject = (arg: RemoveProjectArg): Promise<EmptyObject> => {
  const { projectId } = arg;
  return fetcher({
    url: `/projects/${projectId}`,
    options: {
      method: 'DELETE',
    },
  });
};

export function useProjectRemove(opt?: MutationOptions<EmptyObject>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: removeProject,
    meta: {
      name: 'Project removal',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
