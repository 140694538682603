import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { MenuItem } from 'src/types/Menu';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const MENU_URL = 'useMenu';
export const GET_MENU_KEY = MENU_URL;

type Response = {
  menu: MenuItem[];
};

const getMenu = (instanceId: number): Promise<Response> => {
  return fetcher({ url: `/editor/${instanceId}/menu` });
};

type UseMenuArgs = {
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>;
};

export function useMenu({ options }: UseMenuArgs = {}) {
  const { instanceId } = useInstanceContext();
  const { data, error, isError, isLoading } = useQuery({
    queryKey: [GET_MENU_KEY, instanceId],
    enabled: instanceId > 0,
    queryFn: () => getMenu(instanceId),
    meta: {
      name: 'Navigation',
    },
    ...options,
  });

  return {
    data,
    isLoading: isLoading,
    error: isError ? error : null,
  };
}
