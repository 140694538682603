import * as yup from 'yup';
import { TreeNode } from 'src/types/Template';
import { fields } from 'src/fields';
import { AnyObject, ObjectSchema } from 'yup';
import { StringObject } from 'src/types/Common';

type Item = {
  entityFieldId: TreeNode['entityFieldId'];
  settings: TreeNode['settings'];
};
type GetValidationSchemaArgs = {
  data: Item[];
};

export function getValidationSchema({
  data,
}: GetValidationSchemaArgs): ObjectSchema<StringObject, AnyObject> {
  const schemaFields: AnyObject = {};
  data.forEach((item) => {
    const validationFn = fields[item.settings.type].getFieldValidationSchema;

    if (validationFn) {
      // @ts-expect-error: fix me if you can
      const rule = validationFn({ data: item.settings, id: item.id });

      if (rule && item.entityFieldId) {
        schemaFields[item.entityFieldId] = rule;
      }
    }
  });

  const internalLabelSchema = {
    internalLabel: yup.string().required('required field'),
  };

  const schema = yup
    .object()
    .shape({ ...schemaFields, ...internalLabelSchema });

  return schema;
}
