import _ from 'lodash';
import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultValues } from 'src/fields/DynamicGroupRow';
import { getNodeKey } from 'src/utils/getNodeKey';
import { FIELD_TYPE } from 'src/constants/components';

type InsertDynamicGroupChildrenArgs = {
  childrenStamp: TreeNode[];
  treeData: TreeNode[];
  groupId: string;
};

export function insertDynamicGroupChildren({
  childrenStamp,
  treeData,
  groupId,
}: InsertDynamicGroupChildrenArgs) {
  const children = _.cloneDeep(childrenStamp);
  const dynamicGroupRow = {
    id: uuidv4(),
    settings: getDefaultValues(),
    expanded: true,
    children,
  };

  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: TreeNode }) => {
      if (
        node.id === groupId &&
        node.settings.type === FIELD_TYPE.DYNAMIC_GROUP
      ) {
        const childIndex = node.children?.length ? node.children.length : 0;
        const groupPath = node.templateGroupPath
          ? [...node.templateGroupPath, childIndex]
          : [childIndex];

        buildRowChildren({
          node: dynamicGroupRow,
          parentId: node.id || 'root',
          groupPath,
          groupId: node.templateFieldId || '',
        });

        return {
          ...node,
          children: node?.children?.length
            ? [...node.children, dynamicGroupRow]
            : [dynamicGroupRow],
        };
      }

      return node;
    },
  });

  return updatedTree as TreeNode[];
}

type UpdateNodeIdsArgs = {
  node: TreeNode;
  parentId: string;
  groupPath: number[];
  groupId: string;
};

export function buildRowChildren({
  node,
  parentId,
  groupPath,
  groupId,
}: UpdateNodeIdsArgs) {
  const isDynamicGroup = node?.settings?.type === FIELD_TYPE.DYNAMIC_GROUP;
  if (isDynamicGroup) {
    // node.groupPath = node.groupPath ?
    // avoid coping child group nodes from stamp, we need only first level group
    node.children = undefined;
    node.templateGroupId = node.templateFieldId;
  } else {
    node.templateGroupId = groupId;
  }
  node.id = uuidv4();
  node.entityFieldId = uuidv4();
  node.templateGroupPath = groupPath;
  node.parentId = parentId;

  if (node.children) {
    for (const childNode of node.children) {
      buildRowChildren({
        node: childNode,
        parentId: node.id || 'root',
        groupPath,
        groupId: node.templateGroupId || '',
      });
    }
  }
}
