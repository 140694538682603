import { IconButton, Inset, HoverCard } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

type ImageMagnifyProps = {
  src: string;
  alt: string;
};

export function ImageMagnify({ src, alt }: ImageMagnifyProps) {
  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <IconButton color="plum" size="1" title="Expand">
          <MagnifyingGlassIcon />
        </IconButton>
      </HoverCard.Trigger>
      <HoverCard.Content className="image-magnify__content">
        <Inset clip="padding-box">
          <img className="image-magnify__img" src={src} alt={alt} />
        </Inset>
      </HoverCard.Content>
    </HoverCard.Root>
  );
}
