import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const exportItems = (instanceId: number): Promise<unknown> => {
  return fetcher({
    url: `/editor/${instanceId}/export/templates`,
    options: {
      method: 'POST',
      body: JSON.stringify({}),
    },
  });
};

export function useExportItems(opt?: MutationOptions<unknown>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: () => exportItems(instanceId),
    meta: {
      name: 'Export items',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    reset,
  };
}
