import React from 'react';
import './Page__Drawer.css';

type PageDrawerProps = {
  children: React.ReactNode;
  onNavClose: () => void;
};

export function Page__Drawer({ children, onNavClose }: PageDrawerProps) {
  return (
    <>
      <div className="page-drawer__content">{children}</div>
      <div className="page-drawer__overlay" onClick={() => onNavClose()}></div>
    </>
  );
}
