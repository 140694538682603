import type { MutationOptions } from 'src/types/Api';
import { useApiMutationTurnstile } from 'src/components/CloudflareTurnstile';

export type RegistrationRequestType = {
  account_name: string;
  email: string;
};

export function useRegistration(opt?: MutationOptions<unknown>) {
  const { mutate, isMutating, error, isChallenging, challengeError } =
    useApiMutationTurnstile({
      meta: {
        name: 'Registration',
      },
      ...opt,
    });

  const registration = (args: RegistrationRequestType) =>
    mutate({ url: '/registration', method: 'POST', body: args });

  return {
    mutate: registration,
    isMutating,
    error,
    isChallenging,
    challengeError,
  };
}
