import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiWorkspaceMembersResponse } from 'src/types/Members';

export const GET_WORKSPACE_MEMBERS_KEY = 'useWorkspaceMembers';

type GetWorkspaceMembersArg = {
  workspaceId: number;
};

const getWorkspaceMembers = ({
  workspaceId,
}: GetWorkspaceMembersArg): Promise<ApiWorkspaceMembersResponse> => {
  return fetcher({ url: `/workspaces/${workspaceId}/accounts` });
};

type UseMenuArgs = {
  workspaceId: number | undefined;
  options?: Omit<
    UseQueryOptions<ApiWorkspaceMembersResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useWorkspaceMembers({ options, workspaceId }: UseMenuArgs) {
  const { data, error, isPending, isError, isSuccess } = useQuery({
    queryKey: [GET_WORKSPACE_MEMBERS_KEY, workspaceId],
    queryFn: () =>
      workspaceId ? getWorkspaceMembers({ workspaceId }) : undefined,
    meta: {
      name: 'Workspaces members',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
    isSuccess,
  };
}
