import React from 'react';
import { MembersTable } from './MembersTable';
import { Navigate } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { ErrorApi } from 'src/components/ErrorApi';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { ApiWorkspaceResponse } from 'src/types/Workspace';
import { useProjectMembers } from 'src/hooks/api/useProjectMembers';
import { TableLoading } from 'src/components/TableLoading';

type MembersTabProps = {
  projectId: number;
  workspaceId: number;
  workspaces: ApiWorkspaceResponse[];
};

export function MembersTab({
  projectId,
  workspaceId,
  workspaces,
}: MembersTabProps) {
  const {
    data: membersData,
    isLoading: isMembersLoading,
    error: membersError,
  } = useProjectMembers({ projectId });

  if (isMembersLoading) {
    return <TableLoading headers={['ID', 'Name', 'Email']} rowsAmount={2} />;
  }

  if (membersError) {
    return <ErrorApi error={membersError} />;
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: workspaces,
  });

  if (!workspaceData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const accounts = membersData?.members || [];

  return (
    <>
      <MembersTable
        accounts={accounts}
        workspaceId={workspaceId}
        projectId={projectId}
      />
    </>
  );
}
