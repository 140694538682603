import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@radix-ui/themes/styles.css';
import { Theme, Portal } from '@radix-ui/themes';
import App from './App';
import { ThemeProvider } from 'src/providers/ThemeProvider';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  createRoutesFromChildren,
  useNavigationType,
  matchRoutes,
} from 'react-router-dom';
import { Toaster } from 'sonner';
import { Toast } from './components/Toast';
import { ApiError } from './types/Api';
import { history } from './utils/history';
import { appRoutes } from './utils/routePaths';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: (failureCount, error) => {
        if (failureCount > 3) {
          const sentryEventId = Sentry.captureException(error);
          if (history.navigate) {
            history.navigate(appRoutes.error(), { state: { sentryEventId } });
          } else {
            window.location.href = `${appRoutes.error()}?sentryEventId=${sentryEventId}`;
          }
        }

        return true;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const eventId = Sentry.captureException(error);
      Toast({
        title: query.meta?.name as string,
        text: error?.message,
        variant: 'error',
        eventId,
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: ApiError, _variables, _context, mutation) => {
      const eventId = Sentry.captureException(error);
      Toast({
        title: mutation.meta?.name as string,
        text: error?.message || error?.error,
        variant: 'error',
        eventId,
      });
    },
  }),
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://api.lootfabriq.io/'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = document.getElementById('root');
if (root) {
  ReactDOM.createRoot(root).render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider storageKey="vite-ui-theme">
        <Theme accentColor="gray" grayColor="mauve">
          <App />
        </Theme>
        <Portal className="toasts-container">
          <Theme
            accentColor="gray"
            grayColor="mauve"
            className="toasts-container__theme"
          >
            <Toaster
              visibleToasts={9}
              position="bottom-right"
              duration={7000}
            />
          </Theme>
        </Portal>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
