import React from 'react';
import { Flex } from '@radix-ui/themes';
import { getImageUrl } from 'src/utils/getImageUrl';
import { ImagePreview } from 'src/components/ImagePreview';
import { LazyImage } from 'src/components/LazyImage';
import { ImageMagnify } from 'src/components/ImageMagnify';

type ImageCellProps = {
  fieldValue?: string;
  instanceId: number;
  fieldName: string;
};

export function ImageCell({
  fieldValue,
  fieldName,
  instanceId,
}: ImageCellProps) {
  if (!fieldValue) {
    return '-';
  }

  const url = getImageUrl({ instanceId, imagePath: fieldValue });
  if (!url) {
    return '-';
  }
  const alt = `Image for ${fieldName} field`;
  return (
    <Flex justify="center">
      <ImagePreview
        image={<LazyImage mix="items-list__cell-image" src={url} alt={alt} />}
      >
        <ImageMagnify src={url} alt={alt} />
      </ImagePreview>
    </Flex>
  );
}
