import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { ErrorMessage } from 'src/components/ErrorMessage/index.js';
import { CheckIcon } from '@radix-ui/react-icons';

type TextFieldProps = {
  mods?: Mods;
  mix?: Mix;
  errorMessage?: string | undefined;
  children?: React.ReactNode;
  dataTestId?: string;
};

type CombinedProps = TextFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = React.forwardRef<HTMLInputElement, CombinedProps>(
  (props, ref) => {
    const { children, errorMessage, dataTestId, ...rest } = props;

    return (
      <div className={b('checkbox', props)} data-testid={dataTestId}>
        <label className="checkbox__label">
          <input
            className="checkbox__control"
            {...rest}
            ref={ref}
            type="checkbox"
          />
          <span className="checkbox__mark">
            <CheckIcon className="checkbox__check" />
          </span>
          {children}
        </label>

        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
