import React from 'react';
import { Dialog, Flex, IconButton, Card } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

type DialogReportProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  openCheckout: () => void;
};

export function CheckoutPaymentMethodDialog({
  isOpen,
  onOpenChange,
  openCheckout,
}: DialogReportProps) {
  React.useEffect(() => {
    if (isOpen) {
      openCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        maxWidth="700px"
        // disable close outside to add ability to interact with toast
        onInteractOutside={(e) => e.preventDefault()}
      >
        <Flex gap="3" align="center" justify="between">
          <Dialog.Title mb="0">Payment method update</Dialog.Title>

          <Dialog.Close>
            <IconButton variant="soft" color="gray" title="Close checkout">
              <Cross1Icon />
            </IconButton>
          </Dialog.Close>
        </Flex>

        <Card variant="surface" size="3" mt="2">
          <div className="checkout-container"></div>
        </Card>
      </Dialog.Content>
    </Dialog.Root>
  );
}
