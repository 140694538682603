import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { Link as RouterLink } from 'react-router-dom';
import logoDarkUrl from 'src/assets/logo-dark.svg';
import logoLightUrl from 'src/assets/logo-light.svg';
import { useTheme } from 'src/providers/ThemeProvider';

type LogoProps = {
  mods?: Mods;
  mix?: Mix;
};

export function Logo(props: LogoProps) {
  const { theme } = useTheme();

  return (
    <RouterLink className={b('logo', props)} to="/">
      <img
        className="logo__image"
        src={theme === 'light' ? logoLightUrl : logoDarkUrl}
        alt="Lootfabriq logo"
        width="130px"
        height="55px"
      />
    </RouterLink>
  );
}
