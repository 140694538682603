import { ObjectValues } from 'src/types/Common';

export const PLANS = {
  TEAMS: 'Teams',
  STARTER: 'Starter',
} as const;

export type PlanType = ObjectValues<typeof PLANS>;

export const PRICES = {
  [PLANS.STARTER]: 16,
  [PLANS.TEAMS]: 29,
  PROJECT: 4,
};

export const TEAMS_FEATURES = [
  'Staging, and production instances',
  'Easily sync data between instances',
  'Custom roles with specific permissions',
  'Upload images up to 4 MB',
];

export const STARTER_FEATURES = [
  'Create templates and items, export JSON',
  'Organize templates in the navigation',
  'Download JSON from the app or via API',
  'Upload images up to 2 MB',
  'Includes 3 projects',
];
