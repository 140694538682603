import { ApiWorkspaceResponse } from 'src/types/Workspace';

type GetWorkspaceByIdArg = {
  workspaces: ApiWorkspaceResponse[] | null;
  workspaceId: number;
};

export const getWorkspaceById = ({
  workspaces,
  workspaceId,
}: GetWorkspaceByIdArg) => {
  return workspaces?.find((item) => {
    return item.id === workspaceId;
  });
};
