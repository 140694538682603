import * as yup from 'yup';
import {
  templateIdValidation,
  templateLabelValidation,
} from './newTemplateValidationSchema';

export const validationSchema = yup.object().shape({
  templateLabel: templateLabelValidation,
  templateId: templateIdValidation,
});
