import React, { createContext, useContext } from 'react';
import { useAccountSettings } from 'src/hooks/api/useAccountSettings';
import { getInstanceById } from 'src/utils/getInstanceById';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { useInstanceActivate } from 'src/hooks/api/useInstanceActivate';

type InstanceContextProps = {
  instanceId: number;
  setInstanceId: (value: number) => void;
  refetchInstanceId: () => void;
};

const InstanceContext = createContext<InstanceContextProps>({
  instanceId: -1,
  setInstanceId: () => {},
  refetchInstanceId: () => {},
});

type InstanceProviderProps = {
  children: React.ReactNode;
};
export const InstanceProvider = ({ children }: InstanceProviderProps) => {
  const { data: workspacesList } = useWorkspacesList();
  const { data: settingsData, refetch: refetchSettings } = useAccountSettings();
  const { mutate: activateInstance } = useInstanceActivate();

  const checkAndSetInstanceId = React.useCallback(
    (newInstanceId: number) => {
      // project can be removed or user can lose access to instance, but it still can be in active_instance in settings
      // reset to 0 if user don't have access
      const isInstanceAvailable = getInstanceById({
        instanceId: newInstanceId,
        workspaces: workspacesList?.workspaces || [],
      });
      const instanceValue = isInstanceAvailable ? newInstanceId : 0;
      if (settingsData?.active_instance_id !== instanceValue) {
        activateInstance({ instanceId: instanceValue });
      }
    },
    [
      activateInstance,
      settingsData?.active_instance_id,
      workspacesList?.workspaces,
    ]
  );

  return (
    <InstanceContext.Provider
      value={{
        instanceId: settingsData ? settingsData.active_instance_id : -1,
        setInstanceId: checkAndSetInstanceId,
        refetchInstanceId: refetchSettings,
      }}
    >
      {children}
    </InstanceContext.Provider>
  );
};

export const useInstanceContext = () => {
  const context = useContext(InstanceContext);
  if (!context) {
    throw new Error('useInstanceContext must be used within a InstanceContext');
  }
  return context;
};
