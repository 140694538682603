import type { MutationOptions } from 'src/types/Api';
import { useApiMutationTurnstile } from 'src/components/CloudflareTurnstile';

export type PasswordResetRequestType = {
  email: string;
};

export function usePasswordReset(opt?: MutationOptions<unknown>) {
  const { mutate, isMutating, error, isChallenging, challengeError, reset } =
    useApiMutationTurnstile({
      meta: {
        name: 'Password reset',
      },
      ...opt,
    });

  const passwordReset = (args: PasswordResetRequestType) =>
    mutate({
      url: '/accounts/password-reset-email',
      method: 'POST',
      body: args,
    });

  return {
    mutate: passwordReset,
    isMutating,
    error,
    reset,
    isChallenging,
    challengeError,
  };
}
