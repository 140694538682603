import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type RenameProjectArg = {
  name: string;
  projectId: number;
};

const renameProject = (arg: RenameProjectArg): Promise<RenameProjectArg> => {
  const { projectId, ...data } = arg;
  return fetcher({
    url: `/projects/${projectId}/rename`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  });
};

export function useProjectRename(opt?: MutationOptions<RenameProjectArg>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: renameProject,
    meta: {
      name: 'Project rename',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
