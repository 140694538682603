import { FieldErrors, Resolver } from 'react-hook-form';
import * as yup from 'yup';
import { TreeNode } from 'src/types/Template';
import { StringObject } from 'src/types/Common';
import { getFlatDataFromTemplateTree } from 'src/utils/getFlatDataFromTemplateTree';
import { getValidationSchema } from './getValidationSchema';

type FormContext = { templateTree: TreeNode[] };
export const itemFormResolver: Resolver<
  { [key: string]: string | number | number[] },
  FormContext
> = async (
  formData: { [key: string]: string | number | number[] },
  context?: FormContext
) => {
  const templateTree = context?.templateTree || [];
  const flatTree = getFlatDataFromTemplateTree({
    templateTree,
  });
  const fields = flatTree.map((item) => ({
    entityFieldId: item?.node?.entityFieldId,
    settings: item.node.settings,
  }));

  const validationSchema = getValidationSchema({ data: fields });

  try {
    const validatedData = await validationSchema.validate(formData, {
      abortEarly: false,
      context: context,
    });
    return { values: validatedData, errors: {} };
  } catch (yupError) {
    if (yupError instanceof yup.ValidationError) {
      const errors: FieldErrors<StringObject> = yupError.inner.reduce(
        (allErrors: FieldErrors<StringObject>, currentError) => ({
          ...allErrors,
          [currentError.path ?? '']: {
            // Fallback to empty string if path is undefined
            type: currentError.type ?? 'validation',
            message: currentError.message,
          },
        }),
        {}
      );
      return { values: {}, errors };
    } else {
      throw yupError;
    }
  }
};
