import { ObjectValues } from 'src/types/Common';

export const FIELD_TYPE = {
  TEXT: 'text',
  INTEGER: 'integer',
  GROUP: 'group',
  ITEM_LINK: 'item-link',
  ITEM_LINK_LIST: 'item-link-list',
  DYNAMIC_GROUP: 'dynamic-group',
  DYNAMIC_GROUP_ROW: 'dynamic-group-row',
  BOOLEAN: 'boolean',
  FLOAT: 'float',
  IMAGE: 'image',
} as const;

export type FieldType = ObjectValues<typeof FIELD_TYPE>;

export const TEMPLATE_FIELD_STATUS = {
  ADDED: 1,
  UPDATED: 2,
  REMOVED: 3,
} as const;

export type TemplateFieldStatus = ObjectValues<typeof TEMPLATE_FIELD_STATUS>;

export const COMPONENTS_HEADLINES = {
  [FIELD_TYPE.TEXT]: {
    badge: 'text',
    title: 'Text',
  },
  [FIELD_TYPE.INTEGER]: {
    badge: 'integer',
    title: 'Integer',
  },
  [FIELD_TYPE.FLOAT]: {
    badge: 'float',
    title: 'Float',
  },
  [FIELD_TYPE.GROUP]: {
    badge: 'group',
    title: 'Group',
  },
  [FIELD_TYPE.ITEM_LINK]: {
    badge: 'item-link',
    title: 'Item link',
  },
  [FIELD_TYPE.ITEM_LINK_LIST]: {
    badge: 'item-link-list',
    title: 'Item link list',
  },
  [FIELD_TYPE.DYNAMIC_GROUP]: {
    badge: 'dynamic-group',
    title: 'Dynamic group',
  },
  [FIELD_TYPE.DYNAMIC_GROUP_ROW]: {
    badge: 'dynamic-group-row',
    title: 'Dynamic group row',
  },
  [FIELD_TYPE.BOOLEAN]: {
    badge: 'checkbox',
    title: 'Checkbox',
  },
  [FIELD_TYPE.IMAGE]: {
    badge: 'image',
    title: 'Image',
  },
};
