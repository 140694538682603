import { ApiWorkspaceResponse } from 'src/types/Workspace';

type Option = {
  id: string;
  label: string;
}[];

export type GetProjectsOptionsArgs = {
  workspaces: ApiWorkspaceResponse[];
};

export const getProjectsOptions = ({ workspaces }: GetProjectsOptionsArgs) => {
  const projects: Option = [];
  workspaces.forEach((item) => {
    item.projects?.forEach((project) => {
      projects.push({
        id: project.id.toString(),
        label: project.name,
      });
    });
  });

  return projects;
};
