import React from 'react';
import { Box, Table, Skeleton } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { SubscriptionProjectsUpdate } from 'src/types/Subscription';
import { ApiError } from 'src/types/Api';
import pluralize from 'pluralize';
import { getPrice } from '../utils/getPrice';
import { useSubscriptionProjectsUpdate } from 'src/hooks/api/useSubscriptionProjectsUpdate';
import { PROJECTS_INCLUDED_IN_PLAN } from './ProjectPricing';

type ProjectPricingDialogProps = {
  workspaceId: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  newAdditionalProjects: number;
  projectsDiff: number;
  pricePreview: SubscriptionProjectsUpdate | undefined;
  isLoading: boolean;
  subscriptionPreviewError: ApiError | null;
  onSuccessfulUpdate: (projects: number) => void;
};

export function ProjectPricingDialog({
  workspaceId,
  isDialogOpen,
  setIsDialogOpen,
  newAdditionalProjects,
  projectsDiff,
  pricePreview,
  isLoading,
  subscriptionPreviewError,
  onSuccessfulUpdate,
}: ProjectPricingDialogProps) {
  const {
    mutate: updateProjects,
    isMutating: isUpdateProjectsMutating,
    error: updateProjectsError,
  } = useSubscriptionProjectsUpdate({
    onSuccess: () => {
      onSuccessfulUpdate(newAdditionalProjects + PROJECTS_INCLUDED_IN_PLAN);
      setIsDialogOpen(false);
    },
  });

  const handleProjectsChange = (value: number) => {
    updateProjects({
      workspaceId,
      new_value: value,
    });
  };

  return (
    <AlertDialog
      title="Confirm subscription changes"
      onAction={() => handleProjectsChange(newAdditionalProjects)}
      onCancel={() => setIsDialogOpen(false)}
      open={isDialogOpen}
      color="plum"
      actionText="Yes, confirm project changes"
      cancelText="No, keep my current projects"
      loading={isUpdateProjectsMutating || isLoading}
    >
      {!!updateProjectsError && (
        <Box mb="4">
          <ErrorApi error={updateProjectsError} />
        </Box>
      )}
      {!!subscriptionPreviewError && (
        <Box mb="4">
          <ErrorApi error={subscriptionPreviewError} />
        </Box>
      )}
      You're updating your subscription by{' '}
      {projectsDiff > 0 ? 'adding' : 'removing'} {Math.abs(projectsDiff)}{' '}
      {pluralize('project', Math.abs(projectsDiff))}:
      <Box my="4">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Payment</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Subtotal</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Taxes</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Total</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell>Next payment</Table.RowHeaderCell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.next?.subtotal)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.next?.tax)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.next?.total)}
                </Skeleton>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeaderCell>Future payments</Table.RowHeaderCell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.recurring?.subtotal)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.recurring?.tax)}
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton loading={isLoading}>
                  ${getPrice(pricePreview?.recurring?.total)}
                </Skeleton>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
      </Box>
      You can start using the new projects now, but payment will be processed
      later on your next billing date. This is why your next payment may differ
      from your future recurring payments.
    </AlertDialog>
  );
}
