import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type PasswordResetConfirmationRequestType = {
  password: string;
  hash: string;
};

const passwordResetConfirmation = (
  arg: PasswordResetConfirmationRequestType
): Promise<unknown> => {
  return fetcher({
    url: '/accounts/password-reset',
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
    isPublic: true,
  });
};

export function usePasswordResetConfirmation(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: passwordResetConfirmation,
    meta: {
      name: 'Password reset confirmation',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
