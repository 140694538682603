import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';
import { ApiWorkspaceResponse } from 'src/types/Workspace';
import { WorkspacesRolesMap } from 'src/utils/getWorkspacesRolesMap';

type GetAvailableWorkspaces = {
  workspaces: ApiWorkspaceResponse[];
  workspacesRoleMap: WorkspacesRolesMap;
};

export function getAvailableWorkspaces({
  workspaces,
  workspacesRoleMap,
}: GetAvailableWorkspaces) {
  return workspaces.filter((item) => {
    const role = workspacesRoleMap.get(item.id);
    if (role === WORKSPACES_ROLES.OWNER || role === WORKSPACES_ROLES.ADMIN) {
      return item;
    }
  });
}
