export function formatError(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    try {
      return JSON.stringify(error, null, 2);
    } catch (jsonError) {
      return 'An unknown error occurred';
    }
  }
}
