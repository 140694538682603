import React from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { TreeNode } from 'src/types/Template';
import { Form } from './components/Form';

type ItemFormProps = {
  methods: UseFormReturn<FieldValues>;
  templateTree: TreeNode[] | null;
};

export function ItemForm(props: ItemFormProps) {
  const { templateTree, methods } = props;

  return <Form templateTree={templateTree || []} methods={methods} />;
}
