import React from 'react';
import { Input } from 'src/components/Input';
import { Container, Skeleton } from '@radix-ui/themes';

export function SkeletonPage() {
  return (
    <Container size="2" display="initial" align="center" pl="1" pr="4">
      <Input
        label="Label"
        required
        helperText="Used to identify items within the app. Not included in exports"
      />
      <Skeleton height="30px" mt="3" />
      <Skeleton height="30px" mt="3" />
    </Container>
  );
}
