import React from 'react';
import { PLANS, TEAMS_FEATURES } from 'src/constants/plans';
import { PlanCard } from './PlanCard';
import { Subscription } from 'src/types/Subscription';

type TeamsPlanBasicCardProps = {
  subscription?: Subscription;
  onPlanSelect?: () => void;
  isLoading?: boolean;
};

export function TeamsPlanBasicCard({
  subscription,
  onPlanSelect,
  isLoading,
}: TeamsPlanBasicCardProps) {
  return (
    <PlanCard
      plan={PLANS.TEAMS}
      subscription={subscription}
      featuresDescription="Everything in Starter, plus:"
      features={TEAMS_FEATURES}
      name="Teams plan"
      description="Optimal for teams with advanced needs"
      price="$29"
      onPlanSelect={onPlanSelect}
      isLoading={isLoading}
    />
  );
}
