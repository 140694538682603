import React from 'react';
import { Box, Button, Flex, IconButton, Popover } from '@radix-ui/themes';
import { Pencil1Icon } from '@radix-ui/react-icons';
import { Input } from 'src/components/Input';
import { MenuItem, MenuNode } from 'src/types/Menu';
import { updateName } from '../../utils/updateName';
import { Toast } from '../../../../../components/Toast';

type ItemDropdownProps = {
  onOpenChange: (open: boolean) => void;
  onTreeUpdate: (tree: MenuNode[]) => void;
  node: MenuItem;
  path: number[];
  treeData: MenuNode[];
};
export function ItemRename({
  onOpenChange,
  node,
  path,
  treeData,
  onTreeUpdate,
}: ItemDropdownProps) {
  const [itemName, setItemName] = React.useState(node.label);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const updatedTree = updateName({ node, path, treeData, name: itemName });
      onTreeUpdate(updatedTree);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred';
      Toast({
        text: errorMessage,
        variant: 'error',
      });
    }
  };

  return (
    <Popover.Root onOpenChange={onOpenChange}>
      <Popover.Trigger>
        <IconButton variant="ghost" title="Rename" color="gold">
          <Pencil1Icon width="16" height="16" />
        </IconButton>
      </Popover.Trigger>
      <Popover.Content>
        <form onSubmit={handleSubmit}>
          <Box minWidth="300px">
            <Input
              label="Group name"
              required
              placeholder="Enter group name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Box>
          <Popover.Close>
            <Flex justify="end">
              <Button variant="soft" type="submit" mt="4" color="plum">
                Rename group
              </Button>
            </Flex>
          </Popover.Close>
        </form>
      </Popover.Content>
    </Popover.Root>
  );
}
