import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';
import { MenuNode } from 'src/types/Menu';

type SortChildrenByOrderArgs<T extends TreeNode | MenuNode> = {
  treeData: T[];
};

export const sortChildrenByOrder = <T extends TreeNode | MenuNode>({
  treeData,
}: SortChildrenByOrderArgs<T>) => {
  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: T }) => {
      const children = node.children;
      if (!children) {
        return node;
      }

      children.sort((a, b) => (a?.order || 0) - (b?.order || 0));

      return {
        ...node,
        children,
      };
    },
  });

  return updatedTree as T[];
};
