import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { ExportReport } from 'src/types/ExportReport';

export const GET_REPORTS_LIST_KEY = 'useExportReportsList';

type Response = {
  reports: ExportReport[];
};

const getReportsList = (instanceId: number): Promise<Response> => {
  return fetcher({ url: `/instances/${instanceId}/reports` });
};

type UseReportsListArgs = {
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useExportReportsList({ options }: UseReportsListArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError } = useQuery({
    queryKey: [GET_REPORTS_LIST_KEY, instanceId],
    queryFn: () => getReportsList(instanceId),
    enabled: instanceId > 0,
    meta: {
      name: 'Export reports list',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
  };
}
