import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import { TreeNode } from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';
import { getNodeKey } from 'src/utils/getNodeKey';

type RemoveDynamicGroupsChildrenFromTreeArgs = {
  treeData: TreeNode[];
};

export function removeDynamicGroupsChildrenFromTree({
  treeData,
}: RemoveDynamicGroupsChildrenFromTreeArgs) {
  const updatedTree = mapTree({
    treeData,
    ignoreCollapsed: false,
    getNodeKey,
    callback: ({ node }: { node: TreeNode }) => {
      if (node?.settings?.type === FIELD_TYPE.DYNAMIC_GROUP) {
        return {
          ...node,
          children: [],
        };
      }

      return node;
    },
  });

  return updatedTree as TreeNode[];
}
