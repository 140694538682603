import React from 'react';
import pluralize from 'pluralize';
import { Flex, Text, Box, Link, Button } from '@radix-ui/themes';
import { MagicWandIcon } from '@radix-ui/react-icons';
import { Callout } from 'src/components/Callout';
import { Link as RouterLink } from 'react-router-dom';
import { useSubscription } from 'src/hooks/api/useSubscription';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { getWorkspaceByInstanceId } from 'src/utils/getWorkspaceByInstanceId';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { appRoutes } from 'src/utils/routePaths';
import { calculateTimeLeft } from 'src/utils/calculateTimeLeft';
import { calculateDataRemovalDate } from '../utils/calculateDataRemovalDate';
import { Access } from 'src/components/Access';
import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';

export function Nav__SubscriptionInfo() {
  const { instanceId } = useInstanceContext();
  const { data: workspacesData } = useWorkspacesList();
  const activeWorkspace = getWorkspaceByInstanceId({
    workspaces: workspacesData?.workspaces || [],
    instanceId,
  });
  const { data: subscription } = useSubscription({
    workspaceId: activeWorkspace?.id.toString(),
  });

  const nextBillingDateTimeLeft = calculateTimeLeft({
    targetDateStr: subscription?.next_billing_date_at,
  });

  if (
    subscription?.status === SUBSCRIPTION_STATUS.TRIAL &&
    nextBillingDateTimeLeft.amount > 0
  ) {
    return (
      <Box py="4">
        <Callout color="grass" hideIcon variant="outline">
          <Text size="2" weight="medium">
            Teams trial ends in {nextBillingDateTimeLeft.amount}{' '}
            {nextBillingDateTimeLeft.type === 'd'
              ? pluralize('day', nextBillingDateTimeLeft.amount)
              : pluralize('hour', nextBillingDateTimeLeft.amount)}
          </Text>

          <Access roles={[WORKSPACES_ROLES.OWNER]} ignorePermissions>
            <Flex justify="start" mt="2" width="100%" as="span">
              <Button size="1" variant="soft" color="grass" asChild>
                <RouterLink to={appRoutes.settingsBilling()}>
                  <MagicWandIcon /> Buy now
                </RouterLink>
              </Button>
            </Flex>
          </Access>
        </Callout>
      </Box>
    );
  }

  if (
    subscription?.status === SUBSCRIPTION_STATUS.TRIAL &&
    nextBillingDateTimeLeft.amount < 1
  ) {
    return (
      <Box py="4">
        <Callout color="gold" hideIcon>
          <Text size="2" weight="medium">
            Trial ended. <br /> Upgrade to keep using {subscription?.type}.
          </Text>

          <Access roles={[WORKSPACES_ROLES.OWNER]} ignorePermissions>
            <Flex justify="start" mt="2" width="100%" as="span">
              <Button size="2" variant="soft" color="gold" asChild>
                <RouterLink to={appRoutes.settingsBilling()}>
                  <MagicWandIcon /> Buy now
                </RouterLink>
              </Button>
            </Flex>
          </Access>
        </Callout>
      </Box>
    );
  }

  if (subscription?.status === SUBSCRIPTION_STATUS.PAST_DUE) {
    return (
      <Box py="4">
        <Callout color="gold" hideIcon>
          <Text size="2" weight="medium">
            Subscription is past due. <br /> Please update your payment.
          </Text>

          <Access roles={[WORKSPACES_ROLES.OWNER]} ignorePermissions>
            <Flex justify="start" mt="2" width="100%" as="span">
              <Button size="1" variant="soft" color="gold" asChild>
                <RouterLink to={appRoutes.settingsBilling()}>
                  <MagicWandIcon /> Update payment details
                </RouterLink>
              </Button>
            </Flex>
          </Access>
        </Callout>
      </Box>
    );
  }

  if (subscription?.status === SUBSCRIPTION_STATUS.CANCELED) {
    return (
      <Box py="4">
        <Callout color="gold" hideIcon>
          <Text size="2" weight="medium">
            Subscription canceled. <br />
            Your data will be stored until{' '}
            {calculateDataRemovalDate(subscription?.next_billing_date_at)}.
            <br />
            Need help?{' '}
            <Link href="mailto:support@lootfabriq.io">Contact support</Link>
          </Text>
        </Callout>
      </Box>
    );
  }

  if (subscription?.status === SUBSCRIPTION_STATUS.CANCEL_REQUESTED) {
    return (
      <Box py="4">
        <Callout color="gold" hideIcon>
          <Text size="2" weight="medium">
            Subscription canceled. <br />{' '}
            {nextBillingDateTimeLeft.amount > 0 && (
              <>
                Still active for {nextBillingDateTimeLeft.amount}{' '}
                {nextBillingDateTimeLeft.type === 'd'
                  ? pluralize('day', nextBillingDateTimeLeft.amount)
                  : pluralize('hour', nextBillingDateTimeLeft.amount)}
              </>
            )}
          </Text>

          <Access roles={[WORKSPACES_ROLES.OWNER]} ignorePermissions>
            <Flex justify="start" mt="2" width="100%" as="span">
              <Button size="1" variant="soft" color="gold" asChild>
                <RouterLink to={appRoutes.settingsBilling()}>
                  <MagicWandIcon /> Reactivate
                </RouterLink>
              </Button>
            </Flex>
          </Access>
        </Callout>
      </Box>
    );
  }

  return null;
}
