import { MemberRoleForInstance } from 'src/hooks/api/useProjectMemberRoles';
import { INSTANCE } from 'src/constants/instance';
import { UpdateUserFormValues } from '../types/FormValues';

type GetFormDefaultValuesArgs = {
  initialData: MemberRoleForInstance[];
};

export const getFormDefaultValues = ({
  initialData,
}: GetFormDefaultValuesArgs) => {
  const data: UpdateUserFormValues = {
    [INSTANCE.DEV]: null,
    [INSTANCE.STAGE]: null,
    [INSTANCE.PROD]: null,
  };

  initialData.forEach((item) => {
    const roles = item?.roles?.map((value) => value.toString());
    data[item.env] = roles;
  });

  return data;
};
