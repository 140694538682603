import { Text } from '@radix-ui/themes';
import React from 'react';

export function RequiredMark() {
  return (
    <Text color="gold" size="4">
      *
    </Text>
  );
}
