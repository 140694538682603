import { addChildrenOrder } from 'src/utils/addChildrenOrder';
import { getFlatDataFromTemplateTree } from './getFlatDataFromTemplateTree';
import { TemplateField, TreeNode } from 'src/types/Template';
import { convertFlatTreeToFields } from './convertFlatTreeToFields';

type ConvertTreeToTemplateFieldsArgs = {
  templateTree: TreeNode[];
};

export function convertTreeToTemplateFields({
  templateTree,
}: ConvertTreeToTemplateFieldsArgs) {
  const treeWithOrderedChildren = addChildrenOrder({
    treeData: templateTree,
  });
  const data = getFlatDataFromTemplateTree({
    templateTree: treeWithOrderedChildren,
  });
  const templateData: TemplateField[] = convertFlatTreeToFields({
    flatTree: data,
  });

  return templateData;
}
