import React, { createContext, useContext } from 'react';
import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import { PermissionType } from 'src/constants/permissions';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useAccountWorkspacesRoles } from 'src/hooks/api/useAccountWorkspacesRoles';
import { useAccountPermissions } from 'src/hooks/api/useAccountPermissions';
import {
  getWorkspacesRolesMap,
  WorkspacesRolesMap,
} from 'src/utils/getWorkspacesRolesMap';

type UserContextProps = {
  role: WorkspaceRolesType | null;
  permissions: PermissionType[] | null;
  workspacesRoleMap: WorkspacesRolesMap;
  isLoading: boolean;
};

const UserContext = createContext<UserContextProps>({
  role: null,
  permissions: [],
  workspacesRoleMap: new Map(),
  isLoading: false,
});

type UserProviderProps = {
  children: React.ReactNode;
};
export const UserProvider = ({ children }: UserProviderProps) => {
  const { instanceId } = useInstanceContext();
  const { data: userData, isLoading: isPermissionsLoading } =
    useAccountPermissions({ instanceId });
  const { data: accountWorkspaces } = useAccountWorkspacesRoles();

  const workspacesRoleMap = React.useMemo(
    () =>
      getWorkspacesRolesMap({
        workspaces: accountWorkspaces?.workspaces || [],
      }),
    [accountWorkspaces?.workspaces]
  );

  return (
    <UserContext.Provider
      value={{
        role: userData?.workspace_role_id || null,
        permissions: userData?.permissions || null,
        workspacesRoleMap,
        isLoading: isPermissionsLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContext');
  }
  return context;
};
