import React from 'react';
import { Box, Button, Flex, Popover } from '@radix-ui/themes';
import { insertItem } from '../../utils/insertItem';
import { MenuNode } from 'src/types/Menu';
import { ReactComponent as Directory } from 'src/assets/directory.svg';
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'src/components/Input';
import { Toast } from '../../../../../components/Toast';

type GroupCreationProps = {
  treeData: MenuNode[];
  onCreate: (tree: MenuNode[]) => void;
};

export function GroupCreation({ treeData, onCreate }: GroupCreationProps) {
  const [groupName, setGroupName] = React.useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const group = {
      id: uuidv4(),
      label: groupName,
      template_id: '',
      parent_id: 'root',
      order: 0,
    };

    try {
      const updatedTree = insertItem({ treeData, node: group });
      onCreate(updatedTree);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred';
      Toast({
        text: errorMessage,
        variant: 'error',
      });
    }
  };

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="soft" color="gold" size="1">
          <Directory width="16" height="16" /> Add group
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <form onSubmit={handleSubmit}>
          <Box minWidth="300px">
            <Input
              label="Group name"
              required
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Box>
          <Popover.Close>
            <Flex justify="end">
              <Button variant="soft" type="submit" mt="4" color="plum">
                Create group
              </Button>
            </Flex>
          </Popover.Close>
        </form>
      </Popover.Content>
    </Popover.Root>
  );
}
