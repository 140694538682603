import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { AccountSettings } from 'src/types/Account';

export const GET_ACCOUNT_SETTINGS_KEY = 'useAccountSettings';

type Response = AccountSettings;

const getAccountSettings = async (): Promise<Response> => {
  const response = await fetcher({
    url: '/accounts/settings',
  });

  return response;
};

type UseAccountSettingsArgs = {
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>;
};

export function useAccountSettings({ options }: UseAccountSettingsArgs = {}) {
  const { data, error, isPending, isError, isSuccess, refetch } = useQuery({
    queryKey: [GET_ACCOUNT_SETTINGS_KEY],
    queryFn: () => getAccountSettings(),
    meta: {
      name: 'Account settings',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
    isSuccess,
    refetch,
  };
}
