import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { ApiError } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type Response = {
  items: { internal_label: string; id: number }[] | null;
};

type GetItemsLabelsByIdsArgs = {
  ids: number[];
  templateId: string;
  instanceId: number;
};

const getItemsLabelsByIds = (
  arg: GetItemsLabelsByIdsArgs
): Promise<Response> => {
  const { instanceId, templateId, ids } = arg;

  return fetcher({
    url: `/editor/${instanceId}/items/${templateId}/byId`,
    options: {
      method: 'POST',
      body: JSON.stringify({ ids: ids }),
    },
  });
};

export function useItemsLabelsByIds(
  opt?: UseMutationOptions<
    Response,
    ApiError,
    Omit<GetItemsLabelsByIdsArgs, 'instanceId'>
  >
) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error, mutateAsync } = useMutation({
    mutationFn: (args: Omit<GetItemsLabelsByIdsArgs, 'instanceId'>) =>
      getItemsLabelsByIds({ ...args, instanceId }),
    meta: {
      name: 'Items labels',
    },
    ...opt,
  });

  return {
    mutate,
    mutateAsync,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
