import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { Logo } from 'src/components/Logo';
import { Box, Flex, Link, Text } from '@radix-ui/themes';
import { DotFilledIcon } from '@radix-ui/react-icons';

type PageAccentProps = {
  mods?: Mods;
  mix?: Mix;
  children: React.ReactNode;
};

export function PageAccent(props: PageAccentProps) {
  return (
    <div className={b('page-accent', props)}>
      <Box py="4">
        <Logo />
      </Box>
      <div className={b('page-accent__body', props)}>
        <div className="page-accent__content">{props.children}</div>
      </div>

      <Flex mt="4" py="5" justify="center" gap="2" align="center">
        <Text color="gray" size="2">
          © Lootfabriq
        </Text>
        <DotFilledIcon color="gray" width="8px" />
        <Link
          underline="always"
          size="2"
          href="https://lootfabriq.io/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </Link>
        <DotFilledIcon color="gray" width="8px" />
        <Link
          underline="always"
          size="2"
          href="https://lootfabriq.io/terms"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms & Conditions
        </Link>
      </Flex>
    </div>
  );
}
