import { ApiRoleDefinition } from 'src/types/Roles';
import { PERMISSIONS, PermissionsKeys } from 'src/constants/permissions';
import { FormValues } from '../types/FormValues';

export const getFormInitialValues = (role: ApiRoleDefinition): FormValues => {
  const permissions = {} as { [key in PermissionsKeys]: boolean };

  Object.keys(PERMISSIONS).forEach((key) => {
    const permissionKey = key as PermissionsKeys;
    permissions[permissionKey] = role.permissions
      ? role.permissions.includes(PERMISSIONS[permissionKey])
      : false;
  });
  const initialValues: FormValues = {
    name: role.name,
    permissions,
  };

  return initialValues;
};
