import { StringObject } from 'src/types/Common';

export const REGISTRATION_CONFIRM = '/registration/confirm';
export const REGISTRATION_HELP = '/registration/help';
export const REGISTRATION = '/registration';

export const PASSWORD_RESET = '/password-reset';
export const PASSWORD_RESET_CONFIRM = '/password-reset/confirm';

export const LOGIN = '/login';

export const ACCESS_DENIED = '/access-denied';
export const ACTION_NOT_ALLOWED_FOR_INSTANCE = '/not-allowed';
export const ERROR = '/error';

export const INSTANCE = 'instance/:instanceId';

export const TEMPLATE_EDIT = 'templates/:templateId/edit';
export const TEMPLATE_NEW = 'templates/new';
export const TEMPLATE_COPY = 'templates/:templateId/copy';

export const ITEMS = 'templates/:templateId/items';
export const ITEM_NEW = 'templates/:templateId/items/new';
export const ITEM_EDIT = 'templates/:templateId/items/:id/edit';

export const SETTINGS_INSTANCE_SYNC = '/instance-sync';
export const SETTINGS_EXPORTED_DATA = '/settings/exported-data';
export const SETTINGS_EXPORT = '/settings/export-settings';

export const SETTINGS_NAVIGATION_SETUP = '/settings/navigation-setup';

export const SETTINGS_ACCOUNT = '/settings/account';
export const SETTINGS_BILLING = '/settings/billing';
export const SETTINGS_WORKSPACE = '/settings/workspaces/:workspaceId';
export const SETTINGS_WORKSPACE_MEMBERS =
  '/settings/workspaces/:workspaceId/members';
export const SETTINGS_WORKSPACE_ROLES =
  '/settings/workspaces/:workspaceId/roles';
export const SETTINGS_PROJECT =
  '/settings/workspaces/:workspaceId/projects/:projectId';
export const SETTINGS_PROJECT_MEMBER_ADD =
  '/settings/workspaces/:workspaceId/projects/:projectId/members/new';
export const SETTINGS_PROJECT_MEMBER_EDIT =
  '/settings/workspaces/:workspaceId/projects/:projectId/members/:memberId/edit';

export const SETTINGS_PROJECT_ROLE_NEW =
  '/settings/workspaces/:workspaceId/roles/new';
export const SETTINGS_PROJECT_ROLE_EDIT =
  '/settings/workspaces/:workspaceId/roles/:roleId/edit';

export const PROJECT_REMOVAL_CANCELLATION = '/project-removal/cancelled';
export const EMAIL_UPDATE_CONFIRMATION = '/email-update/confirm';

export const NOT_FOUND = '/not-found';

export const appRoutes = {
  registrationConfirm: () => REGISTRATION_CONFIRM,
  registrationHelp: () => REGISTRATION_HELP,
  registration: () => REGISTRATION,
  passwordReset: () => PASSWORD_RESET,
  passwordResetConfirm: () => PASSWORD_RESET_CONFIRM,
  login: () => LOGIN,
  accessDenied: () => ACCESS_DENIED,
  actionNotAllowedForInstance: () => ACTION_NOT_ALLOWED_FOR_INSTANCE,
  error: () => ERROR,
  notFound: () => NOT_FOUND,
  templateNew: getTemplateNewRoute,
  templateEdit: getTemplateEditRoute,
  templateCopy: getTemplateCopyRoute,
  items: getItemsRoute,
  itemNew: getItemNewRoute,
  itemEdit: getItemEditRoute,
  settingsInstanceSync: () => SETTINGS_INSTANCE_SYNC,
  settingsExportedData: () => SETTINGS_EXPORTED_DATA,
  settingsExport: () => SETTINGS_EXPORT,
  settingsNavigationSetup: () => SETTINGS_NAVIGATION_SETUP,
  settingsAccount: () => SETTINGS_ACCOUNT,
  settingsBilling: () => SETTINGS_BILLING,
  settingsWorkspace: getWorkspaceSettingsRoute,
  settingsWorkspaceMembers: getWorkspaceMembersSettingsRoute,
  settingsWorkspaceRoles: getWorkspaceRolesSettingsRoute,
  settingsProject: getProjectSettingsRoute,
  settingsProjectAddMember: getProjectSettingsNewMemberRoute,
  settingsProjectEditMember: getProjectSettingsEditMemberRoute,
  settingsProjectNewRole: getWorkspaceRoleNewRoute,
  settingsProjectEditRole: getProjectRoleEditRoute,
  projectRemovalCancellation: () => PROJECT_REMOVAL_CANCELLATION,
  emailUpdateConfirmation: () => EMAIL_UPDATE_CONFIRMATION,
};

type TemplatesArgs = {
  instanceId: number;
  templateId: string;
};

function getTemplateNewRoute({ instanceId }: { instanceId: number }) {
  return createPath(`/${INSTANCE}/${TEMPLATE_NEW}`, {
    instanceId: instanceId.toString(),
  });
}

function getTemplateEditRoute({ instanceId, templateId }: TemplatesArgs) {
  return createPath(`/${INSTANCE}/${TEMPLATE_EDIT}`, {
    instanceId: instanceId.toString(),
    templateId,
  });
}

function getTemplateCopyRoute({ instanceId, templateId }: TemplatesArgs) {
  return createPath(`/${INSTANCE}/${TEMPLATE_COPY}`, {
    instanceId: instanceId.toString(),
    templateId,
  });
}

function getItemsRoute({ instanceId, templateId }: TemplatesArgs) {
  return createPath(`/${INSTANCE}/${ITEMS}`, {
    instanceId: instanceId.toString(),
    templateId,
  });
}

function getItemNewRoute({ instanceId, templateId }: TemplatesArgs) {
  return createPath(`/${INSTANCE}/${ITEM_NEW}`, {
    instanceId: instanceId.toString(),
    templateId,
  });
}

function getWorkspaceSettingsRoute({ workspaceId }: { workspaceId: number }) {
  return createPath(`${SETTINGS_WORKSPACE}`, {
    workspaceId: workspaceId.toString(),
  });
}

function getWorkspaceMembersSettingsRoute({
  workspaceId,
}: {
  workspaceId: number;
}) {
  return createPath(`${SETTINGS_WORKSPACE_MEMBERS}`, {
    workspaceId: workspaceId.toString(),
  });
}

function getWorkspaceRolesSettingsRoute({
  workspaceId,
}: {
  workspaceId: number;
}) {
  return createPath(`${SETTINGS_WORKSPACE_ROLES}`, {
    workspaceId: workspaceId.toString(),
  });
}

function getProjectSettingsRoute({
  projectId,
  workspaceId,
}: {
  projectId: number;
  workspaceId: number;
}) {
  return createPath(`${SETTINGS_PROJECT}`, {
    projectId: projectId.toString(),
    workspaceId: workspaceId.toString(),
  });
}

function getProjectSettingsNewMemberRoute({
  projectId,
  workspaceId,
}: {
  projectId: number;
  workspaceId: number;
}) {
  return createPath(`${SETTINGS_PROJECT_MEMBER_ADD}`, {
    projectId: projectId.toString(),
    workspaceId: workspaceId.toString(),
  });
}

function getProjectSettingsEditMemberRoute({
  projectId,
  workspaceId,
  memberId,
}: {
  projectId: number;
  workspaceId: number;
  memberId: number;
}) {
  return createPath(`${SETTINGS_PROJECT_MEMBER_EDIT}`, {
    projectId: projectId.toString(),
    workspaceId: workspaceId.toString(),
    memberId: memberId.toString(),
  });
}

function getProjectRoleEditRoute({
  workspaceId,
  roleId,
}: {
  workspaceId: number;
  roleId: number;
}) {
  return createPath(`${SETTINGS_PROJECT_ROLE_EDIT}`, {
    workspaceId: workspaceId.toString(),
    roleId: roleId.toString(),
  });
}

function getWorkspaceRoleNewRoute({ workspaceId }: { workspaceId: number }) {
  return createPath(`${SETTINGS_PROJECT_ROLE_NEW}`, {
    workspaceId: workspaceId.toString(),
  });
}

type ItemsArgs = {
  instanceId: number;
  templateId: string;
  id: string;
};

function getItemEditRoute({ instanceId, templateId, id }: ItemsArgs) {
  return createPath(`/${INSTANCE}/${ITEM_EDIT}`, {
    instanceId: instanceId.toString(),
    templateId,
    id,
  });
}

export function createPath(routePath: string, params: StringObject) {
  let newPath = routePath;
  for (const key of Object.keys(params)) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      newPath = newPath.replace(`:${key}`, params[key]);
    }
  }
  return newPath;
}
