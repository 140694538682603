import React, { useRef } from 'react';
import { MenuItem, MenuNode } from 'src/types/Menu';
import { Flex, IconButton } from '@radix-ui/themes';
import { TrashIcon, OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { ItemRename } from '../ItemRename';
import { ReactComponent as Directory } from 'src/assets/directory.svg';
import { removeItem } from '../../utils/removeItem';
import { Link as RouterLink } from 'react-router-dom';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { appRoutes } from 'src/utils/routePaths';
import { Toast } from '../../../../../components/Toast';

type NavItemProps = {
  node: MenuItem;
  path: number[];
  treeData: MenuNode[];
  onTreeUpdate: (tree: MenuNode[]) => void;
};
export function NavItem({ node, path, treeData, onTreeUpdate }: NavItemProps) {
  const { instanceId } = useInstanceContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const isGroup = !node.template_id;

  const handleOpen = (open: boolean) => {
    const navItemNode = ref.current;
    if (!navItemNode) {
      return;
    }

    const node = navItemNode.closest('.rstcustom__node');
    if (!node) {
      return;
    }
    if (open) {
      node.classList.add('active');
    } else {
      node.classList.remove('active');
    }
  };

  const handleRemove = () => {
    try {
      const updatedTree = removeItem({ treeData, path });
      onTreeUpdate(updatedTree);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred';
      Toast({
        text: errorMessage,
        variant: 'error',
      });
    }
  };
  return (
    <div className="nav-item" ref={ref}>
      {isGroup ? (
        <Flex align="center">
          <span className="nav-item__group">
            <Directory />
            <Nav__TextOverflowed text={node.label} />
          </span>
        </Flex>
      ) : (
        <Nav__TextOverflowed text={`${node.label}`} />
      )}

      <div className="menu-tree__actions">
        {!isGroup && (
          <IconButton
            color="gold"
            variant="ghost"
            title="Open template link in new window"
            ml="2"
            onClick={handleRemove}
            asChild
          >
            <RouterLink
              to={appRoutes.items({
                instanceId,
                templateId: node.template_id,
              })}
              target="_blank"
            >
              <OpenInNewWindowIcon />
            </RouterLink>
          </IconButton>
        )}

        {isGroup && (
          <ItemRename
            onOpenChange={handleOpen}
            node={node}
            path={path}
            treeData={treeData}
            onTreeUpdate={onTreeUpdate}
          />
        )}

        <IconButton
          variant="ghost"
          title="Remove"
          color="gold"
          ml="2"
          onClick={handleRemove}
        >
          <TrashIcon />
        </IconButton>
      </div>
    </div>
  );
}

function Nav__TextOverflowed({ text }: { text: string }) {
  return (
    <span className="nav__item-text-wrapper">
      <span className="nav__item-text">{text}</span>
    </span>
  );
}
