import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import { Flex, DataList, Card, Skeleton, Text } from '@radix-ui/themes';
import { AccountRenamePopup } from './components/AccountRenamePopup';
import { AccountEmailUpdatePopup } from './components/AccountEmailUpdatePopup';
import { PasswordResetPopup } from './components/PasswordResetPopup';
import { useAccount } from 'src/hooks/api/useAccount';

const PAGE_TITLE = 'Profile';

export function Account() {
  const { data, isLoading, error } = useAccount();

  if (error) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} />

        <Page__Content>
          <ErrorApi error={error} />
        </Page__Content>
      </>
    );
  }

  const email = data?.email;
  const name = data?.name;

  return (
    <>
      <Page__Header title={PAGE_TITLE}>
        {!isLoading && email && <PasswordResetPopup email={email} />}
      </Page__Header>

      <Page__Content>
        <Flex>
          <Card variant="surface" size="4">
            <DataList.Root size="2">
              <DataList.Item>
                <DataList.Label minWidth="80px">Name</DataList.Label>
                <DataList.Value>
                  <Flex align="center" gap="2">
                    <Skeleton loading={isLoading} minWidth="100px">
                      <Text>{name}</Text>
                    </Skeleton>
                    {!isLoading && name && <AccountRenamePopup name={name} />}
                  </Flex>
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label minWidth="80px">Email</DataList.Label>
                <DataList.Value>
                  <Flex align="center" gap="2">
                    <Skeleton loading={isLoading} minWidth="140px">
                      <Text>{email}</Text>
                    </Skeleton>
                    {!isLoading && <AccountEmailUpdatePopup />}
                  </Flex>
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </Card>
        </Flex>
      </Page__Content>
    </>
  );
}
