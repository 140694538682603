import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from '@radix-ui/themes';
import { TableDropdown } from './TableDropdown';
import b, { Mix, Mods } from 'bem-react-helper';

type Member = {
  id: number;
  name: string;
  email: string;
};
const columnHelper = createColumnHelper<Member>();

type MembersTableProps = {
  accounts: Member[];
  workspaceId: number;
  projectId: number;
  mods?: Mods;
  mix?: Mix;
};

export function MembersTable(props: MembersTableProps) {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => info.getValue(),
        header: () => <span>ID</span>,
      }),
      columnHelper.accessor('name', {
        cell: (info) => {
          return info.getValue() || '-';
        },
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('id', {
        header: () => <span></span>,
        cell: (info) => {
          const memberId = info.row.original.id;
          const memberName = info.row.original.name;

          return (
            <TableDropdown
              workspaceId={props.workspaceId}
              memberId={memberId}
              memberName={memberName}
              projectId={props.projectId}
            />
          );
        },
      }),
    ],
    [props.projectId, props.workspaceId]
  );
  const table = useReactTable({
    data: props.accounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface" className={b('members-table', props)}>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell key={headerIndex}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <Table.Row key={rowIndex}>
              {row.getVisibleCells().map((cell, cellIndex) => (
                <Table.Cell key={cellIndex}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </>
  );
}
