import { ApiWorkspaceResponse, Instance } from 'src/types/Workspace';

export type GetInstancesListByProjectIdArgs = {
  id: number;
  workspaces: ApiWorkspaceResponse[];
};

export const getInstancesListByProjectId = ({
  id,
  workspaces,
}: GetInstancesListByProjectIdArgs) => {
  let instances: Instance[] = [];

  workspaces.some((item) => {
    const project = item?.projects?.find((project) => project.id === id);

    if (project && project?.instances?.length) {
      instances = project.instances;

      return true;
    }

    return false;
  });

  return instances;
};
