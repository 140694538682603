import { FIELD_TYPE } from 'src/constants/components';
import * as Integer from './Integer';
import * as Float from './Float';
import * as Group from './Group';
import * as Text from './Text';
import * as RelationTemplate from './RelationTemplate';
import * as RelationTemplateList from './RelationTemplateList';
import * as DynamicGroup from './DynamicGroup';
import * as DynamicGroupRow from './DynamicGroupRow';
import * as Boolean from './Boolean';
import * as Image from './Image';

export const fields = {
  [FIELD_TYPE.INTEGER]: Integer,
  [FIELD_TYPE.FLOAT]: Float,
  [FIELD_TYPE.GROUP]: Group,
  [FIELD_TYPE.TEXT]: Text,
  [FIELD_TYPE.ITEM_LINK]: RelationTemplate,
  [FIELD_TYPE.ITEM_LINK_LIST]: RelationTemplateList,
  [FIELD_TYPE.DYNAMIC_GROUP]: DynamicGroup,
  [FIELD_TYPE.DYNAMIC_GROUP_ROW]: DynamicGroupRow,
  [FIELD_TYPE.BOOLEAN]: Boolean,
  [FIELD_TYPE.IMAGE]: Image,
};
