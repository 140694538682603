import {
  ApiTemplateResponse,
  SettingsRelationTemplateListType,
  SettingsRelationTemplateType,
} from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';

export const fillItemLinkAndItemLinkListTemplateIdMap = (
  apiTemplateResponse: ApiTemplateResponse
): Map<string, string> => {
  const templateIdMap = new Map<string, string>();

  apiTemplateResponse?.fields?.forEach((field) => {
    const { type, templateId } = field.settings as
      | SettingsRelationTemplateType
      | SettingsRelationTemplateListType;

    if (type === FIELD_TYPE.ITEM_LINK || type === FIELD_TYPE.ITEM_LINK_LIST) {
      templateIdMap.set(field.id, templateId);
    }
  });

  return templateIdMap;
};
