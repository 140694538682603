import * as yup from 'yup';

export const LATIN_REGEX = /^[a-zA-Z0-9_]+$/;

export const templateIdValidation = yup
  .string()
  .min(3, 'ID must be at least 3 characters')
  .max(64, 'ID must be at most 64 characters')
  .matches(LATIN_REGEX, {
    message: 'Must include only Latin letters and numbers',
    name: 'latin',
  })
  .required();

export const templateLabelValidation = yup
  .string()
  .required('Name is a required field')
  .min(3, 'Name must be at least 3 characters')
  .max(64, 'Name must be at most 64 characters');

export const validationSchema = yup.object().shape({
  templateLabel: templateLabelValidation,
  isTemplateIdUnique: yup
    .boolean()
    // https://github.com/react-hook-form/resolvers/issues/180#issuecomment-869800038
    .test({
      name: 'isUnique',
      test: (value, { createError }) => {
        if (!value) {
          return createError({
            path: 'isTemplateIdUnique',
            message: 'Template ID must be unique',
          });
        }
        return true;
      },
    }),
  templateId: templateIdValidation,
});
