import { SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Popover, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { Input } from 'src/components/Input';
import {
  useWorkspaceRename,
  RenameWorkspaceArg,
} from 'src/hooks/api/useWorkspaceRename';
import { useQueryClient } from '@tanstack/react-query';
import { GET_WORKSPACE_KEY } from 'src/hooks/api/useWorkspacesList';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Workspace name is a required field')
    .max(32, 'Maximum 32 characters allowed'),
});

type RenameWorkspaceProps = {
  workspaceName: string;
  workspaceId: number;
};

export function RenameWorkspace({
  workspaceName,
  workspaceId,
}: RenameWorkspaceProps) {
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: renameWorkspace,
    isMutating,
    error,
  } = useWorkspaceRename({
    onSuccess: () => {
      setIsPopoverOpen(false);
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_KEY] });
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { name: workspaceName },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleRename: SubmitHandler<
    Omit<RenameWorkspaceArg, 'workspaceId'>
  > = async (
    data: Omit<RenameWorkspaceArg, 'workspaceId'>,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    renameWorkspace({ ...data, workspaceId });
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button variant="soft" color="gold">
            Rename workspace
          </Button>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleRename)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.name?.message}
              label="Workspace name"
              placeholder="Enter workspace name"
              data-testid="workspace-name"
              required
              {...register('name')}
            />

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                type="submit"
                color="plum"
                loading={isMutating}
              >
                Rename workspace
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
