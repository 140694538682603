import { ApiWorkspaceResponse } from 'src/types/Workspace';

type GetInstanceByIdArgs = {
  workspaces?: ApiWorkspaceResponse[];
  instanceId: number;
};

export const getInstanceById = ({
  workspaces,
  instanceId,
}: GetInstanceByIdArgs) => {
  if (!workspaces) {
    return null;
  }

  for (const workspace of workspaces) {
    const projects = workspace?.projects || [];
    for (const project of projects) {
      const instances = project?.instances || [];
      const instance = instances.find((inst) => inst.id === instanceId);
      if (instance) {
        return instance;
      }
    }
  }

  return null;
};
