import React, { useEffect, useRef, useState } from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { Spinner } from '@radix-ui/themes';

type LazyImageProps = {
  src?: string;
  alt: string;
  mods?: Mods;
  mix?: Mix;
};

export function LazyImage({ src, alt, ...props }: LazyImageProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const imgRef = useRef(null);

  useEffect(() => {
    const imageNode = imgRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageSrc(src || '');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (imageNode) {
      observer.observe(imageNode);
    }

    return () => {
      if (imageNode) {
        observer.unobserve(imageNode);
      }
    };
  }, [src]);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={b('lazy-image', props, { loading: !isLoaded })}>
      {!isLoaded && (
        <Spinner
          className="lazy-image__spinner"
          title={`Loading image: ${alt} `}
        />
      )}
      <img ref={imgRef} alt={alt} src={imageSrc} onLoad={handleLoad} />
    </div>
  );
}

export default LazyImage;
