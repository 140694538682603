import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import type { PermissionUpdate } from 'src/types/Roles';

export type RoleUpdateArgs = {
  role_id?: number;
  name: string;
  workspace_id: number;
  permissions: PermissionUpdate[];
};

const roleUpdate = (arg: RoleUpdateArgs): Promise<unknown> => {
  return fetcher({
    url: `/workspaces/${arg.workspace_id}/roles`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useWorkspaceRoleDefinitionUpdate(
  opt?: MutationOptions<unknown>
) {
  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: roleUpdate,
    meta: {
      name: 'Role update',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    reset,
  };
}
