import { NavTree } from '../NavTree';
import React from 'react';
import { Flex, IconButton, Link } from '@radix-ui/themes';
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { GearIcon, HomeIcon } from '@radix-ui/react-icons';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getProjectByInstanceId } from 'src/utils/getProjectByInstanceId';
import { appRoutes } from 'src/utils/routePaths';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';

export function Nav__Templates() {
  const navigate = useNavigate();
  const { instanceId } = useInstanceContext();
  const { data: workspacesData } = useWorkspacesList();

  const activeProject = getProjectByInstanceId({
    workspaces: workspacesData?.workspaces || [],
    instanceId: instanceId,
  });

  return (
    <ul className="nav__list">
      <li className="nav__templates-dashboard">
        <Link asChild color="gray" className="nav__item-link">
          <RouterNavLink to="/">
            <Flex align="center" justify="between" width="100%">
              <Flex align="center" gap="2">
                <HomeIcon width="16px" height="16px" />{' '}
                <span data-testid="dashboard-navigation-link">
                  {activeProject?.name || 'Dashboard'}
                </span>
              </Flex>
            </Flex>

            <IconButton
              className="nav__settings-icon"
              variant="ghost"
              color="gold"
              title="Settings"
              size="2"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                navigate(appRoutes.settingsAccount());
              }}
            >
              <GearIcon />
            </IconButton>
          </RouterNavLink>
        </Link>
      </li>

      {!!instanceId && (
        <Access roles={ROLES_LIST} permissionsGroups={[[PERMISSIONS.READ]]}>
          <li className="nav__group nav__templates">
            <NavTree />
          </li>
        </Access>
      )}
    </ul>
  );
}
