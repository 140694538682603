import { TreeNodeFlat } from 'src/types/Template';

type ConvertFlatTreeToFieldsArgs = {
  flatTree: TreeNodeFlat[];
};
export function convertFlatTreeToFields({
  flatTree,
}: ConvertFlatTreeToFieldsArgs) {
  return flatTree.map((item) => ({
    id: item.node.id,
    parentId: item?.parentNode?.id || 'root',
    settings: item.node.settings,
    created_at: item.node.created_at,
    updated_at: item.node.updated_at,
    order: item.node?.order || 0,
  }));
}
