import React from 'react';
import { DropdownMenu, IconButton } from '@radix-ui/themes';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import {
  WorkspaceRolesType,
  WORKSPACES_ROLES,
} from 'src/constants/workspaceRoles';
import { UpdateRole } from './UpdateRole';
import { useWorkspaceMemberRevoke } from 'src/hooks/api/useWorkspaceMemberRevoke';
import { Toast } from 'src/components/Toast';
import { GET_WORKSPACE_MEMBERS_KEY } from 'src/hooks/api/useWorkspaceMembers';
import { useQueryClient } from '@tanstack/react-query';
import { useUserContext } from 'src/providers/UserProvider';

type TableDropdownProps = {
  workspaceId: number;
  roleId: WorkspaceRolesType;
  memberId: number;
};

export function TableDropdown({
  workspaceId,
  memberId,
  roleId,
}: TableDropdownProps) {
  const { workspacesRoleMap } = useUserContext();
  const queryClient = useQueryClient();
  const { mutate: revokeInvite } = useWorkspaceMemberRevoke({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_MEMBERS_KEY, workspaceId],
      });
      Toast({
        text: 'Member successfully removed',
        variant: 'info',
      });
    },
  });
  const [isRenamePopupOpen, setIsRenamePopupOpen] = React.useState(false);

  const userRole = workspacesRoleMap.get(workspaceId) || null;
  const isOwner = userRole === WORKSPACES_ROLES.OWNER;
  const isAdmin = userRole === WORKSPACES_ROLES.ADMIN;

  // admin can't update owner's role or remove owner from workspace
  const canUpdateUser =
    isOwner || (isAdmin && roleId !== WORKSPACES_ROLES.OWNER);

  if (!canUpdateUser) {
    return null;
  }

  return (
    <>
      <UpdateRole
        memberId={memberId}
        workspaceId={workspaceId}
        isPopoverOpen={isRenamePopupOpen}
        onOpenChange={setIsRenamePopupOpen}
      />
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton variant="ghost" title="Edit member">
            <DotsHorizontalIcon />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content variant="soft">
          <DropdownMenu.Item onClick={() => setIsRenamePopupOpen(true)}>
            Update role
          </DropdownMenu.Item>

          <DropdownMenu.Item
            color="red"
            onClick={() => revokeInvite({ member_id: memberId, workspaceId })}
          >
            Remove member
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}
