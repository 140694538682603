import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Pencil1Icon } from '@radix-ui/react-icons';
import * as yup from 'yup';
import { Box, Flex, IconButton, Popover, Button } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAccountRename,
  AccountRenameArg,
} from 'src/hooks/api/useAccountRename';
import { GET_ACCOUNT_KEY } from 'src/hooks/api/useAccount';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is a required field')
    .max(32, 'Maximum 32 characters allowed'),
});

type AccountRenamePopupProps = {
  name: string;
};

export function AccountRenamePopup({ name }: AccountRenamePopupProps) {
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: renameAccount,
    isMutating,
    error,
  } = useAccountRename({
    onSuccess: () => {
      setIsPopoverOpen(false);
      queryClient.invalidateQueries({ queryKey: [GET_ACCOUNT_KEY] });
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name,
    },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleCreation: SubmitHandler<AccountRenameArg> = async (
    data: AccountRenameArg,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    renameAccount(data);
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <IconButton
            size="1"
            title="Rename account"
            variant="ghost"
            color="gold"
          >
            <Pencil1Icon />
          </IconButton>
        </Popover.Trigger>
        <Popover.Content width="360px">
          <form onSubmit={handleSubmit(handleCreation)}>
            {!!error && (
              <Box mb="4">
                <ErrorApi error={error} />
              </Box>
            )}

            <Input
              errorMessage={errors.name?.message}
              label="Name"
              placeholder="Enter account name"
              required
              {...register('name')}
            />

            <Flex justify="end" mt="4">
              <Button
                variant="soft"
                color="plum"
                type="submit"
                loading={isMutating}
              >
                Update name
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
