import b, { type Mods, type Mix } from 'bem-react-helper';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import React from 'react';

type CardDnDProps = {
  mods?: Mods;
  mix?: Mix;
  title?: string;
  children?: React.ReactNode;
  innerRef?: React.Ref<HTMLDivElement>;
};

export function CardDnD(props: CardDnDProps) {
  const { title, children, innerRef } = props;

  return (
    <div className={b('card-dnd', props)} ref={innerRef}>
      <div className="card-dnd__content">
        <DragHandleDots2Icon className="card-dnd__icon" />
        {title}
        {children}
      </div>
    </div>
  );
}
