import { EmptyObject } from 'src/types/Common';

type ImageObject =
  | {
      v?: string;
      p?: string;
      [key: string]: ImageObject | string | undefined;
    }
  | EmptyObject;

export function updateImagesPathInPreview(obj: ImageObject) {
  if ('v' in obj && 'p' in obj && typeof obj.p === 'string' && obj.p !== '') {
    obj.p = 'path/to/uploaded/image.jpg';
  }

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      typeof obj[key] === 'object' &&
      obj[key] !== null
    ) {
      updateImagesPathInPreview(obj[key] as ImageObject);
    }
  }
}
