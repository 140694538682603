import React from 'react';
import { Dialog, Flex, Button } from '@radix-ui/themes';
import { ReportDetails } from './ReportDetails';

type DialogReportProps = {
  trigger: React.ReactNode;
  reportId: string;
};

export function DialogReport({ trigger, reportId }: DialogReportProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content
        maxWidth="1200px"
        // disable close outside to add ability to interact with toast
        onInteractOutside={(e) => e.preventDefault()}
      >
        <Dialog.Title hidden>Report data</Dialog.Title>

        <ReportDetails reportId={reportId} />

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
