import { ApiWorkspaceResponse } from 'src/types/Workspace';
import { WorkspacesRolesMap } from 'src/utils/getWorkspacesRolesMap';
import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';

type GetAvailableWorkspacesArgs = {
  workspaces: ApiWorkspaceResponse[];
  workspacesRoleMap: WorkspacesRolesMap;
};

export const getAvailableWorkspaces = ({
  workspaces,
  workspacesRoleMap,
}: GetAvailableWorkspacesArgs) => {
  return workspaces.filter((item) => {
    const role = workspacesRoleMap.get(item.id);
    return role === WORKSPACES_ROLES.OWNER;
  });
};
