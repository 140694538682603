import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiWorkspacesListResponse } from 'src/types/Workspace';

export const GET_WORKSPACE_KEY = 'useWorkspacesList';

const getWorkspaces = async (): Promise<ApiWorkspacesListResponse> => {
  const response: ApiWorkspacesListResponse = await fetcher({
    url: '/workspaces',
  });

  // Filter out projects with 'DeleteInProgress' status
  if (response.workspaces) {
    response.workspaces.forEach((workspace) => {
      if (workspace.projects) {
        workspace.projects = workspace.projects.filter(
          (project) => project.status !== 'DeleteInProgress'
        );
      }
    });
  }

  return response;
};

type UseMenuArgs = {
  options?: Omit<
    UseQueryOptions<ApiWorkspacesListResponse>,
    'queryKey' | 'queryFn'
  >;
};

export function useWorkspacesList({ options }: UseMenuArgs = {}) {
  const { data, error, isPending, isError, isSuccess, refetch } = useQuery({
    queryKey: [GET_WORKSPACE_KEY],
    queryFn: getWorkspaces,
    staleTime: Infinity, // changes rarely, it's better to refetch if needed
    gcTime: Infinity,
    meta: {
      name: 'Workspaces list',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    error: isError ? error : null,
    isSuccess,
    refetch,
  };
}
