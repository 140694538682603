import React from 'react';
import { Heading, Flex, Box } from '@radix-ui/themes';
import { Logo } from 'src/components/Logo';

type ErrorPageProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgSrcWebp?: string;
  children: React.ReactNode;
  headline: string;
};

export function ErrorPage({
  imgSrc,
  imgSrcWebp,
  imgAlt,
  headline,
  children,
}: ErrorPageProps) {
  const hasImage = imgSrc || imgSrcWebp;

  return (
    <div className="error-page">
      <Box>
        <Logo />
      </Box>
      <Flex direction="column" justify="center">
        <Flex
          p="4"
          gap="8"
          align="center"
          direction={{
            initial: 'column',
            md: 'row',
          }}
        >
          <Box className="error-page__body">
            <Heading size="8" mb="6">
              {headline}
            </Heading>
            {children}
          </Box>

          {!!hasImage && (
            <picture>
              <source type="image/webp" srcSet={imgSrcWebp} />
              <source type="image/jpeg" srcSet={imgSrc} />
              <img className="error-page__img" src={imgSrc} alt={imgAlt} />
            </picture>
          )}
        </Flex>
      </Flex>
    </div>
  );
}
