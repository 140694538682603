import { FIELD_TYPE } from 'src/constants/components';
import { Box } from '@radix-ui/themes';
import { fields } from 'src/fields';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { TreeNode } from 'src/types/Template';

type ItemFormProps = {
  methods: UseFormReturn<FieldValues>;
  templateTree: TreeNode[];
};

export function Form(props: ItemFormProps) {
  const { templateTree = [], methods } = props;

  return (
    <div data-testid="item-form">
      {templateTree.map((node) => {
        const Component = fields[node?.settings?.type]?.Field;
        const children = node.children;
        const fieldRegisterName = node.entityFieldId;

        if (!Component) {
          return null;
        }

        if (node.settings.type === FIELD_TYPE.GROUP && children) {
          if (!fieldRegisterName) {
            return null;
          }
          return (
            <Component
              // @ts-expect-error: fix me if you can
              data={node.settings}
              key={node.id}
              id={fieldRegisterName}
              methods={methods}
            >
              <Form templateTree={children} methods={methods} />
            </Component>
          );
        }

        if (node.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW && children) {
          return (
            <Component
              // @ts-expect-error: fix me if you can
              data={node.settings}
              node={node}
              key={node.id}
            >
              <Form templateTree={children} methods={methods} />
            </Component>
          );
        }

        if (node.settings.type === FIELD_TYPE.DYNAMIC_GROUP) {
          return (
            <Component
              // @ts-expect-error: fix me if you can
              data={node.settings}
              key={node.entityFieldId}
              node={node}
            >
              {!!children && <Form templateTree={children} methods={methods} />}
            </Component>
          );
        }

        if (!fieldRegisterName) {
          return null;
        }

        return (
          <Box my="2" key={node.id}>
            <Component
              // @ts-expect-error: fix me if you can
              data={node.settings}
              id={fieldRegisterName}
              methods={methods}
            />
          </Box>
        );
      })}
    </div>
  );
}
