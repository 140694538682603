import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const CHECK_TEMPLATE_ID_KEY = 'useTemplateIdCheck';

export type ActivateInstanceArgs = {
  instanceId: number;
  templateId: string;
};

type Response = {
  is_unique: boolean;
};

const checkTemplateId = (arg: ActivateInstanceArgs): Promise<Response> => {
  return fetcher({
    url: `/editor/${arg.instanceId}/templates/${arg.templateId}/unique`,
    options: {
      method: 'GET',
    },
  });
};

type UseTemplateIdCheckArgs = {
  templateId: string;
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useTemplateIdCheck({
  options,
  templateId,
}: UseTemplateIdCheckArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isLoading, isError } = useQuery({
    queryKey: [CHECK_TEMPLATE_ID_KEY, instanceId, templateId],
    queryFn: () => checkTemplateId({ instanceId, templateId }),
    enabled: !!templateId && instanceId !== -1,
    meta: {
      name: 'Template ID uniqueness check',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
}
