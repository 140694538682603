import { HTML5Backend } from 'react-dnd-html5-backend';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { createDragDropManager } from 'dnd-core';

// Need to pass manager or context to make wrapper global.
// https://github.com/react-dnd/react-dnd/issues/3119#issuecomment-818447265
// https://github.com/react-dnd/react-dnd/issues/3178
// https://github.com/react-dnd/react-dnd/issues/3257
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const manager = createDragDropManager(HTML5Backend, window);

type DndWrapperProps = {
  children: React.ReactNode;
};

export function DndWrapper({ children }: DndWrapperProps) {
  return (
    // @ts-expect-error: error due to an old library version and a mix of class and functional components in react-sortable-tree
    <DndProvider backend={HTML5Backend} context={window}>
      {children}
    </DndProvider>
  );
}
