import React from 'react';
import * as yup from 'yup';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import { Grid } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { Checkbox } from 'src/components/Checkbox';
import { FIELD_TYPE } from 'src/constants/components.js';
import { TemplateSettingsForm } from './components/TemplateSettingsForm';
import * as TemplateTypes from 'src/types/Template';
import {
  nameSchema,
  labelSchema,
  minimumIntegerSchema,
  maximumIntegerSchema,
} from './utils/schema';
import { setValuesAsNumber } from './utils/setValuesAsNumber';
import { nameTooltip } from './utils/tooltipText';

const settingsValidationSchema = yup.object().shape(
  {
    type: yup.string().oneOf([FIELD_TYPE.TEXT]).required(),
    name: nameSchema,
    label: labelSchema,
    required: yup.boolean().required(),
    minimum: minimumIntegerSchema,
    maximum: maximumIntegerSchema,
  },
  [['maximum', 'minimum']] // https://dev.to/gabrielterriaga/how-to-validate-two-fields-that-depend-on-each-other-with-yup-1ccg
);

export const getDefaultValues = () => ({
  type: FIELD_TYPE.TEXT,
  name: '',
  label: '',
  required: false,
  minimum: null,
  maximum: null,
});

export const validate = (
  data: TemplateTypes.SettingsTextType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

type GetFieldValidationSchemaArgs = {
  data: TemplateTypes.SettingsTextType;
};

export const getFieldValidationSchema = ({
  data,
}: GetFieldValidationSchemaArgs) => {
  let schema = yup.string();

  if (data.required) {
    schema = schema.required(`${data.name} is required field`);
  }

  if (data.minimum) {
    schema = schema.min(
      data.minimum,
      `must be at least ${data.minimum} characters`
    );
  }

  if (data.maximum) {
    schema = schema.max(
      data.maximum,
      `must be at most ${data.maximum} characters`
    );
  }

  return schema;
};

type FieldProps = {
  data: TemplateTypes.SettingsTextType;
  id: string;
  methods: UseFormReturn<{ [key: string]: string }>;
};

export function Field({ data, methods, id }: FieldProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Input
      mods={{ type: 'primary' }}
      label={data.label}
      required={data.required}
      errorMessage={errors[id]?.message}
      {...register(id)}
    />
  );
}

type SettingsProps = {
  methods: UseFormReturn<TemplateTypes.SettingsTextType>;
};
type ValidFieldNames = keyof TemplateTypes.SettingsTextType;

export function Settings({ methods }: SettingsProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Grid gap="4">
      <Input
        label="Label"
        errorMessage={errors.label?.message}
        required
        data-testid="text-field-label"
        autoFocus
        {...register<ValidFieldNames>('label')}
      />

      <Input
        label="Name"
        tooltip={nameTooltip}
        errorMessage={errors.name?.message}
        required
        data-testid="text-field-name"
        {...register<ValidFieldNames>('name')}
      />

      <Checkbox
        mods={{ type: 'primary' }}
        errorMessage={errors.required?.message}
        dataTestId="text-field-required-checkbox"
        {...register<ValidFieldNames>('required')}
      >
        Required
      </Checkbox>

      <Input
        mods={{ type: 'primary' }}
        label="Minimum length"
        errorMessage={errors.minimum?.message}
        data-testid="text-field-min-length"
        {...register<ValidFieldNames>('minimum', {
          setValueAs: setValuesAsNumber,
        })}
      />

      <Input
        mods={{ type: 'primary' }}
        label="Maximum length"
        errorMessage={errors.maximum?.message}
        data-testid="text-field-max-length"
        {...register<ValidFieldNames>('maximum', {
          setValueAs: setValuesAsNumber,
        })}
      />
    </Grid>
  );
}

type SettingsFormProps = {
  initialValues: TemplateTypes.SettingsTextType;
};

export function SettingsForm({ initialValues }: SettingsFormProps) {
  // backend can send empty fields from other settings. The reason is that all fields setting should merged by backend.
  // to solve the issue, pick just fields which we need
  const values = _.pick(initialValues, Object.keys(getDefaultValues()));
  return (
    <TemplateSettingsForm
      initialValues={values}
      validationSchema={settingsValidationSchema}
    >
      {(methods: UseFormReturn<TemplateTypes.SettingsTextType>) => (
        <Settings methods={methods} />
      )}
    </TemplateSettingsForm>
  );
}
