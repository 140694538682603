import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { SelectField } from 'src/components/SelectField';
import { useTemplatesList } from 'src/hooks/api/useTemplatesList';
import { Control, Path } from 'react-hook-form';
import { StringObject } from 'src/types/Common';

type TemplatesSelectProps<T extends FieldValues> = {
  control: Control<T, StringObject>;
  errorMessage?: string | undefined;
  name: Path<T>;
};

export function TemplatesSelect<T extends FieldValues>({
  control,
  errorMessage,
  name,
}: TemplatesSelectProps<T>) {
  const { data } = useTemplatesList();
  const options = data?.templates.map((item) => {
    return {
      id: item.id,
      label: item.header.label,
    };
  });

  return (
    <Controller
      render={({ field: { value, onChange } }) => (
        <SelectField
          errorMessage={errorMessage}
          options={options || []}
          value={value}
          onChange={onChange as (value: string | number) => void}
          label="Template"
          placeholder="Pick template"
          required
        />
      )}
      control={control}
      name={name}
    />
  );
}
