import React from 'react';
import b, { Mods, Mix } from 'bem-react-helper';
import {
  Tabs,
  Tabs__Item,
  Tabs__List,
  Tabs__Content,
} from 'src/components/Tabs';
import { ComponentsList } from '../ComponentsList';
import { FieldSettings } from '../FieldSettings';
import { Box } from '@radix-ui/themes';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { Callout } from 'src/components/Callout';

function useForceUpdate() {
  const [key, setKey] = React.useState(0);

  const updateKey = React.useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return {
    updateKey,
    key,
  };
}

const TABS_KEYS = {
  COMPONENTS: 'components',
  SETTINGS: 'settings',
};

type SidebarProps = {
  mods?: Mods;
  mix?: Mix;
};

export function Sidebar(props: SidebarProps) {
  const [selectedTab, setSelectedTab] = React.useState(TABS_KEYS.COMPONENTS);
  const { templateTreeActiveField } = useTemplateTreeContext();
  const { key, updateKey } = useForceUpdate();
  const activeNode = templateTreeActiveField?.node;

  React.useEffect(() => {
    if (templateTreeActiveField?.node.id) {
      setSelectedTab(TABS_KEYS.SETTINGS);
      // force update for react hooks form
      updateKey();
    } else {
      setSelectedTab(TABS_KEYS.COMPONENTS);
    }
  }, [templateTreeActiveField, updateKey]);

  return (
    <div className={b('sidebar', props)}>
      <Tabs
        value={selectedTab}
        onValueChange={setSelectedTab}
        mods={{ type: 'secondary' }}
      >
        <Tabs__List ariaLabel="Template settings">
          <Tabs__Item value={TABS_KEYS.COMPONENTS} dataTestId="components-tab">
            Components
          </Tabs__Item>
          <Tabs__Item
            value={TABS_KEYS.SETTINGS}
            dataTestId="field-settings-tab"
          >
            Settings
          </Tabs__Item>
        </Tabs__List>

        <Tabs__Content value={TABS_KEYS.COMPONENTS}>
          <Box mt="2">
            <ComponentsList />
          </Box>
        </Tabs__Content>

        <Tabs__Content value={TABS_KEYS.SETTINGS}>
          <Box mt="2">
            {!activeNode && (
              <Callout color="gold">
                Click on any field on the right to check the settings
              </Callout>
            )}

            {activeNode && <FieldSettings data={activeNode} key={key} />}
          </Box>
        </Tabs__Content>
      </Tabs>
    </div>
  );
}
