import React from 'react';
import { Flex, Heading, IconButton, Skeleton } from '@radix-ui/themes';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

type PageProps = {
  title?: string | undefined | React.ReactNode;
  children?: React.ReactNode;
  dataTestId?: string;
  headingSuffix?: React.ReactNode;
  isLoading?: boolean;
  backButtonUrl?: string;
};

export function Page__Header(props: PageProps) {
  const {
    backButtonUrl,
    isLoading,
    dataTestId,
    title,
    headingSuffix,
    children,
  } = props;
  const navigate = useNavigate();

  return (
    <Flex mb="1" justify="between" className="page__header">
      <Flex gap="4" align="center">
        {!!backButtonUrl && (
          <IconButton variant="ghost" onClick={() => navigate(backButtonUrl)}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        <Skeleton loading={!!isLoading} width="200px" height="20px">
          <Heading size="4" data-testid={dataTestId} weight="medium">
            {title}
          </Heading>
        </Skeleton>

        {headingSuffix}
      </Flex>

      <Flex gap="2" wrap="wrap" align="center">
        {children}
      </Flex>
    </Flex>
  );
}
