import React from 'react';
import { Navigate } from 'react-router-dom';
import { PlanType } from 'src/constants/plans';
import { appRoutes } from 'src/utils/routePaths';
import { useActiveSubscription } from 'src/hooks/useActiveSubscription';

type AccessSubscriptionProps = {
  plan?: PlanType[];
  children: React.ReactNode;
  redirectIfFailed?: boolean;
  allowedStatuses?: string[];
  selectedWorkspaceId?: string;
};

export function AccessSubscription({
  plan,
  children,
  redirectIfFailed,
  allowedStatuses = [],
  selectedWorkspaceId,
}: AccessSubscriptionProps) {
  const { subscription, isLoading } = useActiveSubscription({
    selectedWorkspaceId,
  });

  if (isLoading) {
    return null;
  }

  if (!subscription) {
    return null;
  }

  if (
    (!plan || plan.includes(subscription?.type)) &&
    (allowedStatuses.length === 0 ||
      allowedStatuses.includes(subscription?.status))
  ) {
    return <>{children}</>;
  }

  if (redirectIfFailed && subscription) {
    return <Navigate to={appRoutes.accessDenied()} />;
  }

  return null;
}
