import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from '@radix-ui/themes';
import { format } from 'date-fns';
import { DownloadFile } from './DownloadFile';
import './ReportTable.css';

type ReportTableType = {
  id: string;
  started_at: string;
  completed_at: string;
  template_id: string;
  file: string;
};

const columnHelper = createColumnHelper<ReportTableType>();

type ReportTableProps = {
  data: ReportTableType[];
  path: string;
};

export function ReportTable(props: ReportTableProps) {
  const { data, path } = props;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => {
          const reportId = info.getValue();

          return <span>{reportId}</span>;
        },
        header: () => <span>ID</span>,
        size: 240,
      }),
      columnHelper.accessor('template_id', {
        cell: (info) => {
          const templateId = info.getValue();

          return <span>{templateId}</span>;
        },
        header: () => <span>Template ID</span>,
        size: 240,
      }),
      columnHelper.accessor('started_at', {
        cell: (info) => {
          const startedAt = format(info.getValue(), 'dd MMM, yyyy H:mm:ss');
          return <span>{startedAt}</span>;
        },
        header: () => <span>Started</span>,
      }),
      columnHelper.accessor('completed_at', {
        cell: (info) => {
          const completedAt = format(info.getValue(), 'dd MMM, yyyy H:mm:ss');
          return <span>{completedAt}</span>;
        },
        header: () => <span>Completed</span>,
      }),
      columnHelper.accessor('file', {
        cell: (info) => {
          const fileName = info.getValue();
          return <span>{fileName}</span>;
        },
        header: () => <span>File</span>,
      }),
      columnHelper.accessor('file', {
        cell: (info) => {
          const fileName = info.getValue();
          return <DownloadFile fileName={fileName} path={path} />;
        },
        header: () => <></>,
      }),
    ],
    [path]
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface" className="report-table">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell
                  key={headerIndex}
                  {...(header.id === 'id' || header.id === 'total_steps'
                    ? { width: `${header.getSize()}px` }
                    : {})}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No results</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
