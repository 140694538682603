import React from 'react';

type ImagePreviewProps = {
  image: React.ReactNode;
  children: React.ReactNode;
};

export function ImagePreview({ image, children }: ImagePreviewProps) {
  return (
    <div className="image-preview">
      <div className="image-preview__button-group">{children}</div>

      {image}
    </div>
  );
}
