import React from 'react';
import { SortableTreeWithoutDndContext as SortableTree } from '@nosferatu500/react-sortable-tree';
// @ts-expect-error: doesn't have types
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import { NavItem } from '../NavItem';
import { MenuItem, MenuNode } from 'src/types/Menu';
import { DndWrapper } from 'src/components/DndWrapper';
import { updateScrollIndicatorPosition } from 'src/utils/updateScrollIndicatorPosition';

type MenuTreeProps = {
  tree: MenuNode[];
  onChange: (tree: MenuItem[]) => void;
};

export function MenuTree({ tree, onChange }: MenuTreeProps) {
  const handleTreeUpdate = (updatedTree: MenuNode[]) => {
    onChange(updatedTree);
  };

  React.useEffect(() => {
    const scrollerElement: HTMLDivElement | null = document.querySelector(
      '[data-virtuoso-scroller="true"]'
    );

    if (!scrollerElement) {
      return;
    }

    const handleScroll = () => {
      updateScrollIndicatorPosition(scrollerElement);
    };

    handleScroll();
    scrollerElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollerElement.removeEventListener('scroll', handleScroll);
    };
  }, [tree]);

  return (
    <DndWrapper>
      <div className="menu-tree">
        <SortableTree
          treeData={tree}
          onChange={handleTreeUpdate}
          theme={FileExplorerTheme}
          getNodeKey={({ node }) => node.id}
          canNodeHaveChildren={(node) => !node.template_id}
          generateNodeProps={({ node, path }) => ({
            title: () => {
              return (
                <NavItem
                  node={node}
                  key={node.id}
                  path={path}
                  treeData={tree}
                  onTreeUpdate={handleTreeUpdate}
                />
              );
            },
          })}
        />
      </div>
    </DndWrapper>
  );
}
