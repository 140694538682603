import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Popover,
  Text,
  Strong,
  Button,
} from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { usePasswordReset } from 'src/hooks/api/usePasswordReset';
import { Challenge } from 'src/components/CloudflareTurnstile';

type PasswordResetPopupProps = {
  email: string;
};

export function PasswordResetPopup({ email }: PasswordResetPopupProps) {
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    mutate: resetPassword,
    isMutating,
    error,
    reset: resetMutation,
    isChallenging,
    challengeError,
  } = usePasswordReset({
    onSuccess: () => {
      setIsEmailSent(true);
    },
  });

  const handlePasswordReset = async (e?: React.BaseSyntheticEvent) => {
    e?.preventDefault();
    resetPassword({ email });
  };

  const handleOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    if (!isOpen) {
      setIsEmailSent(false);
      resetMutation();
    }
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={handleOpenChange}>
        <Popover.Trigger>
          <Button color="gold" variant="soft">
            Change password
          </Button>
        </Popover.Trigger>
        <Popover.Content width="400px">
          <Challenge isChallenging={isChallenging} error={challengeError} />

          {!!error && (
            <Box mb="4">
              <ErrorApi error={error} />
            </Box>
          )}

          {!isEmailSent && (
            <StepOne
              email={email}
              onClick={handlePasswordReset}
              isSending={isMutating}
            />
          )}

          {isEmailSent && <StepTwo email={email} />}
        </Popover.Content>
      </Popover.Root>
    </>
  );
}

type StepOneProps = {
  email: string;
  onClick: () => void;
  isSending: boolean;
};

function StepOne({ email, onClick, isSending }: StepOneProps) {
  return (
    <div>
      <Text size="2">
        We'll send instructions to reset your password to your email address:{' '}
        <Strong>{email}</Strong>
      </Text>

      <Flex justify="end" mt="4">
        <Button
          variant="soft"
          color="plum"
          type="button"
          onClick={onClick}
          loading={isSending}
        >
          Reset password
        </Button>
      </Flex>
    </div>
  );
}

type StepTwoProps = {
  email: string;
};

function StepTwo({ email }: StepTwoProps) {
  return (
    <div>
      <Heading size="3" mb="2">
        Almost there 🪄
      </Heading>
      <Text size="2">
        An email with password reset instructions has been sent to{' '}
        <Strong>{email}</Strong>.
      </Text>
    </div>
  );
}
