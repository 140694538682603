import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiTemplateResponse } from 'src/types/Template';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useLocation } from 'react-router-dom';

export const TEMPLATE_BY_ID_KEY = 'useTemplateById';

export const getTemplateById = (
  id: string,
  instanceId: number
): Promise<ApiTemplateResponse> => {
  return fetcher({ url: `/editor/${instanceId}/templates/${id}` });
};

type UseTemplateByIdArgs = {
  id: string | undefined;
  options?: Omit<
    UseQueryOptions<ApiTemplateResponse | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useTemplateById({ id, options }: UseTemplateByIdArgs) {
  const { instanceId } = useInstanceContext();
  const location = useLocation(); // use it as queryKey deps always get fresh data for template

  const { data, error, isLoading, isError } = useQuery({
    queryKey: [TEMPLATE_BY_ID_KEY, id, instanceId, location.pathname],
    queryFn: () => (id ? getTemplateById(id, instanceId) : undefined),
    enabled: !!id && instanceId !== -1,
    meta: {
      name: 'Get template by ID',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
}
