import { Table, Skeleton } from '@radix-ui/themes';

type TableLoadingProps = {
  headers: string[];
  rowsAmount: number;
};

export function TableLoading({ headers, rowsAmount }: TableLoadingProps) {
  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          {headers.map((header, index) => (
            <Table.ColumnHeaderCell key={index}>
              {header || <Skeleton width="100px" />}
            </Table.ColumnHeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {new Array(rowsAmount).fill(0).map((_, index) => (
          <Table.Row key={index}>
            {headers.map((header, index) => (
              <Table.Cell key={index}>
                <Skeleton maxWidth={`${100 + 30 * (index + 1)}px`} />
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
}
