import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Heading, Link, Text, Button } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { Notification } from './components/Notification';
import {
  useRegistration,
  RegistrationRequestType,
} from 'src/hooks/api/useRegistration';
import { appRoutes } from 'src/utils/routePaths';
import { Challenge } from 'src/components/CloudflareTurnstile';

const validationSchema = yup.object().shape({
  account_name: yup
    .string()
    .required('Name is a required field')
    .min(2, 'Must be at least 2 characters')
    .max(32, 'Must be at most 32 characters'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is a required field')
    .min(5, 'Must be at least 5 characters')
    .max(64, 'Must be at most 64 characters'),
});

export default function Registration() {
  const [showNotification, setShowNotification] = React.useState(false);

  const {
    mutate: registration,
    error,
    isMutating,
    isChallenging,
    challengeError,
  } = useRegistration({
    onSuccess: () => {
      if (!showNotification) {
        setShowNotification(true);
      }
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = methods;

  const handleRegistration: SubmitHandler<RegistrationRequestType> = async (
    data: RegistrationRequestType
  ) => {
    await registration(data);
  };

  if (showNotification) {
    return <Notification email={methods.getValues('email')} />;
  }

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <form onSubmit={handleSubmit(handleRegistration)}>
        <Box>
          <Heading mb="4" data-testid="registration-header">
            Welcome to Lootfabriq
          </Heading>

          {!!error && (
            <Box my="4">
              <ErrorApi error={error} />
            </Box>
          )}
          <Box mb="4">
            <Input
              size="3"
              label="Name"
              data-testid="registration-name"
              errorMessage={errors.account_name?.message}
              required
              {...register('account_name')}
            />
          </Box>
          <Box mb="6">
            <Input
              size="3"
              label="Email"
              data-testid="registration-email"
              errorMessage={errors.email?.message}
              required
              {...register('email')}
            />
          </Box>

          <Challenge isChallenging={isChallenging} error={challengeError} />
          <Flex justify="end">
            <Button
              variant="soft"
              color="plum"
              size="4"
              type="submit"
              style={{ width: '100%' }}
              loading={isMutating}
              data-testid="create-account"
            >
              Create account
            </Button>
          </Flex>
        </Box>
      </form>

      <Flex mt="6" justify="center">
        <Text size="2" color="gray" align="center">
          Already have an account?{' '}
          <Link asChild size="2" underline="always" color="gray">
            <RouterLink to={appRoutes.login()}>Log in</RouterLink>
          </Link>
        </Text>
      </Flex>
    </PageAccent>
  );
}
