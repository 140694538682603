import { ErrorGlobal } from 'src/components/ErrorGlobal';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

export function Error() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const sentryEventId =
    location.state?.sentryEventId || searchParams.get('sentryEventId') || null;

  return <ErrorGlobal eventId={sentryEventId} />;
}
