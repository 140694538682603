import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { ScrollArea as RadixScrollArea } from '@radix-ui/themes';

type ScrollAreaProps = {
  children: React.ReactNode;
  mods?: Mods;
  mix?: Mix;
};

// need classes for tree scroll handling =(
export const SCROLL_AREA_CLASS = {
  ROOT: 'scroll-area',
  TOP: 'scroll-area_top',
  BOTTOM: 'scroll-area_bottom',
  STICKY: 'scroll-area_sticky',
};

export function ScrollArea(props: ScrollAreaProps) {
  const scrollAreaRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = React.useState(0);
  const [scrollBottom, setScrollBottom] = React.useState(0);

  React.useLayoutEffect(() => {
    const element = scrollAreaRef.current;
    if (element) {
      const elementScrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;

      if (elementScrollBottom) {
        setScrollBottom(elementScrollBottom);
      }
    }
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;

    setScrollTop(element.scrollTop);
    setScrollBottom(
      element.scrollHeight - element.scrollTop - element.clientHeight
    );
  };

  return (
    <RadixScrollArea
      className={b('scroll-area', props, {
        top: scrollTop > 0,
        bottom: scrollBottom > 1,
      })}
      size="1"
      type="auto"
      ref={scrollAreaRef}
      onScroll={handleScroll}
      style={{ height: '100%' }}
    >
      {props.children}
    </RadixScrollArea>
  );
}
