import { walk } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';
import { getNodeKey } from 'src/utils/getNodeKey';

type GetDynamicGroupsFromTreeArgs = {
  tree: TreeNode[];
};

export function getDynamicGroupsFromTree({
  tree,
}: GetDynamicGroupsFromTreeArgs) {
  const map = new Map();

  walk({
    treeData: tree,
    getNodeKey,
    callback: ({ node }: { node: TreeNode }) => {
      if (node.children && node.settings.type === FIELD_TYPE.DYNAMIC_GROUP) {
        if (node.templateFieldId) {
          map.set(node.templateFieldId, node.children);
        }
      }
    },
    ignoreCollapsed: false,
  });

  return map;
}
