import React from 'react';
import { CopyIcon, CheckIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
// https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/169#issuecomment-1049003185
// @ts-expect-error: library types bug
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/light';
// @ts-expect-error: library types bug
import { darcula, vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useTheme } from 'src/providers/ThemeProvider';
import { useCopyToClipboard, useInterval } from 'usehooks-ts';
import { Toast } from 'src/components/Toast';

type CopyCodeProps = {
  code: string;
  language: string;
};

export function CopyCode({ code, language }: CopyCodeProps) {
  const { theme } = useTheme();
  const [isCopied, setIsCopied] = React.useState(false);
  const [, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => {
    if (isCopied) {
      return;
    }

    copy(text)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        if (typeof error === 'object') {
          Toast({
            text: `Failed to copy: ${JSON.stringify(error)}`,
            variant: 'error',
          });
        } else {
          Toast({
            text: `Failed to copy: ${error}`,
            variant: 'error',
          });
        }
      });
  };

  useInterval(
    () => {
      setIsCopied(false);
    },
    isCopied ? 2000 : null
  );

  const Icon = isCopied ? CheckIcon : CopyIcon;

  return (
    <div className="copy-code">
      <IconButton
        title="Copy to clipboard"
        variant="soft"
        color="gold"
        className="copy-code__icon"
        onClick={() => handleCopy(code)}
        data-testid="copy-code-to-clipboard"
      >
        <Icon />
      </IconButton>

      <SyntaxHighlighter
        wrapLongLines={true}
        PreTag={PreTag}
        language={language}
        style={theme === 'light' ? vs : darcula}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
}

type PreTagProps = {
  children: React.ReactNode;
};

function PreTag({ children }: PreTagProps) {
  return <pre className="copy-code__pre">{children}</pre>;
}
