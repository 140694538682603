// https://developers.cloudflare.com/turnstile/troubleshooting/client-side-errors/error-codes/
export function isRetryableError(code: string) {
  const retryableErrorPatterns = [
    '102', // Invalid Parameters, advised to retry the challenge
    '103', // Retry recommended, similar to 102***
    '104', // Retry recommended, similar to 102***
    '106', // Invalid Parameters, advised to retry the challenge
    '11060', // Challenge timed out, advised to retry
    '11062', // Challenge timed out (visible mode), advised to retry
    '300', // Generic client execution error, retry recommended
    '600', // Challenge execution failure, retry recommended
  ];

  return retryableErrorPatterns.some((pattern) => code.startsWith(pattern));
}
