import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ItemField } from 'src/types/Item';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type EditItemArgs = {
  id?: string;
  template_id: string;
  internal_label: string;
  fields: ItemField[];
  instanceId: number;
};

const editItem = (arg: EditItemArgs): Promise<unknown> => {
  return fetcher({
    url: `/editor/${arg.instanceId}/items`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useEditItem(opt?: MutationOptions<unknown>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (arg: Omit<EditItemArgs, 'instanceId'>) =>
      editItem({ ...arg, instanceId }),
    meta: {
      name: 'Edit item',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
