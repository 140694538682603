import React from 'react';
import pluralize from 'pluralize';
import {
  Box,
  Card,
  DataList,
  Flex,
  Separator,
  Text,
  Tooltip,
  IconButton,
  Heading,
} from '@radix-ui/themes';
import { Subscription } from 'src/types/Subscription';
import { format } from 'date-fns';
import { InfoCircledIcon } from '@radix-ui/react-icons';

type SubscriptionInfoCardProps = {
  subscription?: Subscription;
};

export function SubscriptionInfoCard({
  subscription,
}: SubscriptionInfoCardProps) {
  if (!subscription) {
    return null;
  }

  const usedMembers = subscription.used_members;
  const totalMembers = subscription.members;
  const usedProjects = subscription.used_projects;
  const totalProjects = subscription.projects;
  const billingDate = format(
    new Date(subscription.next_billing_date_at),
    'MMMM d, yyyy'
  );
  const recurringPayment = subscription.recurring_payment
    ? subscription.recurring_payment / 100
    : 0;

  const nextPayment = subscription.next_payment
    ? subscription.next_payment / 100
    : 0;

  return (
    <>
      <Card variant="classic" size="3">
        <Box minWidth="290px">
          <Heading size="4">Billing Information</Heading>

          <Separator my="5" size="4" />
          <Flex justify="between" align="center">
            <Flex direction="column">
              <Text weight="medium">{subscription.type} plan</Text>
            </Flex>

            <Text color="gray" size="2">
              Billed monthly
            </Text>
          </Flex>
          <Separator my="5" size="4" />

          <DataList.Root>
            <DataList.Item>
              <DataList.Label>
                <Text>Members</Text>
              </DataList.Label>
              <DataList.Value>
                <Flex justify="end" width="100%">
                  <Text>
                    {usedMembers} of {totalMembers}{' '}
                    {pluralize('member', totalMembers)} used
                  </Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>

            <DataList.Item>
              <DataList.Label>
                <Text>Projects</Text>
              </DataList.Label>
              <DataList.Value>
                <Flex justify="end" width="100%">
                  <Text size="2">
                    {usedProjects} of {totalProjects}{' '}
                    {pluralize('projects', totalMembers)} used
                  </Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>

            <DataList.Item>
              <DataList.Label>
                <Text>Next billing date</Text>
              </DataList.Label>
              <DataList.Value>
                <Flex justify="end" width="100%">
                  <Text>{billingDate}</Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>

            <DataList.Item>
              <DataList.Label>
                <Text>Recurring payment</Text>
              </DataList.Label>
              <DataList.Value>
                <Flex justify="end" width="100%">
                  <Text>${recurringPayment}</Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>

            <DataList.Item>
              <DataList.Label>
                <Flex gap="2" align="center">
                  <Text>Next payment</Text>{' '}
                  <Tooltip content="This amount may differ from your recurring payment due to prorated charges from recent changes to your subscription">
                    <IconButton size="1" variant="ghost">
                      <InfoCircledIcon />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </DataList.Label>
              <DataList.Value>
                <Flex justify="end" width="100%">
                  <Text>${nextPayment}</Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>
          </DataList.Root>
        </Box>
      </Card>
    </>
  );
}
