import React from 'react';
import * as yup from 'yup';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import { Grid } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { Checkbox } from 'src/components/Checkbox';
import { FIELD_TYPE } from 'src/constants/components.js';
import { TemplateSettingsForm } from './components/TemplateSettingsForm';
import * as TemplateTypes from 'src/types/Template';
import { nameSchema, labelSchema } from './utils/schema';
import { nameTooltip } from './utils/tooltipText';

const settingsValidationSchema = yup.object().shape({
  type: yup.string().oneOf([FIELD_TYPE.BOOLEAN]).required(),
  name: nameSchema,
  label: labelSchema,
});

export const getDefaultValues = () => ({
  type: FIELD_TYPE.BOOLEAN,
  name: '',
  label: '',
});

export const validate = (
  data: TemplateTypes.SettingsBooleanType,
  tree: TemplateTypes.TreeNode[]
) =>
  settingsValidationSchema.validate(data, { context: { templateTree: tree } });

export const getFieldValidationSchema = () => {
  const schema = yup.boolean().nullable();

  return schema;
};

type FieldProps = {
  data: TemplateTypes.SettingsBooleanType;
  id: string;
  methods: UseFormReturn<{ [key: string]: string }>;
};

export function Field({ data, methods, id }: FieldProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Checkbox
      mods={{ type: 'primary' }}
      errorMessage={errors[id]?.message}
      {...register(id)}
    >
      {data.label}
    </Checkbox>
  );
}

type SettingsProps = {
  methods: UseFormReturn<TemplateTypes.SettingsBooleanType>;
};
type ValidFieldNames = keyof TemplateTypes.SettingsBooleanType;

export function Settings({ methods }: SettingsProps) {
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Grid gap="4">
      <Input
        label="Label"
        errorMessage={errors.label?.message}
        required
        data-testid="boolean-field-label"
        autoFocus
        {...register<ValidFieldNames>('label')}
      />

      <Input
        label="Name"
        tooltip={nameTooltip}
        errorMessage={errors.name?.message}
        required
        data-testid="boolean-field-name"
        {...register<ValidFieldNames>('name')}
      />
    </Grid>
  );
}

type SettingsFormProps = {
  initialValues: TemplateTypes.SettingsBooleanType;
};

/* istanbul ignore next -- @preserve ;test with e2e */
export function SettingsForm({ initialValues }: SettingsFormProps) {
  // backend can send empty fields from other settings. The reason is that all fields setting should merged by backend.
  // to solve the issue, pick just fields which we need
  const values = _.pick(initialValues, Object.keys(getDefaultValues()));
  return (
    <TemplateSettingsForm
      initialValues={values}
      validationSchema={settingsValidationSchema}
    >
      {(methods: UseFormReturn<TemplateTypes.SettingsBooleanType>) => (
        <Settings methods={methods} />
      )}
    </TemplateSettingsForm>
  );
}
