import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAccessTokenExpired, isRefreshTokenExpired } from 'src/utils/token';
import { InstanceProvider } from 'src/providers/InstanceProvider';
import { UserProvider } from 'src/providers/UserProvider';
import { DataProvider } from 'src/providers/DataProvider';

type ProtectedRouteProps = {
  redirectPath?: string;
  children?: React.ReactNode;
};

export function ProtectedRoute({
  children,
  redirectPath,
}: ProtectedRouteProps) {
  const isSessionExpired = isAccessTokenExpired() && isRefreshTokenExpired();
  const { pathname } = useLocation();

  if (isSessionExpired) {
    return (
      <Navigate to={redirectPath ?? '/login'} state={{ from: { pathname } }} />
    );
  }

  return (
    <DataProvider>
      <InstanceProvider>
        <UserProvider>{children || <Outlet />}</UserProvider>
      </InstanceProvider>
    </DataProvider>
  );
}
