type Option = {
  id: string;
  label: string;
};

type GetFilteredOptionsArg = {
  value: string;
  options: Option[];
};

export const getFilteredOptions = ({
  value,
  options,
}: GetFilteredOptionsArg) => {
  if (!value) {
    return options || [];
  }
  const filtered = options?.filter((option) =>
    option.label.toLowerCase().includes(value.toLowerCase())
  );
  return filtered || [];
};
