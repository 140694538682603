import React from 'react';
import { PLANS } from 'src/constants/plans';
import { Subscription } from 'src/types/Subscription';
import { Box } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { useSubscriptionDowngrade } from 'src/hooks/api/useSubscriptionDowngrade';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { StarterPlanBasicCard } from './StarterPlanBasicCard';

type StarterPlanDowngradeProps = {
  workspaceId: string;
  subscription?: Subscription;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function StarterPlanDowngrade({
  workspaceId,
  onSuccessfulPayment,
  subscription,
}: StarterPlanDowngradeProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {
    mutate: downgradeSubscription,
    isMutating: isDowngrading,
    error: downgradeError,
  } = useSubscriptionDowngrade({
    onSuccess: () => {
      onSuccessfulPayment({
        plan: PLANS.STARTER,
        status: SUBSCRIPTION_STATUS.ACTIVE,
      });
      setIsDialogOpen(false);
    },
  });

  const handleDowngrade = () => {
    downgradeSubscription({ workspaceId });
  };

  return (
    <>
      <StarterPlanBasicCard
        subscription={subscription}
        onPlanSelect={() => setIsDialogOpen(true)}
      />

      <AlertDialog
        title="Downgrade to the Starter plan"
        onAction={handleDowngrade}
        onCancel={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        color="tomato"
        actionText="Yes, downgrade my plan"
        cancelText="No, keep my current plan"
        loading={isDowngrading}
      >
        {!!downgradeError && (
          <Box mb="4">
            <ErrorApi error={downgradeError} />
          </Box>
        )}
        You're about to downgrade to the Starter plan, which will reduce your
        subscription to $16/month per member + tax.
        <Box mt="2">
          This change will hide your instances, make everyone an admin, and
          prevent the use of custom roles. If you decide to upgrade again,
          you'll need to manually set all roles.
        </Box>
        <Box mt="2">Do you want to proceed?</Box>
      </AlertDialog>
    </>
  );
}
