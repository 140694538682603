import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';

export type ActivateInstanceArgs = {
  instanceId: number;
};

const activateInstance = (arg: ActivateInstanceArgs): Promise<unknown> => {
  return fetcher({
    url: `/instances/${arg.instanceId}/activate`,
    options: {
      method: 'POST',
    },
  });
};

export function useInstanceActivate(opt?: MutationOptions<unknown>) {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: activateInstance,
    meta: {
      name: 'Activate instance',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
