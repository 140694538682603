import React from 'react';
import { Combobox } from 'src/components/Combobox';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Box } from '@radix-ui/themes';
import { useItemsHeadersList } from 'src/hooks/api/useItemsHeadersList';
import { ComboboxValues } from 'src/components/ComboboxValues';
import { Control, FieldValues } from 'react-hook-form';
import { StringObject } from 'src/types/Common';
import { getFilteredOptions } from 'src/utils/getFilteredOptions';

type ControlType = Control<FieldValues, StringObject>;

type Option = {
  id: string;
  label: string;
};

type ItemsListComboboxProps = {
  control: ControlType;
  errorMessage?: string;
  templateId: string;
  id: string;
  required?: boolean;
  label: string;
};

export function ItemsListCombobox({
  control,
  errorMessage,
  templateId,
  id,
  required,
  label,
}: ItemsListComboboxProps) {
  const [query, setQuery] = React.useState('');
  const { data } = useItemsHeadersList({ templateId });

  const options = data?.items?.map((item) => ({
    id: item.id,
    label: item.internal_label,
  }));

  const removeItem = (id: string, value: Option[]) => {
    const filteredItems = value.filter((item) => item.id !== id);

    return filteredItems;
  };

  const getIds = (value: Option[] | null) => {
    if (!value) {
      return null;
    }
    const IDs = value.map((item) => item.id);
    return IDs.length ? IDs : null;
  };

  const transformIdsToOptions = (value: string[] | null) => {
    if (!value || !options) {
      return [];
    }

    const selectedOptions: Option[] = [];

    value.forEach((id) => {
      const foundOption = options.find((option) => option.id === id);
      if (foundOption) {
        selectedOptions.push(foundOption);
      }
    });

    return selectedOptions;
  };

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        const handleChange = (value: Option[] | null) => {
          const IDs = getIds(value);

          onChange(IDs);
        };
        return (
          <>
            <Combobox
              onChange={handleChange}
              onInputChange={setQuery}
              value={transformIdsToOptions(value)}
              inputValue={query}
              options={getFilteredOptions({
                value: query,
                options: options || [],
              })}
              label={label}
              placeholder="Search"
              required={required}
              multiple
              nullable
            />

            {!!options?.length && (
              <ComboboxValues
                selectedValues={transformIdsToOptions(value)}
                onRemove={(id) => {
                  const selectedOptions = transformIdsToOptions(value);
                  const filteredItems = removeItem(id, selectedOptions);
                  const IDs = getIds(filteredItems);
                  onChange(IDs);
                }}
              />
            )}

            {errorMessage && (
              <Box mt="2">
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
          </>
        );
      }}
      control={control}
      name={id}
    />
  );
}
