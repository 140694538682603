import React from 'react';
import { Page__Header, Page__Content } from 'src/components/Page';
import {
  Text,
  Flex,
  Box,
  Badge,
  Grid,
  Card,
  Button,
  Skeleton,
} from '@radix-ui/themes';
import {
  DashboardIcon,
  DownloadIcon,
  MagicWandIcon,
  MixerVerticalIcon,
  PlusIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { ProjectCreation } from 'src/components/ProjectCreation';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getProjectByInstanceId } from 'src/utils/getProjectByInstanceId';
import { hasProjects } from 'src/utils/hasProjects';
import { useUserContext } from 'src/providers/UserProvider';
import { canCreateProject } from './utils/canCreateProject';
import { ROLES_LIST, WORKSPACES_ROLES } from 'src/constants/workspaceRoles';
import { Callout } from 'src/components/Callout';
import { ErrorApi } from 'src/components/ErrorApi';
import { PERMISSIONS } from 'src/constants/permissions';
import { appRoutes } from 'src/utils/routePaths';
import { Access } from 'src/components/Access';
import { CardLink } from 'src/components/CardLink';
import { getInstanceById } from 'src/utils/getInstanceById';
import { INSTANCE_BADGE_COLOR } from 'src/constants/instance';
import { CopyCode } from 'src/components/CopyCode';
import { PLANS } from 'src/constants/plans';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { WorkspaceCreation } from 'src/components/WorkspaceCreation';

const DEFAULT_PAGE_TITLE = 'Dashboard';

export default function MainPage() {
  const { instanceId } = useInstanceContext();
  const { workspacesRoleMap } = useUserContext();
  const {
    data: workspacesData,
    error: workspacesError,
    isLoading: isWorkspacesLoading,
  } = useWorkspacesList();

  const activeProject = getProjectByInstanceId({
    workspaces: workspacesData?.workspaces || [],
    instanceId: instanceId,
  });

  const activeInstanceData = getInstanceById({
    workspaces: workspacesData?.workspaces || [],
    instanceId,
  });

  const rolesList = [...workspacesRoleMap.values()];
  const isMember =
    rolesList.length &&
    rolesList.every((role) => role === WORKSPACES_ROLES.MEMBER);

  if (isWorkspacesLoading) {
    return (
      <>
        <Page__Header title={DEFAULT_PAGE_TITLE} />

        <Page__Content>
          <CardLink
            isLoading={true}
            title="Quick start demo"
            to={appRoutes.settingsInstanceSync()}
            href="https://docs.lootfabriq.io/start-here/demo/"
            rel="noopener noreferrer"
            target="_blank"
            icon={<MagicWandIcon />}
          >
            <Skeleton loading={true}>
              Learn how to create your first template and item. Perfect for new
              users
            </Skeleton>
          </CardLink>
        </Page__Content>
      </>
    );
  }

  if (workspacesError) {
    return (
      <>
        <Page__Header title={DEFAULT_PAGE_TITLE} />

        <Page__Content>
          <ErrorApi error={workspacesError} />
        </Page__Content>
      </>
    );
  }

  const badgeColor = INSTANCE_BADGE_COLOR[activeInstanceData?.env || 'Dev'];
  const hasWorkspaces = !!workspacesData?.workspaces?.length;

  return (
    <>
      <Page__Header
        title={activeProject?.name || DEFAULT_PAGE_TITLE}
        dataTestId="dashboard-header"
        headingSuffix={
          <>{canCreateProject({ workspacesRoleMap }) && <ProjectCreation />}</>
        }
      />

      <Page__Content>
        {!!isMember && !hasProjects(workspacesData?.workspaces) && (
          <Box maxWidth="600px" mb="4">
            <Callout color="tomato">
              <Text>
                You haven&rsquo;t been added to&nbsp;any projects yet. Please
                contact your admin for access.
              </Text>
            </Callout>
          </Box>
        )}

        <Flex gap="4" wrap="wrap">
          {instanceId > 0 && (
            <Box maxWidth="390px" width="100%">
              <Card variant="surface" size="2">
                <Box>
                  <Flex align="center" gap="2">
                    <Flex align="center" gap="2">
                      <Text size="3" weight="medium">
                        Current instance
                      </Text>
                    </Flex>
                  </Flex>
                  <Box mt="2">
                    <Grid gap="2" align="center" columns="auto 1fr">
                      <Text>ID</Text>
                      <CopyCode
                        code={instanceId.toString()}
                        language={'JavaScript'}
                      />
                    </Grid>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}

          {!hasWorkspaces && (
            <Box maxWidth="390px" width="100%">
              <Card variant="surface" size="2">
                <Box>
                  <Flex align="center" gap="2">
                    <Flex align="center" gap="2">
                      <Text size="3" weight="medium">
                        Set up your workspace
                      </Text>
                    </Flex>
                  </Flex>
                  <Box mt="2">
                    <Text size="3" color="gray">
                      Start by creating a workspace to organize your work. Once
                      it's ready, you can begin your first project.
                    </Text>
                  </Box>

                  <Box mt="4">
                    <WorkspaceCreation
                      trigger={
                        <Button
                          variant="soft"
                          color="plum"
                          data-testid="create-project"
                        >
                          <PlusIcon /> Create workspace
                        </Button>
                      }
                    />
                  </Box>
                </Box>
              </Card>
            </Box>
          )}

          {hasWorkspaces && !isMember && !hasProjects(workspacesData?.workspaces) && (
            <Box maxWidth="390px" width="100%">
              <Card variant="surface" size="2">
                <Box>
                  <Flex align="center" gap="2">
                    <Text size="3" weight="medium">
                      Create your first project
                    </Text>
                  </Flex>
                  <Box mt="2">
                    <Text size="3" color="gray">
                      Start by creating your first project to begin working with
                      templates and items.
                    </Text>
                  </Box>

                  <Box mt="4">
                    <ProjectCreation />
                  </Box>
                </Box>
              </Card>
            </Box>
          )}

          {hasWorkspaces && (
            <CardLink
              title="Quick start demo"
              to={appRoutes.settingsInstanceSync()}
              href="https://docs.lootfabriq.io/start-here/demo/"
              rel="noopener noreferrer"
              target="_blank"
              icon={<MagicWandIcon />}
            >
              Learn how to create your first template and item. Perfect for new
              users
            </CardLink>
          )}

          <Access roles={ROLES_LIST} permissionsGroups={[[PERMISSIONS.EXPORT]]}>
            <CardLink
              title="Exported data"
              to={appRoutes.settingsExportedData()}
              icon={<DownloadIcon />}
              badge={
                <Badge color={badgeColor}>
                  {activeInstanceData?.env?.toLowerCase()}
                </Badge>
              }
            >
              View, download, and check the status of your exported JSON data
              files
            </CardLink>
          </Access>

          <Access roles={ROLES_LIST} permissionsGroups={[[PERMISSIONS.READ]]}>
            <CardLink
              title="Navigation setup"
              to={appRoutes.settingsNavigationSetup()}
              icon={<DashboardIcon />}
              badge={
                <Badge color={badgeColor}>
                  {activeInstanceData?.env?.toLowerCase()}
                </Badge>
              }
            >
              Organize your templates for better workflow management in complex
              projects
            </CardLink>
          </Access>

          <Access
            roles={ROLES_LIST}
            permissionsGroups={[
              [PERMISSIONS.INSTANCE_CHANGES],
              [PERMISSIONS.EXPORT],
            ]}
          >
            <AccessSubscription plan={[PLANS.TEAMS]}>
              <Access
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.INSTANCE_CHANGES]]}
              >
                <CardLink
                  title="Sync instances"
                  to={appRoutes.settingsInstanceSync()}
                  icon={<UpdateIcon />}
                  badge={
                    <Badge color={badgeColor}>
                      {activeInstanceData?.env?.toLowerCase()}
                    </Badge>
                  }
                >
                  Transfer data between development, staging, and production
                  environments
                </CardLink>
              </Access>
            </AccessSubscription>

            <Access
              roles={ROLES_LIST}
              permissionsGroups={[[PERMISSIONS.EXPORT]]}
            >
              <CardLink
                title="Export settings"
                to={appRoutes.settingsExport()}
                icon={<MixerVerticalIcon />}
              >
                Generate a token to securely download your JSON data via API
                access
              </CardLink>
            </Access>
          </Access>
        </Flex>
      </Page__Content>
    </>
  );
}
