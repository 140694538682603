import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex, Heading, Button } from '@radix-ui/themes';
import { PageAccent } from 'src/components/PageAccent';
import { Input } from 'src/components/Input';
import { ErrorApi } from 'src/components/ErrorApi';
import { ValidationList } from 'src/components/ValidationList';
import { usePasswordResetConfirmation } from 'src/hooks/api/usePasswordResetConfirmation';
import {
  getPasswordValidationSchema,
  PASSWORD_ERRORS,
} from 'src/utils/getPasswordValidationSchema';
import { appRoutes } from 'src/utils/routePaths';
import { useLogout } from 'src/hooks/api/useLogout';

const passwordValidationSchema = getPasswordValidationSchema();
const validationSchema = passwordValidationSchema.shape({
  hash: yup.string().required(),
  newPassword: yup
    .string()
    .required('Required field')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

type FormDataType = {
  password?: string;
  hash: string;
  newPassword: string;
};

export default function PasswordResetConfirmation() {
  const { mutate: logout } = useLogout();
  const navigate = useNavigate();
  const methods = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 250,
  });
  const {
    mutate: registrationConfirmation,
    error,
    isMutating,
  } = usePasswordResetConfirmation({
    onSuccess: () => {
      logout();
      navigate(appRoutes.login());
    },
  });
  const [searchParams] = useSearchParams();
  const {
    formState: { errors },
    register,
    handleSubmit,
    trigger,
  } = methods;

  React.useEffect(() => {
    const hash = searchParams.get('hash');
    if (hash && !methods.getValues('hash')) {
      methods.setValue('hash', hash);
    }
  }, [methods, searchParams]);

  React.useEffect(() => {
    trigger('password');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegistration: SubmitHandler<FormDataType> = async (
    data: FormDataType
  ) => {
    registrationConfirmation({
      password: data.password || '',
      hash: data.hash,
    });
  };

  const errorType = errors.password?.type || '';
  // don't show errors if includes in id checklist
  const passwordErrorMessage = Object.keys(PASSWORD_ERRORS).includes(errorType)
    ? ''
    : errors.password?.message;

  return (
    <PageAccent mods={{ type: 'primary' }}>
      <form onSubmit={handleSubmit(handleRegistration)}>
        <Box>
          <Heading mb="4" data-testid="new-password">
            Change your password
          </Heading>

          {!!error && (
            <Box my="4">
              <ErrorApi error={error} />
            </Box>
          )}

          <Box mb="2">
            <Input
              size="3"
              label="New password"
              type="password"
              data-testid="password-reset-new-password"
              errorMessage={passwordErrorMessage}
              required
              {...register('password')}
            />
          </Box>

          <Box mb="4">
            <ValidationList
              data={[
                {
                  isValid: !errors.password?.types?.min,
                  text: 'At least 8 characters',
                },
                {
                  isValid:
                    !errors.password?.types?.[PASSWORD_ERRORS.containsNumber],
                  text: 'Contains a number',
                },
                {
                  isValid:
                    !errors.password?.types?.[PASSWORD_ERRORS.lettersMix],
                  text: 'Use a mix of uppercase and lowercase letters',
                },
                {
                  isValid: !errors.password?.types?.[PASSWORD_ERRORS.noSpaces],
                  text: 'No spaces allowed',
                },
              ]}
            />
          </Box>

          <Box mb="5">
            <Input
              size="3"
              label="Confirm new password"
              type="password"
              errorMessage={errors.newPassword?.message}
              data-testid="password-reset-new-password-confirmation"
              required
              {...register('newPassword')}
            />
          </Box>
          <Flex justify="end">
            <Button
              variant="soft"
              color="plum"
              size="4"
              type="submit"
              style={{ width: '100%' }}
              loading={isMutating}
              data-testid="save-password"
            >
              Save new password
            </Button>
          </Flex>
        </Box>
      </form>
    </PageAccent>
  );
}
