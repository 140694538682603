import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { TemplateField } from 'src/types/Template';
import { ItemField } from 'src/types/Item';
import { StringObject } from 'src/types/Common';

type ExportTemplateArgs = {
  template_fields: TemplateField[];
  template_id: string;
  item_fields: Omit<ItemField, 'status'>[];
};

const exportTemplate = (
  data: ExportTemplateArgs,
  instanceId: number
): Promise<StringObject> => {
  return fetcher({
    url: `/editor/${instanceId}/example-export`,
    options: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  });
};

export function useTemplateExportJson(opt?: MutationOptions<StringObject>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: (data: ExportTemplateArgs) => exportTemplate(data, instanceId),
    meta: {
      name: 'Template export',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
    data,
  };
}
