import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import type { TreeNode } from 'src/types/Template';
import { getNodeKey } from 'src/utils/getNodeKey';
import { getDynamicGroupRowsMap } from './getDynamicGroupRowsMap';
import { getItemFieldsValuesMap } from './getItemFieldsValuesMap';
import { FIELD_TYPE } from 'src/constants/components';
import { buildInitialTreeChildren } from './buildInitialTreeChildren';
import { ResponseItemField } from 'src/types/Item';

type BuildInitialTreeArgs = {
  treeData: TreeNode[];
  dynamicGroupsChildrenStampMap: Map<string, TreeNode[]>;
  itemFields: ResponseItemField[];
};

export function buildInitialTree({
  treeData,
  dynamicGroupsChildrenStampMap,
  itemFields,
}: BuildInitialTreeArgs) {
  const itemFieldsMap = getItemFieldsValuesMap({ data: itemFields });
  const rowsAmountMapByGroupId = getDynamicGroupRowsMap({ data: itemFields });

  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: TreeNode }) => {
      if (node?.settings?.type === FIELD_TYPE.DYNAMIC_GROUP) {
        buildInitialTreeChildren({
          node,
          parentId: node.parentId || '',
          dynamicGroupsChildrenStampMap,
          rowsAmountMapByGroupId,
          parentGroupId: node.templateFieldId || '',
          parentGroupPath: null,
          itemFieldsMap: itemFieldsMap,
        });
      }

      return node;
    },
  });

  return updatedTree as TreeNode[];
}
