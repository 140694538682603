import { ApiWorkspacesListResponse, Instance } from 'src/types/Workspace';
import { PROJECT_STATUS } from 'src/constants/project';

export const getFirstAvailableProjectInstanceId = (
  workspacesData: ApiWorkspacesListResponse | undefined | null
): number | null => {
  if (!workspacesData?.workspaces) {
    return null;
  }

  for (const workspace of workspacesData.workspaces) {
    if (workspace.projects) {
      for (const project of workspace.projects) {
        if (project.status !== PROJECT_STATUS.REMOVED && project.instances) {
          const firstInstance = project.instances.find(
            (instance: Instance) => !!instance
          );
          if (firstInstance) {
            return firstInstance.id;
          }
        }
      }
    }
  }

  return null;
};
