import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { Navigate, useParams } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { getWorkspaceById } from 'src/utils/getWorkspaceById';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { ErrorApi } from 'src/components/ErrorApi';
import { Container } from '@radix-ui/themes';
import { MagicWandIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { RenameWorkspace } from './components/RenameWorkspace';
import { ProjectCreation } from 'src/components/ProjectCreation';
import { CardLink } from 'src/components/CardLink';

export function WorkspaceOverview() {
  const { data, isLoading, error } = useWorkspacesList();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  if (isLoading) {
    return (
      <>
        <Page__Header isLoading={true} />

        <Page__Content>
          <DocumentationCard />
        </Page__Content>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Page__Header title="Workspace overview" />

        <Page__Content>
          <ErrorApi error={error} />
        </Page__Content>
      </>
    );
  }

  if (!workspaceId || !data?.workspaces) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  const workspaceData = getWorkspaceById({
    workspaceId,
    workspaces: data?.workspaces,
  });

  if (!workspaceData) {
    return <Navigate to={appRoutes.notFound()} />;
  }

  return (
    <>
      <Page__Header
        title={`${workspaceData.name}`}
        headingSuffix={<ProjectCreation />}
      >
        <Flex gap="2" align="center">
          <RenameWorkspace
            workspaceName={workspaceData.name}
            workspaceId={workspaceId}
          />

          {/*<IconButton variant="soft" color="tomato" title="Delete workspace">*/}
          {/*  <TrashIcon />*/}
          {/*</IconButton>*/}
        </Flex>
      </Page__Header>

      <Page__Content>
        <Container size="1" display="initial" align="left">
          <DocumentationCard />
        </Container>
      </Page__Content>
    </>
  );
}

function DocumentationCard() {
  return (
    <CardLink
      title="Enhance your workspace knowledge"
      to={appRoutes.settingsInstanceSync()}
      href="https://docs.lootfabriq.io/guides/roles/"
      rel="noopener noreferrer"
      target="_blank"
      icon={<MagicWandIcon />}
    >
      Discover how to optimize your workflow by understanding workspaces and
      roles
    </CardLink>
  );
}
