import React from 'react';
import { Box, Flex, Spinner } from '@radix-ui/themes';
import { Callout } from 'src/components/Callout';
import { TURNSTILE_WIDGET_ID } from '../hooks/useTurnstile';
import './Challenge.css';

type ChallengeProps = {
  isChallenging: boolean;
  error: boolean;
};

export function Challenge({ isChallenging, error }: ChallengeProps) {
  return (
    <div className="challenge">
      <div className="challenge__captcha" id={TURNSTILE_WIDGET_ID}></div>

      {error && (
        <Box mb="3">
          <Callout color="tomato" hideIcon>
            Oops! We couldn’t confirm you're human. Please refresh the page and
            give it another try.
          </Callout>
        </Box>
      )}

      {isChallenging && (
        <Box mb="3">
          <Callout color="gold" hideIcon>
            <Flex as="span" align="center" justify="between">
              <Spinner mr="2" />
              Checking you are a human...
            </Flex>
          </Callout>
        </Box>
      )}
    </div>
  );
}
