import type { FileDropItem } from 'react-aria';
type FileOrFileDropItem = File | FileDropItem;

export async function convertToBase64(
  item: FileOrFileDropItem
): Promise<string> {
  if (item instanceof File) {
    return fileToBase64(item);
  } else {
    const file = await item.getFile();
    return fileToBase64(file);
  }
}

function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}
