import { fields } from 'src/fields';
import { TreeNode } from 'src/types/Template';
import { getFlatDataFromTemplateTree } from './getFlatDataFromTemplateTree';
import { validateTemplateChildren } from './validateTemplateChildren';

export const validateTemplate = async (templateTree: TreeNode[]) => {
  const flatTemplate = getFlatDataFromTemplateTree({ templateTree });
  const invalidChildrenMap = validateTemplateChildren({ tree: templateTree });
  const invalidFields = [];
  for (const field of flatTemplate) {
    const validateFn = fields[field.node.settings.type]?.validate;

    if (!validateFn) {
      return invalidFields;
    }
    try {
      // @ts-expect-error: fix me if you can
      await validateFn(field.node.settings, templateTree);
    } catch (err) {
      invalidFields.push(field);
    }

    if (invalidChildrenMap.has(field.node.id)) {
      invalidFields.push(field);
    }
  }

  return invalidFields;
};
