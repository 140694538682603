import { map as mapTree } from '@nosferatu500/react-sortable-tree';
import { TreeNode } from 'src/types/Template';
import { FIELD_TYPE } from 'src/constants/components';
import { getNodeKey } from 'src/utils/getNodeKey';

type RemoveDynamicGroupsChildrenFromTreeArgs = {
  treeData: TreeNode[];
  groupId: string;
  rowId: string;
};

export function removeDynamicGroupRow({
  treeData,
  groupId,
  rowId,
}: RemoveDynamicGroupsChildrenFromTreeArgs) {
  const updatedTree = mapTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }: { node: TreeNode }) => {
      if (
        (node.templateGroupId === groupId || node.id === groupId) &&
        node?.settings?.type === FIELD_TYPE.DYNAMIC_GROUP
      ) {
        const children =
          node.children?.filter?.((node) => {
            return node.id !== rowId;
          }) || [];

        const containsOnlyRows = children.every(
          (child) => child.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW
        );

        if (!containsOnlyRows) {
          return node;
        }

        children.forEach((child, index) => {
          const path = node.templateGroupPath
            ? [...node.templateGroupPath, index]
            : [index];

          updateGroupPath({ node: child, groupPath: path });
        });

        return {
          ...node,
          children,
        };
      }

      return node;
    },
  });

  return updatedTree as TreeNode[];
}

type UpdateGroupPathArgs = {
  node: TreeNode;
  groupPath: number[];
};

function updateGroupPath({ node, groupPath }: UpdateGroupPathArgs) {
  node.templateGroupPath = groupPath;

  node.children?.forEach((childNode, index) => {
    const isDynamicRow =
      childNode?.settings?.type === FIELD_TYPE.DYNAMIC_GROUP_ROW;

    let path = groupPath;

    if (isDynamicRow) {
      path = groupPath ? [...groupPath, index] : [index];
    }

    updateGroupPath({
      node: childNode,
      groupPath: path,
    });
  });
}
