import React, { Component } from 'react';
import b, { Mix, Mods } from 'bem-react-helper';
import {
  DropTarget,
  ConnectDropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd';
import { DRAGGABLE_CARD_NODE_TYPE } from '../ComponentsList';
import { TreeNode as TreeNodeType } from 'src/types/Template';
import { NodeData } from '@nosferatu500/react-sortable-tree';
import { Flex } from '@radix-ui/themes';
import { TrashIcon } from '@radix-ui/react-icons';

const trashAreaSpec = {
  // https://github.com/nosferatu500/react-sortable-tree/blob/a582ef764e70b16177adacd3f97b059e3b7cd56f/src/stories/advanced/drag-out-to-remove.tsx#L13
  // The endDrag handler on the tree source will use some of the properties of
  // the source, like node, treeIndex, and path to determine where it was before.
  // The treeId must be changed, or it interprets it as dropping within itself.
  drop: (
    props: { node: TreeNodeType },
    monitor: DropTargetMonitor<TreeNodeType>
  ) => {
    return {
      ...monitor.getItem(),
      treeId: 'trash',
    };
  },
};
type DraggedItemType = NodeData & { node: TreeNodeType; treeId: string };

const canDropItems = (monitor: DropTargetMonitor<DraggedItemType>) => {
  const draggedItem = monitor.getItem();
  // if item has treeId it means it dragged from tree, not from fields list
  if (draggedItem && !!draggedItem.treeId) {
    return monitor.canDrop();
  }
  return false;
};
const trashAreaCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor<DraggedItemType>
) => {
  const draggedItem = monitor.getItem();
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }) && canDropItems(monitor),
    isDragging: !!draggedItem?.treeId,
  };
};

type ExternalNodeProps = {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
  isDragging: boolean;
  mods?: Mods;
  mix?: Mix;
};

class trashAreaBaseComponent extends Component<ExternalNodeProps> {
  trashAreaRef: React.RefObject<HTMLDivElement>;

  constructor(props: ExternalNodeProps) {
    super(props);
    this.trashAreaRef = React.createRef();
  }

  componentDidUpdate(prevProps: ExternalNodeProps) {
    if (
      prevProps.isDragging &&
      !this.props.isDragging &&
      this.trashAreaRef.current
    ) {
      this.trashAreaRef.current.classList.add('trash-area_dragging-finished');
      setTimeout(() => {
        if (this.trashAreaRef.current) {
          this.trashAreaRef.current.classList.remove(
            'trash-area_dragging-finished'
          );
        }
      }, 5000);
    }
  }

  render() {
    const { connectDropTarget, isOver, isDragging, mods, mix } = this.props;

    return connectDropTarget(
      <div
        ref={this.trashAreaRef}
        className={b(
          'trash-area',
          { mods, mix },
          { over: isOver, dragging: isDragging }
        )}
      >
        <div>
          <Flex mb="2" align="center" justify="center">
            <TrashIcon width={20} height={20} />
          </Flex>
          Drop here to remove
        </div>
      </div>
    );
  }
}

export const TrashArea = DropTarget(
  DRAGGABLE_CARD_NODE_TYPE,
  trashAreaSpec,
  trashAreaCollect
)(trashAreaBaseComponent);
