import _, { isArray } from 'lodash';
import * as TemplateTypes from 'src/types/Template';
import { TEMPLATE_FIELD_STATUS } from 'src/constants/components';

type AddStatusesToFieldsArgs = {
  initialData: TemplateTypes.TemplateField[];
  currentData: TemplateTypes.TemplateField[];
};

export const addStatusesToTemplateFields = ({
  initialData,
  currentData,
}: AddStatusesToFieldsArgs) => {
  const initialDataMap = isArray(initialData) ? getMap(initialData) : new Map();
  const currentDataMap = isArray(initialData) ? getMap(currentData) : new Map();
  const fields: TemplateTypes.TemplateField[] = [];

  currentDataMap.forEach((field) => {
    const fieldValue = initialDataMap.get(field.id);
    if (!fieldValue) {
      fields.push({ ...field, status: TEMPLATE_FIELD_STATUS.ADDED });
      return;
    }

    if (!_.isEqual(field, fieldValue)) {
      fields.push({ ...field, status: TEMPLATE_FIELD_STATUS.UPDATED });
    }
  });

  initialDataMap.forEach((field) => {
    const fieldValue = currentDataMap.get(field.id);
    if (!fieldValue) {
      fields.push({ ...field, status: TEMPLATE_FIELD_STATUS.REMOVED });
    }
  });

  return fields;
};

export function getMap(
  data: TemplateTypes.TemplateField[]
): Map<TemplateTypes.TemplateField['id'], TemplateTypes.TemplateField> {
  const map = new Map();

  data.forEach((field) => {
    // remove status fields so it's easy to compare fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, ...fieldClone } = _.cloneDeep(field);
    map.set(fieldClone.id, fieldClone);
  });

  return map;
}
