import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const GET_REPORTS_DOWNLOAD = 'useExportDownloadByFileName';

type Response = {
  url: string;
};

type GetReportDownloadArgs = {
  instanceId: number;
  pathFromReport: string;
  fileName: string;
};

const getReportDownload = ({
  instanceId,
  pathFromReport,
  fileName,
}: GetReportDownloadArgs): Promise<Response> => {
  return fetcher({
    url: `/instances/${instanceId}/dictionary/${pathFromReport}/${fileName}`,
  });
};

type UseReportByIdArgs = {
  pathFromReport?: string;
  fileName?: string;
  options?: Omit<UseQueryOptions<Response | undefined>, 'queryKey' | 'queryFn'>;
};

export function useExportGetDownloadUrl({
  options,
  pathFromReport,
  fileName,
}: UseReportByIdArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isLoading, isError, refetch, isRefetching } = useQuery({
    queryKey: [GET_REPORTS_DOWNLOAD, instanceId, pathFromReport, fileName],
    queryFn: () =>
      pathFromReport && fileName
        ? getReportDownload({ instanceId, fileName, pathFromReport })
        : undefined,
    enabled: false,
    meta: {
      name: 'Report download',
    },
    ...options,
  });

  return {
    data,
    isLoading: isLoading || isRefetching,
    error: isError ? error : null,
    refetch,
  };
}
