import React from 'react';
import { Badge, Box, Heading, Flex, Text } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { ReportTable } from './ReportTable';
import { useExportReportById } from 'src/hooks/api/useExportReportById';
import { TableLoading } from 'src/components/TableLoading';

type ReportDetailsProps = {
  reportId: string;
};

export function ReportDetails({ reportId }: ReportDetailsProps) {
  const {
    data: reportData,
    isLoading: isReportDataLoading,
    error: reportDataError,
  } = useExportReportById({ reportId });

  if (isReportDataLoading) {
    return (
      <TableLoading
        rowsAmount={1}
        headers={['ID', 'Template ID', 'Started', 'Completed']}
      />
    );
  }

  const totalSteps = reportData?.report.total_steps || 0;
  const completedSteps = reportData?.report.completed_steps || 0;
  const progress = totalSteps ? (completedSteps / totalSteps) * 100 : 100;

  return (
    <>
      <Heading size="5" as="h2" mb="4">
        <Flex display="inline-flex" gap="4" align="center">
          Report data{' '}
          <Text color="gray" size="2">
            [{reportId}]
          </Text>
          {progress === 100 ? (
            <Badge color="grass">completed</Badge>
          ) : (
            <Badge color="orange">{Math.round(progress)}% completed</Badge>
          )}
        </Flex>
      </Heading>

      {reportDataError && (
        <Box>
          <ErrorApi error={reportDataError} />
        </Box>
      )}
      <ReportTable
        data={reportData?.report?.steps || []}
        path={reportData?.report.path || ''}
      />
    </>
  );
}
