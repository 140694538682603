import { ApiWorkspaceResponse, WorkspacesProject } from 'src/types/Workspace';

type getActiveProjectNameArgs = {
  workspaces?: ApiWorkspaceResponse[] | null;
  instanceId: number;
};

export const getWorkspaceByInstanceId = ({
  workspaces,
  instanceId,
}: getActiveProjectNameArgs) => {
  if (!workspaces) {
    return null;
  }
  for (const workspace of workspaces) {
    const project = getProject({
      projects: workspace?.projects || [],
      instanceId: instanceId,
    });

    if (project) {
      return workspace;
    }
  }

  return null;
};

type getProjectNameArgs = {
  projects: WorkspacesProject[];
  instanceId: number;
};

const getProject = ({ projects, instanceId }: getProjectNameArgs) => {
  const project = projects.find((project) => {
    const instances = project?.instances || [];
    const hasActiveInstance = instances.find(
      (instance) => instance.id === instanceId
    );

    if (hasActiveInstance) {
      return project;
    }
  });

  return project || null;
};
