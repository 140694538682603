import React, { useState } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { type VirtuosoHandle } from 'react-virtuoso';
import { SortableTreeWithoutDndContext as SortableTree } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { DRAGGABLE_CARD_NODE_TYPE } from '../ComponentsList';
import { FIELD_TYPE } from 'src/constants/components';
import { TreeNode } from '../TreeNode';
import type {
  TemplateField,
  TreeNode as TreeNodeType,
} from 'src/types/Template';
import { getFlatDataFromTemplateTree } from 'src/utils/getFlatDataFromTemplateTree';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { SWAP_DIRECTION, swapChildren } from './utils/swapChildren';
import { canNodeHaveChildren } from 'src/utils/canNodeHaveChildren';
import { validateTemplate } from 'src/utils/validateTemplate';
import { invalidNodesToMap } from 'src/utils/invalidNodesToMap';
import { updateScrollIndicatorPosition } from 'src/utils/updateScrollIndicatorPosition';

export type Color = 'grass' | 'yellow' | 'lime' | 'sky' | 'gray';
const colors: Color[] = ['grass', 'yellow', 'lime', 'sky'];

type NodeType = TemplateField & { isInvalid?: boolean; color: Color };

export function TemplateTree() {
  const {
    templateTree,
    setTemplateTree,
    templateTreeActiveField,
    setTemplateTreeActiveField,
    templateTreeInvalidNodes,
    setTemplateTreeInvalidNodes,
    isFormSubmitted,
    setIsFormSubmitted,
  } = useTemplateTreeContext();
  const virtuosoRef = React.useRef<VirtuosoHandle | null>(null);
  const [templateDynamicGroups, setTemplateDynamicGroups] = useState(new Map());

  React.useLayoutEffect(() => {
    const scrollerElement: HTMLDivElement | null = document.querySelector(
      '[data-virtuoso-scroller="true"]'
    );

    if (!scrollerElement) {
      return;
    }

    const handleScroll = () => {
      updateScrollIndicatorPosition(scrollerElement);
    };

    handleScroll();
    scrollerElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollerElement.removeEventListener('scroll', handleScroll);
    };
  }, [templateTree]);

  React.useEffect(() => {
    const virtuosoRefCurrent = virtuosoRef.current;
    if (
      templateTreeInvalidNodes.size &&
      virtuosoRefCurrent &&
      isFormSubmitted
    ) {
      // scroll to first invalid node on template submit
      const flatTemplate = getFlatDataFromTemplateTree({ templateTree });
      const [firstInvalidNodeId] = templateTreeInvalidNodes.keys();
      const firstInvalidNodeIdIndex = flatTemplate.findIndex((item) => {
        return item.node.id === firstInvalidNodeId;
      });
      if (virtuosoRefCurrent) {
        virtuosoRefCurrent.scrollToIndex({
          align: 'center',
          behavior: 'smooth',
          index: firstInvalidNodeIdIndex,
        });
      }
    }

    if (isFormSubmitted) {
      setIsFormSubmitted(false);
    }
  }, [
    setTemplateTree,
    templateTree,
    templateTreeInvalidNodes,
    isFormSubmitted,
    setIsFormSubmitted,
  ]);

  const hasDynamicGroupAncestors = (path: number[], type: string) => {
    let amountOfDynamicGroupAncestors = 0;
    path.forEach((item) => {
      if (templateDynamicGroups.has(item)) {
        amountOfDynamicGroupAncestors = amountOfDynamicGroupAncestors + 1;
      }
    });

    const isDynamicGroupNode = type === FIELD_TYPE.DYNAMIC_GROUP;

    if (isDynamicGroupNode) {
      return amountOfDynamicGroupAncestors > 1;
    }

    return !!amountOfDynamicGroupAncestors;
  };

  const onMoveUpNode = (id: string, parentId: string) => {
    const updatedTree = swapChildren({
      id,
      parentId,
      direction: SWAP_DIRECTION.UP as 'up' | 'down',
      treeData: templateTree,
    });

    setTemplateTree(updatedTree);
  };

  const onMoveDownNode = (id: string, parentId: string) => {
    const updatedTree = swapChildren({
      id,
      parentId,
      direction: SWAP_DIRECTION.DOWN as 'up' | 'down',
      treeData: templateTree,
    });

    setTemplateTree(updatedTree);
  };

  const onMoveNode = async ({ treeData }: { treeData: TreeNodeType[] }) => {
    const invalidFields = await validateTemplate(treeData);
    const invalidFieldsMap = invalidNodesToMap({ data: invalidFields });
    setTemplateTreeInvalidNodes(invalidFieldsMap);
  };

  const canDrag = ({ node, path }: { node: TreeNodeType; path: number[] }) => {
    return !hasDynamicGroupAncestors(path, node.settings.type);
  };

  return (
    <div className="template-tree">
      <SortableTree
        treeData={templateTree}
        virtuosoRef={virtuosoRef}
        onChange={(data) => setTemplateTree(data)}
        dndType={DRAGGABLE_CARD_NODE_TYPE}
        canDrag={canDrag}
        canNodeHaveChildren={canNodeHaveChildren}
        onMoveNode={onMoveNode}
        getNodeKey={({ node }) => {
          if (!node.id) {
            node.id = uuidv4();
          }
          return node.id;
        }}
        generateNodeProps={(node) => ({
          onClick: () => {
            const data: NodeType = node.node;
            const isSelected = templateTreeActiveField?.node?.id === data.id;
            const isStateChanged =
              templateTreeActiveField?.node?.isInvalid === data.isInvalid;
            if (isSelected && !isStateChanged) {
              return;
            }
            setTemplateTreeActiveField(node);
          },
          title: () => {
            const data: NodeType = node.node;
            // @ts-expect-error: label and name can be undefined for FIELD_TYPE.DYNAMIC_GROUP which is fine
            const { type, label, name, ...restSettings } = data.settings;

            if (
              type === FIELD_TYPE.GROUP ||
              type === FIELD_TYPE.DYNAMIC_GROUP
            ) {
              const modulo = node.path.length % colors.length;
              data.color = colors[modulo];
            } else {
              data.color = 'gray';
            }

            if (!data.id) {
              data.id = uuidv4();
            }

            const isSelected = templateTreeActiveField?.node?.id === data.id;

            if (!data.isInvalid && templateTreeInvalidNodes.has(data.id)) {
              data.isInvalid = true;
            }

            if (data.isInvalid && !templateTreeInvalidNodes.has(data.id)) {
              data.isInvalid = false;
            }

            // save all dynamic groups ids to templateDynamicGroups
            if (
              data.settings.type === FIELD_TYPE.DYNAMIC_GROUP &&
              !templateDynamicGroups.has(data.id)
            ) {
              const newTemplateDynamicGroups = _.cloneDeep(
                templateDynamicGroups
              );
              newTemplateDynamicGroups.set(data.id, data);
              // Here is a problem as react-sortable-tree still on classes
              // https://github.com/frontend-collective/react-sortable-tree/issues/687#issuecomment-676919628
              setTimeout(() => {
                setTemplateDynamicGroups(newTemplateDynamicGroups);
              }, 0);
            }

            const parentId = node.path[node.path.length - 2];

            return (
              <TreeNode
                mods={{
                  type: data.color,
                  invalid: !!data.isInvalid,
                  selected: isSelected,
                }}
                name={name}
                reorder={hasDynamicGroupAncestors(
                  node.path,
                  data.settings.type
                )}
                type={type}
                label={label}
                meta={restSettings}
                color={data.color}
                id={data.id}
                parentId={parentId ? parentId.toString() : ''}
                onMoveUp={onMoveUpNode}
                onMoveDown={onMoveDownNode}
              />
            );
          },
        })}
      />
    </div>
  );
}
