import React from 'react';
import {
  Dialog,
  Flex,
  IconButton,
  Card,
  Grid,
  DataList,
  Text,
  Separator,
  Skeleton,
} from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import pluralize from 'pluralize';

type DialogReportProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  openCheckout: () => void;
  tax?: number;
  total?: number;
  numberOfMembers?: number;
  subtotal?: number;
  planName?: string;
  isLoading: boolean;
};

export function CheckoutDialog({
  isOpen,
  onOpenChange,
  openCheckout,
  tax,
  total,
  numberOfMembers,
  subtotal,
  planName,
  isLoading,
}: DialogReportProps) {
  React.useEffect(() => {
    if (isOpen) {
      openCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        maxWidth="1000px"
        // disable close outside to add ability to interact with toast
        onInteractOutside={(e) => e.preventDefault()}
      >
        <Flex gap="3" align="center" justify="between">
          <Dialog.Title mb="0">Checkout</Dialog.Title>

          <Dialog.Close>
            <IconButton variant="soft" color="gray" title="Close checkout">
              <Cross1Icon />
            </IconButton>
          </Dialog.Close>
        </Flex>

        <Grid
          columns={{
            initial: 'auto',
            sm: '1fr 280px',
            md: '1fr 360px',
          }}
          gap="4"
          mt="2"
        >
          <Card variant="surface" size="3">
            <div className="checkout-container"></div>
          </Card>

          <div>
            <Card variant="surface" size="3">
              <Flex justify="between" align="end">
                <Flex direction="column">
                  <Skeleton loading={isLoading}>
                    <Text weight="medium">{planName} plan</Text>
                  </Skeleton>
                  <Text mt="2" color="gray" size="2">
                    Billed monthly
                  </Text>
                </Flex>

                <Skeleton loading={isLoading}>
                  <Text color="gray" size="2">
                    {numberOfMembers} {pluralize('member', numberOfMembers)}
                  </Text>
                </Skeleton>
              </Flex>
              <Separator my="3" size="4" />

              <DataList.Root>
                <DataList.Item>
                  <DataList.Label>
                    <Text>Subtotal</Text>
                  </DataList.Label>
                  <DataList.Value>
                    <Flex justify="end" width="100%">
                      <Skeleton loading={isLoading}>
                        <Text>${subtotal}</Text>
                      </Skeleton>
                    </Flex>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>
                    <Text>Taxes</Text>
                  </DataList.Label>
                  <DataList.Value>
                    <Flex justify="end" width="100%">
                      <Skeleton loading={isLoading}>
                        <Text>${tax}</Text>
                      </Skeleton>
                    </Flex>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>
                    <Text weight="bold">Pay today</Text>
                  </DataList.Label>
                  <DataList.Value>
                    <Flex justify="end" width="100%">
                      <Skeleton loading={isLoading}>
                        <Text weight="bold">${total}</Text>
                      </Skeleton>
                    </Flex>
                  </DataList.Value>
                </DataList.Item>
              </DataList.Root>
            </Card>
          </div>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}
