import {
  PERMISSION_STATUS,
  PERMISSIONS,
  PermissionsKeys,
  PermissionType,
} from 'src/constants/permissions';
import { FormValues } from '../types/FormValues';

export function getPermissionsFromNewForm(formData: FormValues) {
  const permissions: PermissionType[] = [];

  Object.entries(formData.permissions).forEach(([key, checked]) => {
    if (checked) {
      permissions.push(PERMISSIONS[key as PermissionsKeys]);
    }
  });

  const addedPermissions = permissions.map((permissionId) => {
    return {
      permission_id: permissionId,
      status: PERMISSION_STATUS.CREATE,
    };
  });

  return addedPermissions;
}
