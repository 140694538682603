import { useMutation } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import type { ItemField } from 'src/types/Item';
import type { MutationOptions } from 'src/types/Api';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type CreateItemArgs = {
  id?: string;
  template_id: string;
  internal_label: string;
  fields: ItemField[];
  instanceId: number;
};

const createItem = (arg: CreateItemArgs): Promise<unknown> => {
  return fetcher({
    url: `/editor/${arg.instanceId}/items`,
    options: {
      method: 'POST',
      body: JSON.stringify(arg),
    },
  });
};

export function useCreateItem(opt?: MutationOptions<unknown>) {
  const { instanceId } = useInstanceContext();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (args: Omit<CreateItemArgs, 'instanceId'>) =>
      createItem({ ...args, instanceId }),
    meta: {
      name: 'Create item',
    },
    ...opt,
  });

  return {
    mutate,
    isMutating: isPending,
    error: isError ? error : null,
  };
}
