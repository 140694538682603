import { SubscriptionState } from './getSubscriptionState';
import { PLANS, PlanType } from 'src/constants/plans';

type GetButtonTextArgs = {
  subscriptionState: SubscriptionState;
  isPlanSelected: boolean;
  activePlan?: PlanType;
};

export const getPlanCardButtonText = ({
  subscriptionState,
  isPlanSelected,
  activePlan,
}: GetButtonTextArgs) => {
  if (subscriptionState.trial) {
    return 'Pay now';
  }

  if (subscriptionState.active) {
    if (isPlanSelected) {
      return 'Current plan';
    }
    if (activePlan === PLANS.STARTER) {
      return 'Upgrade now';
    } else {
      return 'Downgrade now';
    }
  }

  if (subscriptionState.cancelRequested && isPlanSelected) {
    return 'Current plan';
  }

  if (subscriptionState.canceled) {
    return 'Select plan';
  }

  if (subscriptionState.pastDue) {
    return isPlanSelected ? 'Current plan' : 'Upgrade now';
  }

  return 'Select plan';
};
