import { WorkspaceRolesType } from 'src/constants/workspaceRoles';
import { AccountWorkspaces } from 'src/types/Account';

export type WorkspacesRolesMap = Map<number, WorkspaceRolesType>;

type GetWorkspacesRolesMapArgs = {
  workspaces: AccountWorkspaces['workspaces'] | null;
};

export function getWorkspacesRolesMap({
  workspaces,
}: GetWorkspacesRolesMapArgs) {
  const workspaceRolesMap: WorkspacesRolesMap = new Map();

  if (workspaces) {
    workspaces.forEach((workspace) => {
      workspaceRolesMap.set(workspace.id, workspace.workspace_role_id);
    });
  }

  return workspaceRolesMap;
}
