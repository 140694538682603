import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { InstanceValue } from 'src/types/Workspace';

const ACCOUNT_ROLES_KEY = 'useProjectMemberRoles';

export type MemberRoleForInstance = {
  instance_id: number;
  env: InstanceValue;
  roles: number[] | null;
};

type ResponseType = {
  instances: MemberRoleForInstance[] | null;
  user: {
    name: string;
    email: string;
  };
};

export const getRoles = (
  projectID: number,
  memberID: number
): Promise<ResponseType> => {
  return fetcher({ url: `/projects/${projectID}/accounts/${memberID}/roles` });
};

type UseProjectRolesArgs = {
  projectId: number | undefined;
  memberId: number | undefined;
  options?: Omit<
    UseQueryOptions<ResponseType | undefined>,
    'queryKey' | 'queryFn'
  >;
};

export function useProjectMemberRoles({
  projectId,
  memberId,
  options,
}: UseProjectRolesArgs) {
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: [ACCOUNT_ROLES_KEY, projectId, memberId],
    queryFn: () =>
      projectId && memberId ? getRoles(projectId, memberId) : undefined,
    enabled: !!projectId && !!memberId,
    meta: {
      name: 'Member roles',
    },
    ...options,
  });

  return {
    data,
    isLoading,
    error: isError ? error : null,
    refetch,
  };
}
