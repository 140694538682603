import React from 'react';
import { Box, TextField, IconButton, Flex, Text } from '@radix-ui/themes';
import {
  CopyIcon,
  EyeOpenIcon,
  EyeClosedIcon,
  CheckIcon,
} from '@radix-ui/react-icons';
import { useApiToken } from 'src/hooks/api/useApiToken';
import { useCopyToClipboard, useInterval } from 'usehooks-ts';
import { Toast } from 'src/components/Toast';
import * as Label from '@radix-ui/react-label';
import { RegenerateToken } from './RegenerateToken';

export function Token() {
  const { data } = useApiToken();
  const [isTokenVisible, setIsTokenVisible] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => {
    if (isCopied) {
      return;
    }

    copy(text)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        if (typeof error === 'object') {
          Toast({
            text: `Failed to copy: ${JSON.stringify(error)}`,
            variant: 'error',
          });
        } else {
          Toast({
            text: `Failed to copy: ${error}`,
            variant: 'error',
          });
        }
      });
  };

  useInterval(
    () => {
      setIsCopied(false);
    },
    isCopied ? 2000 : null
  );

  const Icon = isCopied ? CheckIcon : CopyIcon;

  return (
    <Box p="1" maxWidth="600px">
      <Label.Root htmlFor="apiToken">
        <Text>API Token</Text>
      </Label.Root>

      <TextField.Root
        mt="2"
        id="apiToken"
        variant="soft"
        size="3"
        readOnly
        value={data?.token}
        type={isTokenVisible ? 'text' : 'password'}
      >
        <TextField.Slot pr="3" side="right">
          <Flex gap="4">
            <RegenerateToken />
            <IconButton
              size="2"
              variant="ghost"
              color="gold"
              onClick={() => setIsTokenVisible(!isTokenVisible)}
            >
              {isTokenVisible ? (
                <EyeOpenIcon height="16" width="16" />
              ) : (
                <EyeClosedIcon height="16" width="16" />
              )}
            </IconButton>

            <IconButton
              size="2"
              variant="ghost"
              color="gold"
              onClick={() => handleCopy(data?.token || '')}
            >
              <Icon height="16" width="16" />
            </IconButton>
          </Flex>
        </TextField.Slot>
      </TextField.Root>
    </Box>
  );
}
